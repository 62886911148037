import { StyledButton } from "@shared/components";
import { Protocol } from "@shared/models";
import React from "react";
import classnames from "classnames";

import "./index.scss";

interface ProtocolGuidePlayHeaderProps {
  onPreviousStepClick: () => void;
  onStopClick: () => void;
  onCompleteClick: () => void;
  isCompleted: boolean;
  isMobile: boolean;
  isFirstStep: boolean;
  protocol: Protocol | null;
}

const ProtocolGuidePlayHeader = (props: ProtocolGuidePlayHeaderProps) => {
  const {
    protocol,
    onPreviousStepClick,
    isCompleted,
    onStopClick,
    onCompleteClick,
    isMobile,
    isFirstStep,
  } = props;

  return (
    <div
      className={classnames("protocol-guide-play-header", {
        mobile: isMobile,
      })}
    >
      <div className="protocol-guide-play-title">{protocol ? protocol.name : ""}</div>
      {!isMobile ? (
        <div className="protocol-guide-play-actions">
          {!isFirstStep ? (
            <StyledButton
              className="previous"
              label="Previous Step"
              bordered={true}
              onClick={() => onPreviousStepClick()}
            />
          ) : null}
          {isCompleted ? (
            <StyledButton
              className="complete"
              label={"Complete"}
              bordered={true}
              color="accent"
              textColor="red"
              onClick={() => onCompleteClick()}
            />
          ) : (
            <StyledButton
              className="stop"
              label={"Stop"}
              bordered={true}
              color="accent"
              textColor="red"
              onClick={() => onStopClick()}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProtocolGuidePlayHeader;
