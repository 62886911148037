import React, { useState, useCallback, useMemo } from "react";
import "./index.scss";
import usePWA from "react-pwa-install-prompt";
import browser from "browser-detect";
import { useWindowDimensions } from "@shared/hooks";

import { Modal } from "../Modal";
import { InstallFromSafari } from "./components";
import { InstallFromNotSupportedIOSBrowser } from "./components/InstallFromNotSupportedIOSBrowser";

const checkIOS = () => {
  const os = browser().os;
  return os ? os.toLowerCase().includes("os x") : false;
};

const checkSafari = () => {
  return browser().name === `safari`;
};

function InstallAppBtn() {
  const { promptInstall, isInstallPromptSupported, isStandalone } = usePWA();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { isDesktop } = useWindowDimensions();

  const isIOS = useMemo(() => checkIOS(), []);
  const isSafari = useMemo(() => checkSafari(), []);

  const isInstallSupported = useMemo(() => {
    if (isDesktop || isStandalone) return false;

    return isInstallPromptSupported || isIOS;
  }, [isDesktop, isInstallPromptSupported, isStandalone, isIOS]);

  const modalInstruction = useMemo(() => {
    if (isIOS && !isSafari) {
      return (
        <InstallFromNotSupportedIOSBrowser onClose={() => setIsModalOpened((prev) => !prev)} />
      );
    }

    if (isIOS && isSafari) {
      return <InstallFromSafari onClose={() => setIsModalOpened((prev) => !prev)} />;
    }

    return null;
  }, [setIsModalOpened, isIOS, isSafari]);

  const onClickInstall = useCallback(() => {
    if (isIOS) {
      setIsModalOpened((prev) => !prev);
      return;
    }

    promptInstall();
  }, [setIsModalOpened, promptInstall, isIOS]);

  return isInstallSupported ? (
    <>
      <div className="install-app" onClick={onClickInstall}>
        Install App
      </div>
      <Modal
        className="safari-modal"
        isShowing={isModalOpened}
        onClose={() => setIsModalOpened((prev) => !prev)}
        isSmall={true}
        showCloseIcon={false}
        headingFontSize="18px"
        heading="Install Viewfinder Pathways"
      >
        {modalInstruction}
      </Modal>
    </>
  ) : null;
}

export default InstallAppBtn;
