import styled from "styled-components";

import styles from "../../../styles/variables.scss";

interface IDivider {
  marginTop?: string;
  marginBottom?: string;
}

export default styled.div`
  width: 100%;
  border-top: 1px solid ${styles.borderColor};
  margin-top: ${(props: IDivider) => props.marginTop || "20px"};
  margin-bottom: ${(props: IDivider) => props.marginBottom || "0px"};
`;
