import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { useWindowDimensions } from "@shared/hooks";
import { scrollToTop, getFirstNodeByClassName } from "@shared/utils";

const ScrollBtn = () => {
  const [show, setStatus] = useState(false);
  const { isMobileVersion } = useWindowDimensions();
  const doc = getFirstNodeByClassName("app-content-wrapper");

  const handleScroll = useCallback(
    () => setStatus(!!doc.scrollTop && doc.scrollTop > 195 && isMobileVersion),
    [isMobileVersion, doc],
  );

  useEffect(() => {
    doc.addEventListener("scroll", handleScroll);

    return () => doc.removeEventListener("scroll", handleScroll);
  }, [doc, isMobileVersion, handleScroll]);

  return show ? (
    <div
      className="scroll-into-view-btn"
      onClick={() =>
        scrollToTop({
          top: 0,
          behavior: "smooth",
        })
      }
    >
      <img src="/icons/general/scroll-arrow.svg" alt="Scroll into view" />
    </div>
  ) : null;
};

export default ScrollBtn;
