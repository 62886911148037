import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FIND_HTML_TAGS } from "@shared/constants";

import { Slide } from "../";

interface MarketingSlidePageProps extends Slide {
  isShowing: boolean;
  goBack: () => void;
}

const MarketingSlidePage = ({
  goBack,
  isShowing,
  title,
  description,
  content,
}: MarketingSlidePageProps) => {
  const rootEl = document.getElementById("root");

  useEffect(() => {
    if (rootEl && isShowing) rootEl.style.position = "fixed";
  }, [isShowing, rootEl]);

  return isShowing
    ? ReactDOM.createPortal(
        <div className="marketing-slide-content">
          <div
            className="back-to-slide"
            onClick={() => {
              goBack();
              if (rootEl) rootEl.style.position = "unset";
            }}
          >
            Back
          </div>
          <div className="slide-content-body">
            <h2 className="title">
              {(title as string).replace(new RegExp(FIND_HTML_TAGS, "g"), "")}
            </h2>
            <div className="text">{description}</div>
            <div className="horizontal-divider" />
            {content}
          </div>
        </div>,
        document.body,
      )
    : null;
};

export default MarketingSlidePage;
