import React from "react";

import { CardFooter } from "../";
import "./index.scss";

const ApplicationOfAlgorithms = () => {
  return (
    <div className="application-of-algorithms">
      <p className="content">
        The following decision tree is provided to help you select the most appropriate
        perioperative goal-directed therapy (PGDT) protocol for your patient. When choosing a
        protocol, the clinician should consider method of ventilation as well as other patient
        variables (positioning, minimally-invasive surgery, etc.).
      </p>
      <br />
      <div className="image-container">
        <img src={`/images/slides/application-of-algorithms-1.png`} alt="Viewfinder pathways" />
      </div>
      <CardFooter>
        <p>* At risk due to comorbidities or the surgical procedure itself.</p>
        <p>
          † Factors that may impact reliability of SVV: Spontaneous breathing, tidal volume &lt;8
          ml/kg, open chest, cardiac arrhythmias, right ventricular failure and abdominal pressure.
        </p>
        <p>
          Abbreviations: BP: Blood Pressure; CVP: Central Venous Pressure; HR: Heart Rate; SV:
          Stroke Volume; SVV: Stroke Volume Variation.
        </p>
      </CardFooter>
      <div className="card-title">Limits of Dynamic Indices (SVV, PPV, PVI)</div>
      <div className="image-container second-image">
        <img src={`/images/slides/application-of-algorithms-2.png`} alt="Viewfinder pathways" />
      </div>
      <CardFooter>
        <p>
          Monnet, X. et al. Prediction of fluid responsiveness: An update. Annals of Intensive Care
          2016 doi: 10.1186/s13613-016-0216-7
        </p>
      </CardFooter>
    </div>
  );
};

export default ApplicationOfAlgorithms;
