import React from "react";
import classnames from "classnames";
import styled, { css } from "styled-components";

import styles from ".././../../../styles/variables.scss";

interface StyledButtonProps {
  disabled?: boolean;
  label?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
  className?: string;
  type?: "button" | "submit";
}

type Color = "accent" | "black" | "white" | "disabled" | "red" | "grey" | "darkgrey";

const colorsMap = {
  accent: styles.accentColor,
  black: styles.dark,
  white: styles.white,
  disabled: styles.disabledButtonColor,
  red: styles.red,
  grey: styles.grey,
  darkgrey: "#3C5154",
};

interface IButton {
  bordered?: boolean;
  filled?: boolean;
  color?: Color;
  textColor?: Color;
  hideLabelMobile?: boolean;
  hideTablet?: boolean;
  disabled?: boolean;
  height?: number;
  width?: number;
  minWidth?: number;
  background?: string;
  disabledTextColor?: Color;
  reverse?: boolean;
  iconMarginRight?: string;
  tabletWidth?: string;
  iconWidth?: string;
}

function getColorValue(color?: Color, defaultColor?: string) {
  return color ? colorsMap[color] : defaultColor;
}

const borderedButtonStyle = css`
  padding: 5px 25px;
  border: 2px solid
    ${(props: IButton) =>
      getColorValue(props.disabled ? "disabled" : props.color, styles.mainTextColor)};
  border-radius: 10px;
  @media (max-width: ${(props: IButton) => props.tabletWidth || styles.tablet}) {
    padding: 5px 18px;
  }
`;

const filledButtonStyle = css`
  padding: 5px 30px;
  border-radius: 10px;
  background-color: ${(props: IButton) => getColorValue(props.color, "transparent")};
`;

const borderedIconStyle = css`
  background-position: 5px;
`;

const hideTabletCss = css`
  @media (max-width: ${(props: IButton) => props.tabletWidth || styles.tablet}) {
    display: none;
  }
`;

const Button = styled.button`
  height: ${({ height = 40 }) => height}px;
  width: ${({ width }) => (width ? width + "px" : "fit-content")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth + "px" : "fit-content")};
  display: flex;
  align-items: center;
  margin-top: 0;
  justify-content: center;
  flex-direction: ${({ reverse = false }) => (reverse ? "row-reverse" : "row")};
  color: ${(props: IButton) =>
    getColorValue(
      props.disabled ? props.disabledTextColor || "disabled" : props.textColor,
      styles.mainTextColor,
    )};
  font-weight: bold;
  font-size: ${styles.fontSizeNormal};
  letter-spacing: -0.3px;
  background: ${({ background = "none" }) => background};
  border: none;
  cursor: pointer;
  ${(props: IButton) => props.bordered && borderedButtonStyle}
  ${(props: IButton) => props.filled && filledButtonStyle}
  ${(props: IButton) => props.hideTablet && hideTabletCss}
`;

export const IconButton = styled.div`
  width: ${(props: IButton) => props.iconWidth || "20px"};
  height: 20px;
  margin-right: ${(props: IButton) => props.iconMarginRight || "10px"};
  background-position: right;
  ${(props: IButton) => props.bordered && borderedIconStyle}
`;

const Label = styled.div`
  font-family: ${styles.globalFontFamily};
  line-height: 20px;
  ${(props: IButton) => props.hideLabelMobile && hideTabletCss}
`;

function StyledButton(props: StyledButtonProps & IButton) {
  const { label, icon, onClick, className, ...btnProps } = props;
  return (
    <Button
      className={classnames("styled-button", className)}
      disabled={btnProps.disabled}
      onClick={(e) => !btnProps.disabled && onClick && onClick(e)}
      {...btnProps}
    >
      {icon && (
        <IconButton
          className={`icon icon-button ${icon} ${btnProps.disabled ? "disabled" : ""}`}
          {...btnProps}
        />
      )}
      {label && (
        <Label className="styled-button-label" {...btnProps}>
          {label}
        </Label>
      )}
    </Button>
  );
}

export default StyledButton;
