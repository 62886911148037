import { FormikErrors, FormikProps } from "formik";
import * as Yup from "yup";
import { RefObject } from "react";
import { pdfjs } from "react-pdf";
import Cookie from "js-cookie";
import { ACKNOWLEDGE_EXPIRE_IN_DAYS, DEMO_POPUP_EXPIRE_IN_DAYS } from "@shared/constants";
import { OptionTypeBase, ValueType } from "react-select";

const ACKNOWLEDGE_COOKIE_NAME = "acknowledge";
const GUIDE_DEMO_COOKIE_NAME = "hide_guide_demo";

export const getUSFormattedDate = (date: Date | string | undefined | null) => {
  return date
    ? new Date(date).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" })
    : "-";
};

export function sliceArray<T>(start: number, end: number, array: T[]) {
  return array.slice(start, end);
}

export const openLinkInNewWindow = (link: string) => {
  window.open(link);
};

export function getOptions<T>(values: T[], labelColumn: keyof T, valueColumn: keyof T) {
  return values.map((v: T) => ({
    label: String(v[labelColumn]),
    value: String(v[valueColumn]),
  }));
}

export function getOptionByValueColumn<T>(
  values: T[],
  labelColumn: keyof T,
  valueColumn: keyof T,
  value: string,
) {
  const foundValue = values.find((v) => String(v[valueColumn]) === value);
  return foundValue
    ? {
        label: String(foundValue[labelColumn]),
        value: String(foundValue[valueColumn]),
      }
    : null;
}

export async function validateFormValues<T>(
  formRef: RefObject<FormikProps<T>> | null,
  yupSchema: Yup.Schema<T>,
  values: T,
  cb: () => void,
): Promise<boolean> {
  const isValidForm = await yupSchema.isValid(values);
  if (isValidForm) return true;

  setTimeout(async () => {
    if (formRef && formRef.current) {
      await formRef.current.validateForm();
      const fieldWithError = document.getElementsByClassName("error-message");
      if (fieldWithError.length) {
        fieldWithError[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  }, 0);

  cb();
  return false;
}

export async function handleFormSubmitWithScroll<T>(formRef: RefObject<FormikProps<T>> | null) {
  if (formRef && formRef.current) {
    const errors: FormikErrors<T> = await formRef.current.validateForm();
    if (!Object.keys(errors).length) {
      formRef.current.submitForm();
      return;
    }

    const fieldWithError = document.getElementById(Object.keys(errors)[0]);
    if (fieldWithError) {
      fieldWithError.scrollIntoView({ behavior: "smooth" });
    }
  }
}

export function getUniqueElements<T>(arr: T[]) {
  return Array.from(new Set(arr));
}

export function getFirstNodeByClassName(name: string) {
  return document.getElementsByClassName(name)[0];
}

export function scrollToTop(params?: { [key: string]: number | string }) {
  params ? window.scrollTo(params) : window.scrollTo(0, 0);

  const appContentWrapper = getFirstNodeByClassName("app-content-wrapper");
  if (appContentWrapper) {
    params
      ? appContentWrapper.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      : appContentWrapper.scrollTo(0, 0);
  }
}

export function scrollToBottom(className?: string) {
  const element = getFirstNodeByClassName(className || "app-content-wrapper");
  if (element) {
    element.scrollTo(0, element.scrollHeight);
  }
}

export function getFileExtension(fileName: string) {
  return fileName.split(".").pop();
}

export async function convertPdfToImages(fileUrl: string) {
  const doc = await pdfjs.getDocument(fileUrl).promise;
  const images = [];
  for (let i = 0; i < doc.numPages; i++) {
    const page = await doc.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    if (canvasContext) {
      await page.render({
        canvasContext,
        viewport,
      }).promise;
    }
    images.push(canvas.toDataURL("image/jpeg"));
  }
  return images;
}

export function setGuestIsAcknowledged() {
  Cookie.set(ACKNOWLEDGE_COOKIE_NAME, new Date().toISOString(), {
    expires: ACKNOWLEDGE_EXPIRE_IN_DAYS,
    path: "/",
  });
}

export function getGuestIsAcknowledged() {
  return Boolean(Cookie.get(ACKNOWLEDGE_COOKIE_NAME));
}

export function setGuestDemoPopup() {
  Cookie.set(GUIDE_DEMO_COOKIE_NAME, new Date().toISOString(), {
    expires: DEMO_POPUP_EXPIRE_IN_DAYS,
    path: "/",
  });
}

export function getGuestDemoPopup() {
  return Boolean(Cookie.get(GUIDE_DEMO_COOKIE_NAME));
}

export function isMobile() {
  return window.innerWidth <= 991;
}

// get n random elements at unique keys from array up to the size of array.
export function sampleSize<T>([...arr]: T[], n = 1) {
  let m = arr.length;
  while (m) {
    const i = Math.floor(Math.random() * m--);
    [arr[m], arr[i]] = [arr[i], arr[m]];
  }
  return arr.slice(0, n);
}

export function getFileSizeInBytesFromBase64(base64: string) {
  return base64.length * (3 / 4);
}

export function openPrintDialog(protocolId: string) {
  window.open(`/protocols/${protocolId}/print`, "_blank");
}

export function preloadPrintDialog(protocolId: string) {
  fetch(`/protocols/${protocolId}/print`).then();
}

export function processShareFormBeforeSubmit() {
  const input = document.querySelector(
    ".share-field .input-multi-value-text input",
  ) as HTMLInputElement;
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  )?.set;
  if (nativeInputValueSetter) {
    nativeInputValueSetter.call(input, `${input.value},`);
  }

  const ev2 = new Event("input", { bubbles: true });
  input.dispatchEvent(ev2);
}

export function focusToFirstError() {
  const erroredInput = document.querySelectorAll(".input-wrapper.error > input")[0] as HTMLElement;
  if (erroredInput) {
    erroredInput.focus();
  }
}

export const canNativeShare = () => !!navigator.share;

export const nativeShare = async (params: { title?: string; url?: string; text?: string }) => {
  if (canNativeShare()) {
    await navigator.share(params);
  }
};

export const getProtocolUrl = (protocolId: string) => {
  const originUrl = window.location.origin.toString();
  return `${originUrl}/protocols/${protocolId}`;
};

export const nativeShareProtocols = async (protocolsData: { name: string; id: string }[]) => {
  await nativeShare({
    title: "Edwards protocols",
    text: `${protocolsData
      .map((item) => `${item.name} - ${getProtocolUrl(item.id)}`)
      .join("\n\r")}`,
  });
};

export const copyElement = <T>(element: T): T => {
  return JSON.parse(JSON.stringify(element)) as T;
};

export const getOptionValue = (option: ValueType<OptionTypeBase, false> | null) => {
  return option ? Number(option.value) : null;
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const sendEmail = (email: string) => {
  window.location.href = `mailto:${email}`;
};

export function downLoadFileFromUrl(url: string, fileName: string) {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
