import { tagSchema } from "./tag.schema";

export const fileSchema = {
  type: "object",
  required: ["id", "name", "url", "type"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    url: { type: "string" },
    type: { type: "string" },
    tags: {
      type: ["array"],
      items: [tagSchema],
    },
    is_deleted: { type: ["boolean"] },
    created_at: { type: ["string"] },
    updated_at: { type: ["string", "null"] },
    created_by: { type: ["string"] },
    updated_by: { type: ["string", "null"] },
  },
  additionalProperties: false,
};
