import {
  ICustomEdge,
  IStepHandle,
  ICustomElements,
} from "@shared/components/InteractiveGuide/interfaces";
import { EDGES, STEP_HANDLES } from "@shared/components/InteractiveGuide/constants";
import variables from "@styles/variables.scss";
import { Elements, Node } from "react-flow-renderer";
import { getStepLabelText } from "@containers/Protocol/utils";

import { IGuideStep, IGuideStepConnection, ParameterUnit } from "../../../models";

export const getElements = (
  guideSteps: IGuideStep[],
  isEdit = false,
  showTooltip = false,
  parameters: ParameterUnit[],
) => {
  const elementsArr: ICustomElements[] = [];
  guideSteps.forEach(({ handles, ...step }) => {
    const sourcePosition: IStepHandle[] = [];
    const targetPosition: IStepHandle[] = [];

    handles.forEach((handle) => {
      if (handle.type === STEP_HANDLES.TARGET) {
        targetPosition.push({ ...handle, id: handle.id.toString() });
      } else {
        sourcePosition.push({ ...handle, id: handle.id.toString() });
      }
    });

    const { sequence, is_opacity, is_active, styles = [], is_hidden, ...rest } = step;
    const label = getStepLabelText(step as IGuideStep, parameters, true);

    const stepStyle: { [key: string]: string | number } = {};

    styles.forEach(({ attribute, value }) => {
      stepStyle[attribute] = value;
    });

    elementsArr.push({
      isHidden: !!is_hidden,
      data: {
        sourcePosition,
        targetPosition,
        sequence,
        ...stepStyle,
        label,
        is_opacity: !!is_opacity,
        is_active: !!is_active,
        is_edit: !!isEdit,
        is_tooltip: !!showTooltip,
      },
      is_edit: !!isEdit,
      ...rest,
      id: rest.id.toString(),
    } as ICustomElements);
  });

  return elementsArr;
};

export const getEdges = (guideStepConnections: IGuideStepConnection[]) => {
  const edgesArr: ICustomEdge[] = [];

  guideStepConnections.forEach((connection) => {
    const {
      source_handle_id,
      step_source_id,
      step_target_id,
      target_handle_id,
      id,
      styles,
      is_opacity,
    } = connection;

    const edgeColor = styles?.find((st) => st.attribute === "color");
    const edgeStyle: { [key: string]: string | number } = {};

    styles
      ?.filter((st) => st.attribute !== "color")
      .forEach(({ attribute, value }) => {
        edgeStyle[attribute] = value;
      });

    let dataStyle = { color: variables.dark };

    if (edgeColor) {
      const { attribute, value } = edgeColor;
      dataStyle = { [attribute]: value } as { color: string };
    }

    edgesArr.push({
      id: id.toString(),
      source: step_source_id.toString(),
      target: step_target_id.toString(),
      sourceHandle: source_handle_id.toString(),
      targetHandle: target_handle_id.toString(),
      data: {
        text: connection.text,
        ...dataStyle,
        color: getLabelColor(connection.type, !!is_opacity),
      },
      style: {
        ...edgeStyle,
        opacity: is_opacity ? 0.05 : 1,
      },
      type: connection.type as EDGES,
    });
  });

  return edgesArr;
};

export const recalculateElementsPosition = (data: Elements) => {
  const recalulated = data.map((el) => {
    const elNode = el as Node;
    if (elNode.position?.x) {
      elNode.position.x = elNode.position.x / 2;
    }

    elNode.position.y = elNode.position.y * 1.5;
    return elNode;
  });

  return recalulated;
};

export const getLabelColor = (type: string, is_opacity: boolean) => {
  switch (type) {
    case EDGES.CUSTOM_EDGE_FAILURE: {
      return is_opacity ? "rgba(207, 32, 47, 0.5)" : "#cf202f";
    }
    default: {
      return is_opacity ? "rgba(43, 65, 68, 0.5)" : "#2b4144";
    }
  }
};
