import { all, fork } from "redux-saga/effects";

import { sagas as dashboardSaga } from "../containers/Dashboard/store";
import { sagas as authSaga } from "../containers/Auth/store";
import { sagas as sharedSaga } from "../shared/store";
import { sagas as protocolSaga } from "../containers/Protocol/store";

const allSagas = [dashboardSaga, sharedSaga, authSaga, protocolSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
