import React, { useEffect, useRef, ReactElement } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { FormikProps, FormikValues } from "formik";

import { useOutsideClick } from "../../hooks";
import "./index.scss";

type ActionBtnPosition = "footer" | "header";
interface ModalProps extends Partial<FormikProps<FormikValues>> {
  isShowing: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  width?: string;
  onClose: () => void;
  children?: React.ReactNode;
  heading?: string;
  showCloseIcon?: boolean;
  closeOnOutside?: boolean;
  onBack?: () => void;
  showActionBtnGroup?: boolean;
  closeBtn?: React.ReactNode;
  confirmBtn?: React.ReactNode;
  isFixed?: boolean;
  actionBtnPosition?: ActionBtnPosition;
  pipeProps?: boolean;
  className?: string;
  headingFontSize?: string;
  noDisabledButton?: boolean;
}

const Modal = (props: ModalProps) => {
  const wrapperRef = useRef(null);
  const {
    isShowing,
    onClose,
    children = <div />,
    isLarge,
    isSmall,
    width,
    heading,
    showCloseIcon = true,
    closeOnOutside = true,
    showActionBtnGroup = false,
    onBack,
    confirmBtn,
    closeBtn,
    isFixed = false,
    actionBtnPosition = "header",
    pipeProps = false,
    className,
    headingFontSize = "24px",
    noDisabledButton,
  } = props;
  const { isOutside, setOusideValue } = useOutsideClick(wrapperRef);

  useEffect(() => {
    if (isOutside && closeOnOutside) {
      onClose();
      setOusideValue();
    }
  }, [isOutside, onClose, setOusideValue, closeOnOutside]);

  return isShowing
    ? ReactDOM.createPortal(
        <div id="modal" className={classnames(className)}>
          <div className="modal-overlay" />
          <div className="modal-wrapper">
            <div
              style={{ width }}
              className={classnames("modal box", {
                large: isLarge,
                small: isSmall,
                fixed: isFixed,
              })}
              ref={wrapperRef}
            >
              {heading ? (
                <div className="heading">
                  <div className="heading-actions">
                    {onBack ? (
                      <div className="back" onClick={onBack}>
                        <span>Back</span>
                      </div>
                    ) : (
                      <div />
                    )}
                    {showCloseIcon ? (
                      <div className="icon delete" onClick={() => onClose()} />
                    ) : (
                      <div />
                    )}
                  </div>
                  <div className="heading-content">
                    <div
                      className="title"
                      style={{ fontSize: headingFontSize }}
                      dangerouslySetInnerHTML={{ __html: heading }}
                    />
                    {showActionBtnGroup && actionBtnPosition === "header" ? (
                      <div className="btn-group">
                        {closeBtn}
                        {React.cloneElement(confirmBtn as ReactElement, {
                          disabled: noDisabledButton ? false : !props.isValid,
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {pipeProps ? React.cloneElement(children as ReactElement, { ...props }) : children}
              {showActionBtnGroup && actionBtnPosition === "footer" ? (
                <div className="btn-group-footer">
                  {closeBtn}
                  {React.cloneElement(confirmBtn as ReactElement, {
                    disabled: noDisabledButton ? false : !props.isValid,
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};

export default Modal;
