import React from "react";

import "./index.scss";

interface NoResultFoundProps {
  isIcon?: boolean;
  text: string;
  loading?: boolean;
}

function NoResultFound(props: NoResultFoundProps) {
  const { isIcon, text, loading } = props;
  return (
    <div className="no-result-found">
      <div className="no-result-img">
        {isIcon ? <img src="/images/general/no-result.png" alt="no result found" /> : null}
      </div>
      <div className="no-result-text">{loading ? "Loading..." : text}</div>
    </div>
  );
}

export default NoResultFound;
