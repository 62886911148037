import React from "react";
import classnames from "classnames";

import "./index.scss";

interface RadioItem {
  value: string;
  name: string;
}

interface StyledRadioProps extends React.HTMLProps<HTMLInputElement> {
  items: RadioItem[];
  label: string;
  value: string;
  className?: string;
}

function StyledRadio(props: StyledRadioProps) {
  const { items, label, value, className, ...inputProps } = props;
  return (
    <div className={classnames("styled-radio", className)}>
      <label className="styled-radio-label">{label}</label>
      <div className="styled-radio-items">
        {items.map((item, index) => (
          <label className={classnames({ checked: item.value === value })} key={item.value}>
            <div className="checkmark">
              <div />
            </div>
            <span className="name">{item.name}</span>
            <input type="radio" id={`${props.name}_${index}`} value={item.value} {...inputProps} />
          </label>
        ))}
      </div>
    </div>
  );
}

export default StyledRadio;
