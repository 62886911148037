import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "@shared/store";

function useOnlineAction() {
  const dispatch = useDispatch();
  const isOnline = useSelector(selectors.getIsOnline());
  const onlineAction = useCallback(
    (action: () => void) => {
      if (isOnline) {
        action();
      } else {
        dispatch(
          actions.showNotification({
            appearance: "error",
            message: "Not available offline. Please check your internet connection.",
          }),
        );
      }
    },
    [isOnline, dispatch],
  );
  return { onlineAction };
}

export default useOnlineAction;
