import React, { useCallback, useMemo, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Button, Icon } from "@shared/components";

import "./index.scss";
import { HeaderUser } from "./components";
import { User } from "../../models";
import { ROUTE_PATHS } from "../../constants";
import { InstallAppBtn } from "../InstallAppBtn";

export interface HeaderProps {
  user: User | null;
  onLogin: () => void;
  onLogout: () => void;
  onEdit: () => void;
  isOnline: boolean;
}

function Header(props: HeaderProps) {
  const { user, onLogin, onLogout, onEdit, isOnline } = props;
  const dispatch = useDispatch();
  const [showOfflineHeader, setShowOfflineHeader] = useState(true);

  const onLogoClick = useCallback(() => {
    dispatch(push(ROUTE_PATHS.PROTOCOLS_DASHBOARD));
  }, [dispatch]);

  const renderRightBlock = useMemo(() => {
    if (!user) {
      return <Button className="outlined login-button" onClick={onLogin} label="Log In" />;
    }
    return <HeaderUser onEdit={onEdit} onLogout={onLogout} user={user} />;
  }, [user, onLogin, onLogout, onEdit]);

  return (
    <div className="header-conteiner">
      {!isOnline && showOfflineHeader && (
        <div className="offline-header">
          <div className="offline-header-info">
            <Icon type="offline" />
            <div className="offline-header-label">
              <div className="offline-header-label-main">You’re offline</div>
              <div className="offline-header-label-addit">Not all features are available.</div>
            </div>
          </div>
          <div className="offline-header-close" onClick={() => setShowOfflineHeader(false)}>
            Close
          </div>
        </div>
      )}
      <div className="header-label">Viewfinder Pathways</div>
      <div className="header-logo" onClick={() => onLogoClick()}>
        <img src="/images/general/logo_desktop.png" alt="logo" />
      </div>
      <InstallAppBtn />
      <div className="header-user">{renderRightBlock}</div>
    </div>
  );
}

export default Header;
