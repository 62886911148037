import React, { useMemo, useCallback, useState } from "react";
import { IGuideStep, IGuideStepConnection, ParameterUnit } from "@shared/models";
import { Field, FieldProps, Form, Formik } from "formik";
import { StyledRadio } from "@shared/components/Input/StyledRadio";
import "./index.scss";
import { StyledButton } from "@shared/components";
import { getStepLabelText, getYesNoConnectionsCounts } from "@containers/Protocol/utils";
import { EDGES } from "@shared/components/InteractiveGuide/constants";

interface RadioConditionProps {
  step: IGuideStep;
  parameters: ParameterUnit[];
  steps: IGuideStep[];
  onHandleSubmit: (step: IGuideStep) => void;
  connections: IGuideStepConnection[];
}

const getNextStepOptions = (steps: IGuideStep[], parameters: ParameterUnit[]) => {
  return steps.map((s) => ({
    name: getStepLabelText(s, parameters),
    value: String(s.id),
  }));
};

const RadioCondition = (props: RadioConditionProps) => {
  const { steps, onHandleSubmit, parameters, connections, step } = props;
  const [isForm, setIsForm] = useState(false);
  const [filteredSteps, setFilteredSteps] = useState<IGuideStep[]>(steps);

  const isYesNoSteps = useMemo(() => {
    const connectionInfo = getYesNoConnectionsCounts(connections);
    return connectionInfo.YES > 0 && connectionInfo.NO > 0;
  }, [connections]);

  const stepText = useMemo(() => getStepLabelText(step, parameters), [step, parameters]);

  const stepOptions = useMemo(() => {
    return getNextStepOptions(filteredSteps, parameters);
  }, [filteredSteps, parameters]);

  const formValues = useMemo(() => {
    return { next_step: stepOptions.length ? String(stepOptions[0].value) : null };
  }, [stepOptions]);

  const handleSubmit = useCallback(
    (stepId: string | null) => {
      const step = steps.find((s) => s.id === Number(stepId));
      if (step) onHandleSubmit(step);
    },
    [steps, onHandleSubmit],
  );

  const handleYesNoSubmit = useCallback(
    (type: string) => {
      setIsForm((prev) => !prev);
      const yesNoSteps = steps.filter((s) =>
        connections.find((c) => c.type === type && s.id === c.step_target_id),
      );

      if (yesNoSteps.length === 1) {
        onHandleSubmit(yesNoSteps[0]);
        return;
      }

      setFilteredSteps(yesNoSteps);
    },
    [steps, connections, onHandleSubmit],
  );

  return (
    <div className="radio-condition">
      {isYesNoSteps && !isForm ? (
        <div className="yes-no-condition">
          <div className="yes-no-condition-text" dangerouslySetInnerHTML={{ __html: stepText }} />
          <div className="yes-no-condition-actions">
            <StyledButton
              label="No"
              className="styled-button-bg red styled-button-bordered customize"
              filled={true}
              color="accent"
              textColor="white"
              onClick={() => handleYesNoSubmit(EDGES.CUSTOM_EDGE_FAILURE)}
            />
            <StyledButton
              label="Yes"
              className="styled-button-bg red styled-button-bordered customize"
              filled={true}
              color="grey"
              textColor="white"
              onClick={() => handleYesNoSubmit(EDGES.CUSTOM_EDGE_SUCCESS)}
            />
          </div>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values.next_step);
          }}
          initialValues={formValues}
        >
          {(formikProps) => (
            <Form>
              <div className="radio-condition-form">
                <Field name="next_step">
                  {({ field }: FieldProps) => (
                    <StyledRadio
                      label=""
                      {...field}
                      items={stepOptions}
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        const value = (event.target as HTMLInputElement).value;
                        formikProps.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
                <StyledButton
                  type="submit"
                  label="Proceed"
                  className="styled-button-bg red styled-button-bordered customize"
                  filled={true}
                  color="accent"
                  textColor="white"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default RadioCondition;
