import { createAsyncAction, createStandardAction } from "typesafe-actions";

import {
  AuthShape,
  LoginShape,
  RegistrationShape,
  ActivateShape,
  ResendResponseShape,
  SetNewPasswordShape,
} from "../interfaces";
import { AuthActionTypes } from "./constants";

export const updateCurrentAuthForm = createStandardAction(
  AuthActionTypes.UPDATE_CURRENT_AUTH_FORM,
)<string>();

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE,
)<LoginShape, undefined, Error>();

export const signUp = createAsyncAction(
  AuthActionTypes.SIGN_UP,
  AuthActionTypes.SIGN_UP_SUCCESS,
  AuthActionTypes.SIGN_UP_FAILURE,
)<RegistrationShape, undefined, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, Error>();

export const checkEmailAvailability = createAsyncAction(
  AuthActionTypes.CHECK_EMAIL_AVAILABILITY,
  AuthActionTypes.CHECK_EMAIL_AVAILABILITY_SUCCESS,
  AuthActionTypes.CHECK_EMAIL_AVAILABILITY_FAILURE,
)<AuthShape, undefined, Error>();

export const activateAccount = createAsyncAction(
  AuthActionTypes.ACTIVATE,
  AuthActionTypes.ACTIVATE_SUCCESS,
  AuthActionTypes.ACTIVATE_FAILURE,
)<ActivateShape, undefined, Error>();

export const resendConfirmation = createAsyncAction(
  AuthActionTypes.RESEND_CONFIRMATION,
  AuthActionTypes.RESEND_CONFIRMATION_SUCCESS,
  AuthActionTypes.RESEND_CONFIRMATION_FAILURE,
)<AuthShape, ResendResponseShape, Error>();

export const resendRestorePassword = createAsyncAction(
  AuthActionTypes.RESEND_RESTORE,
  AuthActionTypes.RESEND_RESTORE_SUCCESS,
  AuthActionTypes.RESEND_RESTORE_FAILURE,
)<AuthShape, ResendResponseShape, Error>();

export const restorePassword = createAsyncAction(
  AuthActionTypes.RESTORE_PASSWORD,
  AuthActionTypes.RESTORE_PASSWORD_SUCCESS,
  AuthActionTypes.RESTORE_PASSWORD_FAILURE,
)<AuthShape, undefined, Error>();

export const setNewPassword = createAsyncAction(
  AuthActionTypes.SET_NEW_PASSWORD,
  AuthActionTypes.SET_NEW_PASSWORD_SUCCESS,
  AuthActionTypes.SET_NEW_PASSWORD_FAILURE,
)<SetNewPasswordShape, ResendResponseShape, Error>();

export const setRestoreHash = createStandardAction(AuthActionTypes.SET_RESTORE_HASH)<string>();
