import React, { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import { Avatar, HoverPopup, ProfileView } from "@shared/components";
import { ROUTE_PATHS } from "@shared/constants";
import { useOnlineAction } from "@shared/hooks";

import { User } from "../../../../models";
import "./index.scss";

export interface HeaderUserProps {
  user: User;
  onLogout: () => void;
  onEdit: () => void;
}

function HeaderUser(props: HeaderUserProps) {
  const history = useHistory();
  const location = useLocation();
  const { onlineAction } = useOnlineAction();
  const { user, onLogout, onEdit } = props;

  const isAvatarActive = useMemo(() => {
    return location.pathname.includes(ROUTE_PATHS.PROFILE);
  }, [location]);

  const onMobileOpenProfile = useCallback(() => {
    history.push(ROUTE_PATHS.PROFILE_VIEW);
  }, [history]);

  const avatarUrl = useMemo(() => {
    return user.files.length ? user.files[0].url : null;
  }, [user]);

  return (
    <div className="user">
      <Avatar
        className={classnames("hide-desktop user-mobile-avatar", { active: isAvatarActive })}
        onClick={onMobileOpenProfile}
        url={avatarUrl}
      />
      <HoverPopup className="hide-tablet user-profile-popup" button={<Avatar url={avatarUrl} />}>
        {({ onClose }) => (
          <ProfileView
            closeOnEdit={true}
            onEdit={() => onlineAction(onEdit)}
            onClose={onClose}
            onLogout={() => onlineAction(onLogout)}
            user={user}
          />
        )}
      </HoverPopup>
    </div>
  );
}

export default HeaderUser;
