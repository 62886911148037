import { createAsyncAction, createStandardAction } from "typesafe-actions";

import { Protocol, FavoriteDto } from "../../../shared/models";
import {
  CreateProtocolDto,
  EditProtocolDto,
  SharedProtocolDto,
  ProtocolUpdateStatisticDto,
} from "../interfaces";
import { ProtocolActionTypes } from "./constants";

export const getProtocol = createAsyncAction(
  ProtocolActionTypes.GET_PROTOCOL,
  ProtocolActionTypes.GET_PROTOCOL_SUCCESS,
  ProtocolActionTypes.GET_PROTOCOL_FAILURE,
)<string, Protocol | null, Error>();

export const deleteProtocol = createAsyncAction(
  ProtocolActionTypes.DELETE_PROTOCOL,
  ProtocolActionTypes.DELETE_PROTOCOL_SUCCESS,
  ProtocolActionTypes.DELETE_PROTOCOL_FAILURE,
)<string, { message: string; protocol_id: string }, Error>();

export const createProtocol = createAsyncAction(
  ProtocolActionTypes.CREATE_PROTOCOL,
  ProtocolActionTypes.CREATE_PROTOCOL_SUCCESS,
  ProtocolActionTypes.CREATE_PROTOCOL_FAILURE,
)<CreateProtocolDto, { protocol: Protocol; message: string }, Error>();

export const editProtocol = createAsyncAction(
  ProtocolActionTypes.EDIT_PROTOCOL,
  ProtocolActionTypes.EDIT_PROTOCOL_SUCCESS,
  ProtocolActionTypes.EDIT_PROTOCOL_FAILURE,
)<
  {
    id: string;
    data: EditProtocolDto;
  },
  { protocol: Protocol; message: string },
  Error
>();

export const setIsChangedProtocolForm = createStandardAction(
  ProtocolActionTypes.SET_PROTOCOL_FORM_CHANGED,
)<boolean>();

export const setProtocolFavorite = createAsyncAction(
  ProtocolActionTypes.SET_PROTOCOL_FAVORITE,
  ProtocolActionTypes.SET_PROTOCOL_FAVORITE_SUCCESS,
  ProtocolActionTypes.SET_PROTOCOL_FAVORITE_FAILURE,
)<Protocol, FavoriteDto, Error>();

export const shareProtocols = createAsyncAction(
  ProtocolActionTypes.SHARE_PROTOCOLS,
  ProtocolActionTypes.SHARE_PROTOCOLS_SUCCESS,
  ProtocolActionTypes.SHARE_PROTOCOLS_FAILURE,
)<SharedProtocolDto, undefined, Error>();

export const updateProtocolStatistic = createAsyncAction(
  ProtocolActionTypes.UPDATE_PROTOCOL_STATISTIC,
  ProtocolActionTypes.UPDATE_PROTOCOL_STATISTIC_SUCCESS,
  ProtocolActionTypes.UPDATE_PROTOCOL_STATISTIC_FAILURE,
)<ProtocolUpdateStatisticDto, undefined, Error>();
