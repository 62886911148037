import { API, SCHEMAS, METHODS } from "../constants";
import { API_ENUM } from "../constants/api";
import { SchemasMap } from "../interfaces";
import { mapToSchema } from "../utils";
import {
  createInteractiveGuideStatisticRequestSchema,
  createInteractiveGuideStatisticResponseSchema,
  updateProtocolStatisticRequestSchema,
} from "../validators";

const schemaShapes: SchemasMap<keyof typeof API.STATISTIC> = {
  [API_ENUM.CREATE_INTERACTIVE_GUIDE_STATISTIC]: {
    request: {
      name: `${API.STATISTIC.CREATE_INTERACTIVE_GUIDE_STATISTIC}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createInteractiveGuideStatisticRequestSchema,
    },
    response: {
      name: `${API.STATISTIC.CREATE_INTERACTIVE_GUIDE_STATISTIC}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createInteractiveGuideStatisticResponseSchema,
    },
  },
  [API_ENUM.UPDATE_PROTOCOL_STATISTIC]: {
    request: {
      name: `${API.STATISTIC.UPDATE_PROTOCOL_STATISTIC}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: updateProtocolStatisticRequestSchema,
    },
    response: {
      name: `${API.STATISTIC.UPDATE_PROTOCOL_STATISTIC}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
};

export default mapToSchema(schemaShapes);
