import { favoriteSchema } from "./favorite.schema";
import { fileSchema } from "./file.schema";
import { procedureTypeSchema } from "./procedureType.schema";
import { tagSchema } from "./tag.schema";
import { typeSubtypeSchema } from "./type.schema";

export const protocolGuideStyleSchema = {
  type: "object",
  required: ["attribute", "id", "value"],
  properties: {
    id: { type: "number" },
    attribute: { type: "string" },
    value: { type: "string" },
  },
  additionalProperties: false,
};

export const guideHandleOffsetSchema = {
  type: ["object", "null"],
  required: ["id", "left", "rught", "top", "bottom"],
  properties: {
    id: { type: "number" },
    left: { type: ["string", "null"] },
    rught: { type: ["string", "null"] },
    top: { type: ["string", "null"] },
    bottom: { type: ["string", "null"] },
  },
};

export const guideStepHandleSchepa = {
  type: "object",
  required: ["id", "position", "type"],
  properties: {
    id: { type: "number" },
    guide_step_handle_id: { type: "number" },
    offset: guideHandleOffsetSchema,
    position: { type: "string" },
    type: { type: "string" },
  },
};

export const guideElementPositionSchepa = {
  type: "object",
  required: ["id", "x", "y"],
  properties: {
    id: { type: "number" },
    x: { type: "number" },
    y: { type: "number" },
  },
};

export const guideStepSettingParameterSchema = {
  type: "object",
  required: ["parameter_unit_id"],
  properties: {
    parameter_unit_id: { type: ["number"] },
  },
};

export const guideStepSettingSchema = {
  type: "object",
  required: ["id", "field_type", "guide_step_id"],
  properties: {
    id: { type: "number" },
    guide_step_id: { type: "number" },
    parameter_unit_id: { type: ["number", "null"] },
    source_guide_step_setting_id: { type: ["number", "null"] },
    field_type: { type: "string" },
    text: { type: ["string", "null"] },
    is_editable: { type: ["boolean"] },
    sequence: { type: "number" },
    setting_parameters: {
      type: "array",
      items: [guideStepSettingParameterSchema],
    },
  },
};

export const protocolGuideStepSchema = {
  type: "object",
  required: ["id", "text", "is_hidden", "selectable", "animated", "type", "handles"],
  properties: {
    id: { type: "number" },
    text: { type: "string" },
    is_hidden: { type: "boolean" },
    styles: {
      type: "array",
      items: [protocolGuideStyleSchema],
    },
    position: guideElementPositionSchepa,
    settings: {
      type: "array",
      items: [guideStepSettingSchema],
    },
    selectable: { type: "boolean" },
    animated: { type: "boolean" },
    type: { type: "string" },
    sequence: { type: ["number", "null"] },
    handles: {
      type: "array",
      items: [guideStepHandleSchepa],
    },
  },
  additionalProperties: false,
};

export const protocolGuideStepConnectionSchema = {
  type: "object",
  required: ["id", "text", "type"],
  properties: {
    id: { type: "number" },
    text: { type: "string" },
    step_source_id: { type: "number" },
    step_target_id: { type: "number" },
    styles: {
      type: ["array"],
      items: [protocolGuideStyleSchema],
    },
    is_marker: { type: "boolean" },
    label_offset_id: { type: ["number", "null"] },
    source_handle_id: { type: "number" },
    target_handle_id: { type: "number" },
    type: { type: "string" },
  },
  additionalProperties: false,
};

export const protocolSchema = {
  type: "object",
  required: ["id", "name", "created_at", "created_by", "status", "tags", "files"],
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    user_id: { type: ["number", "null"] },
    parent_protocol_id: { type: ["string", "null"] },
    short_description: { type: ["string", "null"] },
    long_description: { type: ["string", "null"] },
    reference: { type: ["string", "null"] },
    study_size: { type: ["string", "null"] },
    asa_rating: { type: ["string", "null"] },
    based_on: { type: ["string", "null"] },
    status: { type: "string" },

    type_subtype: typeSubtypeSchema,
    tags: {
      type: ["array"],
      items: [tagSchema],
    },
    files: {
      type: ["array"],
      items: [fileSchema],
    },
    procedure_types: {
      type: ["array"],
      items: [procedureTypeSchema],
    },
    favorites: {
      type: ["array"],
      items: [favoriteSchema],
    },
    guide_steps: {
      type: ["array"],
      items: [protocolGuideStepSchema],
    },
    guide_step_connections: {
      type: ["array"],
      items: [protocolGuideStepConnectionSchema],
    },
    created_at: { type: ["string"] },
    created_by: { type: ["string"] },
    is_shared: { type: ["boolean"] },
  },
  additionalProperties: false,
};

export const protocolItemListSchema = {
  type: "object",
  required: ["id", "name", "created_at", "created_by", "status", "tags", "files"],
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    short_description: { type: ["string", "null"] },
    parent_protocol_id: { type: ["string", "null"] },
    user_id: { type: ["number", "null"] },
    long_description: { type: ["string", "null"] },
    reference: { type: ["string", "null"] },
    status: { type: "string" },
    study_size: { type: ["string", "null"] },
    asa_rating: { type: ["string", "null"] },
    based_on: { type: ["string", "null"] },
    type_subtype: typeSubtypeSchema,
    tags: {
      type: ["array"],
      items: [tagSchema],
    },
    files: {
      type: ["array"],
      items: [fileSchema],
    },
    procedure_types: {
      type: ["array"],
      items: [procedureTypeSchema],
    },
    favorites: {
      type: ["array"],
      items: [favoriteSchema],
    },
    created_at: { type: ["string"] },
    created_by: { type: ["string"] },
    is_shared: { type: ["boolean"] },
  },
  additionalProperties: false,
};

export const protocolsResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    count: { type: "number" },
    items: {
      type: ["array"],
      items: protocolItemListSchema,
    },
  },
};

export const editGuideStepSettingRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id"],
  properties: {
    id: { type: "number" },
    parameter_unit_id: { type: ["number", "null"] },
    text: { type: ["number", "string", "null"] },
  },
};

export const editGuideStepRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["id", "settings"],
  properties: {
    id: { type: "number" },
    settings: {
      type: ["array"],
      items: [editGuideStepSettingRequestSchema],
    },
  },
};

export const createProtocolRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name", "type_subtype_id", "status"],
  properties: {
    name: { type: "string" },
    short_description: { type: "string" },
    long_description: { type: "string" },
    reference: { type: "string" },
    type_subtype_id: { type: "number" },
    study_size: { type: "string" },
    asa_rating: { type: "string" },
    status: { type: "string" },
    based_on: { type: ["string", "null"] },
    parent_protocol_id: { type: ["string", "null"] },
    procedure_types: {
      type: ["array"],
      items: { type: "number" },
    },
    tags: {
      type: ["array"],
      items: { type: "number" },
    },
    files: {
      type: ["array"],
      items: [
        {
          type: "object",
          required: ["fileName", "base64Data", "type"],
          properties: {
            fileName: { type: "string" },
            base64Data: { type: "string" },
            type: { type: "string" },
          },
        },
      ],
    },
    guide_steps: {
      type: ["array"],
      items: [editGuideStepRequestSchema],
    },
    parent_files: {
      type: ["array"],
      items: { type: "number" },
    },
  },
};

export const editProtocolRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name", "type_subtype_id", "status"],
  properties: {
    name: { type: "string" },
    short_description: { type: "string" },
    long_description: { type: "string" },
    reference: { type: "string" },
    type_subtype_id: { type: "number" },
    study_size: { type: "string" },
    asa_rating: { type: "string" },
    status: { type: "string" },
    based_on: { type: ["string", "null"] },
    guide_steps: {
      type: ["array"],
      items: [editGuideStepRequestSchema],
    },
    procedure_types: {
      type: ["array"],
      items: { type: "number" },
    },
    tags: {
      type: ["array"],
      items: { type: "number" },
    },
    new_files: {
      type: ["array"],
      items: [
        {
          type: "object",
          required: ["fileName", "base64Data", "type"],
          properties: {
            fileName: { type: "string" },
            base64Data: { type: "string" },
            type: { type: "string" },
          },
        },
      ],
    },
    old_files: {
      type: ["array"],
      items: [
        {
          type: "number",
        },
      ],
    },
  },
};

export const createProtocolResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    protocol: protocolSchema,
  },
};

export const editProtocolResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    protocol: protocolSchema,
  },
};

export const deleteProtocolResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "protocol_id"],
  properties: {
    message: { type: "string" },
    protocol_id: { type: "string" },
  },
};

export const favoriteProtocolResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["protocol_id", "user_id"],
  properties: {
    user_id: { type: "number" },
    protocol_id: { type: "string" },
    favorite_id: { type: ["number", "null"] },
  },
};

export const sharedProtocolRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "url", "protocols", "emails"],
  properties: {
    emails: {
      type: "array",
      items: [{ type: "string" }],
      minItems: 1,
      maxItems: 10,
      additionalProperties: false,
    },
    message: { type: "string" },
    url: { type: "string" },
    protocols: {
      type: "array",
      items: [
        {
          type: "object",
          additionalProperties: false,
          properties: {
            id: { type: "string" },
            url: { type: "string" },
            name: { type: "string" },
          },
        },
      ],
      minItems: 1,
      additionalProperties: false,
    },
  },
};

export const sharedProtocolResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
    shared: {
      status: { type: "string" },
      reason: {
        type: "object",
        additionalProperties: false,
        properties: {
          statusCode: { type: "number" },
          name: { type: "string" },
          code: { type: "number" },
        },
      },
    },
  },
};

export const updateProtocolStatisticRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["fields"],
  properties: {
    protocol_id: { type: "string" },
    fields: {
      type: "array",
      items: [{ type: "string" }],
      additionalProperties: false,
    },
  },
};
