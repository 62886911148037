import React, { useState, useEffect } from "react";
import { StyledButton, Modal } from "@shared/components";
import { useWindowDimensions } from "@shared/hooks";
import { useSelector } from "react-redux";
import { selectors } from "@shared/store";
import { sendTileViewEvent } from "@shared/utils/googleAdv";

import { Slide, MarketingSlidePage } from "../";

import "./index.scss";

interface MarketingSlideProps extends Slide {
  hideSlide: () => void;
}

const MarketingSlide = (props: MarketingSlideProps) => {
  const { title, description, imageSrc, hideSlide, shortTitle, contentWidth, content, gadvKey } =
    props;
  const [showModal, toggleModal] = useState<boolean>(false);
  const { isMobileVersion } = useWindowDimensions();
  const user = useSelector(selectors.getUser());

  useEffect(() => {
    if (showModal && gadvKey) {
      sendTileViewEvent(gadvKey);
    }
  }, [showModal, gadvKey]);

  return (
    <div className="marketing-slide">
      <img src={imageSrc} alt="slide" />
      <div className="slide-content">
        <div className="slide-content-box">
          <h2 className="slide-title" dangerouslySetInnerHTML={{ __html: title as string }} />
          <p className="slide-text" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="slide-actions">
          <StyledButton
            label="Hide"
            textColor="grey"
            onClick={() => {
              hideSlide();
              user && localStorage.setItem("showMarketingCards", "false");
            }}
          />
          <StyledButton reverse={true} label="Read more" onClick={() => toggleModal(true)} />
        </div>
      </div>
      {isMobileVersion ? (
        <MarketingSlidePage {...props} isShowing={showModal} goBack={() => toggleModal(false)} />
      ) : (
        <Modal
          isShowing={showModal}
          onClose={() => toggleModal(false)}
          width={contentWidth}
          heading={shortTitle}
          showCloseIcon={true}
        >
          <div>{content}</div>
        </Modal>
      )}
    </div>
  );
};

export default MarketingSlide;
