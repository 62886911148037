import { Configuration, ConfigurationObject } from "./shared/interfaces";
const { REACT_APP_ENV = "dev" } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:4000/api/v1/",
  pingUrl: "https://httpbin.org/get",
};

const develop: Configuration = {
  ...dev,
  baseApiUrl: "http://edwards-stage.us-east-2.elasticbeanstalk.com/api/v1/",
};

const qa: Configuration = {
  ...dev,
  baseApiUrl: "https://edwards-qa-api.azurewebsites.net/api/v1/",
  googleAdvId: "G-693G96RR94",
  hideCustomize: false,
  hideShare: false,
};

const uat: Configuration = {
  ...dev,
  baseApiUrl: "https://edwards-uat-api.azurewebsites.net/api/v1/",
  googleAdvId: "G-NLCYW5XY7G",
  hideCustomize: true,
  hideShare: true,
};

const prod: Configuration = {
  ...dev,
  baseApiUrl: "https://edwards-prod-api.azurewebsites.net/api/v1/",
  hideCustomize: true,
  hideShare: true,
  googleAdvId: "G-3C0EZYYD7L",
};

const config: ConfigurationObject = {
  dev,
  develop,
  qa,
  uat,
  prod,
};

const configElement: Configuration = config[REACT_APP_ENV];
export default configElement;
