import { Icon, StyledButton } from "@shared/components";
import React from "react";
import classnames from "classnames";

import "./index.scss";

interface ProtocolGuidePlayFooterProps {
  isGuideView: boolean;
  onPreviousStepClick: () => void;
  onCompleteClick: () => void;
  onStopClick: () => void;
  onShowGuideClick: () => void;
  isCompleted: boolean;
  isFirstStep: boolean;
}

const ProtocolGuidePlayFooter = (props: ProtocolGuidePlayFooterProps) => {
  const {
    onPreviousStepClick,
    isCompleted,
    onCompleteClick,
    onStopClick,
    onShowGuideClick,
    isGuideView,
    isFirstStep,
  } = props;

  return (
    <div className={classnames("protocol-guide-play-footer")}>
      <div className="protocol-guide-play-actions">
        {!isFirstStep ? (
          <StyledButton
            width={88}
            minWidth={88}
            className="previous"
            label="Prev"
            bordered={true}
            onClick={() => onPreviousStepClick()}
          />
        ) : (
          <div className="empty-prev" />
        )}
        <Icon type={isGuideView ? "hide-guide" : "show-guide"} onClick={() => onShowGuideClick()} />
        {isCompleted ? (
          <StyledButton
            width={88}
            minWidth={88}
            className="complete"
            label={"Complete"}
            bordered={true}
            color="accent"
            textColor="red"
            onClick={() => onCompleteClick()}
          />
        ) : (
          <StyledButton
            width={88}
            minWidth={88}
            className="stop"
            label={"Stop"}
            bordered={true}
            color="accent"
            textColor="red"
            onClick={() => onStopClick()}
          />
        )}
      </div>
    </div>
  );
};

export default ProtocolGuidePlayFooter;
