import React from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { Button, FormikErrorMessage, Input } from "@shared/components";
import { InputTypes } from "@shared/constants";
import { NewPasswordShape } from "@containers/Auth/interfaces";
import { AuthFormSchema } from "@containers/Auth/constants";
import "./index.scss";

interface SetNewPasswordFormProps {
  handleSubmit: (values: NewPasswordShape) => void;
}

const SetNewPasswordForm = (props: SetNewPasswordFormProps) => {
  const { handleSubmit } = props;

  return (
    <div className="new-password-form">
      <Formik
        initialValues={{ password: "", password_confirmation: "" }}
        validationSchema={AuthFormSchema.NEW_PASSWORD.VALIDATION}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched }: FormikProps<NewPasswordShape>) => (
          <Form>
            <Field name="password">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.PASSWORD}
                    isRequiredField={true}
                    label="Set Password"
                    {...field}
                    isShowPasswordIcon={true}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <Field name="password_confirmation">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.PASSWORD}
                    isRequiredField={true}
                    label="Confirm Password"
                    {...field}
                    isShowPasswordIcon={true}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <div className="new-password-form-footer">
              <Button label="Set New Password" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SetNewPasswordForm;
