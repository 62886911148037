import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Field,
  FieldArray,
  FieldProps,
  FormikErrors,
  FormikTouched,
  FieldArrayRenderProps,
} from "formik";
import classnames from "classnames";
import { ProtocolContext, ProtocolContextInterface } from "@containers/Dashboard/containers";
import {
  ProtocolDetailsContext,
  ProtocolDetailsContextInterface,
} from "@containers/Protocol/containers/ProtocolDetailContainer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FormikErrorMessage,
  Textarea,
  InputMultiValueText,
  StyledButton,
  Label,
  Divider,
} from "@shared/components";
import "./index.scss";
import { Protocol } from "@shared/models";
import { useDispatch } from "react-redux";
import { actions as sharedActions } from "@shared/store";
import { MAX_SHARE_EMAILS } from "@containers/Protocol/constants";
import { SharedProtocolShape } from "@containers/Protocol/interfaces";
import { useWindowDimensions } from "@shared/hooks";
import {
  getUniqueElements,
  sliceArray,
  nativeShare,
  getProtocolUrl,
  sendShareEvent,
} from "@shared/utils";

const getEmailErrors = (errors: FormikErrors<SharedProtocolShape>) => {
  return errors && Array.isArray(errors["emails"])
    ? { ...errors, emails: getUniqueElements(errors["emails"]) }
    : errors;
};

const getSelectedProtocolsInfo = (
  arrayHelpers: FieldArrayRenderProps,
  maxViewProtocols: number,
) => {
  const protocols = arrayHelpers.form.values[arrayHelpers.name] || [];
  const slicedProtocols = sliceArray<Protocol>(0, maxViewProtocols, protocols);
  const slicedDifference = protocols.length - slicedProtocols.length;
  const moreLabel = slicedDifference
    ? `And ${slicedDifference} more protocol${slicedDifference > 1 ? "s" : ""}`
    : null;

  return {
    protocols,
    slicedProtocols,
    moreLabel,
  };
};

type ContextType = ProtocolDetailsContextInterface | ProtocolContextInterface;
type FormErrors = FormikErrors<{ [key: string]: string | string[] }>;
type FormIsIsTouched = FormikTouched<{ [key: string]: boolean | undefined }>;

interface ShareProtocolProps {
  handleCloseShareView?: () => void;
  closeModal?: () => void;
  errors: FormErrors;
  touched: FormIsIsTouched;
  showCommonForm?: boolean;
}

const ShareProtocol = ({
  errors,
  touched,
  closeModal,
  showCommonForm,
}: Partial<ShareProtocolProps>) => {
  const { isDesktop, isTabletVersion } = useWindowDimensions();
  const [copied, setCopiedStatus] = useState<string | null>(null);
  const [maxViewProtocols, setProtocolViewcount] = useState(3);
  const [, setContext] = useState<null | ContextType>(null);
  const dispatch = useDispatch();

  const protocolContext = useContext(ProtocolContext);
  const protocolDetailsContext = useContext(ProtocolDetailsContext);

  useEffect(() => {
    setProtocolViewcount(!isDesktop ? 3 : 10);
  }, [isDesktop]);

  useEffect(() => {
    setContext({ ...protocolContext, ...protocolDetailsContext });
  }, [protocolContext, protocolDetailsContext]);

  const handleCopyLink = useCallback(
    (protocolId: string) => {
      setCopiedStatus(protocolId);
      dispatch(
        sharedActions.showNotification({
          message: "Link copied",
          appearance: "success",
        }),
      );
    },
    [dispatch],
  );

  const handleNativeShareOne = useCallback((protocol: Protocol) => {
    sendShareEvent({
      protocol_id: protocol.id,
      protocol_name: protocol.name,
      recipient: "unknown",
      type: "copy_link",
      share_id: String(Date.now()),
    });
    nativeShare({
      title: protocol.name,
      url: getProtocolUrl(protocol.id),
    });
  }, []);

  return (
    <>
      <div className="share-protocol-field">
        {closeModal && (
          <div className="share-protocol-cancel-mobile" onClick={closeModal}>
            Cancel
          </div>
        )}
        {showCommonForm && (
          <div className="share-field">
            <FieldArray
              name="emails"
              render={(arrayHelpers) => {
                return (
                  <InputMultiValueText
                    {...arrayHelpers}
                    label={
                      !isDesktop
                        ? "You can add one or more recipient emails"
                        : "You can add one or more recipients by typing their emails and pressing Enter"
                    }
                    max={MAX_SHARE_EMAILS}
                    placeholder="example@email.email"
                  />
                );
              }}
            />
            <FormikErrorMessage
              name="emails"
              errors={getEmailErrors(errors as FormErrors)}
              touched={touched as FormIsIsTouched}
            />
          </div>
        )}
      </div>
      {showCommonForm && (
        <>
          <Field name="message">
            {({ field }: FieldProps) => {
              return (
                <div>
                  <Textarea max={250} {...field} label="Message (optional)" />
                  <FormikErrorMessage
                    name={field.name}
                    errors={errors as FormErrors}
                    touched={touched as FormIsIsTouched}
                  />
                </div>
              );
            }}
          </Field>
          <Divider marginTop="20px" />
        </>
      )}
      <div className="share-protocol-field column">
        <FieldArray
          name="protocols"
          render={(arrayHelpers) => {
            const { protocols, slicedProtocols, moreLabel } = getSelectedProtocolsInfo(
              arrayHelpers,
              maxViewProtocols,
            );
            return protocols.length ? (
              <div>
                <Label
                  name={arrayHelpers.name}
                  label={`Selected Protocol${protocols.length > 1 ? "s" : ""}`}
                />
                <div className="protocol-field">
                  {slicedProtocols.map((protocol: Protocol, idx: number) => (
                    <div className="protocol-field-content" key={protocol.id}>
                      <div className={classnames("protocol-item", { fullwidth: !showCommonForm })}>
                        <div className="protocol-name">{protocol.name}</div>
                        <div className="protocol-share-buttons">
                          {protocols.length > 1 ? (
                            <div
                              className="icon delete"
                              onClick={() => {
                                //context?.setProtocolToShare(protocol);
                                arrayHelpers.remove(idx);
                              }}
                            />
                          ) : null}
                          {!showCommonForm ? (
                            <StyledButton
                              icon="share"
                              height={40}
                              width={50}
                              type="button"
                              className="share-native-icon-button"
                              onClick={() => handleNativeShareOne(protocol)}
                            />
                          ) : null}
                        </div>
                      </div>
                      {showCommonForm && (
                        <CopyToClipboard
                          text={getProtocolUrl(protocol.id)}
                          onCopy={() => handleCopyLink(protocol.id)}
                        >
                          <StyledButton
                            icon={
                              (protocol.id === copied && !isDesktop) || isTabletVersion
                                ? undefined
                                : "copy"
                            }
                            height={40}
                            width={!isDesktop ? 100 : 120}
                            minWidth={!isDesktop ? 100 : 120}
                            label={`${protocol.id === copied ? "Copied" : "Copy"} ${
                              !isDesktop ? "" : "Link"
                            }`}
                            type="button"
                          />
                        </CopyToClipboard>
                      )}
                    </div>
                  ))}
                </div>
                {moreLabel && <div className="share-protocols-label-more">{moreLabel}</div>}
              </div>
            ) : (
              <div className="protocol-empty-state">{`You don't have selected protocols`}</div>
            );
          }}
        />
        <FormikErrorMessage
          name="protocols"
          errors={errors as FormErrors}
          touched={touched as FormIsIsTouched}
        />
      </div>
    </>
  );
};

export default ShareProtocol;
