import React from "react";
import { InstallAppBtn } from "@shared/components";

import "./index.scss";

const ViewfinderPathways = () => (
  <div className="viewfinder-pathways">
    <p className="content">
      Viewfinder Pathways is a protocol library designed to help inform clinicians of key published
      protocols used to guide hemodynamic monitoring in moderate-to high-risk surgery.
      <span className="row-breaker" />
      Viewfinder Pathways is not intended to recommend a specific protocol, but to guide hemodynamic
      optimization through perioperative goal-directed therapy and Acumen Hypotension Prediction
      Index software (HPI)-based protocolized hemodynamic management.
      <span className="row-breaker" />
      Such protocols have been shown to reduce the number of peri-and postoperative complications,
      and improve patient outcomes. Since every case is different, physicians must weigh the risks
      and benefits of initiating any specific protocol.
      <span className="row-breaker" />
      Personalize your protocol library--filter by favorite, procedure type or tags. Follow along
      with interactive guides to assist in training and education. Upload your hospital&apos;s own
      treatment algorithm to your private library. Install Viewfinder Pathways on your mobile device
      to access in offline mode.
    </p>
    <div className="image-container">
      <img src="/images/slides/viewfinder-pathways.png" alt="Viewfinder pathways" />
    </div>
    <InstallAppBtn />
  </div>
);

export default ViewfinderPathways;
