import React, { useMemo } from "react";
import { IGuideStep, IGuideStepConnection, ParameterUnit } from "@shared/models";
import { getYesNoConnectionsCounts } from "@containers/Protocol/utils";
import classnames from "classnames";

import { ButtonCondition, RadioCondition, ResultCondition, YesNoCondition } from "./components";
import { RestartCondition } from "./components/RestartCondition";
import "./index.scss";

interface ProtocolGuidePlayConditionsProps {
  parameters: ParameterUnit[];
  isMobile?: boolean;
  isFirstStep: boolean;
  nextSteps: IGuideStep[];
  currentStep: IGuideStep;
  nextConnections: IGuideStepConnection[];
  handleSubmit: (step: IGuideStep) => void;
  isShowRestartCondition: boolean;
  handleRestartSubmit: () => void;
}

const ProtocolGuidePlayConditions = (props: ProtocolGuidePlayConditionsProps) => {
  const {
    nextSteps,
    nextConnections,
    currentStep,
    handleSubmit,
    isFirstStep,
    isMobile = false,
    isShowRestartCondition = false,
    handleRestartSubmit,
    parameters,
  } = props;

  const condition = useMemo(() => {
    if (isShowRestartCondition) return <RestartCondition onHandleSubmit={handleRestartSubmit} />;
    if (!nextSteps.length && !isFirstStep)
      return <ResultCondition step={currentStep} parameters={parameters} />;
    if (nextSteps.length === 1)
      return (
        <ButtonCondition
          step={currentStep}
          steps={nextSteps}
          onHandleSubmit={handleSubmit}
          parameters={parameters}
        />
      );
    if (nextSteps.length === 2 && getYesNoConnectionsCounts(nextConnections).YES === 1) {
      return (
        <YesNoCondition
          step={currentStep}
          steps={nextSteps}
          connections={nextConnections}
          onHandleSubmit={handleSubmit}
          parameters={parameters}
        />
      );
    }

    return (
      <RadioCondition
        step={currentStep}
        steps={nextSteps}
        connections={nextConnections}
        onHandleSubmit={handleSubmit}
        parameters={parameters}
      />
    );
  }, [
    nextSteps,
    nextConnections,
    currentStep,
    handleSubmit,
    isFirstStep,
    isShowRestartCondition,
    handleRestartSubmit,
    parameters,
  ]);

  return (
    <div
      className={classnames("play-guide-conditions", {
        mobile: isMobile,
      })}
    >
      {condition}
    </div>
  );
};

export default ProtocolGuidePlayConditions;
