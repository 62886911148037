import React from "react";
import classnames from "classnames";

import "./index.scss";

interface ErrorMessageProps {
  className?: string;
  children: React.ReactNode;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { children, className = "" } = props;

  return <p className={classnames("errorMessage", className)}>{children}</p>;
};

export default ErrorMessage;
