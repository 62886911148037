import React, { useEffect, useState, useCallback, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikErrors, FormikProps, FormikTouched } from "formik";

import { selectors } from "../../../store";
import { Tag } from "../../../../../shared/models";
import {
  actions as sharedActions,
  selectors as sharedSelectors,
} from "../../../../../shared/store";
import { ProtocolForm, TagForm } from "../../../components/ProtocolDetailContainer";
import { selectors as dashboardSelectors } from "../../../../Dashboard/store";
import { Modal } from "../../../../../shared/components";
import { ProtocolFormShape } from "../../../interfaces";
import { getOptions } from "../../../../../shared/utils";

interface ProtocolCreateEditContainerProps {
  formValues: ProtocolFormShape;
  formErrors: FormikErrors<ProtocolFormShape>;
  formTouched: FormikTouched<ProtocolFormShape>;
  formRef: RefObject<FormikProps<ProtocolFormShape>> | null;
  onChangeForm: (isChanged: boolean) => void;
}

const ProtocolCreateEditContainer = (props: ProtocolCreateEditContainerProps) => {
  const { formValues, formRef, formErrors, formTouched, onChangeForm } = props;
  const dispatch = useDispatch();

  const [isTagFormModalOpened, setIsTagFormModalOpened] = useState(false);
  const currentProtocol = useSelector(selectors.getCurrentProtocol());

  const tags = useSelector(sharedSelectors.getTags());
  const procedureTypes = useSelector(sharedSelectors.getProcedureTypes());
  const typeSubtypes = useSelector(sharedSelectors.getTypeSubtypes());
  const createdTag = useSelector(sharedSelectors.getCreatedTag());
  const protocols = useSelector(dashboardSelectors.getProtocols());

  useEffect(() => {
    if (formRef && formRef.current && createdTag) {
      dispatch(sharedActions.clearCreatedTag());
      formRef.current.setFieldValue("tags", [
        ...formRef.current.values.tags,
        ...getOptions<Tag>([createdTag], "name", "id"),
      ]);
    }
  }, [createdTag, formRef, dispatch]);

  const onShowCreateTagForm = useCallback(() => {
    setIsTagFormModalOpened(true);
  }, [setIsTagFormModalOpened]);

  const createNewTag = useCallback(
    (values: Partial<Tag>) => {
      dispatch(sharedActions.createTag.request(values));
      setIsTagFormModalOpened(false);
    },
    [dispatch, setIsTagFormModalOpened],
  );

  const handleCloseTagFormModal = useCallback(() => {
    setIsTagFormModalOpened(false);
  }, [setIsTagFormModalOpened]);

  return (
    <div className="protocol-form-container">
      <ProtocolForm
        formValues={formValues}
        formErrors={formErrors}
        formTouched={formTouched}
        protocol={currentProtocol}
        tags={tags}
        procedureTypes={procedureTypes}
        typeSubtypes={typeSubtypes}
        formRef={formRef}
        onChangeForm={() => onChangeForm(true)}
        onCreateTag={onShowCreateTagForm}
        protocols={protocols}
      />
      <Modal
        isShowing={isTagFormModalOpened}
        onClose={handleCloseTagFormModal}
        width="370px"
        heading="Create new tag"
        showCloseIcon={false}
      >
        <TagForm
          focusOnOpen={true}
          handleSubmit={createNewTag}
          handleCancel={() => setIsTagFormModalOpened(false)}
        />
      </Modal>
    </div>
  );
};

export default ProtocolCreateEditContainer;
