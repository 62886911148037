import React from "react";
import styled from "styled-components";

import styles from "../../../styles/variables.scss";

type TagType = "standart" | "draft" | "finished" | "native" | "custom" | "count";

interface TagProps {
  type?: TagType;
  label: string;
}

interface ITag {
  className?: string;
}

const BaseTag = styled.div`
  width: fit-content;
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  font-family: ${styles.secondGlobalFontFamily};
  border-radius: 4px;
`;

const StandartTag = styled(BaseTag)`
  color: ${styles.dark};
  background: ${styles.lightGrey};
`;

const NativeTag = styled(BaseTag)`
  color: #c3a772;
  background: #f8ebd2;
`;

const CustomTag = styled(BaseTag)`
  color: #90a495;
  background: #65a07929;
`;

const FinishedTag = styled(BaseTag)`
  color: #90a495;
  background: rgba(101, 160, 121, 0.16);
`;

const DraftTag = styled(BaseTag)`
  color: #a7a7a7;
  background: #e9e9e9;
`;

const CountTag = styled(BaseTag)`
  color: ${styles.dark};
  background: #fffff;
  border: 1px solid #e5ebed;
`;

export default function Tag(props: TagProps & ITag) {
  const { label, ...tagProps } = props;
  switch (props.type) {
    case "custom":
      return <CustomTag {...tagProps}>{label}</CustomTag>;
    case "native":
      return <NativeTag {...tagProps}>{label}</NativeTag>;
    case "finished":
      return <FinishedTag {...tagProps}>{label}</FinishedTag>;
    case "draft":
      return <DraftTag {...tagProps}>{label}</DraftTag>;
    case "count":
      return <CountTag {...tagProps}>{label}</CountTag>;
    case "standart":
    default:
      return <StandartTag {...tagProps}>{label}</StandartTag>;
  }
}
