import React, { useCallback } from "react";
import { ProfileView } from "@shared/components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { selectors } from "@shared/store";
import { actions } from "@containers/Auth/store";
import { ROUTE_PATHS } from "@shared/constants";
import { useScrollToTop, useOnlineAction } from "@shared/hooks";

import "./index.scss";

export default function ProfileViewContainer() {
  const dispatch = useDispatch();
  useScrollToTop();
  const { onlineAction } = useOnlineAction();
  const history = useHistory();
  const user = useSelector(selectors.getUser());

  const onLogout = useCallback(() => {
    dispatch(actions.logout.request());
    history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
  }, [dispatch, history]);

  const onEdit = useCallback(() => {
    history.push(ROUTE_PATHS.PROFILE_EDIT);
  }, [history]);

  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  return user ? (
    <div className="profile-view-container">
      <ProfileView
        onEdit={() => onlineAction(onEdit)}
        onClose={onClose}
        onLogout={() => onlineAction(onLogout)}
        user={user}
      />
    </div>
  ) : null;
}
