import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import React, { useState } from "react";

import "./index.scss";
import { Button, FormikErrorMessage, Input } from "../../../../../shared/components";
import { InputTypes } from "../../../../../shared/constants";
import { LoginShape } from "../../../interfaces";
import { AuthFormSchema } from "../../../constants";

interface LoginFormProps {
  handleSubmit: (values: LoginShape) => void;
  handleForgot: () => void;
  defaultValue: LoginShape;
}

const LoginForm = (props: LoginFormProps) => {
  const { handleSubmit, handleForgot, defaultValue } = props;
  const [formValues] = useState<LoginShape>(defaultValue);

  return (
    <div className="login-form">
      <Formik
        validationSchema={AuthFormSchema.LOGIN.VALIDATION}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={formValues}
      >
        {({ errors, touched }: FormikProps<LoginShape>) => (
          <Form>
            <Field name="email">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.EMAIL}
                    isRequiredField={true}
                    label="Email"
                    {...field}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.PASSWORD}
                    isRequiredField={true}
                    isShowPasswordIcon={true}
                    label="Password"
                    {...field}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <div className="forgot-password-row" onClick={handleForgot}>
              Forgot Password
            </div>
            <div className="login-form-footer">
              <Button label="Log In" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
