import React, { useContext } from "react";
import { StyledButton } from "@shared/components";

import { ProtocolShareViewContext } from "../ProtocolShareView";
import "./index.scss";

const MobileShareViewActions = () => {
  const { cancel, unselectAll, selectAll, share } = useContext(ProtocolShareViewContext);

  return (
    <div className="mobile-share-view-actions">
      <StyledButton
        className="squized-mobile-button"
        label="Cancel"
        textColor="grey"
        onClick={cancel}
      />
      <div className="toolbar">
        <StyledButton
          className="squized-mobile-button"
          label="Unselect All"
          textColor="grey"
          onClick={unselectAll}
        />
        <div className="vertical-divider" />
        <StyledButton
          className="squized-mobile-button"
          label="Select All"
          textColor="red"
          onClick={selectAll}
        />
        <div className="vertical-divider" />
        <StyledButton
          className="squized-mobile-button"
          label="Share"
          icon="share-protocols"
          onClick={share}
        />
      </div>
    </div>
  );
};

export default MobileShareViewActions;
