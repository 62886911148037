import { Dispatch } from "react";
import { AnyAction } from "redux";

import { DashboardFilter } from "../interfaces";
import { updateFilter } from "../store/actions";

export const handleFilterChange = (
  dispatch: Dispatch<AnyAction>,
  value: Partial<DashboardFilter>,
) => {
  dispatch(
    updateFilter({
      ...value,
    }),
  );
};
