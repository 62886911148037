import { useMemo } from "react";
import { useSelector } from "react-redux";

import { DashboardFilter } from "../interfaces";
import { selectors } from "../store";

export default function useFilterDashboardSelector() {
  const filter = useSelector(selectors.getFilter());

  const protocolFilter: DashboardFilter = useMemo(() => {
    const { search, tags, procedure_type, show_more } = filter;

    const protocolFilterValues: DashboardFilter = {
      search,
      tags,
      procedure_type,
      show_more,
    };

    return protocolFilterValues;
  }, [filter]);

  const isProtocolSearch = useMemo(() => {
    return (
      Boolean(filter.search || filter.procedure_type || filter.tags.length) || filter.show_more
    );
  }, [filter]);

  return {
    protocolFilter,
    isProtocolSearch,
  };
}
