import React, { memo, useMemo } from "react";
import { Handle } from "react-flow-renderer";

import { DiamondShape, RectangleShape } from "..";
import { SHAPE_TYPES } from "../../constants";
import { IGuindElementProps } from "../../interfaces";
import variables from "../../../../../styles/variables.scss";

const GuindElement = memo(
  ({
    data: {
      color,
      styles,
      label,
      sequence,
      sourcePosition,
      targetPosition,
      is_edit,
      is_opacity,
      is_active,
      is_tooltip,
      ...rest
    },
  }: IGuindElementProps) => {
    const renderHandle = useMemo(() => {
      return [sourcePosition, targetPosition].flat().map((element, idx) => {
        const { offset = {}, ...restElementProps } = element;
        return (
          <Handle
            key={`${rest.id}-${idx}`}
            {...restElementProps}
            style={{
              ...offset,
              visibility: "hidden",
              [`margin${element.position.charAt(0).toUpperCase() + element.position.slice(1)}`]:
                element.type === "source" ? 4 : 3,
            }}
          />
        );
      });
    }, [sourcePosition, targetPosition, rest.id]);

    const renderShape = useMemo(() => {
      const shapeProps = {
        color: color ? variables[color] : variables.white,
        styles,
        label,
        sequence,
        is_edit,
        is_opacity,
        is_active,
        is_tooltip,
      };

      if (styles.includes(SHAPE_TYPES.RHOMBUS)) {
        return <DiamondShape {...shapeProps} />;
      }

      return <RectangleShape {...shapeProps} />;
    }, [styles, label, color, sequence, is_edit, is_opacity, is_active, is_tooltip]);

    return (
      <>
        {renderShape}
        {renderHandle}
      </>
    );
  },
);

export default GuindElement;
