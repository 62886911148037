import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { StyledButton, StyledCheckbox } from "@shared/components";
import { getGuestIsAcknowledged, sendDisclaimerAcknowledgeEvent } from "@shared/utils";
import { selectors as sharedSelectors } from "@shared/store";
import { selectors as authSelectors } from "@containers/Auth/store";

import "./index.scss";

interface DisclaimerProps {
  onSubmit: () => void;
  className?: string;
}

function Disclaimer(props: DisclaimerProps) {
  const { onSubmit, className } = props;
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [showError, setShowError] = useState(false);
  const user = useSelector(sharedSelectors.getUser());
  const isAuthentificated = useSelector(authSelectors.authentificated());
  const required = useMemo(() => {
    const isAcknowledged = getGuestIsAcknowledged();
    return (user && !user?.is_acknowledged) || (!user && (!isAcknowledged || isAuthentificated));
  }, [user, isAuthentificated]);

  const handleAcknowledge = useCallback(() => {
    if (!isAcknowledged && required) {
      setShowError(true);
      return;
    }
    onSubmit();
    sendDisclaimerAcknowledgeEvent();
  }, [isAcknowledged, onSubmit, required]);

  return (
    <div className={classnames("disclaimer-wrapper", className)}>
      <div className="disclaimer-header">Disclaimer</div>
      <div className="disclaimer-content">
        This protocol summary web application is designed to help inform clinicians of key published
        protocols used to guide hemodynamic monitoring in moderate-to high-risk surgery. It is not
        intended to recommend a specific protocol, but to guide hemodynamic optimization through
        perioperative goal-directed therapy protocols that have been shown to reduce the number of
        peri- and postoperative complications, and improve patient outcomes. Since every case is
        different, physicians must weigh the risks and benefits of initiating any specific protocol.
        <br />
        <br />
        Edwards provides this information for your convenience. It is not intended to describe,
        recommend, or suggest any use, feature, or benefit of Edwards products and does not
        constitute any medical advice. The information provided is not meant to be a substitute for
        professional advice and is not to be used alone for medical diagnosis or medical treatment.
        Medicine is an ever-changing science. As new research and clinical experience broaden our
        knowledge, changes in treatment and drug therapy are required. In view of the possibility of
        human error or changes in medical sciences, Edwards cannot warrant that the information is
        in every respect accurate or complete, and Edwards thus cannot be responsible for any errors
        or omissions or the results obtained from the use of such information. Extensive effort has
        been exerted to make this information as accurate as possible. However, the accuracy and
        completeness of the information provided cannot be guaranteed. This is to be used as a guide
        only, and healthcare professionals should use sound clinical judgment and individualize
        therapy to each specific patient care situation. Edwards makes no claims whatsoever,
        expressed or implied, about the authenticity, accuracy, reliability, completeness, or
        timeliness of the material, calculations, software, text, graphics, or other information
        given.
      </div>
      {required && (
        <div className="disclaimer-checkbox">
          <StyledCheckbox
            label="I have read and agree to the Viewfinder Pathways Terms and Conditions."
            checked={isAcknowledged}
            onChange={(e) => {
              setIsAcknowledged(e.currentTarget.checked);
              setShowError(false);
            }}
          />
          <div className="disclaimer-error">
            {showError ? "Please acknowledge the terms first." : ""}
          </div>
        </div>
      )}
      <div className="disclaimer-button">
        <StyledButton
          label="Acknowledge"
          className="styled-button-bg red styled-button-bordered customize"
          filled={true}
          color="accent"
          textColor="white"
          onClick={handleAcknowledge}
        />
      </div>
    </div>
  );
}

export default Disclaimer;
