import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import {
  CreateProtocolDto,
  EditProtocolDto,
  SharedProtocolDto,
  ProtocolUpdateStatisticDto,
} from "../interfaces";

export default {
  getProtocol: (id: string) => request(METHODS.GET, API.PROTOCOL.GET_PROTOCOL(id))(),
  createProtocol: (payload: CreateProtocolDto) =>
    request(METHODS.POST, API.PROTOCOL.CREATE_PROTOCOL)(payload),
  editProtocol: (payload: { id: string; data: EditProtocolDto }) =>
    request(METHODS.PUT, API.PROTOCOL.EDIT_PROTOCOL(String(payload.id)))(payload.data),
  deleteProtocol: (id: string) => request(METHODS.DELETE, API.PROTOCOL.DELETE_PROTOCOL(id))(),
  setFavoriteProtocol: (id: string) =>
    request(METHODS.POST, API.PROTOCOL.SET_PROTOCOL_FAVORITE(id))(),
  shareProtocols: (payload: SharedProtocolDto) =>
    request(METHODS.POST, API.PROTOCOL.SHARE_PROTOCOLS)(payload),
  updateProtocolSatistic: (payload: ProtocolUpdateStatisticDto) =>
    request(
      METHODS.POST,
      API.STATISTIC.UPDATE_PROTOCOL_STATISTIC,
    )({ protocol_id: payload.protocolId, fields: payload.fields }),
};
