import { createSelector } from "reselect";

import { AppState } from "../interfaces";

const selectShared = (state: AppState) => state.shared;

export const getLoading = () => createSelector(selectShared, (state) => state.loading);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getProcedureTypes = () =>
  createSelector(selectShared, (state) => state.procedure_types);

export const getTags = () => createSelector(selectShared, (state) => state.tags);

export const getTypeSubtypes = () => createSelector(selectShared, (state) => state.type_subtype);

export const getParameters = () => createSelector(selectShared, (state) => state.parameters);

export const getUser = () => createSelector(selectShared, (state) => state.user);

export const getCreatedTag = () => createSelector(selectShared, (state) => state.created_tag);

export const selectRouter = () =>
  createSelector(
    (state: AppState) => state,
    (state) => state,
  );

export const getShowDisclaimerModal = () =>
  createSelector(selectShared, (state) => state.show_disclaimer_modal);

export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);

export const getLoadingSections = () =>
  createSelector(selectShared, (state) => Object.keys(state.loadingSections));

export const getIsOnline = () => createSelector(selectShared, (state) => state.is_online);
