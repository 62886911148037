import { API, SCHEMAS, METHODS } from "../constants";
import { API_ENUM } from "../constants/api";
import { SchemasMap } from "../interfaces";
import { mapToSchema } from "../utils";
import {
  tagsResponseSchema,
  procedureTypesResponseSchema,
  typeSubtypesResponseSchema,
  createTagRequestSchema,
  createTagResponseSchema,
  parameterUnitsResponseSchema,
  latestAppVersionResponseSchema,
} from "../validators";

const schemaShapes: SchemasMap<keyof typeof API.SHARED> = {
  [API_ENUM.GET_PRESIGNED_URL]: {
    request: {
      name: `${API.SHARED.GET_PRESIGNED_URL}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_PRESIGNED_URL}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: {},
    },
  },
  [API_ENUM.GET_LATEST_APP_VERSION]: {
    request: {
      name: `${API.SHARED.GET_LATEST_APP_VERSION}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_LATEST_APP_VERSION}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: latestAppVersionResponseSchema,
    },
  },
  [API_ENUM.GET_PROCEDURE_TYPES]: {
    request: {
      name: `${API.SHARED.GET_PROCEDURE_TYPES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_PROCEDURE_TYPES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: procedureTypesResponseSchema,
    },
  },
  [API_ENUM.GET_TAGS]: {
    request: { name: `${API.SHARED.GET_TAGS}${SCHEMAS.REQUEST}${METHODS.GET}`, schema: {} },
    response: {
      name: `${API.SHARED.GET_TAGS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: tagsResponseSchema,
    },
  },
  [API_ENUM.GET_TYPE_SUBTYPES]: {
    request: {
      name: `${API.SHARED.GET_TYPE_SUBTYPES}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_TYPE_SUBTYPES}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: typeSubtypesResponseSchema,
    },
  },
  [API_ENUM.CREATE_TAG]: {
    request: {
      name: `${API.SHARED.CREATE_TAG}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createTagRequestSchema,
    },
    response: {
      name: `${API.SHARED.CREATE_TAG}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createTagResponseSchema,
    },
  },
  [API_ENUM.GET_PARAMETERS]: {
    request: {
      name: `${API.SHARED.GET_PARAMETERS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.SHARED.GET_PARAMETERS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: parameterUnitsResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
