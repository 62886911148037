import React, { RefObject } from "react";
import classnames from "classnames";

import "./index.scss";

interface InputWrapperProps {
  className?: string;
  children: React.ReactNode;
  innerRef?: RefObject<HTMLDivElement>;
}

export const InputWrapper = (props: InputWrapperProps) => {
  const { children, innerRef, className = "" } = props;

  return (
    <div ref={innerRef} className={classnames("input-wrapper", className)}>
      {children}
    </div>
  );
};
