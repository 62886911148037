import React from "react";

import "./index.scss";
import { LabelProps } from "../../../interfaces";

const Label: React.FunctionComponent<LabelProps> = (props: LabelProps) => {
  const { label, isRequiredField, name } = props;
  return (
    <label htmlFor={name}>
      {label} {isRequiredField ? <span className="required-field">*</span> : null}
    </label>
  );
};

export default Label;
