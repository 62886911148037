import { getStepLabelText } from "@containers/Protocol/utils";
import { IGuideStep, ParameterUnit } from "@shared/models";
import React, { useMemo } from "react";

import "./index.scss";

interface RectangleShapeProps {
  parameters: ParameterUnit[];
  step: IGuideStep;
  onClick: (step: IGuideStep) => void;
}

export default ({ step, onClick, parameters }: RectangleShapeProps) => {
  const stepText = useMemo(() => getStepLabelText(step, parameters, true), [step, parameters]);

  const styles = useMemo(() => {
    return (step.styles || []).map((s) => s.value).join(" ");
  }, [step]);

  return (
    <section className="rectangle-shape-section">
      <div className={`rectangle-shape ${styles}`} onClick={() => onClick(step)}>
        <div className="item-text" dangerouslySetInnerHTML={{ __html: stepText }} />
      </div>
    </section>
  );
};
