import React, { useMemo, useState, useEffect } from "react";
import classnames from "classnames";
import { Protocol, User } from "@shared/models";
import { EmptyListMessage } from "@shared/components";
import { sliceArray } from "@shared/utils/functions";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "@containers/Dashboard/store";
import { handleFilterChange } from "@containers/Dashboard/utils";

import { ProtocolItem } from "../";
import "./index.scss";

export interface ProtocolListItemProps {
  protocols: Protocol[];
  user: User | null;
  onProtocolClick?: (protocol: Protocol) => void;
  onProtocolFavorite: (protocol: Protocol) => void;
  title?: string;
  emptyMessage?: string;
  className?: string;
  noResultsComponent?: React.ReactNode;
  loading?: boolean;
}

export const DEFAULT_COUNT_ITEMS = 4;

function ProtocolListItem(props: ProtocolListItemProps) {
  const {
    protocols,
    onProtocolClick,
    onProtocolFavorite,
    emptyMessage,
    user,
    className,
    title,
    noResultsComponent,
    loading,
  } = props;
  const [isFullList, setIsFullList] = useState(false);
  const { show_more } = useSelector(selectors.getFilter());
  const dispatch = useDispatch();

  useEffect(() => {
    !user ? setIsFullList(true) : setIsFullList(!show_more);
  }, [user, show_more]);

  const slicedProtocols = useMemo(() => {
    return isFullList ? protocols : sliceArray(0, DEFAULT_COUNT_ITEMS, protocols);
  }, [protocols, isFullList]);

  const renderEmptyMessage = useMemo(() => {
    if (noResultsComponent) {
      return noResultsComponent;
    }
    return <EmptyListMessage text={loading ? "Loading..." : emptyMessage} />;
  }, [noResultsComponent, emptyMessage, loading]);

  const hasElements = useMemo(() => Boolean(protocols.length), [protocols]);

  const renderLessMoreBtn = useMemo(() => {
    if (!user) return null;
    return isFullList ? "Show Less" : "Show More";
  }, [user, isFullList]);

  return (
    <div
      className={classnames("protocol-list", className, { "protocol-empty-list": !hasElements })}
    >
      {title && <div className="protocol-list-title">{title}</div>}
      <div className="protocol-list-content">
        {hasElements
          ? slicedProtocols.map((p) => (
              <ProtocolItem
                onProtocolClick={() => {
                  onProtocolClick && onProtocolClick(p);
                }}
                key={p.id}
                protocol={p}
                onProtocolFavorite={() => onProtocolFavorite(p)}
                user={user}
              />
            ))
          : renderEmptyMessage}
      </div>
      {protocols.length > DEFAULT_COUNT_ITEMS && renderLessMoreBtn ? (
        <div
          className="protocol-list-pagination"
          onClick={() => handleFilterChange(dispatch, { show_more: !show_more })}
        >
          {renderLessMoreBtn}
        </div>
      ) : null}
    </div>
  );
}

export default ProtocolListItem;
