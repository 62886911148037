import { StyledButton } from "@shared/components/Button";
import React from "react";
import "./index.scss";

interface installFromNotSupportedIOSBrowserProps {
  onClose: () => void;
}

const InstallFromNotSupportedIOSBrowser = (props: installFromNotSupportedIOSBrowserProps) => {
  return (
    <div className="install-modal-content-wrapper">
      <div className="modal-content">
        <div>This feature is only available in Safari</div>
      </div>
      <div className="modal-actions">
        <StyledButton label={"Ok, got it"} bordered={true} onClick={props.onClose} />
      </div>
    </div>
  );
};
export default InstallFromNotSupportedIOSBrowser;
