import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { tokenHandler } from "../../../shared/utils";
import { AuthStateType } from "../interfaces";
import * as actions from "./actions";

export const initialState: AuthStateType = {
  authorized: !!tokenHandler.get(),
  current_auth_form: "",
  restore_hash: "",
};

const reducer = createReducer<AuthStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.updateCurrentAuthForm, (state, action) =>
    produce(state, (nextState) => {
      nextState.current_auth_form = action.payload;
    }),
  )
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = true;
    }),
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nextState) => {
      nextState.authorized = false;
    }),
  )
  .handleAction(actions.setRestoreHash, (state, action) =>
    produce(state, (nextState) => {
      nextState.restore_hash = action.payload;
    }),
  );

export default reducer;
