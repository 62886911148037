import React from "react";

import "./index.scss";

interface LinkButtonProps {
  url: string;
  title: string;
}

function LinkButton(props: LinkButtonProps) {
  const { url, title } = props;
  return (
    <a className="link-button" href={url} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
}

export default LinkButton;
