import React from "react";
import {
  getMarkerEnd,
  EdgeProps,
  getSmoothStepPath,
  EdgeText,
  getEdgeCenter,
} from "react-flow-renderer";

import variables from "../../../../../styles/variables.scss";

const GuideEdge =
  (markerId: string) =>
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId = markerId,
  }: EdgeProps) => {
    const edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [centerX, centerY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });

    return (
      <React.Fragment key={id}>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={data.is_marker === false ? "" : markerEnd}
        />
        <EdgeText
          // x={centerX + data.labelOffset.x}
          // y={centerY + data.labelOffset.y}
          x={centerX}
          y={centerY}
          label={data.text}
          labelShowBg={true}
          labelBgStyle={{ fill: variables.interactiveGiudeBg }}
          labelStyle={{
            fill: data.color || variables.dark,
            fontSize: "20px",
            fontWeight: "lighter",
            fontVariant: "all-petite-caps",
          }}
          labelBgPadding={[3, 3]}
        />
      </React.Fragment>
    );
  };

export default GuideEdge;
