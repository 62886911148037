import React, { useState, useRef, useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import { FieldArrayRenderProps } from "formik";
import { Label } from "@shared/components/Input/Label";
import "./index.scss";
import classnames from "classnames";

interface InputMultiValueTextProps extends FieldArrayRenderProps {
  label: string;
  isRequiredField?: boolean;
  placeholder: string;
  max?: number;
}

const InputMultiValueText = (props: InputMultiValueTextProps) => {
  const [keywordStr, setKeywordStr] = useState<string>("");
  const textInput = useRef<HTMLInputElement>(null);
  const { max } = props;
  const handleInputChange = useCallback(() => {
    const target = textInput.current;
    const value = target?.value;
    const {
      name,
      push,
      form: { values, setFieldTouched },
    } = props;

    if (value === undefined) {
      return;
    }

    if (value.includes(",") || value.includes(";")) {
      const separator = value.includes(",") ? "," : ";";
      const emails = value.split(separator).filter((v) => !!v);
      emails.forEach((email) => {
        if (!max || values[name].length < max) {
          push(email.toLowerCase().trim());
        }
      });
      setKeywordStr("");
      setFieldTouched(name, true, false);
    } else {
      setKeywordStr(value.toLowerCase());
    }
  }, [props, max]);

  const handleRemoveItem = useCallback(
    (idx: number) => {
      const { remove } = props;
      remove(idx);
    },
    [props],
  );

  const customAcceptEmail = useCallback(() => {
    const target = textInput.current;
    if (!target) {
      return;
    }
    target.value = `${target.value.toLowerCase().trim()},`;
    handleInputChange();
  }, [handleInputChange]);

  const debouncedHandleInput = useMemo(
    () => debounce(customAcceptEmail, 30000),
    [customAcceptEmail],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        customAcceptEmail();
        e.preventDefault();
        debouncedHandleInput.cancel();
      } else {
        debouncedHandleInput();
      }
    },
    [customAcceptEmail, debouncedHandleInput],
  );

  const {
    label,
    isRequiredField,
    name,
    placeholder,
    form: { values, errors },
  } = props;
  return (
    <div className="input-multi-value-text">
      <Label name={name} isRequiredField={isRequiredField} label={label} />
      {max && (
        <div className="input-max-counter">
          {values[name].length || 0}/{max}
        </div>
      )}
      <ul className="list" onClick={() => textInput.current?.focus()}>
        {values[name].map((value: string | number, i: number) => (
          <li
            className={classnames("item", {
              error: errors && errors[name] && (errors[name] as string[])[i],
            })}
            key={`${value}-${i}`}
          >
            {value}
            <div className="icon delete" onClick={() => handleRemoveItem(i)} />
          </li>
        ))}
        <input
          ref={textInput}
          value={keywordStr}
          className="input"
          placeholder={values[name].length ? undefined : placeholder}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </ul>
    </div>
  );
};

export default InputMultiValueText;
