import { createSelector } from "reselect";

import { AppState } from "../../../shared/interfaces";

const selectProtocol = (state: AppState) => state.protocol;

export const getCurrentProtocol = () =>
  createSelector(selectProtocol, (state) => state.current_protocol);
export const getIsChangedProtocolForm = () =>
  createSelector(selectProtocol, (state) => state.is_changed_protocol_form);
