import * as Yup from "yup";

import { LoginShape, RegistrationShape } from "../interfaces";
import {
  FORM_ERROR_MESSAGES,
  IS_EXIST_DIGIT,
  IS_EXIST_LOWERCASE,
  IS_EXIST_UPPERCASE,
} from "../../../shared/constants";

export const AuthCommonFormSchema = {
  SCHEME: {
    email: "",
  },
  VALIDATION: {
    email: Yup.string().email(FORM_ERROR_MESSAGES.EMAIL).required(FORM_ERROR_MESSAGES.REQUIRED),
  },
};

export const PasswordFormSchema = {
  SCHEME: {
    password: "",
    password_confirmation: "",
  },
  VALIDATION: {
    password: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
    password_confirmation: Yup.string()
      .required(FORM_ERROR_MESSAGES.REQUIRED)
      .min(10, FORM_ERROR_MESSAGES.MIN_PASS_CHARS)
      .max(128, `${FORM_ERROR_MESSAGES.MAX_STRING_LENGTH} 128`)
      .matches(IS_EXIST_DIGIT, FORM_ERROR_MESSAGES.DIGIT)
      .matches(IS_EXIST_LOWERCASE, FORM_ERROR_MESSAGES.LOWERCASE)
      .matches(IS_EXIST_UPPERCASE, FORM_ERROR_MESSAGES.UPPERCASE)
      .oneOf([Yup.ref("password")], FORM_ERROR_MESSAGES.PASSWORDS_MATCH),
  },
};

export const BaseProfileFormSchema = {
  SCHEME: {
    ...AuthCommonFormSchema.SCHEME,
    ...PasswordFormSchema.SCHEME,
    first_name: "",
    last_name: "",
    title: "",
    service_line: "",
  },
  VALIDATION: {
    ...AuthCommonFormSchema.VALIDATION,
    ...PasswordFormSchema.VALIDATION,
    first_name: Yup.string()
      .required(FORM_ERROR_MESSAGES.REQUIRED)
      .max(50, `The first name ${FORM_ERROR_MESSAGES.LONG}`),
    last_name: Yup.string()
      .required(FORM_ERROR_MESSAGES.REQUIRED)
      .max(50, `The last name ${FORM_ERROR_MESSAGES.LONG}`),
    title: Yup.string().max(50, `The title ${FORM_ERROR_MESSAGES.LONG}`),
    service_line: Yup.string().max(50, `The service line ${FORM_ERROR_MESSAGES.LONG}`),
  },
};

export const AuthFormSchema = {
  LOGIN: {
    SCHEME: {
      ...AuthCommonFormSchema.SCHEME,
      password: "",
    },
    VALIDATION: Yup.object<LoginShape>().shape({
      ...AuthCommonFormSchema.VALIDATION,
      password: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
    }),
  },
  REGISTRATION: {
    SCHEME: {
      ...BaseProfileFormSchema.SCHEME,
    },
    VALIDATION: Yup.object<RegistrationShape>().shape({
      ...BaseProfileFormSchema.VALIDATION,
    }),
  },
  NEW_PASSWORD: {
    SCHEME: {
      ...PasswordFormSchema.SCHEME,
    },
    VALIDATION: Yup.object<RegistrationShape>().shape({
      ...PasswordFormSchema.VALIDATION,
    }),
  },
  PROFILE_EDIT: {
    SCHEME: {
      ...BaseProfileFormSchema.SCHEME,
      avatar: {
        files: [],
        old_files: [],
      },
      current_password: "",
    },
    VALIDATION: Yup.object<RegistrationShape>().shape({
      ...BaseProfileFormSchema.VALIDATION,
      email: Yup.string().nullable(),
      current_password: Yup.string().when("password", (password: string) => {
        if (password) {
          return Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED);
        }
      }),
      password: Yup.string(),
      password_confirmation: Yup.string().when("password", (password: string) => {
        if (password) {
          return Yup.string()
            .required(FORM_ERROR_MESSAGES.REQUIRED)
            .min(10, FORM_ERROR_MESSAGES.MIN_PASS_CHARS)
            .max(128, `${FORM_ERROR_MESSAGES.MAX_STRING_LENGTH} 128`)
            .matches(IS_EXIST_DIGIT, FORM_ERROR_MESSAGES.DIGIT)
            .matches(IS_EXIST_LOWERCASE, FORM_ERROR_MESSAGES.LOWERCASE)
            .matches(IS_EXIST_UPPERCASE, FORM_ERROR_MESSAGES.UPPERCASE)
            .oneOf([Yup.ref("password")], FORM_ERROR_MESSAGES.PASSWORDS_MATCH);
        }
      }),
    }),
  },
};
