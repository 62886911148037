import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ProfileEditForm } from "@containers/Auth/components";
import { useScrollToTop, useOnlineAction } from "@shared/hooks";
import { ProfileEditDto } from "@shared/interfaces";
import { actions, selectors } from "@shared/store";
import { LeaveConfirmModal } from "@shared/components";

import "./index.scss";

export default function ProfileEditContainer() {
  useScrollToTop();
  const dispatch = useDispatch();
  const history = useHistory();
  const { onlineAction } = useOnlineAction();
  const user = useSelector(selectors.getUser());
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  const closeHandlerWithCheckUnsavedChanges = useCallback(() => {
    hasUnsavedChanges ? setShowLeaveConfirm(true) : onClose();
  }, [onClose, hasUnsavedChanges]);

  const handleSubmit = useCallback(
    (values: ProfileEditDto) => {
      dispatch(
        actions.editProfile.request({
          data: values,
          callback: onClose,
        }),
      );
    },
    [dispatch, onClose],
  );

  const handleLeaveCancel = useCallback(() => {
    setShowLeaveConfirm(false);
  }, []);

  const handleLeaveSuccess = useCallback(() => {
    setShowLeaveConfirm(false);
    onClose();
  }, [onClose]);

  return (
    <div className="profile-edit-container">
      <LeaveConfirmModal
        isShowing={showLeaveConfirm}
        handleCancel={handleLeaveCancel}
        handleSuccess={handleLeaveSuccess}
      />
      <ProfileEditForm
        onChange={() => setHasUnsavedChanges(true)}
        user={user}
        onSubmit={(values) => onlineAction(() => handleSubmit(values))}
        onClose={closeHandlerWithCheckUnsavedChanges}
      />
    </div>
  );
}
