import React, { useState, useEffect } from "react";
import classnames from "classnames";
import ReactFlow, {
  Elements,
  Edge,
  Node,
  Controls,
  OnLoadParams,
  useZoomPanHelper,
} from "react-flow-renderer";
import "./index.scss";
import { useWindowDimensions } from "@shared/hooks";
import {
  IGuideContainer,
  ICustomEdge,
  ICustomElements,
} from "@shared/components/InteractiveGuide/interfaces";
import { NODES, EDGES } from "@shared/components/InteractiveGuide/constants";
import variables from "@styles/variables.scss";
import {
  EdgeMarkerDefinition,
  GuideEdge,
  GuideElement,
} from "@shared/components/InteractiveGuide/components";

import { getElements, getEdges } from "./utils";

const InteractiveGuide = ({
  parameters,
  guideSteps,
  guideStepConnections,
  isEdit,
  onElementClick,
  onInitReactFlowInstance,
  forPdf,
  isFitView = true,
  showTooltip = false,
  isSetCenterActiveStep = false,
  zoom = 0.3,
}: IGuideContainer) => {
  const [isValid, setGuideStatus] = useState(false);
  const [elements, setElements] = useState<Elements>([]);
  const [edges, setEdges] = useState<ICustomEdge[]>([]);
  const [settings] = useState({
    nodesDraggable: false,
    paneMoveable: !forPdf,
    defaultZoom: 0,
    zoomOnScroll: !forPdf,
    preventScrolling: !forPdf,
  });
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null);
  const { width, isMobileVersion } = useWindowDimensions();
  const { setCenter } = useZoomPanHelper();

  useEffect(() => {
    const elementsArr: ICustomElements[] = getElements(guideSteps, isEdit, showTooltip, parameters);
    const edgesArr: ICustomEdge[] = getEdges(guideStepConnections);

    const copiedElements = JSON.parse(JSON.stringify(elementsArr));
    setElements(copiedElements as Elements);
    setEdges(edgesArr);
  }, [guideSteps, guideStepConnections, isEdit, isMobileVersion, showTooltip, parameters]);

  useEffect(() => {
    if (reactFlowInstance && elements.length && width && isFitView) {
      reactFlowInstance.fitView();
    }
  }, [reactFlowInstance, elements.length, width, isFitView]);

  const onLoad = (rf: OnLoadParams) => {
    setReactFlowInstance(rf);
    if (onInitReactFlowInstance) {
      onInitReactFlowInstance(rf);
    }
  };

  useEffect(() => {
    const steps = elements.map((step) => {
      const { sourcePosition, targetPosition } = step.data;
      return [sourcePosition, targetPosition];
    });
    setGuideStatus(elements && edges && steps.flat().some((step) => !!step.length));
  }, [elements, edges]);

  useEffect(() => {
    if (guideSteps.length && isSetCenterActiveStep) {
      const arrX = guideSteps.reduce((r: number[], i) => {
        if (!i.position) return r;
        return [...r, i.position.x];
      }, []);

      const arrY = guideSteps.reduce((r: number[], i) => {
        if (!i.position) return r;
        return [...r, i.position.y];
      }, []);

      const minX = Math.abs(Math.min(...arrX));
      const maxX = Math.abs(Math.max(...arrX));

      const minY = Math.abs(Math.min(...arrY));
      const maxY = Math.abs(Math.max(...arrY));
      setCenter((minX + maxX) / 2, (minY + maxY) / 2, zoom);
    }
  }, [guideSteps, setCenter, isSetCenterActiveStep, zoom]);

  return isValid ? (
    <div
      className={classnames("guide-container", {
        edit: isEdit,
        "for-pdf": forPdf,
      })}
    >
      {forPdf && <div className="guide-container-pdf-header">Schema</div>}
      {parameters.length && elements.length ? (
        <ReactFlow
          className="react-flow-container"
          {...settings}
          onLoad={onLoad}
          onElementClick={(
            _: React.MouseEvent<Element, MouseEvent>,
            element: Node<Elements> | Edge<ICustomEdge>,
          ) => onElementClick(Number(element.id))}
          nodeTypes={{ [NODES.CUSTOM_NODE]: GuideElement }}
          edgeTypes={{
            [EDGES.CUSTOM_EDGE_SUCCESS]: GuideEdge(EDGES.CUSTOM_EDGE_SUCCESS),
            [EDGES.CUSTOM_EDGE_FAILURE]: GuideEdge(EDGES.CUSTOM_EDGE_FAILURE),
          }}
          elements={[...elements, ...(edges as Edge[])]}
        >
          <EdgeMarkerDefinition id={EDGES.CUSTOM_EDGE_SUCCESS} color={variables.dark} />
          <EdgeMarkerDefinition id={EDGES.CUSTOM_EDGE_FAILURE} color={variables.red} />
          {!forPdf && <Controls showInteractive={false} className="controls" />}
        </ReactFlow>
      ) : null}
    </div>
  ) : null;
};

export default InteractiveGuide;
