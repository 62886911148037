import React, { useState, useCallback, useEffect, useMemo } from "react";
import classnames from "classnames";
import { InputFile, FileLabel, Icon, PdfViewer } from "@shared/components";
import { getFileExtension } from "@shared/utils";

import "./index.scss";
import { CustomFile, OldFile } from "../../../interfaces";

export enum PREVIEW_TYPE {
  IMAGE = "Image",
  DOCUMENT = "Document",
}

interface UploadFileProps {
  preview_type: "Image" | "Document";
  name: string;
  children: React.ReactNode;
  onChangeFile: ({ files, old_files }: { files: CustomFile[]; old_files: OldFile[] }) => void;
  onReject: (errorMessage: string) => void;
  value: {
    files: CustomFile[];
    old_files: OldFile[];
  };
  maxFileSize: number;
  maxFiles: number;
  accept?: string;
  label?: string;
  inlineInput?: boolean;
}

const validateFile = (
  file: CustomFile,
  options: { maxSize: number; maxFiles: number },
  countFiles: number,
): string => {
  if (options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  if (options.maxFiles && countFiles + 1 > options.maxFiles) {
    return `Maximum number of files per protocol is ${options.maxFiles}`;
  }

  return "";
};

const renderImage = (file: CustomFile | OldFile, old: boolean, children: React.ReactNode) => {
  const ext = getFileExtension(file.name);
  const fileData = old ? (file as OldFile).url : (file as CustomFile).base64;
  switch (ext) {
    case "pdf":
      return (
        <PdfViewer height={110} className="preview-img" key={file.name} data={fileData}>
          {children}
        </PdfViewer>
      );
    default:
      return (
        <div
          className="preview-img"
          key={file.name}
          style={{ backgroundImage: `url(${fileData})` }}
        >
          {children}
        </div>
      );
  }
};

const UploadFile: React.FunctionComponent<UploadFileProps> = (props: UploadFileProps) => {
  const {
    name,
    children,
    value,
    onChangeFile,
    preview_type,
    maxFileSize,
    maxFiles,
    onReject,
    accept,
    label,
    inlineInput,
  } = props;
  const [files, setFiles] = useState<CustomFile[]>([]);
  const [oldFiles, setOldFiles] = useState<OldFile[]>([]);

  useEffect(() => {
    if (!value || !value.files) {
      setFiles([]);
      return;
    }

    setFiles(value.files);
  }, [value, setFiles]);

  useEffect(() => {
    if (!value || !value.old_files) {
      setOldFiles([]);
      return;
    }

    setOldFiles(value.old_files);
  }, [value, setOldFiles]);

  const onUploadFile = useCallback(
    (file: CustomFile) => {
      const errorMessage = validateFile(
        file,
        { maxSize: maxFileSize, maxFiles },
        files.length + oldFiles.length,
      );
      if (errorMessage) {
        onReject(errorMessage);
        return;
      }

      const isExistsInNewFiles = files.find((f) => f.name === file.name);
      const isExistsInOldFiles = oldFiles.find((f) => f.name === file.name);

      if (!isExistsInNewFiles && !isExistsInOldFiles) {
        onChangeFile({
          files: [...files, file],
          old_files: oldFiles,
        });
      }
    },
    [files, onChangeFile, onReject, maxFileSize, maxFiles, oldFiles],
  );

  const onRemoveNewFile = useCallback(
    (file: CustomFile) => {
      onChangeFile({
        files: files.filter((f) => f.name !== file.name),
        old_files: oldFiles,
      });
    },
    [onChangeFile, files, oldFiles],
  );

  const onRemoveOldFile = useCallback(
    (file: OldFile) => {
      onChangeFile({
        old_files: oldFiles.filter((f) => f.id !== file.id),
        files,
      });
    },
    [onChangeFile, files, oldFiles],
  );

  const renderInput = useMemo(() => {
    if (maxFiles && files.length + oldFiles.length >= maxFiles) {
      return null;
    }
    return (
      <div className="upload-file">
        <InputFile onChangeFile={onUploadFile} name={name} accept={accept}>
          {children}
        </InputFile>
      </div>
    );
  }, [name, accept, children, onUploadFile, maxFiles, files, oldFiles]);

  return (
    <div className={classnames("upload-file-wrapper", { "with-label": Boolean(label) })}>
      {label && <div className="upload-file-label">{label}</div>}
      <div className="upload-file-preview">
        {preview_type === PREVIEW_TYPE.IMAGE ? (
          <>
            {inlineInput && renderInput}
            {files.map((f) =>
              renderImage(f, false, <Icon type="delete" onClick={() => onRemoveNewFile(f)} />),
            )}
            {oldFiles.map((f) =>
              renderImage(f, true, <Icon type="delete" onClick={() => onRemoveOldFile(f)} />),
            )}
          </>
        ) : (
          <div
            className={classnames("preview-documents-wrapper", {
              "preview-documents-empty": !files.length && !oldFiles.length,
            })}
          >
            {inlineInput && renderInput}
            {files.map((f) => (
              <div className="preview-document" key={f.name}>
                <FileLabel label={f.name} icon="delete" onIconClick={() => onRemoveNewFile(f)} />
              </div>
            ))}
            {oldFiles.map((f) => (
              <div className="preview-document" key={f.id}>
                <FileLabel label={f.name} icon="delete" onIconClick={() => onRemoveOldFile(f)} />
              </div>
            ))}
          </div>
        )}
      </div>
      {!inlineInput && renderInput}
    </div>
  );
};

export default UploadFile;
