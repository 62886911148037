import { call, put, takeLatest } from "redux-saga/effects";
import { PROTOCOLS_LOADING_SECTION } from "@containers/Dashboard/constants";

import { Protocol } from "../../../shared/models";
import { addLoadingSection, removeLoadingSection } from "../../../shared/store/actions";
import api from "../api";

import { actions } from "./";

function* getProtocolList() {
  try {
    yield put(
      addLoadingSection({
        loadingSection: PROTOCOLS_LOADING_SECTION,
        requestName: "get_protocols",
      }),
    );
    const protocolsResponse: { count: number; items: Protocol[] } = yield call(api.getProtocols);
    yield put(actions.getProtocols.success(protocolsResponse));
  } catch (error) {
    yield put(actions.getProtocols.failure(error));
  } finally {
    yield put(
      removeLoadingSection({
        loadingSection: PROTOCOLS_LOADING_SECTION,
        requestName: "get_protocols",
      }),
    );
  }
}

function* dashboardSaga() {
  yield takeLatest(actions.getProtocols.request, getProtocolList);
}

export default dashboardSaga;
