export enum ProtocolActionTypes {
  GET_PROTOCOL = "@@PROTOCOL/GET_PROTOCOLS",
  GET_PROTOCOL_SUCCESS = "@@PROTOCOL/GET_PROTOCOLS_SUCCESS",
  GET_PROTOCOL_FAILURE = "@@PROTOCOL/GET_PROTOCOLS_FAILURE",

  CREATE_PROTOCOL = "@@PROTOCOL/CREATE_PROTOCOL",
  CREATE_PROTOCOL_SUCCESS = "@@PROTOCOL/CREATE_PROTOCOL_SUCCESS",
  CREATE_PROTOCOL_FAILURE = "@@PROTOCOL/CREATE_PROTOCOL_FAILURE",

  DELETE_PROTOCOL = "@@PROTOCOL/DELETE_PROTOCOL",
  DELETE_PROTOCOL_SUCCESS = "@@PROTOCOL/DELETE_PROTOCOL_SUCCESS",
  DELETE_PROTOCOL_FAILURE = "@@PROTOCOL/DELETE_PROTOCOL_FAILURE",

  EDIT_PROTOCOL = "@@PROTOCOL/EDIT_PROTOCOL",
  EDIT_PROTOCOL_SUCCESS = "@@PROTOCOL/EDIT_PROTOCOL_SUCCESS",
  EDIT_PROTOCOL_FAILURE = "@@PROTOCOL/EDIT_PROTOCOL_FAILURE",

  SET_PROTOCOL_FORM_CHANGED = "@@PROTOCOL/SET_PROTOCOL_FORM_CHANGED",

  SET_PROTOCOL_FAVORITE = "@@PROTOCOL/SET_PROTOCOL_FAVORITE",
  SET_PROTOCOL_FAVORITE_SUCCESS = "@@PROTOCOL/SET_PROTOCOL_FAVORITE_SUCCESS",
  SET_PROTOCOL_FAVORITE_FAILURE = "@@PROTOCOL/SET_PROTOCOL_FAVORITE_FAILURE",

  SHARE_PROTOCOLS = "@@PROTOCOL/SHARE_PROTOCOL",
  SHARE_PROTOCOLS_SUCCESS = "@@PROTOCOL/SHARE_PROTOCOLS_SUCCESS",
  SHARE_PROTOCOLS_FAILURE = "@@PROTOCOL/SHARE_PROTOCOLS_FAILURE",

  UPDATE_PROTOCOL_STATISTIC = "@@PROTOCOL/UPDATE_PROTOCOL_STATISTIC",
  UPDATE_PROTOCOL_STATISTIC_SUCCESS = "@@PROTOCOL/UPDATE_PROTOCOL_STATISTIC_SUCCESS",
  UPDATE_PROTOCOL_STATISTIC_FAILURE = "@@PROTOCOL/UPDATE_PROTOCOL_STATISTIC_FAILURE",
}
