import React from "react";

import { Icon } from "../../";

import "./index.scss";

interface AddBtnProps {
  onBtnClick?: () => void;
}

function AddBtn(props: AddBtnProps) {
  const { onBtnClick } = props;
  return (
    <div className={`add-btn`} onClick={onBtnClick}>
      <Icon type="plus" />
    </div>
  );
}

export default AddBtn;
