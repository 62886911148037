export const createInteractiveGuideStatisticRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["protocol_id", "answer", "platform"],
  properties: {
    protocol_id: { type: "string" },
    answer: { type: "string" },
    platform: { type: "string" },
  },
};

export const createInteractiveGuideStatisticResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["protocol_guindance_statistic_id"],
  properties: {
    protocol_guindance_statistic_id: { type: "number" },
  },
};
