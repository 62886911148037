import config from "../../config";

export enum API_ENUM {
  LOGIN = "LOGIN",
  REGISTRATION = "REGISTRATION",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SET_PASSWORD = "SET_PASSWORD",
  RESEND_CONFIRMATION = "RESEND_CONFIRMATION",
  RESEND_RESTORE_PASSWORD = "RESEND_RESTORE_PASSWORD",
  GET_USER_HASH = "GET_USER_HASH",
  ACTIVATE_USER_ACCOUNT = "ACTIVATE_USER_ACCOUNT",

  GET_PROTOCOLS = "GET_PROTOCOLS",
  GET_PROTOCOL = "GET_PROTOCOL",
  CREATE_PROTOCOL = "CREATE_PROTOCOL",
  DELETE_PROTOCOL = "DELETE_PROTOCOL",
  EDIT_PROTOCOL = "EDIT_PROTOCOL",
  SET_PROTOCOL_FAVORITE = "SET_PROTOCOL_FAVORITE",
  SHARE_PROTOCOLS = "SHARE_PROTOCOLS",
  UPDATE_PROTOCOL_STATISTIC = "UPDATE_PROTOCOL_STATISTIC",

  CREATE_INTERACTIVE_GUIDE_STATISTIC = "CREATE_INTERACTIVE_GUIDE_STATISTIC",

  GET_PROCEDURE_TYPES = "GET_PROCEDURE_TYPES",
  GET_TAGS = "GET_TAGS",
  GET_PARAMETERS = "GET_PARAMETERS",
  GET_TYPE_SUBTYPES = "GET_TYPE_SUBTYPES",
  CREATE_TAG = "CREATE_TAG",
  GET_LATEST_APP_VERSION = "GET_LATEST_APP_VERSION",
  GET_PRESIGNED_URL = "GET_PRESIGNED_URL",

  GET_USER_DETAILS = "GET_USER_DETAILS",
  CHECK_EMAIL_AVAILABILITY = "CHECK_EMAIL_AVAILABILITY",

  SET_IS_ACKNOWLEDGED = "SET_IS_ACKNOWLEDGED",
  PROFILE_EDIT = "PROFILE_EDIT",
  SET_PLAY_GUIDE_STATUS = "SET_PLAY_GUIDE_STATUS",
}

export default {
  AUTH: {
    [API_ENUM.LOGIN]: `${config.baseApiUrl}auth/login`,
    [API_ENUM.FORGOT_PASSWORD]: `${config.baseApiUrl}auth/forgot-password`,
    [API_ENUM.SET_PASSWORD]: `${config.baseApiUrl}auth/set-password`,
    [API_ENUM.RESEND_CONFIRMATION]: `${config.baseApiUrl}auth/resend-confirmation`,
    [API_ENUM.RESEND_RESTORE_PASSWORD]: `${config.baseApiUrl}auth/resend-forgot`,
    [API_ENUM.REGISTRATION]: `${config.baseApiUrl}auth/registration`,
    [API_ENUM.GET_USER_HASH]: (hash: string) => `${config.baseApiUrl}auth/hash/${hash}`,
    [API_ENUM.ACTIVATE_USER_ACCOUNT]: (hash: string) => `${config.baseApiUrl}auth/activate/${hash}`,
  },
  PROTOCOL: {
    [API_ENUM.GET_PROTOCOLS]: `${config.baseApiUrl}protocols/`,
    [API_ENUM.GET_PROTOCOL]: (id: string) => `${config.baseApiUrl}protocols/${id}`,
    [API_ENUM.CREATE_PROTOCOL]: `${config.baseApiUrl}protocols/`,
    [API_ENUM.EDIT_PROTOCOL]: (id: string) => `${config.baseApiUrl}protocols/${id}`,
    [API_ENUM.DELETE_PROTOCOL]: (id: number | string) => `${config.baseApiUrl}protocols/${id}`,
    [API_ENUM.SET_PROTOCOL_FAVORITE]: (id: number | string) =>
      `${config.baseApiUrl}protocols/${id}/favorites`,
    [API_ENUM.SHARE_PROTOCOLS]: `${config.baseApiUrl}protocols/share`,
  },
  SHARED: {
    [API_ENUM.GET_PROCEDURE_TYPES]: `${config.baseApiUrl}shared/procedure-types/`,
    [API_ENUM.GET_TAGS]: `${config.baseApiUrl}shared/tags/`,
    [API_ENUM.GET_PARAMETERS]: `${config.baseApiUrl}shared/parameters/`,
    [API_ENUM.GET_TYPE_SUBTYPES]: `${config.baseApiUrl}shared/type-subtypes/`,
    [API_ENUM.CREATE_TAG]: `${config.baseApiUrl}shared/tags/`,
    [API_ENUM.GET_LATEST_APP_VERSION]: `${config.baseApiUrl}shared/app-version/`,
    [API_ENUM.GET_PRESIGNED_URL]: `${config.baseApiUrl}shared/presigned-url/`,
  },
  USER: {
    [API_ENUM.GET_USER_DETAILS]: `${config.baseApiUrl}users/details`,
    [API_ENUM.CHECK_EMAIL_AVAILABILITY]: `${config.baseApiUrl}users/availability`,
    [API_ENUM.SET_IS_ACKNOWLEDGED]: `${config.baseApiUrl}users/acknowledge`,
    [API_ENUM.PROFILE_EDIT]: `${config.baseApiUrl}users/edit`,
  },
  STATISTIC: {
    [API_ENUM.CREATE_INTERACTIVE_GUIDE_STATISTIC]: `${config.baseApiUrl}statistics/interactive-guide`,
    [API_ENUM.UPDATE_PROTOCOL_STATISTIC]: `${config.baseApiUrl}statistics/protocol`,
  },
};
