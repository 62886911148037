import React, { RefObject } from "react";
import { Gallery, Item, ItemRef } from "react-photoswipe-gallery";
import { File } from "@shared/models";
import { PdfViewer } from "@shared/components";
import { getFileExtension } from "@shared/utils";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import "./index.scss";

interface ProtocolImagesView {
  mainImage: File | null;
  images: File[];
}

const isPdf = (file: File) => {
  return getFileExtension(file.name) === "pdf";
};

function ProtocolImagesView(props: ProtocolImagesView) {
  const { mainImage, images } = props;

  return mainImage || images.length ? (
    <div className="protocol-info-row">
      <div className="protocol-info-columns">
        <div className="protocol-info-label">Flow diagrams</div>
        <Gallery shareButton={false}>
          {mainImage &&
            (isPdf(mainImage) ? (
              <PdfViewer
                className="image-file-wrapper main-image"
                data={mainImage.url}
                onlyImage={true}
                key={mainImage.id}
                firstPage={true}
                preloader={true}
              />
            ) : (
              <div className="protocol-main-image-wrapper">
                <Item original={mainImage.url} thumbnail={mainImage.url} width="1024" height="768">
                  {({ ref, open }: { ref: ItemRef; open: () => void }) => (
                    <img
                      onError={(e) => (e.currentTarget.style.display = "none")}
                      onLoad={(e) => (e.currentTarget.style.display = "block")}
                      ref={ref as RefObject<HTMLImageElement>}
                      onClick={open}
                      src={mainImage.url}
                      alt="Threathment algrorithm"
                    />
                  )}
                </Item>
              </div>
            ))}
          <div className="protocol-info-images">
            {images.map((file) =>
              isPdf(file) ? (
                <PdfViewer
                  className="image-file-wrapper"
                  data={file.url}
                  height={120}
                  onlyImage={true}
                  key={file.id}
                  preloader={true}
                />
              ) : (
                <div key={file.id} className="image-file-wrapper">
                  <Item original={file.url} thumbnail={file.url} width="1024" height="768">
                    {({ ref, open }: { ref: ItemRef; open: () => void }) => (
                      <img
                        ref={ref as RefObject<HTMLImageElement>}
                        onClick={open}
                        src={file.url}
                        onError={(e) => (e.currentTarget.style.display = "none")}
                        onLoad={(e) => (e.currentTarget.style.display = "block")}
                        alt="Protocol file"
                      />
                    )}
                  </Item>
                </div>
              ),
            )}
          </div>
        </Gallery>
      </div>
    </div>
  ) : null;
}

export default ProtocolImagesView;
