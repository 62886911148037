import React, { useState, useEffect, RefObject, useCallback, useMemo } from "react";
import { InteractiveGuide, Overlay, ScrollBtn } from "@shared/components";
import { GuideStepSetting, IGuideStep, ParameterUnit, Protocol } from "@shared/models";
import {
  ProtocolInteractiveGuideForm,
  ProtocolInteractiveGuideMobileForm,
} from "@containers/Protocol/components";
import {
  InteractiveGuideStepFormShape,
  InteractiveGuideStepSettingFormShape,
  ProtocolInteractiveGuideFormShape,
} from "@containers/Protocol/interfaces";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { useWindowDimensions } from "@shared/hooks";
import { ReactFlowProvider } from "react-flow-renderer";
import { OptionTypeBase, ValueType } from "react-select";
import { GUIDE_STEP_SETTING_FIELDS } from "@containers/Protocol/constants";
import { copyElement, getOptionValue } from "@shared/utils";

interface ProtocolInteractiveGuideContainerProps {
  parameters: ParameterUnit[];
  protocol: Protocol | null;
  formRef: RefObject<FormikProps<ProtocolInteractiveGuideFormShape>> | null;
  formValues: ProtocolInteractiveGuideFormShape;
  formErrors: FormikErrors<ProtocolInteractiveGuideFormShape>;
  formTouched: FormikTouched<ProtocolInteractiveGuideFormShape>;
  setFormValues: (values: ProtocolInteractiveGuideFormShape) => void;
  guideSteps: IGuideStep[];
  setGuideSteps: (guideSteps: IGuideStep[]) => void;
  onChangeForm: (isChanged: boolean) => void;
  isEdit: boolean;
}

const ProtocolInteractiveGuideContainer = (props: ProtocolInteractiveGuideContainerProps) => {
  const {
    formValues,
    formRef,
    isEdit,
    formErrors,
    formTouched,
    onChangeForm,
    setFormValues,
    guideSteps,
    setGuideSteps,
    protocol,
    parameters,
  } = props;

  const [selectedGuideStep, setSelectedGuideStep] = useState<IGuideStep | null>(null);
  const { width, isDesktop } = useWindowDimensions();

  const canEditFormDesktop = useMemo(() => {
    return isEdit && !!isDesktop && !!formValues.guide_steps.length;
  }, [isEdit, isDesktop, formValues.guide_steps.length]);

  const canEditFormMobile = useMemo(() => {
    return isEdit && !isDesktop;
  }, [isEdit, isDesktop]);

  const guideStepConnections = useMemo(() => {
    return protocol && protocol.guide_step_connections ? protocol.guide_step_connections : [];
  }, [protocol]);

  const onElementClick = useCallback(
    (id: number) => {
      if (!isDesktop && isEdit) {
        const element = guideSteps.find((gs) => gs.id === id && gs.sequence && gs.settings?.length);
        setSelectedGuideStep(element ? element : null);
      }
    },
    [guideSteps, setSelectedGuideStep, isDesktop, isEdit],
  );

  const getChangedGuideFormValues = useCallback(() => {
    if (!formRef || !formRef.current) return null;
    return formRef.current.values;
  }, [formRef]);

  const updateInteractiveGuides = useCallback(
    (guideStepValues: InteractiveGuideStepFormShape[]) => {
      const copyGuideSteps = copyElement<IGuideStep[]>(guideSteps);

      guideStepValues.forEach((guideStepValue) => {
        const guideStep = copyGuideSteps.find(
          (gs: IGuideStep) => gs.id === Number(guideStepValue.id),
        );

        if (guideStep && guideStep.settings) {
          guideStep.settings.map((s: GuideStepSetting) => {
            const setting = guideStepValue.settings.find((gsv) => gsv.id === s.id);
            if (setting) {
              s.parameter_unit_id = getOptionValue(setting.parameter_unit);
              s.text = setting.text || "";
            }

            return s;
          });
        }
      });

      setGuideSteps(copyGuideSteps);
    },
    [guideSteps, setGuideSteps],
  );

  const updateSettingDependencies = useCallback(
    (
      guideStepValues: InteractiveGuideStepFormShape[],
      newSettingValue: ValueType<OptionTypeBase, false> | string,
      shapeValue: InteractiveGuideStepSettingFormShape,
    ) => {
      const copyGuideSteps = copyElement(guideStepValues);

      const settings = copyGuideSteps.map((s) => s.settings).flat();
      shapeValue.dependencies.forEach((d) => {
        const setting = settings.find((s) => s.id === d);
        if (setting) {
          if (shapeValue.field_type === GUIDE_STEP_SETTING_FIELDS.PARAMETER) {
            setting.parameter_unit = newSettingValue as ValueType<OptionTypeBase, false>;
          } else {
            setting.text = newSettingValue as string;
          }
        }
      });

      return copyGuideSteps;
    },
    [],
  );

  const updateInteractiveGuideFormValues = useCallback(
    (guideStepValue: InteractiveGuideStepFormShape) => {
      setSelectedGuideStep(null);
      updateInteractiveGuides([guideStepValue]);
      const copyFormValues = copyElement(formValues);
      if (formValues) {
        const guideStepIndex = copyFormValues.guide_steps.findIndex(
          (gs: InteractiveGuideStepFormShape) => gs.id === Number(guideStepValue.id),
        );

        if (guideStepIndex !== -1) {
          copyFormValues.guide_steps[guideStepIndex] = guideStepValue;
          const steps = guideStepValue.settings.reduce((r, i) => {
            if (!i.dependencies.length) {
              return r;
            }

            const value = i.parameter_unit || i.text;
            return updateSettingDependencies(r, value, i);
          }, copyFormValues.guide_steps);

          updateInteractiveGuides(steps);
          setFormValues({ guide_steps: steps });
        }
      }
    },
    [formValues, setFormValues, updateInteractiveGuides, updateSettingDependencies],
  );

  const handleGuideFormChanges = useCallback(
    (
      fieldValue: ValueType<OptionTypeBase, false> | string,
      shapeValue: InteractiveGuideStepSettingFormShape,
    ) => {
      setTimeout(() => {
        onChangeForm(true);
        const values = getChangedGuideFormValues();
        if (values) {
          const steps = shapeValue.dependencies.length
            ? updateSettingDependencies(values.guide_steps, fieldValue, shapeValue)
            : values.guide_steps;

          updateInteractiveGuides(steps);
          setFormValues({ guide_steps: steps });
        }
      }, 0);
    },
    [
      onChangeForm,
      setFormValues,
      getChangedGuideFormValues,
      updateSettingDependencies,
      updateInteractiveGuides,
    ],
  );

  useEffect(() => {
    if (width) {
      setSelectedGuideStep(null);
    }
  }, [width, setSelectedGuideStep]);

  return (
    <div className="protocol-interractive-guide-container">
      {canEditFormMobile ? (
        <div className="protocol-interractive-guide-instruction">Click on item to change it</div>
      ) : null}
      {protocol ? (
        <>
          <div className="interractive-guide-view">
            <ScrollBtn />
            <ReactFlowProvider>
              <InteractiveGuide
                parameters={parameters}
                isEdit={canEditFormDesktop}
                guideSteps={guideSteps}
                onElementClick={onElementClick}
                guideStepConnections={guideStepConnections}
                showTooltip={false}
              />
            </ReactFlowProvider>
          </div>
          {canEditFormDesktop ? (
            <div className="interractive-guide-form">
              <ProtocolInteractiveGuideForm
                parameters={parameters}
                formValues={formValues}
                formRef={formRef}
                formErrors={formErrors}
                formTouched={formTouched}
                onChangeForm={handleGuideFormChanges}
              />
            </div>
          ) : null}
          {canEditFormMobile && selectedGuideStep ? (
            <Overlay
              onClose={() => {
                setSelectedGuideStep(null);
              }}
            >
              <ProtocolInteractiveGuideMobileForm
                parameters={parameters}
                guideStep={selectedGuideStep}
                onCloseForm={() => setSelectedGuideStep(null)}
                onChangeForm={() => {
                  onChangeForm(true);
                }}
                onSubmitForm={updateInteractiveGuideFormValues}
              />
            </Overlay>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default ProtocolInteractiveGuideContainer;
