import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as sharedActions, selectors as sharedSelectors } from "../store";
import useDebounce from "./DebounceHook";

// eslint-disable-next-line
type AnyFunction = (params?: any) => { type: string };
export interface ActionTypes {
  request: AnyFunction;
  success: AnyFunction;
  failure: AnyFunction;
}

interface UseLoaderProps {
  name: string;
  actionTypes: ActionTypes[];
}

export const useLoader = ({ name, actionTypes }: UseLoaderProps) => {
  const dispatch = useDispatch();
  const loadingSection = useSelector(sharedSelectors.getLoadingSection(name));

  const isLoading = useMemo(() => {
    return !!loadingSection && !!loadingSection.length;
  }, [loadingSection]);

  const isLoadingDebounced = useDebounce(isLoading, 750);

  useEffect(() => {
    if (name && actionTypes) {
      const loadingType = actionTypes.reduce(
        (acc, actionType) => {
          const requestType = actionType.request().type;
          const successType = actionType.success().type;
          const errorType = actionType.failure().type;

          acc.startActions.push(requestType);

          acc.stopActions[successType] = requestType;
          acc.stopActions[errorType] = requestType;

          return acc;
        },
        {
          name,
          startActions: [],
          stopActions: {},
        } as {
          name: string;
          startActions: string[];
          stopActions: { [key: string]: string };
        },
      );

      dispatch(sharedActions.addLoadingType(loadingType));
    }

    return () => {
      dispatch(sharedActions.removeLoadingType(name));
    };
  }, [name, dispatch, actionTypes]);

  return { isLoading, isLoadingDebounced: isLoading || isLoadingDebounced };
};
