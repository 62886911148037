import React from "react";

import { AuthShape } from "../../../interfaces";
import "./index.scss";

interface RestorePasswordEmailProps {
  defaultValue: AuthShape;
  onResend: (values: AuthShape) => void;
}

const RestorePasswordEmail = (props: RestorePasswordEmailProps) => {
  const { onResend, defaultValue } = props;

  return (
    <div className="restore-email-sent">
      <p>
        Email has been sent to <span className="email-value">{defaultValue.email}</span> <br /> We
        have sent you necessary instructions to recover your password.
      </p>
      <hr />
      <p>
        If you didn’t get the email please{" "}
        <span className="resend-link email-red-link" onClick={() => onResend(defaultValue)}>
          click here to send again.
        </span>
      </p>
    </div>
  );
};

export default RestorePasswordEmail;
