import React, { useCallback, useMemo } from "react";
import { NoResultFound } from "@shared/components";
import { Protocol, User } from "@shared/models";

import { ProtocolListItem } from "./components";
import config from "../../../../../config";

interface ProtocolsItem {
  searched: Protocol[];
  all: Protocol[];
}

interface ProtocolListProps {
  isSearch: boolean;
  protocols: Protocol[];
  allProtocols: Protocol[];
  user: User | null;
  onProtocolClick?: (protocol: Protocol) => void;
  onProtocolFavorite?: (protocol: Protocol) => void;
  loading: boolean;
}

function getFavorites(arr: Protocol[], userId: number) {
  return arr.filter((p) => (p.favorites || []).find((f) => f.user_id === userId));
}

function getShared(arr: Protocol[]) {
  return arr.filter((p) => p.is_shared);
}

function getCustomProtocols(arr: Protocol[], userId: number) {
  return arr.filter((p) => p.user_id === userId);
}

function ProtocolList(props: ProtocolListProps) {
  const { protocols, allProtocols, user, isSearch, onProtocolClick, onProtocolFavorite, loading } =
    props;

  const localOnProtocolClick = useCallback(
    (protocol: Protocol) => {
      onProtocolClick && onProtocolClick(protocol);
    },
    [onProtocolClick],
  );

  const localOnProtocolFavorite = useCallback(
    (protocol: Protocol) => {
      onProtocolFavorite && onProtocolFavorite(protocol);
    },
    [onProtocolFavorite],
  );

  const favoritesProtocols = useMemo<ProtocolsItem>(() => {
    if (!user || !user.id) return { searched: [], all: [] };
    const searched = getFavorites(protocols, user.id);
    const all = getFavorites(allProtocols, user.id);
    return { searched, all };
  }, [protocols, user, allProtocols]);

  const customProtocols = useMemo<ProtocolsItem>(() => {
    if (!user || !user.id) return { searched: [], all: [] };
    const searched = getCustomProtocols(protocols, user.id);
    const all = getCustomProtocols(allProtocols, user.id);
    return { searched, all };
  }, [protocols, user, allProtocols]);

  const nativeProtocols = useMemo(() => {
    return protocols.filter((p) => !p.user_id);
  }, [protocols]);

  const sharedProtocols = useMemo(() => {
    if (!user) return { searched: [], all: [] };
    const searched = getShared(protocols);
    const all = getShared(allProtocols);
    return { searched, all };
  }, [protocols, user, allProtocols]);

  return (
    <div className="protocol-list-container">
      {user ? (
        <>
          {Boolean(favoritesProtocols.all.length) && (
            <ProtocolListItem
              protocols={favoritesProtocols.searched}
              user={user}
              onProtocolFavorite={localOnProtocolFavorite}
              onProtocolClick={localOnProtocolClick}
              emptyMessage={isSearch ? undefined : "You don't have Favorite Protocols yet"}
              className="favorite-protocol-list"
              title="Favorite Protocols"
              loading={loading}
            />
          )}
          {Boolean(customProtocols.all.length) && !config.hideCustomize && (
            <ProtocolListItem
              protocols={customProtocols.searched}
              user={user}
              onProtocolFavorite={localOnProtocolFavorite}
              onProtocolClick={localOnProtocolClick}
              emptyMessage={isSearch ? undefined : "You don't have Custom Protocols yet"}
              className="custom-protocol-list"
              title="Custom Protocols"
              loading={loading}
            />
          )}
          {Boolean(sharedProtocols.all.length) && !config.hideShare && (
            <ProtocolListItem
              protocols={sharedProtocols.searched}
              user={user}
              onProtocolFavorite={localOnProtocolFavorite}
              onProtocolClick={localOnProtocolClick}
              emptyMessage={isSearch ? undefined : "You don't have Shared Protocols yet"}
              className="favorite-protocol-list"
              title="Shared with you"
              loading={loading}
            />
          )}
          <ProtocolListItem
            protocols={config.hideCustomize ? protocols : nativeProtocols}
            user={user}
            onProtocolFavorite={localOnProtocolFavorite}
            onProtocolClick={localOnProtocolClick}
            emptyMessage={isSearch ? undefined : "You don't have Native Protocols yet"}
            className="standart-protocol-list"
            title={config.hideCustomize ? "All Protocols" : "Native Protocols"}
            loading={loading}
          />
        </>
      ) : (
        <ProtocolListItem
          protocols={nativeProtocols}
          user={user}
          onProtocolFavorite={localOnProtocolFavorite}
          onProtocolClick={localOnProtocolClick}
          emptyMessage={isSearch ? undefined : "You don't have Native Protocols yet"}
          className="standart-protocol-list not-authorized"
          noResultsComponent={
            isSearch ? (
              <NoResultFound loading={loading} isIcon={true} text="Sorry! No results found" />
            ) : undefined
          }
        />
      )}
    </div>
  );
}

export default ProtocolList;
