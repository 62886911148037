import React from "react";

import "./index.scss";

export const NOT_MATHES_TEXT = "No Matches Found";

interface EmptyListMessageProps {
  text?: string;
}

function EmptyListMessage(props: EmptyListMessageProps) {
  const { text = NOT_MATHES_TEXT } = props;
  return <div className="empty-list-message">{text}</div>;
}

export default EmptyListMessage;
