import React from "react";
import "./index.scss";

interface OverlayProps {
  children: React.ReactNode;
  onClose: () => void;
}

function Overlay(props: OverlayProps) {
  return (
    <>
      <div className="overlay" onClick={() => props.onClose()} />
      <div className="overlay-wrapper">
        <div className="inner-wrapper">{props.children}</div>
      </div>
    </>
  );
}

export default Overlay;
