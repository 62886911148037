export enum AuthActionTypes {
  UPDATE_CURRENT_AUTH_FORM = "@@AUTH/UPDATE_CURRENT_AUTH_FORM",

  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  SIGN_UP = "@@AUTH/SIGN_UP",
  SIGN_UP_SUCCESS = "@@AUTH/SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE = "@@AUTH/SIGN_UP_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  CHECK_EMAIL_AVAILABILITY = "@@AUTH/CHECK_EMAIL_AVAILABILITY",
  CHECK_EMAIL_AVAILABILITY_SUCCESS = "@@AUTH/CHECK_EMAIL_AVAILABILITY_SUCCESS",
  CHECK_EMAIL_AVAILABILITY_FAILURE = "@@AUTH/CHECK_EMAIL_AVAILABILITY_FAILURE",

  ACTIVATE = "@@AUTH/ACTIVATE",
  ACTIVATE_SUCCESS = "@@AUTH/ACTIVATE_SUCCESS",
  ACTIVATE_FAILURE = "@@AUTH/ACTIVATE_FAILURE",

  RESEND_CONFIRMATION = "@@AUTH/RESEND_CONFIRMATION",
  RESEND_CONFIRMATION_SUCCESS = "@@AUTH/RESEND_CONFIRMATION_SUCCESS",
  RESEND_CONFIRMATION_FAILURE = "@@AUTH/RESEND_CONFIRMATION_FAILURE",

  RESTORE_PASSWORD = "@@AUTH/RESTORE_PASSWORD",
  RESTORE_PASSWORD_SUCCESS = "@@AUTH/RESTORE_PASSWORD_SUCCESS",
  RESTORE_PASSWORD_FAILURE = "@@AUTH/RESTORE_PASSWORD_FAILURE",

  RESEND_RESTORE = "@@AUTH/RESEND_RESTORE",
  RESEND_RESTORE_SUCCESS = "@@AUTH/RESEND_RESTORE_SUCCESS",
  RESEND_RESTORE_FAILURE = "@@AUTH/RESEND_RESTORE_FAILURE",

  SET_NEW_PASSWORD = "@@AUTH/SET_NEW_PASSWORD",
  SET_NEW_PASSWORD_SUCCESS = "@@AUTH/SET_NEW_PASSWORD_SUCCESS",
  SET_NEW_PASSWORD_FAILURE = "@@AUTH/SET_NEW_PASSWORD_FAILURE",

  SET_RESTORE_HASH = "@@AUTH/SET_RESTORE_HASH",
}
