import React, { useContext, Dispatch, SetStateAction } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ProtocolContext } from "@containers/Dashboard/containers";
import { DashboardFilter } from "@containers/Dashboard/interfaces";
import {
  AddBtn,
  FilterBtn,
  InputSearch,
  MultiselectSearch,
  StyledButton,
} from "@shared/components";

import config from "../../../../../config";

interface ProtocolViewProps {
  showFilter: boolean;
  tagsOptions: { value: number; label: string }[];
  protocolFilter: DashboardFilter;
  handleFilterChange: (dispatch: Dispatch<AnyAction>, value: Partial<DashboardFilter>) => void;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  onCreateNewProtocol: () => void;
}

const ProtocolView = ({
  showFilter,
  tagsOptions,
  protocolFilter,
  handleFilterChange,
  setShowFilter,
  onCreateNewProtocol,
}: ProtocolViewProps) => {
  const dispatch = useDispatch();
  const { toggleShareModal, isShare } = useContext(ProtocolContext);

  return (
    <>
      {!isShare && !config.hideShare ? (
        <div className={classnames("share", { "hide-tablet": showFilter })}>
          <StyledButton
            className="squized-mobile-button"
            label="Share"
            icon="share"
            onClick={toggleShareModal}
            hideLabelMobile={true}
            hideTablet={showFilter}
          />
        </div>
      ) : null}
      <div
        className={classnames("filter-item", {
          show: showFilter,
        })}
      >
        <MultiselectSearch
          options={tagsOptions}
          values={protocolFilter.tags}
          labelMore={"Tags"}
          placeholder="Select Tag"
          onApply={(tags) => handleFilterChange(dispatch, { tags: tags as string[] })}
        />
      </div>
      <div
        className={classnames("filter-item", {
          show: showFilter,
        })}
      >
        <InputSearch
          onChangeSearch={(search) => handleFilterChange(dispatch, { search })}
          placeholder="Search"
          showClearText={true}
          value={protocolFilter.search}
        />
      </div>
      {!showFilter && !isShare ? (
        <div className="add-filter-block">
          <FilterBtn onBtnClick={() => setShowFilter(!showFilter)} />
        </div>
      ) : null}
      {!showFilter && !isShare ? (
        <div className="add-block">
          <AddBtn onBtnClick={() => onCreateNewProtocol()} />
        </div>
      ) : null}
    </>
  );
};

export default ProtocolView;
