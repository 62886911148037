import React from "react";

import "./index.scss";

interface TooltipProps {
  children: React.ReactNode;
  tooltipText?: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
  const { children, tooltipText } = props;

  return (
    <div className="tooltip_wrapper">
      {tooltipText && <div className="tooltip_block">{tooltipText}</div>}
      {children}
    </div>
  );
};

export default Tooltip;
