import React from "react";
import { Field, FieldProps, FormikErrors, FormikTouched } from "formik";
import { FormikErrorMessage, Input } from "@shared/components";
import { InputTypes } from "@shared/constants";
import { RegistrationShape } from "@containers/Auth/interfaces";

interface BaseProfileFormProps {
  errors: FormikErrors<RegistrationShape>;
  touched: FormikTouched<RegistrationShape>;
  editMode?: boolean;
}

const BaseProfileForm = (props: BaseProfileFormProps) => {
  const { errors, touched, editMode } = props;
  return (
    <>
      <div className="input-row columns">
        <Field name="first_name">
          {({ field }: FieldProps) => (
            <div className="input column">
              <Input type={InputTypes.TEXT} isRequiredField={true} label="First Name" {...field}>
                <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
              </Input>
            </div>
          )}
        </Field>
        <Field name="last_name">
          {({ field }: FieldProps) => (
            <div className="input column">
              <Input type={InputTypes.TEXT} isRequiredField={true} label="Last Name" {...field}>
                <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
              </Input>
            </div>
          )}
        </Field>
      </div>
      <div className="input-row columns">
        <Field name="title">
          {({ field }: FieldProps) => (
            <div className="input column">
              <Input type={InputTypes.TEXT} label="Title" {...field}>
                <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
              </Input>
            </div>
          )}
        </Field>
        <Field name="service_line">
          {({ field }: FieldProps) => (
            <div className="input column">
              <Input type={InputTypes.TEXT} autoComplete="false" label="Service Line" {...field}>
                <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
              </Input>
            </div>
          )}
        </Field>
      </div>
      {editMode && (
        <Field name="current_password">
          {({ field }: FieldProps) => (
            <div className="input-row current-password">
              <Input
                type={InputTypes.PASSWORD}
                autoComplete="off"
                label="Current Password"
                {...field}
                isShowPasswordIcon={true}
              >
                <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
              </Input>
            </div>
          )}
        </Field>
      )}
      <Field name="password">
        {({ field }: FieldProps) => (
          <div className="input-row">
            <Input
              type={InputTypes.PASSWORD}
              isRequiredField={!editMode}
              label={editMode ? "New Password" : "Password"}
              {...field}
              autoComplete="off"
              isShowPasswordIcon={true}
            >
              <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
            </Input>
          </div>
        )}
      </Field>
      <Field name="password_confirmation">
        {({ field }: FieldProps) => (
          <div className="input-row">
            <Input
              autoComplete="off"
              type={InputTypes.PASSWORD}
              isRequiredField={!editMode}
              label="Confirm Password"
              {...field}
              isShowPasswordIcon={true}
            >
              <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
            </Input>
          </div>
        )}
      </Field>
    </>
  );
};

export default BaseProfileForm;
