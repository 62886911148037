import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { Protocol, FavoriteDto } from "@shared/models";

import { DashboardFilter } from "../interfaces";
import { DashboardActionTypes } from "./constants";

export const updateFilter = createStandardAction(DashboardActionTypes.UPDATE_FILTER)<
  Partial<DashboardFilter>
>();

export const getProtocols = createAsyncAction(
  DashboardActionTypes.GET_PROTOCOLS,
  DashboardActionTypes.GET_PROTOCOLS_SUCCESS,
  DashboardActionTypes.GET_PROTOCOLS_FAILURE,
)<undefined, { items: Protocol[]; count: number }, Error>();

export const setProtocolFavorite = createStandardAction(
  DashboardActionTypes.SET_PROTOCOL_FAVORITE,
)<FavoriteDto>();
