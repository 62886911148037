import { FormikProps, FormikErrors, FormikTouched } from "formik";
import { useRef, RefObject, useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors as sharedSelectors } from "@shared/store";
import {
  selectors as protocolSelectors,
  actions as protocolActions,
} from "@containers/Protocol/store";
import { PROTOCOL_TYPES } from "@shared/constants";
import {
  getFormValues as getProtocolFormValues,
  getSubtypeOptions,
  prepareFormValues as prepareProtocolFormValues,
} from "@containers/Protocol/components/ProtocolDetailContainer/ProtocolForm/formHelpers";
import {
  getFormValues as getInteractiveFormValues,
  prepareGuideFormValues,
} from "@containers/Protocol/components/ProtocolDetailContainer/ProtocolInteractiveGuideForm/formHelpers";
import { validateFormValues } from "@shared/utils";
import { useExtraInfoProtocol } from "@shared/hooks";
import { IGuideStep, Protocol } from "@shared/models";

import {
  CreateProtocolDto,
  EditProtocolDto,
  ProtocolFormShape,
  ProtocolInteractiveGuideFormShape,
} from "../interfaces";
import { ProtocolFormSchema, ProtocolTabs } from "../constants";

const protocolSchema = ProtocolFormSchema.CREATE_EDIT_PROTOCOL.VALIDATION;
const guideSchema = ProtocolFormSchema.CREATE_EDIT_INTERACTIVE_GUIDE.VALIDATION;

export default function useEditProtocol() {
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState<string | null>(null);
  const [isChangedForms, setIsChangedForms] = useState(false);
  const [isEditProtocolForm, setIsEditProtocolForm] = useState(false);
  const [guideSteps, setGuideSteps] = useState<IGuideStep[]>([]);

  const protocol: Protocol | null = useSelector(protocolSelectors.getCurrentProtocol());
  const typeSubtypes = useSelector(sharedSelectors.getTypeSubtypes());
  const parameters = useSelector(sharedSelectors.getParameters());
  const { isNative, isShared, isInteractive } = useExtraInfoProtocol(protocol);

  useEffect(() => {
    if (protocol?.id) {
      setCurrentForm(isInteractive ? ProtocolTabs.guide : ProtocolTabs.info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInteractive, protocol?.id]);

  // PROTOCOL FORM
  const protocolFormRef = useRef(null) as RefObject<FormikProps<ProtocolFormShape>> | null;
  const [protocolFormErrors, setProtocolFormErrors] = useState<FormikErrors<ProtocolFormShape>>({});
  const [protocolFormTouched, setProtocolFormTouched] = useState<FormikTouched<ProtocolFormShape>>(
    {},
  );
  const [protocolFormValues, setProtocolFormValues] = useState<ProtocolFormShape>(
    getProtocolFormValues([], null),
  );

  const hasFiles = useMemo(() => {
    return Boolean(protocol && protocol.files?.length);
  }, [protocol]);

  // GUID FORM
  const guideFormRef = useRef(null) as RefObject<
    FormikProps<ProtocolInteractiveGuideFormShape>
  > | null;
  const [guideFormErrors, setGuideFormErrors] = useState<
    FormikErrors<ProtocolInteractiveGuideFormShape>
  >({});
  const [guideFormTouched, setGuideFormTouched] = useState<
    FormikTouched<ProtocolInteractiveGuideFormShape>
  >({});
  const [guideFormValues, setGuideFormValues] = useState<ProtocolInteractiveGuideFormShape>({
    guide_steps: [],
  });

  const updateForms = useCallback(() => {
    if (protocolFormRef?.current) {
      setProtocolFormErrors(protocolFormRef.current.errors);
      setProtocolFormTouched(protocolFormRef.current.touched);
      setProtocolFormValues(protocolFormRef.current.values);

      return { protocolValues: protocolFormRef.current.values, guideValues: guideFormValues };
    }

    if (guideFormRef?.current) {
      setGuideFormErrors(guideFormRef.current.errors);
      setGuideFormTouched(guideFormRef.current.touched);
      setGuideFormValues(guideFormRef.current.values);
      return { protocolValues: protocolFormValues, guideValues: guideFormRef.current.values };
    }

    return { protocolValues: protocolFormValues, guideValues: guideFormValues };
  }, [
    protocolFormRef,
    setProtocolFormErrors,
    setProtocolFormTouched,
    setProtocolFormValues,
    guideFormRef,
    setGuideFormErrors,
    setGuideFormTouched,
    setGuideFormValues,
    guideFormValues,
    protocolFormValues,
  ]);

  const validateForms = async (
    protocolValues: ProtocolFormShape,
    guideValues: ProtocolInteractiveGuideFormShape,
  ) => {
    const validator = {
      [ProtocolTabs.info]: () =>
        validateFormValues(protocolFormRef, protocolSchema, protocolValues, () => {
          setCurrentForm(ProtocolTabs.info);
        }),
      [ProtocolTabs.guide]: () =>
        validateFormValues(
          guideFormRef,
          guideSchema,
          guideValues as ProtocolInteractiveGuideFormShape,
          () => {
            setCurrentForm(ProtocolTabs.guide);
          },
        ),
    };

    switch (currentForm) {
      case ProtocolTabs.info: {
        const isValidProtocolForm = await validator[ProtocolTabs.info]();
        if (!isValidProtocolForm) return false;
        setProtocolFormErrors({});

        const isValidGuideForm = await validator[ProtocolTabs.guide]();
        if (!isValidGuideForm) return false;
        setGuideFormErrors({});

        break;
      }
      case ProtocolTabs.guide: {
        const isValidGuideForm = await validator[ProtocolTabs.guide]();
        if (!isValidGuideForm) return false;
        setProtocolFormErrors({});

        const isValidProtocolForm = await validator[ProtocolTabs.info]();
        if (!isValidProtocolForm) return false;
        setGuideFormErrors({});

        break;
      }
    }

    return true;
  };

  const createEditProtocol = async (status: string) => {
    const { protocolValues, guideValues } = updateForms();
    const isValisForm = await validateForms(protocolValues, guideValues);
    if (!isValisForm) {
      return;
    }

    const formatedProtocolValues = prepareProtocolFormValues(
      { ...protocolValues, status },
      typeSubtypes,
      PROTOCOL_TYPES.CUSTOM,
      protocol,
    );

    if (!formatedProtocolValues) return;
    formatedProtocolValues.guide_steps = prepareGuideFormValues(guideValues);

    setIsChangedForms(false);
    setIsEditProtocolForm(false);

    if (protocol && !isNative && !isShared) {
      dispatch(
        protocolActions.editProtocol.request({
          id: protocol.id,
          data: formatedProtocolValues as EditProtocolDto,
        }),
      );
    } else {
      dispatch(protocolActions.createProtocol.request(formatedProtocolValues as CreateProtocolDto));
    }
  };

  // UPDATE INIT FORM DATA
  useEffect(() => {
    setGuideSteps(protocol && protocol.guide_steps ? protocol.guide_steps : []);

    if (!protocol || isEditProtocolForm) {
      setProtocolFormValues(
        getProtocolFormValues(getSubtypeOptions(typeSubtypes, PROTOCOL_TYPES.CUSTOM), protocol),
      );

      if (protocol) {
        setGuideFormValues(getInteractiveFormValues(protocol.guide_steps || [], parameters));
      }
    }
  }, [protocol, typeSubtypes, isEditProtocolForm, parameters]);

  useEffect(() => {
    setProtocolFormErrors({});
    setProtocolFormTouched({});
    setGuideFormErrors({});
    setGuideFormTouched({});
  }, [isEditProtocolForm]);

  return {
    protocolFormRef,
    protocolFormErrors,
    protocolFormTouched,
    setProtocolFormErrors,
    setProtocolFormTouched,
    protocolFormValues,
    setProtocolFormValues,
    guideFormRef,
    guideFormErrors,
    setGuideFormErrors,
    guideFormTouched,
    setGuideFormTouched,
    guideFormValues,
    setGuideFormValues,
    updateForms,
    currentForm,
    setCurrentForm,
    createEditProtocol,
    isChangedForms,
    setIsChangedForms,
    setIsEditProtocolForm,
    isEditProtocolForm,
    guideSteps,
    setGuideSteps,
    parameters,
    hasFiles,
  };
}
