import { EDGES } from "@shared/components/InteractiveGuide/constants";
import { IGuideStepConnection } from "@shared/models";
import classnames from "classnames";
import React, { useMemo } from "react";
import "./index.scss";

interface GuideArrowProps {
  connection: IGuideStepConnection | null;
}

const GuideArrow = (props: GuideArrowProps) => {
  const { connection } = props;

  const isYesArrow = useMemo(() => {
    return !connection || connection.type === EDGES.CUSTOM_EDGE_SUCCESS;
  }, [connection]);

  return (
    <>
      <div className={classnames("protocol-guide-arrow", { yes: isYesArrow })}>
        <div className="protocol-guide-arrow-line" />
        <div className="protocol-guide-arrow-triangle" />
        <div className="protocol-guide-arrow-text">{isYesArrow ? "Yes" : "No"}</div>
      </div>
    </>
  );
};

export default GuideArrow;
