import React from "react";
import classnames from "classnames";

import "./index.scss";

export interface IconLabelProps {
  icon?: string;
  label: string;
  className?: string;
}

function IconLabel(props: IconLabelProps) {
  const { icon, label, className } = props;
  return (
    <div className={classnames("icon-label-wrapper", className)}>
      {icon && (
        <div className="icon-label-img-wrapper">
          <img className="icon-label-img" src={icon} alt="" />
        </div>
      )}
      <div className="icon-label-text">{label}</div>
    </div>
  );
}

export default IconLabel;
