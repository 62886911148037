import React, { useMemo } from "react";
import { IGuideStep, ParameterUnit } from "@shared/models";
import "./index.scss";
import { getStepLabelText } from "@containers/Protocol/utils";

interface ResultConditionProps {
  parameters: ParameterUnit[];
  step: IGuideStep;
}

const ResultCondition = (props: ResultConditionProps) => {
  const { step, parameters } = props;
  const stepText = useMemo(() => getStepLabelText(step, parameters), [step, parameters]);

  return (
    <div>
      <div
        className="result-conditions"
        dangerouslySetInnerHTML={{ __html: `Your result: ${stepText}` }}
      />
    </div>
  );
};

export default ResultCondition;
