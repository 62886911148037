import { Protocol, FavoriteDto } from "../models";

export const updateProtocolFavorites = (protocol: Protocol, result: FavoriteDto): Protocol => {
  if (!protocol.favorites) {
    protocol.favorites = [];
  }
  if (result.favorite_id) {
    protocol.favorites.push({
      id: result.favorite_id,
      user_id: result.user_id,
      protocol_id: protocol.id,
      is_deleted: false,
    });
  } else {
    protocol.favorites = [];
  }
  return protocol;
};
