import React from "react";
import "./index.scss";

const LoadingIndicator = () => (
  <div className="loading-wrapper">
    <div className="loader">Loading...</div>
  </div>
);

export default LoadingIndicator;
