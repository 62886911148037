import { userHashSchema } from "./";

export const loginRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email", "password"],
  properties: {
    email: { type: "string" },
    password: { type: "string" },
  },
};

export const loginResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    token: { type: "string" },
  },
};

export const registrationRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email", "password", "first_name", "last_name", "title", "service_line"],
  properties: {
    email: { type: "string" },
    password: { type: "string" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    phone: { type: ["string", "null"] },
    title: { type: ["string", "null"] },
    service_line: { type: ["string", "null"] },
  },
};

export const registrationResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    hash: userHashSchema,
    message: { type: "string" },
  },
};

export const resendConfirmationRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email"],
  properties: {
    email: { type: "string" },
  },
};

export const resendConfirmationResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
  },
};

export const activateResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    token: { type: "string" },
  },
};

export const activateRequestSchema = {
  type: "object",
  additionalProperties: false,
  properties: {},
};

export const restorePasswordRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["email"],
  properties: {
    email: { type: "string" },
  },
};

export const restorePasswordResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    message: { type: "string" },
  },
};
