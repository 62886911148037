export enum NODES {
  CUSTOM_NODE = "custom-node",
  // todo -> add other nodes ...
}

export enum EDGES {
  CUSTOM_EDGE_SUCCESS = "custom-edge-success",
  CUSTOM_EDGE_FAILURE = "custom-edge-failure",
}

export enum SHAPE_TYPES {
  RHOMBUS = "rhombus",
  DEFAULT = "default",
  SWAMP = "swamp",
  MAIN = "main",
  ROUNDED = "rounded",
  JOINT = "joint",
}

export enum STEP_HANDLES {
  TARGET = "target",
  SOURCE = "source",
}
