import classnames from "classnames";
import React, { useMemo, useCallback, useRef, useEffect, useState, useContext } from "react";
import { useExtraInfoProtocol, useWindowDimensions } from "@shared//hooks";
import { Protocol, User, Tag as TagModel } from "@shared/models";
import { Icon, Tag, Tooltip } from "@shared/components";
import { PROTOCOL_TYPES } from "@shared/constants";
import { ProtocolContext } from "@containers/Dashboard/containers";
import "./index.scss";

interface ProtocolItemProps {
  protocol: Protocol;
  onProtocolClick?: () => void;
  onProtocolFavorite: () => void;
  reducedView?: boolean;
  user: User | null;
}

function ProtocolItem(props: ProtocolItemProps) {
  const { protocol, onProtocolClick, reducedView, onProtocolFavorite, user } = props;
  const { isShare, protocolsToShare, setProtocolToShare } = useContext(ProtocolContext);
  const [itemWidth, setItemWidth] = useState(0);

  const {
    isInteractive,
    isNative,
    isFinished,
    isDocument,
    createdByLabel,
    createdAtFormatted,
    isFavorite,
    availableOffline,
  } = useExtraInfoProtocol(protocol, user);
  const itemRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  const protocolNameWidth = useMemo(() => {
    let minWidth = 130;
    if (isFinished) {
      minWidth += 50;
    }
    if (!protocol.based_on) {
      minWidth += 40;
    }
    if (!availableOffline) {
      minWidth += 40;
    }
    return `${minWidth}px`;
  }, [isFinished, protocol, availableOffline]);

  const tags = useMemo(() => {
    if (!protocol || !protocol.tags || !itemWidth) return [];
    const spaceWidth = 2;
    const maxWidthLength = itemWidth / 10.5;
    const totalWidth = 6;

    const tagsNameLength = protocol.tags.reduce(
      (r: number, t: TagModel) => r + t.name.length + spaceWidth,
      0,
    );

    const MAX_TAGS_NAME_LENGTH =
      tagsNameLength <= maxWidthLength ? maxWidthLength : maxWidthLength - totalWidth;

    return protocol.tags.reduce((result: TagModel[], item: TagModel) => {
      const nameLength = result.reduce(
        (r: number, t: TagModel) => r + t.name.length + spaceWidth,
        0,
      );

      return [
        ...result,
        ...(nameLength + item.name.length + spaceWidth > MAX_TAGS_NAME_LENGTH ? [] : [item]),
      ];
    }, []);
  }, [protocol, itemWidth]);

  const tagsTotal = useMemo(() => {
    return protocol.tags ? protocol.tags.length : 0;
  }, [protocol]);

  const protocolDescription = useMemo(() => {
    const MAX_LENGTH = 160;
    return protocol.short_description.length > MAX_LENGTH
      ? `${protocol.short_description.slice(0, MAX_LENGTH)}...`
      : protocol.short_description;
  }, [protocol]);

  const onFavoriteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onProtocolFavorite && onProtocolFavorite();
    },
    [onProtocolFavorite],
  );

  const onShareClick = useCallback(
    (e) => {
      e.stopPropagation();
      setProtocolToShare(protocol);
    },
    [protocol, setProtocolToShare],
  );

  const renderProtocolHeaderActions = useMemo(() => {
    if (isShare) {
      return (
        <Icon
          onClick={onShareClick}
          type={protocolsToShare.some((p) => p.id === protocol.id) ? "check" : "uncheck"}
        />
      );
    } else {
      return (
        <Icon onClick={onFavoriteClick} type={isFavorite ? "heart-selected" : "heart-empty"} />
      );
    }
  }, [isFavorite, isShare, onFavoriteClick, onShareClick, protocol, protocolsToShare]);

  useEffect(() => {
    if (itemRef && itemRef.current && width) {
      setItemWidth(itemRef.current.clientWidth);
    }
  }, [itemRef, width]);

  return (
    <div
      className={classnames("protocol-item-block box", { reduced: reducedView })}
      onClick={onProtocolClick}
      ref={itemRef}
    >
      <div className="protocol-item-header">
        <Tooltip tooltipText={protocol.name}>
          <div className="protocol-item-header-left" style={{ width: protocolNameWidth }}>
            {protocol.name}
          </div>
        </Tooltip>
        <div className="protocol-item-header-right">
          {availableOffline && <Icon type="available-offline" />}
          {!isFinished ? <div className="status">({protocol.status})</div> : null}
          {protocol.based_on && <div className="protocol-item-based-on">{protocol.based_on}</div>}
          {renderProtocolHeaderActions}
        </div>
      </div>
      <div
        className={classnames("divider", {
          native: isNative,
          custom: !isNative,
        })}
      />
      <div className="protocol-item-content">
        {!reducedView && (
          <div className="protocol-item-content-row">
            <div className="protocol-item-column">
              <Icon type={isNative ? "native-protocol" : "custom-protocol"} />
              <div
                className={classnames("text", {
                  native: isNative,
                })}
              >
                {isNative ? PROTOCOL_TYPES.NATIVE : PROTOCOL_TYPES.CUSTOM}
              </div>
            </div>
            {isInteractive ? (
              <div className="protocol-item-column">
                <Icon type="interactive-guide-card" />
                <div className="text">Interactive</div>
              </div>
            ) : null}

            {isDocument ? (
              <div className="protocol-item-column">
                <Icon type="document-bg" />
                <div className="text">Document</div>
              </div>
            ) : null}
          </div>
        )}
        {!reducedView ? (
          <>
            <div className="protocol-item-info description">
              <div className="protocol-item-info-column">
                <div className="protocol-item-info-header">Findings</div>
                <div className="protocol-item-info-value">{protocolDescription}</div>
              </div>
            </div>
            <div className="protocol-item-info protocol-sizes">
              {protocol.asa_rating && (
                <div className="protocol-item-info-column">
                  <div className="protocol-item-info-header">ASA Score</div>
                  <div className="protocol-item-info-value">{protocol.asa_rating}</div>
                </div>
              )}
            </div>
          </>
        ) : null}
        <div className="protocol-tags-row">
          {tags.map((t: TagModel) => (
            <Tag key={t.id} label={t.name} />
          ))}
          {tagsTotal - tags.length > 0 ? (
            <Tag key="count" label={`+ ${tagsTotal - tags.length}`} type="count" />
          ) : null}
        </div>
      </div>
      <div className="protocol-item-footer">
        <div className="protocol-item-footer-left">{`${createdByLabel} by ${protocol.created_by}`}</div>
        <div className="protocol-item-footer-right">{createdAtFormatted}</div>
      </div>
    </div>
  );
}

export default ProtocolItem;
