import React, { useMemo, useEffect } from "react";
import { EmptyListMessage } from "@shared/components";
import { ProtocolItem } from "@containers/Dashboard/components/ProtocolDashboardContainer/ProtocolList/components";
import { sampleSize } from "@shared/utils";
import { Protocol, User } from "@shared/models";

import "./index.scss";

const MAX_LIST_SIZE = 4;

let randomIds: number[] | null = null;

interface SimilarProtocolsListProps {
  similarProtocols: Protocol[];
  onProtocolClick?: (protocol: Protocol) => void;
  onProtocolFavorite?: (protocol: Protocol) => void;
  user: User | null;
}

function emptyList() {
  return (
    <div className="similar-empty-list">
      <EmptyListMessage />
    </div>
  );
}

function getRandomIds(filteredProtocols: Protocol[]) {
  if (!randomIds) {
    const randomElements = sampleSize<Protocol>(filteredProtocols, MAX_LIST_SIZE);
    randomIds = randomElements.map((rndItem) =>
      filteredProtocols.findIndex((item) => item.id === rndItem.id),
    );
  }
  return randomIds;
}

function SimilarProtocolsList(props: SimilarProtocolsListProps) {
  const { onProtocolClick, onProtocolFavorite, user, similarProtocols } = props;

  useEffect(() => {
    randomIds = null;
  }, []);

  const randomProtocols = useMemo(() => {
    if (similarProtocols.length <= MAX_LIST_SIZE) {
      return similarProtocols;
    }
    const randomIds = getRandomIds(similarProtocols);
    return randomIds.map((id) => similarProtocols[id]).filter((p) => !!p);
  }, [similarProtocols]);

  const listHasElements = useMemo(() => {
    return Boolean(randomProtocols.length);
  }, [randomProtocols]);

  const renderProtocols = useMemo(() => {
    return randomProtocols.map((protocol, index) => (
      <ProtocolItem
        onProtocolClick={() => onProtocolClick && onProtocolClick(protocol)}
        onProtocolFavorite={() => onProtocolFavorite && onProtocolFavorite(protocol)}
        key={index}
        reducedView={true}
        protocol={protocol}
        user={user}
      />
    ));
  }, [randomProtocols, onProtocolClick, onProtocolFavorite, user]);

  const renderList = useMemo(() => {
    return listHasElements ? (
      <div className="similar-protocols-list">{renderProtocols}</div>
    ) : (
      emptyList()
    );
  }, [listHasElements, renderProtocols]);

  return (
    <div className="similar-protocols-wrapper">
      <div className="similar-protocols-title">Other protocols you may be interested in:</div>
      {renderList}
    </div>
  );
}

export default SimilarProtocolsList;
