import { createStandardAction, createAsyncAction } from "typesafe-actions";

import { LoadingType, Notification, ProfileEditDto } from "../interfaces";
import { ParameterUnit, ProcedureType, Tag, TypeSubtype, User } from "../models";
import { SharedActionTypes } from "./constants";

export const startLoading = createStandardAction(SharedActionTypes.START_LOADING)();
export const addLoadingType = createStandardAction(
  SharedActionTypes.ADD_LOADING_TYPE,
)<LoadingType>();
export const removeLoadingType = createStandardAction(
  SharedActionTypes.REMOVE_LOADING_TYPE,
)<string>();

export const addLoadingSection = createStandardAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createStandardAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();

export const stopLoading = createStandardAction(SharedActionTypes.STOP_LOADING)();
export const clearCreatedTag = createStandardAction(SharedActionTypes.CLEAR_CREATED_TAG)();
export const showNotification = createStandardAction(
  SharedActionTypes.SHOW_NOTIFICATION,
)<Notification>();

export const getProcedureTypes = createAsyncAction(
  SharedActionTypes.GET_PROCEDURE_TYPES,
  SharedActionTypes.GET_PROCEDURE_TYPES_SUCCESS,
  SharedActionTypes.GET_PROCEDURE_TYPES_FAILURE,
)<undefined, { items: ProcedureType[]; count: number }, Error>();

export const getParameters = createAsyncAction(
  SharedActionTypes.GET_PARAMETERS,
  SharedActionTypes.GET_PARAMETERS_SUCCESS,
  SharedActionTypes.GET_PARAMETERS_FAILURE,
)<undefined, { items: ParameterUnit[]; count: number }, Error>();

export const getTags = createAsyncAction(
  SharedActionTypes.GET_TAGS,
  SharedActionTypes.GET_TAGS_SUCCESS,
  SharedActionTypes.GET_TAGS_FAILURE,
)<undefined, { items: Tag[]; count: number }, Error>();

export const getPresignedURL = createAsyncAction(
  SharedActionTypes.GET_PRESIGNED_URL,
  SharedActionTypes.GET_PRESIGNED_URL_SUCCESS,
  SharedActionTypes.GET_PRESIGNED_URL_FAILURE,
)<number, string, Error>();

export const createTag = createAsyncAction(
  SharedActionTypes.CREATE_TAG,
  SharedActionTypes.CREATE_TAG_SUCCESS,
  SharedActionTypes.CREATE_TAG_FAILURE,
)<Partial<Tag>, { message: string; tag: Tag }, Error>();

export const getTypeSubtypes = createAsyncAction(
  SharedActionTypes.GET_TYPE_SUBTYPES,
  SharedActionTypes.GET_TYPE_SUBTYPES_SUCCESS,
  SharedActionTypes.GET_TYPE_SUBTYPES_FAILURE,
)<undefined, { items: TypeSubtype[]; count: number }, Error>();

export const getUserData = createAsyncAction(
  SharedActionTypes.GET_USER,
  SharedActionTypes.GET_USER_SUCCESS,
  SharedActionTypes.GET_USER_FAILURE,
)<{ callback?: () => void }, User | null, Error>();

export const setIsAcknowledged = createAsyncAction(
  SharedActionTypes.SET_IS_ACKNOWLEDGED,
  SharedActionTypes.SET_IS_ACKNOWLEDGED_SUCCESS,
  SharedActionTypes.SET_IS_ACKNOWLEDGED_FAILURE,
)<undefined, undefined, Error>();

export const getLatestAppVersion = createAsyncAction(
  SharedActionTypes.GET_LATEST_APP_VERSION,
  SharedActionTypes.GET_LATEST_APP_VERSION_SUCCESS,
  SharedActionTypes.GET_LATEST_APP_VERSION_FAILURE,
)<undefined, { version: string }, Error>();

export const showDisclaimerModal = createStandardAction(SharedActionTypes.SHOW_DISCLAIMER_MODAL)();
export const closeDisclaimerModal = createStandardAction(
  SharedActionTypes.CLOSE_DISCLAIMER_MODAL,
)();

export const editProfile = createAsyncAction(
  SharedActionTypes.EDIT_PROFILE,
  SharedActionTypes.EDIT_PROFILE_SUCCESS,
  SharedActionTypes.EDIT_PROFILE_FAILURE,
)<{ data: ProfileEditDto; callback?: () => void }, { user: User; message: string }, Error>();

export const setIsOnline = createStandardAction(SharedActionTypes.SET_IS_ONLINE)<boolean>();
