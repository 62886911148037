import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import { SCHEMAS } from "../constants";
import schemas from "../schemas";
import tokenHandler from "./tokenHandler";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

const NO_CACHE_URLs = ["/auth/", "/users/"];

axiosInstance.interceptors.request.use(function (config) {
  const token = tokenHandler.get();

  if (config?.url && NO_CACHE_URLs.find((u) => config?.url?.includes(u))) {
    config.headers["Cache-Control"] = "no-cache";
  }

  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }
  return config;
});

const findSchema = (schemaKey: string) => {
  let requestSchema = schemas[schemaKey];
  if (requestSchema) return requestSchema;

  Object.keys(schemas).forEach((key) => {
    const regex = new RegExp(key);
    const result = regex.exec(schemaKey);
    if (result && result.length === 1) {
      requestSchema = schemas[key];
    }
  });

  return requestSchema;
};

// eslint-disable-next-line
const makeRequest = (instance: AxiosInstance) => (method: string, url: string, params: any) => {
  const requestSchema = findSchema(`${url}${SCHEMAS.REQUEST}${method}`);

  if (requestSchema) {
    // eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    const valid = requestSchema(...params);
    if (!valid) {
      console.error(`[${method}] ${url}`);
      console.error(requestSchema.errors);

      return Promise.reject({
        error: "Sended transfer object isn't valid",
      });
    }
  }

  // eslint-disable-next-line
  // @ts-ignore: Unreachable code error
  return instance[method](url, ...params);
};

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const response = res.data || res;
    const { config } = res || {};
    const { url, method } = config || {};
    const responseSchema = findSchema(`${url}${SCHEMAS.RESPONSE}${method}`);
    if (response.error) {
      return Promise.reject(response.error);
    }

    if (responseSchema) {
      const valid = responseSchema(response);
      if (!valid) {
        console.error(response);
        console.error(responseSchema.errors);

        return Promise.reject({
          error: "Received transfer object isn't valid",
        });
      }
    }

    return response;
  },
  (error: AxiosError) => {
    const { response } = error || {};
    const { data } = response || {};
    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(error);
  },
);
/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
// eslint-disable-next-line
export default (method: string, url: string) =>
  // eslint-disable-next-line
  (...params: any[]) =>
    makeRequest(axiosInstance)(method, url, params);
