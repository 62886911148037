import React from "react";
import classnames from "classnames";

import { IShapeProps } from "../../../../interfaces";
import "./index.scss";
import { InteractiveGuideTooltip } from "../../../InteractiveGuideTooltip";

export default ({
  color,
  label,
  sequence,
  is_edit,
  is_opacity,
  is_active,
  is_tooltip,
  styles,
}: IShapeProps) => {
  return (
    <div
      className={classnames(
        "diamond",
        {
          opacity: is_opacity,
          active: is_active,
          border: color.includes("#ffff"),
        },
        styles,
      )}
    >
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <filter id="dropshadow" height="100%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <path
          // eslint-disable-next-line
          // @ts-ignore
          style={{ "--color": color }}
          d="M2,50 50,2 98,50 50,98z"
          className="outer"
        />
      </svg>
      <InteractiveGuideTooltip
        text="You're here"
        show={!!is_active && !!is_tooltip}
        style={{ top: "-30px" }}
      />
      {sequence && is_edit ? <div className="sequence">{sequence}</div> : null}
      <div
        className={classnames("text", { position: !!sequence && is_edit })}
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    </div>
  );
};
