import React from "react";
import classnames from "classnames";

export type IconType =
  | "heart-selected"
  | "heart-empty"
  | "person"
  | "user-login"
  | "arrow-closed"
  | "arrow-opened"
  | "delete"
  | "check-mark"
  | "find"
  | "plus"
  | "plus-gray"
  | "filter"
  | "img"
  | "play"
  | "play-white"
  | "play-red"
  | "share"
  | "dots-white"
  | "dots-dark"
  | "edit"
  | "pdf"
  | "right-arrow"
  | "document"
  | "document-bg"
  | "docx"
  | "close-gray"
  | "interactive-guide-card"
  | "interactive-guide-bg"
  | "custom-protocol"
  | "native-protocol"
  | "interactive-guide"
  | "check"
  | "uncheck"
  | "copy"
  | "show-guide"
  | "safari-download"
  | "offline"
  | "available-offline"
  | "hide-guide";

export interface IconProps {
  type: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export default function Icon(props: IconProps) {
  const { type, className, onClick } = props;
  return <div onClick={onClick} className={classnames("icon", className, type)} />;
}
