import { GUIDE_STEP_SETTING_FIELDS } from "@containers/Protocol/constants";
import { SelectedStepResult } from "@containers/Protocol/interfaces/ProtocolGuidePlay.interface";
import { EDGES } from "@shared/components/InteractiveGuide/constants";
import { FIND_BR_TAGS } from "@shared/constants";
import { ExtraKeyType } from "@shared/interfaces";
import {
  GuideStepSetting,
  IGuideStep,
  IGuideStepConnection,
  ParameterUnit,
  Protocol,
} from "@shared/models";
import { getUniqueElements, sliceArray } from "@shared/utils";

export const getTextFromGuideSetting = (
  activeSetting: GuideStepSetting,
  settings: GuideStepSetting[],
  parameters: ParameterUnit[],
) => {
  switch (activeSetting.field_type) {
    case GUIDE_STEP_SETTING_FIELDS.PARAMETER: {
      const parameter = parameters.find((p) => p.id === activeSetting.parameter_unit_id);
      return parameter ? parameter.parameter : "";
    }
    case GUIDE_STEP_SETTING_FIELDS.TEXT:
    case GUIDE_STEP_SETTING_FIELDS.NUMBER: {
      return activeSetting.text;
    }
    case GUIDE_STEP_SETTING_FIELDS.UNIT: {
      const sourceSetting = settings.find(
        (s) => s.id === activeSetting.source_guide_step_setting_id,
      );
      if (!sourceSetting) {
        return "";
      }

      const parameter = parameters.find((p) => p.id === Number(sourceSetting.parameter_unit_id));
      return parameter ? parameter.unit : "";
    }
    default: {
      return "";
    }
  }
};

export const getTextFromGuideSettings = (
  settings: GuideStepSetting[],
  parameters: ParameterUnit[],
) => {
  return settings.reduce((result, item) => {
    return `${result} ${getTextFromGuideSetting(item, settings, parameters)}`;
  }, "");
};

export const getStepLabelText = (
  step: IGuideStep,
  parameters: ParameterUnit[],
  isHtmlTags = false,
) => {
  const text =
    step.settings && step.settings.length
      ? getTextFromGuideSettings(step.settings, parameters)
      : step.text;

  return isHtmlTags ? text : text.replace(new RegExp(FIND_BR_TAGS, "g"), "");
};

export const getFirstStep = (steps: IGuideStep[]): IGuideStep | null => {
  const stepsWithSequence = steps.filter((s) => s.sequence);
  return stepsWithSequence.reduce((prev: IGuideStep | null, curr: IGuideStep) => {
    return prev && prev.sequence && curr.sequence && prev.sequence < curr.sequence ? prev : curr;
  }, null);
};

export const getActiveStep = (selectedSteps: SelectedStepResult[]): SelectedStepResult => {
  return selectedSteps[selectedSteps.length - 1];
};

export const getConnectionsBySourceNode = (
  step: IGuideStep,
  stepConnections: IGuideStepConnection[],
) => stepConnections.filter((sc) => sc.step_source_id === step.id);

export const getNextStepsByStep = (
  currentStep: IGuideStep,
  steps: IGuideStep[],
  stepConnections: IGuideStepConnection[],
) => {
  const sourceConnections = getConnectionsBySourceNode(currentStep, stepConnections);
  return steps.filter((s) => sourceConnections.find((sc) => sc.step_target_id === s.id));
};

export const getYesNoConnectionsCounts = (connections: IGuideStepConnection[]) => {
  const YESNO = {
    [EDGES.CUSTOM_EDGE_FAILURE]: "NO",
    [EDGES.CUSTOM_EDGE_SUCCESS]: "YES",
  };

  return connections.reduce(
    (r: ExtraKeyType<number>, i: IGuideStepConnection) => {
      r[YESNO[i.type as EDGES]] += 1;
      return r;
    },
    {
      [YESNO[EDGES.CUSTOM_EDGE_FAILURE]]: 0,
      [YESNO[EDGES.CUSTOM_EDGE_SUCCESS]]: 0,
    },
  );
};

export const getSelectedStepsIds = (selectedSteps: SelectedStepResult[]) => {
  return selectedSteps.map((s) => s.step.id);
};

export const getSelectedConnectionsIds = (selectedSteps: SelectedStepResult[]) => {
  return selectedSteps
    .map((s) => s.connections)
    .flat()
    .map((s) => s.id);
};

export const updateOpacityInConnections = (
  connections: IGuideStepConnection[],
  selectedSteps: SelectedStepResult[],
) => {
  const connectionsIds = getSelectedConnectionsIds(selectedSteps);
  return connections.map((c) => ({ ...c, is_opacity: !connectionsIds.includes(c.id) }));
};

export const getGuideSteps = (protocol: Protocol | null) => {
  return protocol && protocol.guide_steps ? protocol.guide_steps : [];
};

export const getGuideStepConnections = (protocol: Protocol | null) => {
  return protocol && protocol.guide_step_connections ? protocol.guide_step_connections : [];
};

export const getAllStepsByStepRecursive = (
  step: IGuideStep,
  steps: IGuideStep[],
  connections: IGuideStepConnection[],
  excludeSteps: number[],
  results: number[],
): number[] => {
  if (results.includes(step.id)) {
    return results;
  }

  if (excludeSteps.includes(step.id)) {
    return results;
  }

  const nextSteps = getNextStepsByStep(step, steps, connections);
  results.push(step.id);

  if (!nextSteps.length) {
    return results;
  }

  nextSteps.forEach((ns) => {
    results = getAllStepsByStepRecursive(ns, steps, connections, excludeSteps, results);
  });

  return [...Array.from(new Set(results))];
};

export const hasStepOpacity = (step: IGuideStep | null | undefined) => {
  return step && step.is_hidden;
};

export const getMobileDisplayedConnections = (
  guideConnections: IGuideStepConnection[],
  selectedSteps: SelectedStepResult[],
) => {
  const selectedConnectionsIds = getSelectedConnectionsIds(selectedSteps);
  return guideConnections.map((gc) => ({
    ...gc,
    is_opacity: !selectedConnectionsIds.find((c) => gc.id === c),
  }));
};

export const getDesktopDisplayedConnections = (
  displayedSteps: IGuideStep[],
  guideConnections: IGuideStepConnection[],
  selectedSteps: SelectedStepResult[],
) => {
  const lastSelectedStep = getActiveStep(selectedSteps);
  const lastSelectedSteps = selectedSteps.filter((s) => s.step.id === lastSelectedStep.step.id);

  const allAvailableConnections = guideConnections.reduce(
    (r: IGuideStepConnection[], i: IGuideStepConnection) => {
      const stepSource = displayedSteps.find((s) => s.id === i.step_source_id);
      const stepTarget = displayedSteps.find((s) => s.id === i.step_target_id);
      if (stepSource && stepTarget) {
        if (lastSelectedStep.step.id !== stepSource.id) {
          r.push(i);
        } else {
          if (lastSelectedSteps.length !== 1) {
            r.push(i);
          }
        }
      }
      return r;
    },
    [],
  );

  return updateOpacityInConnections(allAvailableConnections, selectedSteps);
};

export const getMobileDisplayedSteps = (
  selectedSteps: SelectedStepResult[],
  guideSteps: IGuideStep[],
  guideConnections: IGuideStepConnection[],
) => {
  const activeStep = getActiveStep(selectedSteps).step;

  // get all selected steps
  const steps = selectedSteps.map((s) => s.step);

  // add opacity to steps
  const stepsWithOpacity = guideSteps.map((as) => ({
    ...as,
    is_active: as.id === activeStep.id,
    is_opacity: !steps.find((s) => s.id === as.id),
  }));

  return {
    steps: stepsWithOpacity,
    connections: getMobileDisplayedConnections(guideConnections, selectedSteps),
  };
};

export const getDisplayedSteps = (
  selectedSteps: SelectedStepResult[],
  guideSteps: IGuideStep[],
  guideConnections: IGuideStepConnection[],
  isMobile: boolean,
) => {
  if (isMobile) {
    return getMobileDisplayedSteps(selectedSteps, guideSteps, guideConnections);
  }

  const activeStep = getActiveStep(selectedSteps).step;
  // get all selected steps
  const steps = selectedSteps.map((s) => s.step);

  // get all previous steps from all selected
  const allPrevSteps = sliceArray(0, steps.length - 1, steps)
    .map((s) => getNextStepsByStep(s, guideSteps, guideConnections))
    .flat();

  // get all previous steps without selected
  const allPrevStepsWithoutSelected = allPrevSteps.filter(
    (ps) => !steps.find((s) => s.id === ps.id),
  );

  // add selected steps to allPrevStepsWithoutSelected if this step can be passed in another way
  steps.forEach((step) => {
    const sourceSteps = getSourceNodesIdsByNode(step, guideConnections);
    const existsInSelected = sourceSteps.filter((sourceStep) =>
      selectedSteps.find((selectedStep) => selectedStep.step.id === sourceStep),
    );

    if (existsInSelected.length > 1) {
      allPrevStepsWithoutSelected.push(step);
    }
  });

  // get all available steps from previos not selected steps
  let availableStepsFromPreviosNotSelectedSteps: number[] = [];
  // check if it is not the first step
  if (selectedSteps.length > 1) {
    availableStepsFromPreviosNotSelectedSteps = allPrevStepsWithoutSelected
      .map((s) =>
        getAllStepsByStepRecursive(
          s,
          guideSteps,
          guideConnections,
          steps.map((step) => step.id),
          [],
        ),
      )
      .flat();

    // get unique elements
    availableStepsFromPreviosNotSelectedSteps = getUniqueElements(
      availableStepsFromPreviosNotSelectedSteps,
    );
  }

  // get the steps to be displayed
  const availableSteps = guideSteps.filter(
    (s) =>
      steps.find((step) => step.id === s.id) ||
      availableStepsFromPreviosNotSelectedSteps.includes(s.id),
  );

  // add opacity to steps
  const stepsWithOpacity = availableSteps.map((as) => ({
    ...as,
    is_active: as.id === activeStep.id,
    is_opacity: !steps.find((s) => s.id === as.id),
  }));

  const hiddenSteps = guideSteps
    .filter((g) => !stepsWithOpacity.find((s) => g.id === s.id))
    .map((s) => ({ ...s, is_hidden: true }));

  return {
    steps: [...stepsWithOpacity, ...hiddenSteps],
    connections: getDesktopDisplayedConnections(stepsWithOpacity, guideConnections, selectedSteps),
  };
};

export const getSourceNodesIdsByNode = (
  guideStep: IGuideStep,
  connections: IGuideStepConnection[],
): number[] => {
  const incomingConnections = connections.filter((c) => c.step_target_id === guideStep.id);
  return incomingConnections.map((c) => c.step_source_id);
};

export const checkIsLabel = (guideStep: IGuideStep): boolean => {
  return !!(guideStep.styles || []).find((s) => s.value.includes("joint"));
};

export const getPreviousSelectedSteps = (
  selectedSteps: SelectedStepResult[],
  step: SelectedStepResult | null = null,
) => {
  if (step) {
    const selectedStepIndex = selectedSteps.findIndex((s) => s.index === step.index);
    return sliceArray(0, selectedStepIndex + 1, selectedSteps);
  }

  const steps = sliceArray(0, selectedSteps.length - 1, selectedSteps);
  const stepsWithoutLabels = steps.filter((s) => !checkIsLabel(s.step));
  const previousStep = stepsWithoutLabels[stepsWithoutLabels.length - 1].step;
  const lastIndex = steps.reduce(
    (acc, curr, index) => (curr.step.id === previousStep.id ? index : acc),
    0,
  );

  return sliceArray(0, lastIndex + 1, steps);
};
