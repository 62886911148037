import React from "react";
import { Switch } from "react-router";
import { useSelector } from "react-redux";

import "./index.scss";
import { PERMISSION, ROUTE_PATHS } from "../../../../shared/constants";
import { selectors } from "../../../Auth/store";
import PrivateRoute from "../../../App/PrivateRoute";

import { ProfileViewContainer, ProfileEditContainer } from "./";

const ProtocolContainer = () => {
  const isAuthentificated = useSelector(selectors.authentificated());

  return (
    <div className="profile-container">
      <Switch>
        <PrivateRoute
          path={ROUTE_PATHS.PROFILE_VIEW}
          component={ProfileViewContainer}
          authentificated={isAuthentificated}
          permissions={[PERMISSION.EDIT_PROTOCOL]}
          exact
        />
        <PrivateRoute
          path={ROUTE_PATHS.PROFILE_EDIT}
          component={ProfileEditContainer}
          authentificated={isAuthentificated}
          permissions={[PERMISSION.EDIT_PROTOCOL]}
          exact
        />
      </Switch>
    </div>
  );
};

export default ProtocolContainer;
