import { SelectedStepResult } from "@containers/Protocol/interfaces";
import { SHAPE_TYPES } from "@shared/components/InteractiveGuide/constants";
import { IGuideStepConnection, ParameterUnit } from "@shared/models";
import React, { useMemo } from "react";

import { GuideArrow } from "../GuideArrow";
import { DiamondShape, RectangleShape } from "./components";

import "./index.scss";

interface GuideElementProps {
  parameters: ParameterUnit[];
  connection: IGuideStepConnection | null;
  selectedStep: SelectedStepResult;
  onElementClick: (step: SelectedStepResult) => void;
}

const GuideElement = (props: GuideElementProps) => {
  const { selectedStep, onElementClick, parameters, connection } = props;

  const isDiamond = useMemo(() => {
    const styles = selectedStep.step.styles || [];
    return !!styles.find((s) => s.value.includes(SHAPE_TYPES.RHOMBUS));
  }, [selectedStep]);

  return (
    <div className="protocol-guide-element">
      {isDiamond ? (
        <DiamondShape
          step={selectedStep.step}
          onClick={() => onElementClick(selectedStep)}
          parameters={parameters}
        />
      ) : (
        <RectangleShape
          step={selectedStep.step}
          onClick={() => onElementClick(selectedStep)}
          parameters={parameters}
        />
      )}
      <GuideArrow connection={connection} />
    </div>
  );
};

export default GuideElement;
