import React, { useMemo, useCallback } from "react";
import { User } from "@shared/models";
import { actions } from "@shared/store";
import { Avatar, Divider } from "@shared/components";
import { EDWARDS_EMAIL, ROUTE_PATHS, SIDE_URL } from "@shared/constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { isMobile } from "@shared/utils";
import "./index.scss";

interface ProfileViewProps {
  user: User;
  onLogout: () => void;
  onClose: () => void;
  onEdit: () => void;
  closeOnEdit?: boolean;
}

function ProfileView(props: ProfileViewProps) {
  const { user, closeOnEdit, onLogout, onClose, onEdit } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const onLegalClick = useCallback(() => {
    isMobile() ? history.push(ROUTE_PATHS.DISCLAIMER) : dispatch(actions.showDisclaimerModal());
    onClose();
  }, [dispatch, history, onClose]);

  const actionLinks = useMemo(() => {
    return [{ name: "Terms of Use", action: onLegalClick }];
  }, [onLegalClick]);

  const renderActionLinks = useMemo(() => {
    return actionLinks.map((link) => (
      <div key={link.name} className="profile-view-link" onClick={link.action}>
        {link.name}
      </div>
    ));
  }, [actionLinks]);

  const avatarUrl = useMemo(() => {
    return user.files.length ? user.files[0].url : null;
  }, [user]);

  return (
    <div className="profile-view">
      <div className="close-profile-view" onClick={onClose}>
        Back
      </div>
      <div className="profile-view-info">
        <Avatar url={avatarUrl} />
        <div className="profile-view-name">
          {user.first_name} {user.last_name}
        </div>
        {user.title && <div className="addit-info">{user.title}</div>}
        {user.service_line && <div className="addit-info">{user.service_line}</div>}
        <div className="profile-view-email" title={user.email}>
          {user.email}
        </div>
        <div
          className="profile-view-edit"
          onClick={() => {
            onEdit();
            if (closeOnEdit) {
              onClose();
            }
          }}
        >
          Edit
        </div>
      </div>
      <Divider marginBottom="0" marginTop="0" />
      <div className="profile-view-links-wrapper">
        <div className="profile-view-links">
          {renderActionLinks}
          <a
            href={SIDE_URL.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="profile-view-link"
          >
            Privacy Policy
          </a>
          <a
            href={SIDE_URL.NORMAL_HEMODYNAMIC_RANGES}
            target="_blank"
            rel="noopener noreferrer"
            className="profile-view-link"
          >
            Normal Hemodynamic Ranges
          </a>
          <a className="profile-view-link" href={`mailto:${EDWARDS_EMAIL.SUPPORT}`}>
            Contact Us
          </a>
        </div>
        <div className="profile-view-logout" onClick={onLogout}>
          Log out
        </div>
      </div>
    </div>
  );
}

export default ProfileView;
