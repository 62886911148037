import { Field, FieldProps, Formik, FormikErrors, FormikProps, FormikTouched } from "formik";
import React, { useCallback, RefObject, useMemo } from "react";
import { useDispatch } from "react-redux";
import { OptionTypeBase, ValueType } from "react-select";
import { APPEARANCE } from "@shared/components/Notification/constants";
import { ProcedureType, Protocol, Tag, TypeSubtype } from "@shared/models";
import { actions } from "@shared/store";
import {
  FormikErrorMessage,
  Input,
  Textarea,
  UploadFile,
  Divider,
  Icon,
  StyledRadio,
} from "@shared/components";
import { Select } from "@shared/components/Input/Select";
import { PREVIEW_TYPE } from "@shared/components/Input/UploadFile/UploadFile";
import { InputTypes, PROTOCOL_TYPES, ROUTE_PATHS } from "@shared/constants";
import { getOptions, openLinkInNewWindow, focusToFirstError } from "@shared/utils";
import "./index.scss";

import { ProtocolFormShape } from "../../../interfaces";
import {
  uploadDocumentsChildren,
  uploadImagesChildren,
  validationSchema,
  MAX_FILES,
  MAX_ADDITIONAL_IMAGES,
  MAX_FILE_SIZE,
  getSubtypeOptions,
  basedOnItems,
} from "./formHelpers";
import {
  MAX_LONG_DESCRIPTION,
  MAX_REFERENCE,
  MAX_SHORT_DESCRIPTION,
  MAX_TAGS_COUNT,
  MAX_ASA_RATING,
  MAX_STUDY_SIZE,
  MAX_NAME,
} from "../../../constants";

interface ProtocolFormProps {
  formValues: ProtocolFormShape;
  formErrors: FormikErrors<ProtocolFormShape>;
  formTouched: FormikTouched<ProtocolFormShape>;
  protocol: Protocol | null;
  tags: Tag[];
  procedureTypes: ProcedureType[];
  typeSubtypes: TypeSubtype[];
  formRef: RefObject<FormikProps<ProtocolFormShape>> | null | undefined;
  onChangeForm: () => void;
  onCreateTag: () => void;
  protocols?: Protocol[];
}

const onProtocolClick = (protocol: Protocol) => {
  openLinkInNewWindow(`${ROUTE_PATHS.PROTOCOLS_LIST}/${protocol.id}`);
};

const ProtocolForm = (props: ProtocolFormProps) => {
  const {
    formRef,
    onChangeForm,
    tags,
    procedureTypes,
    typeSubtypes,
    onCreateTag,
    protocol,
    formValues,
    protocols = [],
    formErrors,
    formTouched,
  } = props;

  const dispatch = useDispatch();
  const subtypeOptions = useMemo(
    () => getSubtypeOptions(typeSubtypes, PROTOCOL_TYPES.CUSTOM),
    [typeSubtypes],
  );

  const tagsOptions = useMemo(() => getOptions<Tag>(tags, "name", "id"), [tags]);
  const procedureTypesOptions = useMemo(
    () => getOptions<ProcedureType>(procedureTypes, "name", "id"),
    [procedureTypes],
  );

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        actions.showNotification({
          message,
          appearance: APPEARANCE.ERROR,
        }),
      );
    },
    [dispatch],
  );

  const parentProtocol = useMemo(() => {
    if (!protocol || !protocol.parent_protocol_id) {
      return null;
    }

    const parentProtocol = protocols.find((p) => p.id === protocol.parent_protocol_id);
    return parentProtocol || null;
  }, [protocol, protocols]);

  return (
    <div className="protocol-form">
      <Formik
        validate={() => {
          setTimeout(focusToFirstError, 0);
        }}
        initialErrors={formErrors}
        initialTouched={formTouched}
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        initialValues={formValues}
      >
        {(formikProps) => (
          <form>
            <div className="form-header">
              <div className="form-header-left">
                <Field name="protocol_type">
                  {({ field }: FieldProps) => (
                    <div className="input-item protocol-type">
                      <Select
                        {...field}
                        label="Protocol Type"
                        placeholder="Select"
                        isDisabled={true}
                        options={subtypeOptions}
                        onChange={(value: ValueType<OptionTypeBase, false>) => {
                          formikProps.setFieldValue(field.name, value);
                          onChangeForm();
                        }}
                      />
                      <FormikErrorMessage
                        anyTouched={true}
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <div className="input-item protocol-name">
                      <Input
                        {...field}
                        label="Protocol Name"
                        isRequiredField={true}
                        type={InputTypes.TEXT}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        placeholder="Type something"
                        onInput={onChangeForm}
                        max={MAX_NAME}
                      />
                      <FormikErrorMessage
                        anyTouched={true}
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <Field name="procedure_types">
                  {({ field }: FieldProps) => {
                    return (
                      <div className="input-item protocol-procedure-types">
                        <Select
                          {...field}
                          label="Procedure types"
                          isRequiredField={true}
                          placeholder="Select"
                          options={procedureTypesOptions}
                          isClearable={true}
                          isMulti={true}
                          errors={formikProps.errors}
                          touched={formikProps.touched}
                          onChange={(value: ValueType<OptionTypeBase, false>) => {
                            formikProps.setFieldValue(field.name, value);
                            onChangeForm();
                          }}
                        />
                        <FormikErrorMessage
                          name={field.name}
                          errors={formikProps.errors}
                          touched={formikProps.touched}
                          anyTouched={true}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>
              {parentProtocol && (
                <div className="form-header-right">
                  <div className="parent-protocol-label">Link to original:</div>
                  <div
                    className="parent-protocol-link"
                    onClick={() => onProtocolClick(parentProtocol)}
                  >
                    <div className="parent-protocol-name">{parentProtocol.name}</div>
                    <Icon type="right-arrow" />
                  </div>
                </div>
              )}
            </div>
            <Divider marginTop="15px" marginBottom="20px" />
            <div className="form-center">
              <div className="form-center-left">
                <Field name="tags">
                  {({ field }: FieldProps) => (
                    <div className="input-item protocol-tags">
                      <Select
                        {...field}
                        label="Tags"
                        placeholder="Select"
                        max={MAX_TAGS_COUNT}
                        addButtonLabel="+ Create new tag"
                        isMulti={true}
                        options={tagsOptions}
                        isClearable={true}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        addButtonHandler={onCreateTag}
                        onChange={(value: ValueType<OptionTypeBase, false>) => {
                          formikProps.setFieldValue(field.name, value);
                          onChangeForm();
                        }}
                      />
                      <FormikErrorMessage
                        anyTouched={true}
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <Field name="short_description">
                  {({ field }: FieldProps) => (
                    <div className="input-item">
                      <Textarea
                        {...field}
                        label="Findings"
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        max={MAX_SHORT_DESCRIPTION}
                        onInput={onChangeForm}
                      />
                      <FormikErrorMessage
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <Field name="long_description">
                  {({ field }: FieldProps) => (
                    <div className="input-item">
                      <Textarea
                        {...field}
                        label="Background"
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        max={MAX_LONG_DESCRIPTION}
                        onInput={onChangeForm}
                      />
                      <FormikErrorMessage
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <Field name="study_size">
                  {({ field }: FieldProps) => (
                    <div className="input-item">
                      <Textarea
                        {...field}
                        label="Study size"
                        type={InputTypes.TEXT}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        max={MAX_STUDY_SIZE}
                        onInput={onChangeForm}
                      />
                      <FormikErrorMessage
                        anyTouched={true}
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
                <div className="input-item sizes">
                  <Field name="asa_rating">
                    {({ field }: FieldProps) => (
                      <div className="asa_rating">
                        <Input
                          {...field}
                          label="ASA Score"
                          type={InputTypes.TEXT}
                          errors={formikProps.errors}
                          touched={formikProps.touched}
                          max={MAX_ASA_RATING}
                          onInput={onChangeForm}
                        />
                        <FormikErrorMessage
                          anyTouched={true}
                          name={field.name}
                          errors={formikProps.errors}
                          touched={formikProps.touched}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="based_on">
                    {({ field }: FieldProps) => (
                      <div className="based_on">
                        <StyledRadio
                          {...field}
                          label="Is this protocol SV- or SVV-based?"
                          items={basedOnItems}
                          onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            const value = (event.target as HTMLInputElement).value;
                            formikProps.setFieldValue(field.name, value);
                            onChangeForm();
                          }}
                        />
                        <FormikErrorMessage
                          anyTouched={true}
                          name={field.name}
                          errors={formikProps.errors}
                          touched={formikProps.touched}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <Field name="reference">
                  {({ field }: FieldProps) => (
                    <div className="input-item">
                      <Textarea
                        {...field}
                        label="Reference"
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                        max={MAX_REFERENCE}
                        onInput={onChangeForm}
                      />
                      <FormikErrorMessage
                        name={field.name}
                        errors={formikProps.errors}
                        touched={formikProps.touched}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-center-right">
                <div className="input-item upload-images-wrapper">
                  <UploadFile
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    preview_type={PREVIEW_TYPE.IMAGE}
                    name="main_images"
                    onChangeFile={(value) => {
                      formikProps.setFieldValue("main_images", value);
                      onChangeForm();
                    }}
                    value={formikProps.values.main_images}
                    maxFileSize={MAX_FILE_SIZE}
                    maxFiles={1}
                    onReject={displayFileRejectMessage}
                    label="Treatment Algorithm"
                  >
                    {uploadImagesChildren}
                  </UploadFile>
                  <FormikErrorMessage
                    anyTouched={true}
                    name="images"
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                  />
                </div>
                <div className="input-item upload-images-wrapper">
                  <UploadFile
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    preview_type={PREVIEW_TYPE.IMAGE}
                    name="images"
                    onChangeFile={(value) => {
                      formikProps.setFieldValue("images", value);
                      onChangeForm();
                    }}
                    value={formikProps.values.images}
                    maxFileSize={MAX_FILE_SIZE}
                    maxFiles={MAX_ADDITIONAL_IMAGES}
                    onReject={displayFileRejectMessage}
                    label="Additional images"
                    inlineInput={true}
                  >
                    {uploadImagesChildren}
                  </UploadFile>
                  <FormikErrorMessage
                    anyTouched={true}
                    name="images"
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                  />
                </div>

                <div className="input-item">
                  <UploadFile
                    accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    preview_type={PREVIEW_TYPE.DOCUMENT}
                    name="documents"
                    value={formikProps.values.documents}
                    maxFileSize={MAX_FILE_SIZE}
                    maxFiles={MAX_FILES}
                    onChangeFile={(value) => {
                      formikProps.setFieldValue("documents", value);
                      onChangeForm();
                    }}
                    onReject={displayFileRejectMessage}
                    label="Reference documents"
                  >
                    {uploadDocumentsChildren}
                  </UploadFile>
                  <FormikErrorMessage
                    anyTouched={true}
                    name="documents"
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ProtocolForm;
