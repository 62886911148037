import React, { useEffect, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Protocol } from "@shared/models";
import { actions as sharedActions } from "@shared/store";

import { ProtocolView } from "../../../components";
import { selectors, actions } from "../../../store";

interface ProtocolViewContainerProps {
  protocolId: string;
  hasFiles: boolean;
}

const ProtocolViewContainer: FC<ProtocolViewContainerProps> = ({ protocolId, hasFiles }) => {
  const dispatch = useDispatch();

  const currentProtocol: Protocol | null = useSelector(selectors.getCurrentProtocol());

  useEffect(() => {
    if (protocolId && hasFiles) {
      dispatch(actions.getProtocol.request(String(protocolId)));
    }
  }, [dispatch, protocolId, hasFiles]);

  const onDownloadDocument = useCallback(
    (fileId: number) => {
      dispatch(sharedActions.getPresignedURL.request(fileId));
    },
    [dispatch],
  );

  return (
    <div className="protocol-view-container">
      {currentProtocol ? (
        <ProtocolView onDownloadDocument={onDownloadDocument} protocol={currentProtocol} />
      ) : null}
    </div>
  );
};

export default ProtocolViewContainer;
