import { ExtraKeyType } from "@shared/interfaces";
import React from "react";

import "./index.scss";

interface InteractiveGuideTooltipProps {
  text: string;
  show: boolean;
  style: ExtraKeyType<string | number>;
}

const InteractiveGuideTooltip = ({ text, show, style = {} }: InteractiveGuideTooltipProps) => {
  return show ? (
    <div className="guide-tooltip" style={style}>
      <div>{text}</div>
      <div className="tooltip-arrow" />
    </div>
  ) : null;
};

export default InteractiveGuideTooltip;
