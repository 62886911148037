import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";

import { INotificationProps } from "./interfaces";

const DISMISS_NOTIFICATION_TIMEOUT = 3000;

const Notification = (props: INotificationProps) => {
  const { notification } = props;
  const { addToast, removeAllToasts } = useToasts();

  useEffect(() => {
    removeAllToasts();

    if (notification) {
      addToast(notification.message, {
        appearance: notification.appearance,
        autoDismiss: true,
        autoDismissTimeout: DISMISS_NOTIFICATION_TIMEOUT,
      });
    }
  }, [addToast, removeAllToasts, notification]);

  return null;
};

export default Notification;
