import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import classnames from "classnames";
import { useOutsideClick } from "@shared/hooks";
import { InputSearch, Icon } from "@shared/components";
import { Option } from "@shared/interfaces";

import "./index.scss";

interface MultiselectSearchProps {
  options: Option[];
  values: (string | number)[];
  labelMore: string;
  placeholder: string;
  onApply: (values: (string | number)[]) => void;
}

function MultiselectSearch(props: MultiselectSearchProps) {
  const { options, values, labelMore, placeholder, onApply } = props;
  const wrapperRef = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { isOutside } = useOutsideClick(wrapperRef);
  const [selectedValues, setSelectedValues] = useState(values);
  const [searchValue, setSearchValue] = useState("");

  const optionResults = useMemo(
    () => options.filter((o) => selectedValues.includes(o.value)),
    [selectedValues, options],
  );

  const label = useMemo(() => {
    const firstSelectedOption = optionResults.find(Boolean);
    if (!firstSelectedOption) return "";
    if (optionResults.length === 1) return firstSelectedOption.label;

    return `${firstSelectedOption.label}, +${optionResults.length - 1} ${labelMore}`;
  }, [optionResults, labelMore]);

  const searchedOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, options]);

  const clearValues = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation();
      setSelectedValues([]);
      onApply([]);
      setSearchValue("");
      setIsOpenMenu(false);
    },
    [onApply, setSelectedValues],
  );

  const onSetValues = useCallback(() => {
    setIsOpenMenu(false);
    onApply(selectedValues);
    setSearchValue("");
  }, [onApply, selectedValues]);

  const onOptionSelect = useCallback(
    (option: Option) => {
      setSelectedValues((prevValues) => {
        const result = prevValues.find((v) => option.value === v);
        return result
          ? prevValues.filter((v) => v !== option.value)
          : [...prevValues, option.value];
      });
    },
    [setSelectedValues],
  );

  const onInputClick = useCallback(() => {
    setIsOpenMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setSelectedValues(values);
    setIsOpenMenu(false);
    setSearchValue("");
  }, [values]);

  const onCloseClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      closeMenu();
    },
    [closeMenu],
  );

  useEffect(() => {
    if (isOutside) {
      closeMenu();
    }
  }, [isOutside, closeMenu]);

  return (
    <div className="multiselect-block" ref={wrapperRef}>
      <div className="multiselect-block-wrapper">
        <div className="multiselect-input-block" onClick={onInputClick}>
          {!isOpenMenu ? (
            <div className="multiselect-input-block-preview">
              <div className="multiselect-input-block-preview-left">
                <div className="multiselect-result">{label || placeholder}</div>
              </div>
              <div className="multiselect-input-block-preview-right">
                {selectedValues.length ? (
                  <div
                    onClick={clearValues}
                    className={classnames("icon", {
                      delete: true,
                    })}
                  />
                ) : null}
                <Icon type="arrow-closed" />
              </div>
            </div>
          ) : null}
          {isOpenMenu ? (
            <div className="multiselect-input-block-search">
              <InputSearch
                showClearText={true}
                onChangeSearch={setSearchValue}
                placeholder="Search"
                value={searchValue}
              />
              <Icon type="arrow-opened" onClick={onCloseClick} />
            </div>
          ) : null}
        </div>
        {isOpenMenu ? (
          <div className="multiselect-menu-block">
            <div className="multiselect-menu-options">
              {searchedOptions.length ? (
                searchedOptions.map((o) => (
                  <div
                    className="multiselect-menu-option"
                    key={o.value}
                    onClick={() => onOptionSelect(o)}
                  >
                    <div className="multiselect-menu-option-label">{o.label}</div>
                    {selectedValues.includes(o.value) ? (
                      <div className="icon check-mark"></div>
                    ) : null}
                  </div>
                ))
              ) : (
                <div className="multiselect-menu-no-option">No options</div>
              )}
            </div>

            <div className="multiselect-menu-footer">
              <div className="multiselect-menu-footer-action dark" onClick={clearValues}>
                <span>Clear All</span>
              </div>
              <div className="multiselect-menu-footer-action red" onClick={onSetValues}>
                <span>Apply</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MultiselectSearch;
