import { generatePath } from "react-router-dom";
import { call, put, takeLatest } from "redux-saga/effects";
import { actions as dashboardActions } from "@containers/Dashboard/store";
import { ActionWithPayload } from "@shared/interfaces";
import { showNotification } from "@shared/store/actions";
import {
  sendShareEvent,
  sendProtocolCustomizeEvent,
  getProtocolFavoriteObject,
  sendFavoriteEvent,
} from "@shared/utils";

import { ROUTE_PATHS, PROTOCOL_SUBTYPES } from "../../../shared/constants";
import history from "../../../shared/history";
import { Protocol } from "../../../shared/models";
import { startLoading, stopLoading } from "../../../shared/store/actions";
import api from "../api";
import {
  CreateProtocolDto,
  EditProtocolDto,
  SharedProtocolDto,
  ProtocolUpdateStatisticDto,
} from "../interfaces";

import { actions } from "./";

function* getProtocol({ payload }: ActionWithPayload<string>) {
  try {
    yield put(startLoading());
    const protocolResponse: Protocol = yield call(api.getProtocol, String(payload));
    yield put(actions.getProtocol.success(protocolResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getProtocol.failure(error));
    history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
  }
}

function* createProtocol({ payload }: ActionWithPayload<CreateProtocolDto>) {
  try {
    yield put(startLoading());
    const protocolResponse: {
      protocol: Protocol;
      message: string;
    } = yield call(api.createProtocol, payload);

    yield put(actions.getProtocol.success(null));
    yield put(actions.createProtocol.success(protocolResponse));
    sendProtocolCustomizeEvent({
      protocol_id: protocolResponse.protocol.id,
      protocol_name: protocolResponse.protocol.name,
      procedure_types: protocolResponse.protocol.procedure_types
        ? protocolResponse.protocol.procedure_types.map((type) => type.name)
        : [],
      parent_protocol_id: protocolResponse.protocol.parent_protocol_id || undefined,
    });
    history.push(generatePath(ROUTE_PATHS.PROTOCOLS, { protocolId: protocolResponse.protocol.id }));
    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.createProtocol.failure(error));
  }
}

function* editProtocol({ payload }: ActionWithPayload<{ id: string; data: EditProtocolDto }>) {
  try {
    yield put(startLoading());

    const protocolResponse: {
      protocol: Protocol;
      message: string;
    } = yield call(api.editProtocol, payload);

    yield put(actions.editProtocol.success(protocolResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.editProtocol.failure(error));
  }
}

function* deleteProtocol({ payload }: ActionWithPayload<string>) {
  try {
    yield put(startLoading());

    const protocolResponse: {
      protocol_id: string;
      message: string;
    } = yield call(api.deleteProtocol, payload);

    yield put(actions.deleteProtocol.success(protocolResponse));
    history.push(ROUTE_PATHS.PROTOCOLS_LIST);

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.deleteProtocol.failure(error));
  }
}

function* setProtocolFavorite({ payload }: ActionWithPayload<Protocol>) {
  try {
    const response: {
      favorite_id: number | null;
      user_id: number;
      protocol_id: string;
    } = yield call(api.setFavoriteProtocol, payload.id);
    yield put(actions.setProtocolFavorite.success(response));
    yield put(dashboardActions.setProtocolFavorite(response));
    const protocolFavoriteData = getProtocolFavoriteObject(
      payload,
      !payload.user_id,
      payload.type_subtype?.subtype?.name === PROTOCOL_SUBTYPES.INTERACTIVE,
    );
    sendFavoriteEvent(protocolFavoriteData, !response.favorite_id);
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.setProtocolFavorite.failure(error));
  }
}

function* shareProtocols({ payload }: ActionWithPayload<SharedProtocolDto>) {
  try {
    const { message } = yield call(api.shareProtocols, payload);
    yield put(
      showNotification({
        message,
        appearance: "success",
      }),
    );
    const shareId = Date.now();
    for (const email of payload.emails) {
      for (const protocol of payload.protocols) {
        sendShareEvent({
          protocol_id: protocol.id,
          protocol_name: protocol.name,
          recipient: email,
          type: "email",
          share_id: String(shareId),
        });
      }
    }
  } catch (error) {
    yield put(actions.shareProtocols.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateProtocolStatistic({ payload }: ActionWithPayload<ProtocolUpdateStatisticDto>) {
  try {
    yield call(api.updateProtocolSatistic, payload);
  } catch (error) {
    console.error("Update protocol statistic error", payload, error);
  }
}

function* protocolSaga() {
  yield takeLatest(actions.getProtocol.request, getProtocol);
  yield takeLatest(actions.createProtocol.request, createProtocol);
  yield takeLatest(actions.editProtocol.request, editProtocol);
  yield takeLatest(actions.deleteProtocol.request, deleteProtocol);
  yield takeLatest(actions.setProtocolFavorite.request, setProtocolFavorite);
  yield takeLatest(actions.shareProtocols.request, shareProtocols);
  yield takeLatest(actions.updateProtocolStatistic.request, updateProtocolStatistic);
}

export default protocolSaga;
