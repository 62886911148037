import React, { useMemo } from "react";
import { Switch } from "react-router";
import { useSelector } from "react-redux";
import { useScrollToTop } from "@shared/hooks";
import { getLoading, getLoadingSections } from "@shared/store/selectors";

import { ProtocolDetailContainer } from "../";
import { PERMISSION, ROUTE_PATHS } from "../../../../shared/constants";
import { selectors } from "../../../Auth/store";
import PrivateRoute from "../../../App/PrivateRoute";
import { LoadingIndicator } from "../../../../shared/components";
import { ProtocolGuidePlayContainer } from "../ProtocolGuidePlayContainer";
import { ProtocolPrintContainer } from "../ProtocolPrintContainer";
import "./index.scss";

const ProtocolContainer = () => {
  useScrollToTop();

  const isAuthentificated = useSelector(selectors.authentificated());
  const loading = useSelector(getLoading());
  const loadingSections = useSelector(getLoadingSections());

  const isLoader = useMemo(() => loading || !!loadingSections.length, [loading, loadingSections]);

  return (
    <div className="protocol-container">
      {isLoader && <LoadingIndicator />}
      <Switch>
        <PrivateRoute
          path={ROUTE_PATHS.CREATE_PROTOCOL}
          component={ProtocolDetailContainer}
          authentificated={isAuthentificated}
          permissions={[PERMISSION.EDIT_PROTOCOL]}
          exact
        />
        <PrivateRoute
          path={ROUTE_PATHS.PROTOCOLS}
          component={ProtocolDetailContainer}
          authentificated={true}
          exact
        />
        <PrivateRoute
          path={ROUTE_PATHS.PROTOCOLS_GUIDE_PLAY}
          component={ProtocolGuidePlayContainer}
          authentificated={true}
          exact
        />
        <PrivateRoute
          path={ROUTE_PATHS.PROTOCOLS_PRINT}
          component={ProtocolPrintContainer}
          authentificated={true}
          exact
        />
      </Switch>
    </div>
  );
};

export default ProtocolContainer;
