import React, { useMemo } from "react";
import classnames from "classnames";
import { Protocol, FileType } from "@shared/models";
import { IconLabel, Divider, Tag, FileLabel } from "@shared/components";
import { useExtraInfoProtocol } from "@shared/hooks";
import { PROTOCOL_TYPES_IMG_NAME } from "@containers/Dashboard/constants";

import { ProtocolImagesView } from "../ProtocolImagesView";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import "./index.scss";

interface ProtocolViewProps {
  protocol: Protocol;
  forPdf?: boolean;
  onDownloadDocument?: (fileId: number) => void;
}

function ProtocolView(props: ProtocolViewProps) {
  const { protocol, forPdf, onDownloadDocument } = props;
  const { subTypeName, isInteractive, createdByLabel } = useExtraInfoProtocol(protocol);

  const protocolPdfHeader = useMemo(() => {
    if (!forPdf) {
      return null;
    }
    return (
      <div className="protocol-pdf-header-wrapper">
        <div className="protocol-pdf-header">
          <div className="protocol-pdf-header-name">{protocol.name}</div>
        </div>
        <div className="protocol-pdf-header-title">
          <div className="protocol-pdf-header-general">General Information</div>
          <div className="protocol-pdf-header-created">
            {createdByLabel} by {protocol.created_by}
          </div>
        </div>
      </div>
    );
  }, [forPdf, protocol, createdByLabel]);

  const imagesFiles = useMemo(() => {
    if (forPdf) {
      return [];
    }
    return (protocol.files || []).filter((file) => file.type === FileType.image);
  }, [protocol, forPdf]);

  const mainImage = useMemo(() => {
    const images = (protocol.files || []).filter((file) => file.type === FileType.mainImage);
    return images.find(Boolean) || null;
  }, [protocol]);

  const documentFiles = useMemo(() => {
    return (protocol.files || []).filter((file) => file.type === FileType.document);
  }, [protocol]);

  const renderType = useMemo(() => {
    if (!subTypeName) {
      return null;
    }
    return (
      <div className="protocol-info-columns">
        <div className="protocol-info-label">Protocol Type:</div>
        <IconLabel
          icon={
            isInteractive
              ? "/icons/general/interactive-guide-bg.svg"
              : "/icons/general/static-bg.svg"
          }
          label={subTypeName}
          className="protocol-type"
        />
      </div>
    );
  }, [subTypeName, isInteractive]);

  const renderProcedureTypes = useMemo(() => {
    if (!protocol.procedure_types?.length) {
      return null;
    }

    return (
      <div className="protocol-info-columns">
        <div className="protocol-info-label">Procedure Types:</div>
        <div className="protocol-info-subrow">
          {(protocol.procedure_types || []).map((type, index) => (
            <IconLabel
              className="protocol-procedure-item"
              key={index}
              icon={`/images/protocolTypes/${PROTOCOL_TYPES_IMG_NAME[type.name]}.svg`}
              label={type.name}
            />
          ))}
        </div>
      </div>
    );
  }, [protocol]);

  const renderTags = useMemo(() => {
    if (!protocol.tags?.length || forPdf) {
      return null;
    }
    return (
      <div className="protocol-info-columns">
        <div className="protocol-info-label">Tags:</div>
        <div className="protocol-info-subrow">
          {protocol.tags?.map((tag, index) => (
            <Tag className="protocol-tag-item" key={index} label={tag.name} />
          ))}
        </div>
      </div>
    );
  }, [protocol, forPdf]);

  const renderDocuments = useMemo(() => {
    if (!documentFiles.length || forPdf) {
      return null;
    }
    return (
      <div className="protocol-info-row">
        <div className="protocol-info-columns">
          <div className="protocol-info-label">Reference documents</div>
          <div className="protocol-info-documents">
            {documentFiles.map((file) => (
              <div
                key={file.id}
                className="document-item"
                onClick={() => onDownloadDocument && onDownloadDocument(file.id)}
              >
                <FileLabel label={file.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [documentFiles, forPdf, onDownloadDocument]);

  const renderFindings = useMemo(() => {
    return protocol.short_description ? (
      <div className="protocol-info-row">
        <div className="protocol-info-columns">
          <div className="protocol-info-label">Findings</div>
          <div className="protocol-info-text">{protocol.short_description}</div>
        </div>
      </div>
    ) : null;
  }, [protocol]);

  const renderBackground = useMemo(() => {
    return protocol.long_description ? (
      <div className="protocol-info-row">
        <div className="protocol-info-columns">
          <div className="protocol-info-label">Background</div>
          <div
            className="protocol-info-text"
            dangerouslySetInnerHTML={{ __html: protocol.long_description }}
          />
        </div>
      </div>
    ) : null;
  }, [protocol]);

  const renderReference = useMemo(() => {
    return protocol.reference ? (
      <div className="protocol-info-row">
        <div className="protocol-info-columns">
          <div className="protocol-info-label">Reference</div>
          <div className="protocol-info-text">{protocol.reference}</div>
        </div>
      </div>
    ) : null;
  }, [protocol]);

  const renderStudySize = useMemo(() => {
    return protocol.study_size ? (
      <div className="protocol-info-columns study-size">
        <div className="protocol-info-label">Study size</div>
        <div className="protocol-info-text">{protocol.study_size}</div>
      </div>
    ) : null;
  }, [protocol]);

  const renderAsaRating = useMemo(() => {
    return protocol.asa_rating ? (
      <div className="protocol-info-columns asa-rating">
        <div className="protocol-info-label">ASA Score</div>
        <div className="protocol-info-text">{protocol.asa_rating}</div>
      </div>
    ) : null;
  }, [protocol]);

  const renderBasedOn = useMemo(() => {
    return protocol.based_on ? (
      <div className="protocol-info-columns based-on">
        <div className="protocol-info-label">Protocol based on</div>
        <div className="protocol-info-text">{String(protocol.based_on).toUpperCase()}</div>
      </div>
    ) : null;
  }, [protocol]);

  return (
    <div
      className={classnames("protocol-info-wrapper", {
        "reduced-main-image": forPdf && Boolean(protocol.reference),
      })}
    >
      {protocolPdfHeader}
      <div className="protocol-info-row no-margin protocol-tags">
        {renderType}
        {renderProcedureTypes}
        {renderTags}
      </div>
      <Divider marginTop="23px" />
      {renderFindings}
      {renderBackground}
      <div className="protocol-info-row mobile-column study-rating-wrapper">
        {renderStudySize}
        <div className="study-rating">
          {renderAsaRating}
          {renderBasedOn}
        </div>
      </div>
      {renderReference}
      <ProtocolImagesView images={imagesFiles} mainImage={mainImage} />
      {renderDocuments}
    </div>
  );
}

export default ProtocolView;
