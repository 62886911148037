import Ajv from "ajv";

import authSchema from "./auth.schemas";
import protocolSchema from "./protocol.schemas";
import userSchema from "./user.schemas";
import sharedSchema from "./shared.schemas";
import statisticSchema from "./statistic.schemas";

const ajv = new Ajv();

const allSchemas = {
  ...authSchema,
  ...protocolSchema,
  ...userSchema,
  ...sharedSchema,
  ...statisticSchema,
};

const compiledSchemas = Object.entries(allSchemas).reduce((acc, [key, value]) => {
  acc[key] = ajv.compile(value);
  return acc;
}, {} as { [key: string]: Ajv.ValidateFunction });

export default compiledSchemas;
