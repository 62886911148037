import React from "react";
import classnames from "classnames";

import "./index.scss";

interface TextareaWrapperProps {
  className?: string;
  children: React.ReactNode;
}

export const TextareaWrapper = (props: TextareaWrapperProps) => {
  const { children, className = "" } = props;

  return <div className={classnames("textarea-wrapper", className)}>{children}</div>;
};
