export const typeSchema = {
  type: "object",
  required: ["name", "id"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    description: { type: ["string"] },
    is_deleted: { type: ["boolean"] },
    created_at: { type: ["string"] },
    updated_at: { type: ["string", "null"] },
    created_by: { type: ["string"] },
    updated_by: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const subtypeSchema = {
  type: "object",
  required: ["name", "id"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    description: { type: ["string"] },
    is_deleted: { type: ["boolean"] },
    created_at: { type: ["string"] },
    updated_at: { type: ["string", "null"] },
    created_by: { type: ["string"] },
    updated_by: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const typeSubtypeSchema = {
  type: "object",
  required: ["type_id", "subtype_id", "id", "type", "subtype"],
  properties: {
    id: { type: "number" },
    type_id: { type: "number" },
    subtype_id: { type: "number" },
    type: typeSchema,
    subtype: subtypeSchema,
  },
  additionalProperties: false,
};

export const typeSubtypesResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    count: { type: "number" },
    items: {
      type: ["array"],
      items: typeSubtypeSchema,
    },
  },
};
