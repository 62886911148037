import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect, Switch, Route, useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { isMobile, getGuestIsAcknowledged, setGuestIsAcknowledged } from "@shared/utils/functions";
import { Modal, Disclaimer, Footer, OnlineDetector } from "@shared/components";
import { useWindowDimensions } from "@shared/hooks";
import { init as googleAdvInit, setEventsIsAuthorized, sendDisclaimerEvent } from "@shared/utils";

import "./index.scss";

import { selectors as sharedSelectors, actions as sharedActions } from "../../shared/store";
import { selectors as authSelectors, actions as authActions } from "../../containers/Auth/store";
import { Header } from "../../shared/components";
import { AUTH_FORM_TYPES, ROUTE_PATHS } from "../../shared/constants";
import { DashboardContainer, ProtocolDashboardContainer } from "../Dashboard/containers";
import { ProtocolContainer } from "../Protocol/containers";
import {
  AuthLogicContainer,
  ActivateContainer,
  DisclaimerContainer,
  ProfileContainer,
} from "../Auth/containers";
import config from "../../config";

export default function ApplicationContainer() {
  const dispatch = useDispatch();
  const user = useSelector(sharedSelectors.getUser());
  const isOnline = useSelector(sharedSelectors.getIsOnline());
  const showDisclaimerModal = useSelector(sharedSelectors.getShowDisclaimerModal());
  const isAuthentificated = useSelector(authSelectors.authentificated());
  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useWindowDimensions();

  useEffect(() => {
    googleAdvInit();
  }, []);

  useEffect(() => {
    setEventsIsAuthorized(isAuthentificated);
  }, [isAuthentificated]);

  useEffect(() => {
    if (!user && isAuthentificated) {
      dispatch(sharedActions.getUserData.request({}));
    }
  }, [dispatch, user, isAuthentificated]);

  useEffect(() => {
    dispatch(sharedActions.getLatestAppVersion.request());
  }, [dispatch, user, isAuthentificated]);

  const onOnlineChange = useCallback(
    (value: boolean) => {
      dispatch(sharedActions.setIsOnline(value));
    },
    [dispatch],
  );

  const isRestore = useMemo(() => {
    return location.pathname.includes("restore");
  }, [location]);

  const isPrint = useMemo(() => {
    return location.pathname.includes("print");
  }, [location]);

  const isActivate = useMemo(() => {
    return location.pathname.includes("activate");
  }, [location]);

  const isDisclaimer = useMemo(() => {
    return location.pathname.includes(ROUTE_PATHS.DISCLAIMER);
  }, [location]);

  const isProfile = useMemo(() => {
    return location.pathname.includes(ROUTE_PATHS.PROFILE);
  }, [location]);

  const isProtocol = useMemo(() => {
    return location.pathname.includes("protocols") && !location.pathname.includes("dashboard");
  }, [location]);

  const noShowDisclaimer = useMemo(() => {
    return isRestore || isActivate || isDisclaimer;
  }, [isRestore, isDisclaimer, isActivate]);

  const noShowHeader = useMemo(() => {
    return isPrint;
  }, [isPrint]);

  const noShowFooter = useMemo(() => {
    return isDisclaimer || isProtocol || isProfile;
  }, [isDisclaimer, isProtocol, isProfile]);

  const handleContainerClick = useCallback(() => {
    if (noShowDisclaimer) {
      return;
    }
    if (user || isAuthentificated) {
      if (user && !user.is_acknowledged) {
        history.push(ROUTE_PATHS.DISCLAIMER);
      }
    } else {
      const isAcknowledged = getGuestIsAcknowledged();
      if (!isAcknowledged) {
        !isDesktop || isMobile()
          ? history.push(ROUTE_PATHS.DISCLAIMER)
          : dispatch(sharedActions.showDisclaimerModal());
      }
    }
  }, [user, isAuthentificated, history, dispatch, noShowDisclaimer, isDesktop]);

  useEffect(() => {
    handleContainerClick();
  }, [handleContainerClick]);

  const showProccedAuthForm = useCallback(() => {
    dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROCCED));
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(authActions.logout.request());
  }, [dispatch]);

  const handleDisclaimerSubmit = useCallback(() => {
    const isAcknowledged = getGuestIsAcknowledged();
    if (!user && !isAcknowledged) {
      setGuestIsAcknowledged();
    }
    dispatch(sharedActions.closeDisclaimerModal());
  }, [user, dispatch]);

  useEffect(() => {
    if (showDisclaimerModal) {
      sendDisclaimerEvent();
    }
  }, [showDisclaimerModal]);

  const renderAcknowledgeModal = useMemo(() => {
    return (
      <Modal
        closeOnOutside={false}
        isShowing={showDisclaimerModal}
        onClose={() => dispatch(sharedActions.closeDisclaimerModal())}
      >
        <Disclaimer className="disclaimer-modal" onSubmit={handleDisclaimerSubmit} />
      </Modal>
    );
  }, [showDisclaimerModal, dispatch, handleDisclaimerSubmit]);

  const onProfileEdit = useCallback(() => {
    !isDesktop
      ? history.push(ROUTE_PATHS.PROFILE_EDIT)
      : dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROFILE_EDIT));
  }, [dispatch, isDesktop, history]);

  const renderHeader = useMemo(() => {
    if (noShowHeader) {
      return null;
    }
    return (
      <div className="app-header">
        <Header
          onEdit={onProfileEdit}
          user={user}
          onLogin={showProccedAuthForm}
          onLogout={logout}
          isOnline={isOnline}
        />
      </div>
    );
  }, [showProccedAuthForm, logout, onProfileEdit, noShowHeader, user, isOnline]);

  return (
    <div className="app-container">
      <OnlineDetector url={config.pingUrl} onChange={onOnlineChange} />
      {renderAcknowledgeModal}
      {renderHeader}
      <div
        className={classnames("app-content-wrapper", { "without-header": noShowHeader })}
        onClick={handleContainerClick}
      >
        <div className="app-content">
          <Switch>
            <Route path={ROUTE_PATHS.DASHBOARD} component={DashboardContainer} />
            <Route path={ROUTE_PATHS.RESTORE_PASSWORD} component={DashboardContainer} />
            <Route path={ROUTE_PATHS.PROTOCOLS} component={ProtocolContainer} />
            <Route path={ROUTE_PATHS.ACTIVATE} component={ActivateContainer} />
            <Route path={ROUTE_PATHS.DISCLAIMER} component={DisclaimerContainer} />
            <Route path={ROUTE_PATHS.PROFILE} component={ProfileContainer} />
            <Route path={ROUTE_PATHS.APP} component={ProtocolDashboardContainer} />
            <Redirect to={ROUTE_PATHS.APP} />
          </Switch>
          {!noShowFooter && (
            <div className="app-footer">
              <Footer />
            </div>
          )}
        </div>
      </div>
      <AuthLogicContainer user={user} />
    </div>
  );
}
