import { FormikErrors, FormikTouched } from "formik";
import React, { useMemo } from "react";

import { ErrorMessage } from "../ErrorMessage";

import "./index.scss";

interface FormikErrorMessageProps<T> {
  name: string;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  anyTouched?: boolean;
}

const FormikErrorMessage = <T,>(props: FormikErrorMessageProps<T>) => {
  const { name, errors, touched, anyTouched } = props;
  const touchedCondition = useMemo(() => {
    if (anyTouched) {
      return Boolean(Object.keys(touched).length);
    }
    return touched[name as keyof T];
  }, [touched, name, anyTouched]);

  const error = useMemo(() => {
    return errors[name as keyof T] && touchedCondition ? errors[name as keyof T] : undefined;
  }, [errors, name, touchedCondition]);

  return <>{error ? <ErrorMessage className="error-message">{error}</ErrorMessage> : null}</>;
};

export default FormikErrorMessage;
