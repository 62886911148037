import { ProfileEditDto } from "@shared/interfaces";

import { API, METHODS } from "../../shared/constants";
import { request } from "../../shared/utils";
import { Tag } from "../models";

export default {
  getUserDetails: () => request(METHODS.GET, API.USER.GET_USER_DETAILS)(),
  getProcedureTypes: () => request(METHODS.GET, API.SHARED.GET_PROCEDURE_TYPES)(),
  getTypeSubtypes: () => request(METHODS.GET, API.SHARED.GET_TYPE_SUBTYPES)(),
  getTags: () => request(METHODS.GET, API.SHARED.GET_TAGS)(),
  getPresignedURL: (fileId: number) =>
    request(METHODS.GET, API.SHARED.GET_PRESIGNED_URL)({ params: { file_id: fileId } }),
  getParameters: () => request(METHODS.GET, API.SHARED.GET_PARAMETERS)(),
  createTag: (payload: Partial<Tag>) => request(METHODS.POST, API.SHARED.CREATE_TAG)(payload),
  setIsAcknowledged: () => request(METHODS.POST, API.USER.SET_IS_ACKNOWLEDGED)(),
  getLatestAppVersion: () => request(METHODS.GET, API.SHARED.GET_LATEST_APP_VERSION)(),
  editProfile: (payload: ProfileEditDto) => request(METHODS.POST, API.USER.PROFILE_EDIT)(payload),
};
