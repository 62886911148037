import { Tag } from "./Tag";

export enum FileType {
  document = "Document",
  image = "Image",
  mainImage = "Main Image",
  avatar = "Avatar",
}

export interface File {
  id: number;
  name: string;
  url: string;
  protocol_id: string;
  type: FileType;

  tags?: Tag[];

  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  created_by: string;
  updated_by: string;
}
