import React from "react";

import { StyledButton } from "../Button";
import "./index.scss";

interface RemoveModalContetnProps {
  content: string | React.ReactNode;
  onClose: () => void;
  onSuccess: () => void;
  cancelText?: string;
  removeText?: string;
}

const ModalContent = (props: RemoveModalContetnProps) => {
  return (
    <div className="remove-modal-content-wrapper">
      <div className="modal-content">{props.content}</div>
      <div className="modal-actions">
        <StyledButton
          label={props.cancelText ? props.cancelText : "CANCEL"}
          bordered={true}
          filled={true}
          color="accent"
          textColor="white"
          onClick={props.onClose}
        />
        <StyledButton
          label={props.removeText ? props.removeText : "REMOVE"}
          bordered={true}
          filled={true}
          color="grey"
          textColor="white"
          onClick={props.onSuccess}
        />
      </div>
    </div>
  );
};
export default ModalContent;
