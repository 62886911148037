import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useParams } from "react-router";
import classnames from "classnames";
import { RouteParams } from "@shared/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { InteractiveGuide, Modal, ModalContent } from "@shared/components";
import { useLoadSharedData, useWindowDimensions, useSendPageEvent } from "@shared/hooks";
import { IGuideStep, IGuideStepConnection } from "@shared/models";
import { push } from "connected-react-router";
import {
  ProtocolGuidePlayConditions,
  ProtocolGuidePlayFooter,
  ProtocolGuidePlayHeader,
  ProtocolGuidePlayMobile,
} from "@containers/Protocol/components";
import { sendGuideEndEvent, sendGuideStopEvent, sendGuideRestartEvent } from "@shared/utils";
import { OnLoadParams, ReactFlowProvider } from "react-flow-renderer";
import { generatePath } from "react-router-dom";
import { ROUTE_PATHS } from "@shared/constants";

import { actions, selectors } from "../../store";
import { SelectedStepResult } from "../../interfaces";
import {
  getDisplayedSteps,
  getFirstStep,
  getGuideStepConnections,
  getGuideSteps,
  getNextStepsByStep,
  getPreviousSelectedSteps,
  getConnectionsBySourceNode,
} from "../../utils";

import "./index.scss";

const ProtocolGuidePlayContainer = () => {
  const params = useParams<Partial<RouteParams>>();
  const dispatch = useDispatch();
  const { isDesktop, height } = useWindowDimensions();
  const { parameters } = useLoadSharedData();
  const playViewRef = useRef<HTMLDivElement | null>(null);

  const protocol = useSelector(selectors.getCurrentProtocol());
  const [selectedSteps, setSelectedSteps] = useState<SelectedStepResult[]>([]);
  const [nextSteps, setNextSteps] = useState<IGuideStep[]>([]);
  const [nextConnections, setNextConnections] = useState<IGuideStepConnection[]>([]);
  const [guideSteps, setGuideSteps] = useState<IGuideStep[]>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams | null>(null);
  const [guideStepConnections, setGuideStepConnections] = useState<IGuideStepConnection[]>([]);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isShowMobileGuide, setIsShowMobileGuide] = useState<boolean>(false);
  const [isShowRestartCondition, setIsShowRestartCondition] = useState<boolean>(false);
  const [isStopGuidanceModalOpened, setIsStopGuidanceModalOpened] = useState(false);
  const [isSetCenterActiveStep, setCenterActiveStep] = useState(false);
  const [isSelectedStep, setIsSelectedStep] = useState(false);

  const isFirstStep = useMemo(() => {
    return selectedSteps.length === 1;
  }, [selectedSteps]);

  const currentStep = useMemo(() => {
    if (!selectedSteps.length) return null;
    return selectedSteps[selectedSteps.length - 1].step;
  }, [selectedSteps]);

  useSendPageEvent("protocol_play");

  const handleRestart = useCallback(() => {
    setIsShowRestartCondition(false);
    setSelectedSteps([
      {
        index: Date.now(),
        step: selectedSteps[0].step,
        connections: [],
      },
    ]);
    if (protocol) {
      sendGuideRestartEvent({
        protocol_id: protocol.id,
        protocol_name: protocol.name,
      });
    }
  }, [selectedSteps, protocol]);

  const handleCondition = useCallback(
    (step: IGuideStep) => {
      setIsSelectedStep(true);
      const allConnections = getGuideStepConnections(protocol);
      const connections = allConnections.filter(
        (gsc) =>
          gsc.step_target_id === step.id &&
          gsc.step_source_id === selectedSteps[selectedSteps.length - 1].step.id,
      );

      // if the new step is the initial then show the restart message
      const prevStep = selectedSteps[selectedSteps.length - 1].step;
      const prevStepConnection = allConnections.find(
        (c) => c.step_target_id === prevStep.id && c.step_source_id === step.id,
      );
      const currentTargetStepConnections = allConnections.filter(
        (c) => c.step_target_id === step.id,
      );

      if (
        step.sequence === 1 &&
        (!prevStepConnection || currentTargetStepConnections.length === 1)
      ) {
        setIsShowRestartCondition(true);
        setSelectedSteps((prev) => [
          ...prev,
          {
            index: Date.now(),
            step,
            connections,
          },
        ]);
        return;
      }

      // add step to selected array
      setSelectedSteps((prev) => [
        ...prev,
        {
          index: Date.now(),
          step,
          connections,
        },
      ]);
    },
    [protocol, selectedSteps],
  );

  const completeClickHandle = useCallback(() => {
    if (protocol) {
      dispatch(push(generatePath(ROUTE_PATHS.PROTOCOLS, { protocolId: protocol.id })));
      dispatch(
        actions.updateProtocolStatistic.request({
          protocolId: protocol.id,
          fields: ["guide_completed"],
        }),
      );
    }
  }, [dispatch, protocol]);

  const sendGuideEndGoogleEvent = useCallback(() => {
    if (protocol) {
      sendGuideEndEvent({
        protocol_id: protocol.id,
        protocol_name: protocol.name,
      });
    }
  }, [protocol]);

  const sendGuideStopGoogleEvent = useCallback(() => {
    if (protocol) {
      sendGuideStopEvent({
        protocol_id: protocol.id,
        protocol_name: protocol.name,
      });
    }
  }, [protocol]);

  const handlePreviousStepClick = useCallback(
    (selectedStep: SelectedStepResult | null = null) => {
      setSelectedSteps(getPreviousSelectedSteps(selectedSteps, selectedStep));
      setIsShowRestartCondition(false);
    },
    [selectedSteps],
  );

  useEffect(() => {
    if (params.protocolId) {
      dispatch(actions.getProtocol.request(params.protocolId));
    }
  }, [dispatch, params.protocolId]);

  useEffect(() => {
    setGuideSteps(getGuideSteps(protocol));
    setGuideStepConnections(getGuideStepConnections(protocol));
  }, [protocol]);

  useEffect(() => {
    if (protocol) {
      const step = getFirstStep(getGuideSteps(protocol));
      if (step) {
        setSelectedSteps([
          {
            index: Date.now(),
            step,
            connections: [],
          },
        ]);
      }
    }
  }, [protocol]);

  useEffect(() => {
    if (currentStep) {
      const steps = getGuideSteps(protocol);
      const connections = getGuideStepConnections(protocol);
      setNextSteps(getNextStepsByStep(currentStep, steps, connections));
      setNextConnections(getConnectionsBySourceNode(currentStep, connections));
    }
  }, [currentStep, protocol]);

  useEffect(() => {
    if (selectedSteps.length) {
      const { steps, connections } = getDisplayedSteps(
        selectedSteps,
        getGuideSteps(protocol),
        getGuideStepConnections(protocol),
        !isDesktop,
      );

      setGuideSteps(steps);
      setGuideStepConnections(connections);
    }
  }, [protocol, selectedSteps, reactFlowInstance, isDesktop, isShowRestartCondition]);

  useEffect(() => {
    if (reactFlowInstance && selectedSteps.length && !isSetCenterActiveStep) {
      reactFlowInstance.fitView({ includeHiddenNodes: true });
    }
  }, [reactFlowInstance, selectedSteps.length, isSetCenterActiveStep]);

  useEffect(() => {
    setIsCompleted(!nextSteps.length);
  }, [nextSteps]);

  useEffect(() => {
    setIsShowMobileGuide((prev) => (isDesktop ? false : prev));
  }, [isDesktop]);

  useEffect(() => {
    const headerHeight = 170;
    const minGuideViewHeigth = 600;
    setCenterActiveStep(height - headerHeight < minGuideViewHeigth);
  }, [height]);

  return (
    <div
      className={classnames("play-guide", {
        mobile: !isDesktop,
      })}
    >
      <div className="play-guide-header">
        <ProtocolGuidePlayHeader
          onPreviousStepClick={handlePreviousStepClick}
          onStopClick={() => setIsStopGuidanceModalOpened(!isStopGuidanceModalOpened)}
          onCompleteClick={() => {
            completeClickHandle();
            sendGuideEndGoogleEvent();
          }}
          isCompleted={isCompleted}
          isMobile={!isDesktop}
          isFirstStep={isFirstStep}
          protocol={protocol}
        />
      </div>
      <div
        className={classnames("play-guide-content", {
          blur: true,
        })}
      >
        {protocol && currentStep && isDesktop ? (
          <>
            <ProtocolGuidePlayConditions
              isFirstStep={isFirstStep}
              currentStep={currentStep}
              isShowRestartCondition={isShowRestartCondition}
              nextSteps={nextSteps}
              nextConnections={nextConnections}
              handleSubmit={handleCondition}
              handleRestartSubmit={handleRestart}
              parameters={parameters}
            />
            <div className="play-guide-view" ref={playViewRef}>
              <ReactFlowProvider>
                <InteractiveGuide
                  isSetCenterActiveStep={isSetCenterActiveStep && !isSelectedStep}
                  parameters={parameters}
                  isFitView={false}
                  isEdit={false}
                  guideSteps={guideSteps}
                  guideStepConnections={guideStepConnections}
                  onElementClick={() => {}}
                  onInitReactFlowInstance={setReactFlowInstance}
                  showTooltip={false}
                />
              </ReactFlowProvider>
            </div>
          </>
        ) : null}
        {protocol && currentStep && !isDesktop ? (
          <>
            <div className="play-guide-view">
              <ProtocolGuidePlayMobile
                parameters={parameters}
                selectedSteps={selectedSteps}
                isShowRestartCondition={isShowRestartCondition}
                isFirstStep={isFirstStep}
                currentStep={currentStep}
                nextSteps={nextSteps}
                nextConnections={nextConnections}
                handleSubmit={handleCondition}
                onElementClick={handlePreviousStepClick}
                isGuideView={isShowMobileGuide}
                guideSteps={guideSteps}
                guideStepConnections={guideStepConnections}
                onInitReactFlowInstance={setReactFlowInstance}
                handleRestartSubmit={handleRestart}
              />
            </div>
          </>
        ) : null}
        {!isDesktop ? (
          <ProtocolGuidePlayFooter
            onPreviousStepClick={handlePreviousStepClick}
            onStopClick={() => setIsStopGuidanceModalOpened(!isStopGuidanceModalOpened)}
            onCompleteClick={() => {
              completeClickHandle();
              sendGuideEndGoogleEvent();
            }}
            onShowGuideClick={() => setIsShowMobileGuide(!isShowMobileGuide)}
            isCompleted={isCompleted}
            isGuideView={isShowMobileGuide}
            isFirstStep={isFirstStep}
          />
        ) : null}
      </div>

      <Modal
        isShowing={isStopGuidanceModalOpened}
        onClose={() => setIsStopGuidanceModalOpened(!isStopGuidanceModalOpened)}
        isSmall={true}
        showCloseIcon={false}
        heading="Stop the guidance"
      >
        <ModalContent
          content="Would you like to stop the interactive guidance?"
          onClose={() => setIsStopGuidanceModalOpened(!isStopGuidanceModalOpened)}
          onSuccess={() => {
            completeClickHandle();
            sendGuideStopGoogleEvent();
          }}
          cancelText="No"
          removeText="Yes"
        />
      </Modal>
    </div>
  );
};

export default ProtocolGuidePlayContainer;
