import React from "react";
import { useWindowDimensions } from "@shared/hooks";

import { CardFooter } from "../";
import "./index.scss";

const HemodynamicsFramework = () => {
  const { isMobileVersion } = useWindowDimensions();
  return (
    <div className="hemodynamics-framework">
      <p className="content">by the POQI Fluids Subgroup a rational, step-wise approach</p>
      <div className="image-container">
        <img
          src={`/images/slides/hemodynamics-framework${isMobileVersion ? "-mobile" : ""}.png`}
          alt="Viewfinder pathways"
        />
      </div>
      <CardFooter>
        <p>
          American Society for Enhanced Recovery (ASER) and Perioperative Quality Initiative (POQI)
          joint consensus statement on perioperative fluid management within an enhanced recovery
          pathway fro colorectal surgery.
        </p>
        <p>
          Robert H. Thiele, Karthik Raghunathan, C. S. Brudney, Dileep N. Lobo, Daniel Martin,
          Anthony Senagore, Maxime Cannesson, Tong Joo Gan, Michael Monty G. Mythen, Andrew D. Shaw,
          Timothy E. Miller and For the Perioperative Quality Initiative (POQI) I Workgroup
        </p>
      </CardFooter>
    </div>
  );
};

export default HemodynamicsFramework;
