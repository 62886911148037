import { checkIsLabel, getStepLabelText } from "@containers/Protocol/utils";
import { StyledButton } from "@shared/components";
import { IGuideStep, ParameterUnit } from "@shared/models";
import React, { useMemo, useEffect } from "react";

import "./index.scss";

interface ButtonConditionProps {
  step: IGuideStep;
  steps: IGuideStep[];
  parameters: ParameterUnit[];
  onHandleSubmit: (step: IGuideStep) => void;
}

const ButtonCondition = (props: ButtonConditionProps) => {
  const { step, steps, onHandleSubmit, parameters } = props;

  const nextStep = useMemo(() => {
    return steps.find(Boolean);
  }, [steps]);

  useEffect(() => {
    if (step && checkIsLabel(step) && nextStep) {
      onHandleSubmit(nextStep);
    }
  }, [step, onHandleSubmit, nextStep]);

  const stepText = useMemo(() => getStepLabelText(step, parameters), [step, parameters]);

  return (
    <div className="button-condition">
      <div className="button-condition-text" dangerouslySetInnerHTML={{ __html: stepText }} />
      {nextStep ? (
        <div className="button-condition-action">
          <StyledButton
            label="Proceed"
            className="styled-button-bg red styled-button-bordered customize"
            filled={true}
            color="accent"
            textColor="white"
            onClick={() => onHandleSubmit(nextStep)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ButtonCondition;
