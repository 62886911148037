import { API, METHODS } from "../../../shared/constants";
import { AuthShape, LoginShape, RegistrationShape, SetNewPasswordShape } from "../interfaces";
import { request } from "../../../shared/utils";

export default {
  login: (payload: LoginShape) => request(METHODS.POST, API.AUTH.LOGIN)(payload),
  signUp: (payload: Partial<RegistrationShape>) =>
    request(METHODS.POST, API.AUTH.REGISTRATION)(payload),
  checkEmailAvailability: (payload: AuthShape) =>
    request(
      METHODS.GET,
      API.USER.CHECK_EMAIL_AVAILABILITY,
    )({
      params: payload,
    }),
  activateAccount: (hash: string) => request(METHODS.GET, API.AUTH.ACTIVATE_USER_ACCOUNT(hash))(),
  forgotPassword: (payload: AuthShape) => request(METHODS.POST, API.AUTH.FORGOT_PASSWORD)(payload),
  resendForgotPassword: (payload: AuthShape) =>
    request(METHODS.POST, API.AUTH.RESEND_RESTORE_PASSWORD)(payload),
  resendConfirmation: (payload: AuthShape) =>
    request(METHODS.POST, API.AUTH.RESEND_CONFIRMATION)(payload),
  setNewPassword: (payload: SetNewPasswordShape) =>
    request(METHODS.POST, API.AUTH.SET_PASSWORD)(payload),
};
