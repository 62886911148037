import { API, SCHEMAS, METHODS } from "../constants";
import { API_ENUM } from "../constants/api";
import { SchemasMap } from "../interfaces";
import { mapToSchema } from "../utils";
import {
  getUserDetailsResponsSchema,
  checkEmailAvailabilityRequestSchema,
  checkEmailAvailabilityResponsSchema,
  editProfileRequestSchema,
} from "../validators";

const schemaShapes: SchemasMap<keyof typeof API.USER> = {
  [API_ENUM.GET_USER_DETAILS]: {
    request: {
      name: `${API.USER.GET_USER_DETAILS}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: {},
    },
    response: {
      name: `${API.USER.GET_USER_DETAILS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: getUserDetailsResponsSchema,
    },
  },
  [API_ENUM.CHECK_EMAIL_AVAILABILITY]: {
    request: {
      name: `${API.USER.CHECK_EMAIL_AVAILABILITY}${SCHEMAS.REQUEST}${METHODS.GET}`,
      schema: checkEmailAvailabilityRequestSchema,
    },
    response: {
      name: `${API.USER.CHECK_EMAIL_AVAILABILITY}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: checkEmailAvailabilityResponsSchema,
    },
  },
  [API_ENUM.SET_IS_ACKNOWLEDGED]: {
    request: {
      name: `${API.USER.SET_IS_ACKNOWLEDGED}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: {},
    },
    response: {
      name: `${API.USER.SET_IS_ACKNOWLEDGED}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {},
    },
  },
  [API_ENUM.PROFILE_EDIT]: {
    request: {
      name: `${API.USER.PROFILE_EDIT}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: editProfileRequestSchema,
    },
    response: {
      name: `${API.USER.PROFILE_EDIT}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: {
        user: getUserDetailsResponsSchema,
        message: { type: ["string"] },
      },
    },
  },
};

export default mapToSchema(schemaShapes);
