import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { install } from "resize-observer";

import configureStore from "./store";
import "./styles/index.scss";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import history from "./shared/history";
import { appHeight } from "./shared/utils";
const { store } = configureStore(history);

if (process.env.REACT_APP_ENV === "dev") {
  // eslint-disable-next-line
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
  serviceWorker.unregister();
} else {
  serviceWorker.register({
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
  });
}

window.addEventListener("resize", appHeight);
window.addEventListener("orientationchange", () => {
  setTimeout(appHeight, 1000);
});
install();
appHeight();

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root"),
);
