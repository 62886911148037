import React from "react";
import classnames from "classnames";

import "./index.scss";
import { IShapeProps } from "../../../../interfaces";
import variables from "../../../../../../../styles/variables.scss";
import { InteractiveGuideTooltip } from "../../../InteractiveGuideTooltip";

export default ({
  label,
  color,
  styles,
  sequence,
  is_edit,
  is_opacity,
  is_active,
  is_tooltip,
}: IShapeProps) => (
  <div
    // eslint-disable-next-line
    // @ts-ignore
    style={{ "--color": color ? variables[color] : variables.white }}
    className={classnames("guide-element", { opacity: is_opacity, active: is_active }, styles)}
  >
    <div className="guide-element-wrapper">
      <InteractiveGuideTooltip
        text="You're here"
        show={!!is_active && !!is_tooltip}
        style={{ top: "-50px" }}
      />
      {sequence && is_edit ? (
        <div className="sequence">
          <div>{sequence}</div>
        </div>
      ) : null}
      <div
        className={classnames("label", { position: !!sequence && is_edit })}
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    </div>
  </div>
);
