import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import { AnyAction } from "redux";
import { PWA_CACHE_NAME, APP_VERSION_KEY } from "@shared/constants";

import { ParameterUnit, ProcedureType, Tag, TypeSubtype, User } from "../models";
import { startLoading, stopLoading } from "../store/actions";
import api from "../api";
import { downLoadFileFromUrl, tokenHandler } from "../utils";
import { ActionWithPayload, AppState, ProfileEditDto } from "../interfaces";
import history from "../history";

import { actions } from "./";

function* getUserDataSaga({ payload }: ActionWithPayload<{ callback?: () => void }>) {
  try {
    const { callback } = payload;
    yield put(actions.startLoading());
    const user: User = yield call(api.getUserDetails);
    yield tokenHandler.setUser(user);
    yield put(actions.getUserData.success(user));
    yield put(actions.stopLoading());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(actions.stopLoading());
    yield put(actions.getUserData.failure(error));
  }
}

function* getProcedureTypeList() {
  try {
    yield put(startLoading());
    const procedureTypesResponse: { count: number; items: ProcedureType[] } = yield call(
      api.getProcedureTypes,
    );
    yield put(actions.getProcedureTypes.success(procedureTypesResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getProcedureTypes.failure(error));
  }
}

function* getPresignedUrlSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());
    const procedureTypesResponse: { presigned_url: string; file_name: string } = yield call(
      api.getPresignedURL,
      payload,
    );

    downLoadFileFromUrl(procedureTypesResponse.presigned_url, procedureTypesResponse.file_name);

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getPresignedURL.failure(error));
  }
}

function* getParameterList() {
  try {
    yield put(startLoading());
    const parametersResponse: { count: number; items: ParameterUnit[] } = yield call(
      api.getParameters,
    );
    yield put(actions.getParameters.success(parametersResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getParameters.failure(error));
  }
}

function* getTagList() {
  try {
    yield put(startLoading());
    const tagsResponse: { count: number; items: Tag[] } = yield call(api.getTags);
    yield put(actions.getTags.success(tagsResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getTags.failure(error));
  }
}

function* getTypeSubtypeList() {
  try {
    yield put(startLoading());
    const typeSubtypesResponse: { count: number; items: TypeSubtype[] } = yield call(
      api.getTypeSubtypes,
    );
    yield put(actions.getTypeSubtypes.success(typeSubtypesResponse));

    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.getTypeSubtypes.failure(error));
  }
}

function* createTag({ payload }: ActionWithPayload<Partial<Tag>>) {
  try {
    yield put(startLoading());

    const tagResponse: {
      tag: Tag;
      message: string;
    } = yield call(api.createTag, payload);

    yield put(actions.createTag.success(tagResponse));
    yield put(stopLoading());
  } catch (error) {
    yield put(stopLoading());
    yield put(actions.createTag.failure(error));
  }
}

function* setIsAcknowledgedSaga() {
  try {
    yield put(actions.startLoading());
    yield call(api.setIsAcknowledged);
    yield put(actions.setIsAcknowledged.success());
    yield put(
      actions.getUserData.request({
        callback: () => history.goBack(),
      }),
    );
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    yield put(actions.setIsAcknowledged.failure(error));
  }
}

function* editProfileSaga({
  payload,
}: ActionWithPayload<{ data: ProfileEditDto; callback?: () => void }>) {
  try {
    const { data, callback } = payload;
    yield put(startLoading());
    const response: { user: User; message: string } = yield call(api.editProfile, data);
    yield put(actions.editProfile.success(response));
    yield put(actions.getUserData.success(response.user));
    yield put(stopLoading());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(actions.editProfile.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* getLatestAppVersionSaga() {
  try {
    yield put(startLoading());
    const response: { version: string } = yield call(api.getLatestAppVersion);
    const version = localStorage.getItem(APP_VERSION_KEY);
    if (version && response.version !== version) {
      yield caches.delete(PWA_CACHE_NAME);
      localStorage.setItem(APP_VERSION_KEY, response.version);
      window.location.reload();
    } else {
      localStorage.setItem(APP_VERSION_KEY, response.version);
    }
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.getLatestAppVersion.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) =>
    startActions.includes(payload.type),
  );
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(
      actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }),
    );
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* sharedSaga() {
  yield takeLatest(actions.getProcedureTypes.request, getProcedureTypeList);
  yield takeLatest(actions.getTags.request, getTagList);
  yield takeLatest(actions.createTag.request, createTag);
  yield takeLatest(actions.getUserData.request, getUserDataSaga);
  yield takeLatest(actions.getParameters.request, getParameterList);
  yield takeLatest(actions.getTypeSubtypes.request, getTypeSubtypeList);
  yield takeLatest(actions.setIsAcknowledged.request, setIsAcknowledgedSaga);
  yield takeLatest(actions.editProfile.request, editProfileSaga);
  yield takeLatest(actions.getPresignedURL.request, getPresignedUrlSaga);
  yield takeLatest(actions.getLatestAppVersion.request, getLatestAppVersionSaga);

  yield takeEvery("*", watchLoaders);
}

export default sharedSaga;
