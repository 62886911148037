import React, { useState, useMemo, useEffect } from "react";
import Slider from "react-slick";
import { tokenHandler } from "@shared/utils";
import { useWindowDimensions } from "@shared/hooks";

import {
  MarketingSlide,
  ApplicationOfAlgorithms,
  HemodynamicsFramework,
  ViewfinderPathways,
} from "./";
import "./index.scss";

export interface Slide {
  id: string;
  show: boolean;
  imageSrc: string;
  title: string | { mobile?: string; desktop?: string; tablet?: string };
  shortTitle: string;
  description: string;
  contentWidth: string;
  content: React.ReactNode;
  gadvKey?: string;
}

const MarketingCards = () => {
  const { isMobileVersion, isDesktop, width } = useWindowDimensions();
  const [slides, updateSlides] = useState<Slide[]>([
    {
      id: "slide_3",
      contentWidth: "830px",
      show: true,
      imageSrc: "/images/slides/body.png",
      title: "Hemodynamics Framework",
      shortTitle: "Hemodynamics Framework",
      description:
        "Hemodynamic monitoring helps identify hemodynamic instability and its cause, assess patient's response to therapy, encourage timely intervention to prevent and correct hypoperfusion, and optimize oxygen delivery and consumption.",
      content: <HemodynamicsFramework />,
      gadvKey: "monitoring_tile",
    },
    {
      id: "slide_1",
      contentWidth: "740px",
      show: true,
      imageSrc: "/images/slides/graphic.png",
      title: "Application of Stroke Volume vs Stroke Volume Variation protocols",
      shortTitle: "Application of Stroke Volume vs Stroke Volume Variation protocols",
      description:
        "Select ‘Read More’ for a decision tree to help you select the most appropriate perioperative goal-directed therapy (PGDT) protocol for your patient. When choosing a protocol, the clinician should consider method of ventilation as well as other patient variables (positioning, minimally-invasive surgery, etc.)",
      content: <ApplicationOfAlgorithms />,
      gadvKey: "algorithms_tile",
    },
    {
      id: "slide_2",
      contentWidth: "540px",
      show: true,
      imageSrc: "/images/slides/application.png",
      title: {
        desktop: "Tips for using Viewfinder Pathways",
        mobile: "Tips for using <br /> Viewfinder Pathways",
      },
      shortTitle: "Tips for using Viewfinder Pathways",
      description:
        "Viewfinder Pathways is a protocol library designed to help inform clinicians of key published protocols used to guide hemodynamic monitoring in moderate-to high-risk surgery.",
      content: <ViewfinderPathways />,
      gadvKey: "more_about_tile",
    },
  ]);

  useEffect(() => {
    if (tokenHandler.getUser()) {
      updateSlides((slides) =>
        slides.map((s) => ({ ...s, show: !localStorage.getItem("showMarketingCards") })),
      );
    }
  }, []);

  const hideSlide = () => {
    updateSlides((slides) =>
      slides.map((s) => {
        s.show = false;

        return s;
      }),
    );
  };

  const activeSlides = useMemo(() => {
    return slides.filter((s) => s.show);
  }, [slides]);

  return activeSlides.length ? (
    <div
      className="marketing-cards"
      style={{ width: isDesktop ? "100%" : width - (isMobileVersion ? 30 : 60) }}
    >
      <Slider
        customPaging={(idx: number) => (
          <div className="custom-paging" key={`custom-paging-${idx}`} />
        )}
        dotsClass="slick-dots"
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={10000}
        arrows={false}
      >
        {activeSlides.map((slide) => (
          <React.Fragment key={slide.id}>
            <MarketingSlide
              {...{
                ...slide,
                title:
                  typeof slide.title === "string"
                    ? slide.title
                    : (slide.title[isMobileVersion ? "mobile" : "desktop"] as string),
              }}
              hideSlide={hideSlide}
            />
          </React.Fragment>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default MarketingCards;
