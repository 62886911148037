import React from "react";
import {
  EditProtocolGuideDto,
  InteractiveGuideStepFormShape,
  InteractiveGuideStepSettingFormShape,
  ProtocolInteractiveGuideFormShape,
} from "@containers/Protocol/interfaces";
import { GuideStepSetting, IGuideStep, ParameterUnit } from "@shared/models";
import { getOptions, getOptionByValueColumn } from "@shared/utils";
import { Field, FieldProps, FormikProps } from "formik";
import { Select } from "@shared/components/Input/Select";
import { OptionTypeBase, ValueType } from "react-select";
import { FormikErrorMessage, Input } from "@shared/components";
import { GUIDE_STEP_SETTING_FIELDS } from "@containers/Protocol/constants";
import { FIND_HTML_TAGS, InputTypes } from "@shared/constants";

export const getFormValueFromGuideStep = (
  guideStep: IGuideStep,
  parameters: ParameterUnit[],
): InteractiveGuideStepFormShape => {
  return {
    id: guideStep.id,
    sequence: guideStep.sequence,
    settings: (guideStep.settings || []).map((s) => ({
      id: s.id,
      guide_step_id: guideStep.id,
      parameter_unit: getOptionByValueColumn(
        parameters,
        "parameter",
        "id",
        String(s.parameter_unit_id),
      ),
      source_guide_step_setting_id: s.source_guide_step_setting_id,
      field_type: s.field_type,
      text: s.text || "",
      sequence: s.sequence,
      is_editable: !!s.is_editable,
      parameters: getOptionsForAvailablesParameters(s, parameters),
      dependencies: (s.setting_dependencies || []).map((d) => d.dependent_guide_step_setting_id),
    })),
  };
};

export const getFormValues = (
  guideSteps: IGuideStep[],
  parameters: ParameterUnit[],
): ProtocolInteractiveGuideFormShape => {
  const guideStepsWithSettings = guideSteps.filter(
    (gs) => gs.sequence && gs.settings && gs.settings.length,
  );

  return {
    guide_steps: guideStepsWithSettings.map((gs) => getFormValueFromGuideStep(gs, parameters)),
  };
};

export const prepareGuideFormValues = (
  values: ProtocolInteractiveGuideFormShape,
): EditProtocolGuideDto[] => {
  return values.guide_steps.map((gs) => ({
    id: Number(gs.id),
    settings: gs.settings.map((s) => ({
      id: s.id,
      parameter_unit_id: s.parameter_unit ? Number(s.parameter_unit.value) : null,
      text: s.text || "",
    })),
  }));
};

export const getOptionsForAvailablesParameters = (
  setting: GuideStepSetting,
  parameters: ParameterUnit[],
) => {
  if (setting.field_type !== GUIDE_STEP_SETTING_FIELDS.PARAMETER) {
    return [];
  }

  const settingParameters = setting.setting_parameters || [];

  const availablesParameters = settingParameters.length
    ? parameters.filter((p) => settingParameters.find((s) => s.parameter_unit_id === p.id))
    : parameters;

  return getOptions(availablesParameters, "parameter", "id");
};

export const getTextFromSettingField = (
  currentSettingValue: InteractiveGuideStepSettingFormShape,
  allSettingsValues: InteractiveGuideStepSettingFormShape[],
  parameters: ParameterUnit[],
) => {
  switch (currentSettingValue.field_type) {
    case GUIDE_STEP_SETTING_FIELDS.PARAMETER: {
      return currentSettingValue.parameter_unit ? currentSettingValue.parameter_unit.label : "";
    }
    case GUIDE_STEP_SETTING_FIELDS.TEXT:
    case GUIDE_STEP_SETTING_FIELDS.NUMBER: {
      return currentSettingValue.text;
    }
    case GUIDE_STEP_SETTING_FIELDS.UNIT: {
      const sourceSetting = allSettingsValues.find(
        (s) => s.id === currentSettingValue.source_guide_step_setting_id,
      );
      if (!sourceSetting || !sourceSetting.parameter_unit || !sourceSetting.parameters) {
        return "";
      }

      const unit = parameters.find((p) => p.id === Number(sourceSetting.parameter_unit?.value));
      return unit ? unit.unit : "";
    }
    default: {
      return "";
    }
  }
};

export const getFieldName = (fieldType: string) => {
  switch (fieldType) {
    case GUIDE_STEP_SETTING_FIELDS.PARAMETER: {
      return "parameter_unit";
    }
    default: {
      return "text";
    }
  }
};

export const getField = <T,>(
  value: InteractiveGuideStepSettingFormShape,
  settings: InteractiveGuideStepSettingFormShape[],
  parameters: ParameterUnit[],
  fieldNameRoot: string,
  onChangeForm: (
    fieldValue: ValueType<OptionTypeBase, false> | string,
    shapeValue: InteractiveGuideStepSettingFormShape,
  ) => void,
  formikProps: FormikProps<T>,
) => {
  const CAN_EDIT_FIELDS: string[] = [
    GUIDE_STEP_SETTING_FIELDS.NUMBER,
    GUIDE_STEP_SETTING_FIELDS.TEXT,
    GUIDE_STEP_SETTING_FIELDS.PARAMETER,
  ];
  if (!value.is_editable || !CAN_EDIT_FIELDS.includes(value.field_type)) {
    const text = getTextFromSettingField(value, settings, parameters).replace(
      new RegExp(FIND_HTML_TAGS, "g"),
      "",
    );
    return <div className="text-readonly">{text}</div>;
  }

  switch (value.field_type) {
    case GUIDE_STEP_SETTING_FIELDS.PARAMETER: {
      return (
        <Field name={`${fieldNameRoot}.parameter_unit`}>
          {({ field, meta }: FieldProps) => (
            <div className={`select-item ${value.field_type}`}>
              <Select
                {...field}
                placeholder="Select"
                options={value.parameters || undefined}
                errors={{ [field.name]: meta.error }}
                touched={{ [field.name]: meta.touched }}
                onChange={(fieldValue: ValueType<OptionTypeBase, false>) => {
                  formikProps.setFieldValue(field.name, fieldValue);
                  onChangeForm(fieldValue, value);
                }}
              />
              <FormikErrorMessage
                anyTouched={true}
                name={field.name}
                errors={{ [field.name]: meta.error }}
                touched={{ [field.name]: meta.touched }}
              />
            </div>
          )}
        </Field>
      );
    }
    case GUIDE_STEP_SETTING_FIELDS.TEXT:
    case GUIDE_STEP_SETTING_FIELDS.NUMBER: {
      return (
        <Field name={`${fieldNameRoot}.text`}>
          {({ field, meta }: FieldProps) => (
            <div className={`input-item ${value.field_type}`}>
              <Input
                {...field}
                type={InputTypes.TEXT}
                max={value.field_type === GUIDE_STEP_SETTING_FIELDS.TEXT ? 22 : 5}
                errors={{ [field.name]: meta.error }}
                touched={{ [field.name]: meta.touched }}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  formikProps.setFieldValue(field.name, e.currentTarget.value);
                  onChangeForm(e.currentTarget.value, value);
                }}
              />
              <FormikErrorMessage
                anyTouched={true}
                name={field.name}
                errors={{ [field.name]: meta.error }}
                touched={{ [field.name]: meta.touched }}
              />
            </div>
          )}
        </Field>
      );
    }
  }
};
