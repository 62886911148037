import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import Icon, { IconType } from "@shared/components/Icon/Icon";

import "./index.scss";

export interface FileLabelProps {
  icon?: IconType;
  label: string;
  className?: string;
  onClick?: () => void;
  onIconClick?: () => void;
}

function FileLabel(props: FileLabelProps) {
  const { icon, label, className, onClick, onIconClick } = props;
  const isPdf = useMemo(() => label.indexOf(".pdf") >= 0, [label]);
  const localOnIconClick = useCallback(
    (e: React.MouseEvent) => {
      if (onIconClick) {
        e.stopPropagation();
        onIconClick();
      }
    },
    [onIconClick],
  );
  return (
    <div className={classnames("file-label", className)} onClick={onClick}>
      <Icon type={isPdf ? "pdf" : "docx"} />
      <div className="file-name">{label}</div>
      {icon && <Icon className="action-icon" type={icon} onClick={localOnIconClick} />}
    </div>
  );
}

export default FileLabel;
