import { Protocol } from "@shared/models";

import config from "../../config";

const { googleAdvId } = config;

let isLoaded = false;

interface PageView {
  page_title?: string;
  page_location?: string;
  page_path?: string;
}

interface IProtocolView {
  protocol_id: string;
  protocol_name: string;
  brand: string;
  category: string;
}

interface BaseProtocolEvent {
  protocol_id: string;
  protocol_name: string;
}

interface IShareProtocol extends BaseProtocolEvent {
  recipient: string;
  type: string;
  share_id: string;
}

interface IShareReject extends BaseProtocolEvent {
  recipient: string;
}

type IGuideAction = BaseProtocolEvent;

interface IProtocolCustomized extends BaseProtocolEvent {
  procedure_types: string[];
  parent_protocol_id?: string;
}

interface IProtocolFavorite extends BaseProtocolEvent {
  brand: string;
  category: string;
}

const hasGoogleAdv = () => {
  return typeof window.gtag === "function";
};

export enum GOOGLE_ADV_EVENTS {
  login = "login",
  sign_up = "sign_up",
  page_view = "page_view",
  view_item = "view_item",
  share = "share",
  share_reject = "share_reject",
  guide_started = "guide_started",
  guide_completed = "guide_completed",
  guide_restarted = "guide_restarted",
  guide_stopped = "guide_stopped",
  protocol_customized = "protocol_customized",
  protocol_uploaded = "protocol_uploaded",
  removed_favorites = "removed_favorites",
  added_favorites = "added_favorites",
  disclaimer_showed = "disclaimer_open",
  disclaimer_acknowledge = "disclaimer_acknowledge",
  view_tile_readmore = "view_tile_readmore",
}

export const init = () => {
  if (isLoaded) {
    return;
  }

  isLoaded = true;

  if (typeof window !== "undefined" && typeof document !== "undefined" && googleAdvId) {
    addGlobalTag(googleAdvId);
    window.dataLayer = window.dataLayer || [];
    const gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window?.dataLayer?.push(arguments);
    } as Gtag.Gtag;
    gtag("js", new Date());
    gtag("config", googleAdvId, { send_page_view: false });
    window.gtag = gtag;
  }
};

const addGlobalTag = (id: string) => {
  const script = document.createElement("script") as HTMLScriptElement;
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  const initialScript = document.getElementsByTagName("script")[0];
  if (initialScript && initialScript.parentNode) {
    initialScript.parentNode.insertBefore(script, initialScript);
  }
};

export const sendLoginEvent = () => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.login, {
      method: "Email",
    });
  }
};

export const sendSignUpEvent = () => {
  if (hasGoogleAdv()) {
    window.gtag("event", "sign_up", {
      method: "Email",
    });
  }
};

export const sendTileViewEvent = (tile: string) => {
  setTimeout(() => {
    if (hasGoogleAdv()) {
      window.gtag("event", GOOGLE_ADV_EVENTS.view_tile_readmore, {
        tile_name: tile,
      });
    }
  }, 1000);
};

export const sendDisclaimerEvent = () => {
  setTimeout(() => {
    if (hasGoogleAdv()) {
      window.gtag("event", GOOGLE_ADV_EVENTS.disclaimer_showed);
    }
  }, 1000);
};

export const sendDisclaimerAcknowledgeEvent = () => {
  setTimeout(() => {
    if (hasGoogleAdv()) {
      window.gtag("event", GOOGLE_ADV_EVENTS.disclaimer_acknowledge);
    }
  }, 1000);
};

export const sendPageViewEvent = (params: PageView) => {
  setTimeout(() => {
    if (hasGoogleAdv()) {
      window.gtag("event", GOOGLE_ADV_EVENTS.page_view, {
        ...params,
      });
    }
  }, 1000);
};

export const getProtocolViewObject = (
  protocolId: string,
  protocolName: string,
  isNative: boolean,
  isInteractive: boolean,
): IProtocolView => {
  return {
    protocol_name: protocolName,
    protocol_id: protocolId,
    brand: isNative ? "Native" : "Uploaded",
    category: isInteractive ? "Interactive" : "Static",
  };
};

export const getProtocolFavoriteObject = (
  protocol: Protocol,
  isNative: boolean,
  isInteractive: boolean,
) => {
  return {
    protocol_name: protocol.name,
    protocol_id: protocol.id,
    brand: isNative ? "Native" : "Uploaded",
    category: isInteractive ? "Interactive" : "Static",
  };
};

export const sendProtocolViewEvent = (data: IProtocolView) => {
  setTimeout(() => {
    if (hasGoogleAdv()) {
      window.gtag("event", GOOGLE_ADV_EVENTS.view_item, {
        ...data,
      });
    }
  }, 1000);
};

export const sendShareEvent = (data: IShareProtocol) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.share, {
      ...data,
    });
  }
};

export const sendShareRejectEvent = (data: IShareReject) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.share_reject, {
      ...data,
    });
  }
};

export const sendGuideStartEvent = (data: IGuideAction) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.guide_started, {
      ...data,
      event_label: "guide_action",
    });
  }
};

export const sendGuideEndEvent = (data: IGuideAction) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.guide_completed, {
      ...data,
      event_label: "guide_action",
    });
  }
};

export const sendGuideStopEvent = (data: IGuideAction) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.guide_stopped, {
      ...data,
      event_label: "guide_action",
    });
  }
};

export const sendGuideRestartEvent = (data: IGuideAction) => {
  if (hasGoogleAdv()) {
    window.gtag("event", GOOGLE_ADV_EVENTS.guide_restarted, {
      ...data,
      event_label: "guide_action",
    });
  }
};

export const sendProtocolCustomizeEvent = (data: IProtocolCustomized) => {
  if (hasGoogleAdv()) {
    const eventName = data.parent_protocol_id
      ? GOOGLE_ADV_EVENTS.protocol_customized
      : GOOGLE_ADV_EVENTS.protocol_uploaded;
    window.gtag("event", eventName, {
      ...data,
    });
  }
};

export const sendFavoriteEvent = (data: IProtocolFavorite, remove?: boolean) => {
  if (hasGoogleAdv()) {
    const eventName = remove
      ? GOOGLE_ADV_EVENTS.removed_favorites
      : GOOGLE_ADV_EVENTS.added_favorites;
    window.gtag("event", eventName, {
      ...data,
    });
  }
};

export const setEventsIsAuthorized = (authorized: boolean) => {
  setTimeout(() => {
    if (hasGoogleAdv() && googleAdvId) {
      window.gtag("config", googleAdvId, {
        authorized: authorized ? "user" : "guest",
      });
    }
  }, 3000);
};
