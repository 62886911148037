import { createSelector } from "reselect";

import { AppState } from "../../../shared/interfaces";

const selectAuth = (state: AppState) => state.auth;

export const authentificated = () => createSelector(selectAuth, (state) => state.authorized);

export const currentAuthForm = () => createSelector(selectAuth, (state) => state.current_auth_form);

export const restoreHash = () => createSelector(selectAuth, (state) => state.restore_hash);
