import React from "react";
import { MenuListComponentProps, OptionTypeBase, OptionsType, components } from "react-select";

export const MenuList = (
  props: React.PropsWithChildren<
    MenuListComponentProps<OptionTypeBase | OptionsType<OptionTypeBase>, boolean>
  > & {
    addButtonHandler?: () => void;
    addButtonLabel?: string;
  },
) => {
  const { addButtonHandler, addButtonLabel, ...innerProps } = props;

  return (
    <>
      <components.MenuList {...innerProps}>
        <> {innerProps.children}</>
        {addButtonHandler && (
          <div className="select-add-wrapper">
            <div className="select-add-content">
              <div className="select-add-button" onClick={addButtonHandler}>
                {addButtonLabel}
              </div>
            </div>
          </div>
        )}
      </components.MenuList>
    </>
  );
};
