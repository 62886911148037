import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { LoadingSections, SharedStateType } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: SharedStateType = {
  loading: false,
  notification: null,
  procedure_types: [],
  type_subtype: [],
  parameters: [],
  created_tag: null,
  tags: [],
  user: null,
  show_disclaimer_modal: false,
  is_played_guide: false,
  loadingTypes: [],
  loadingSections: {},
  is_online: true,
};

const reducer = createReducer<SharedStateType, Action>(initialState)
  .handleAction(actions.startLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = true;
    }),
  )
  .handleAction(actions.stopLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loading = false;
    }),
  )
  .handleAction(actions.showNotification, (state, action) =>
    produce(state, (nexState) => {
      nexState.notification = action.payload;
    }),
  )
  .handleAction(actions.getProcedureTypes.success, (state, action) =>
    produce(state, (nexState) => {
      nexState.procedure_types = action.payload.items;
    }),
  )
  .handleAction(actions.getTags.success, (state, action) =>
    produce(state, (nexState) => {
      nexState.tags = action.payload.items;
    }),
  )
  .handleAction(actions.createTag.success, (state, action) =>
    produce(state, (nexState) => {
      nexState.created_tag = action.payload.tag;
      nexState.tags = [...state.tags, action.payload.tag];
    }),
  )
  .handleAction(actions.clearCreatedTag, (state) =>
    produce(state, (nexState) => {
      nexState.created_tag = null;
    }),
  )
  .handleAction(actions.getUserData.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.user = action.payload;
    }),
  )
  .handleAction(actions.getTypeSubtypes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.type_subtype = action.payload.items;
    }),
  )
  .handleAction(actions.setIsAcknowledged.success, (state) =>
    produce(state, (nextState) => {
      if (nextState.user) {
        nextState.user.is_acknowledged = true;
      }
    }),
  )
  .handleAction(actions.showDisclaimerModal, (state) =>
    produce(state, (nextState) => {
      nextState.show_disclaimer_modal = true;
    }),
  )
  .handleAction(actions.closeDisclaimerModal, (state) =>
    produce(state, (nextState) => {
      nextState.show_disclaimer_modal = false;
    }),
  )
  .handleAction(actions.getParameters.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.parameters = action.payload.items;
    }),
  )
  .handleAction(actions.addLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = [...state.loadingTypes, action.payload];
    }),
  )
  .handleAction(actions.removeLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = state.loadingTypes.filter(({ name }) => name !== action.payload);
      nextState.loadingSections = {};
    }),
  )
  .handleAction(actions.addLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections: LoadingSections = { ...state.loadingSections };

      if (!loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = [];
      }

      loadingSections[action.payload.loadingSection] = [
        ...Array.from(
          new Set([...loadingSections[action.payload.loadingSection], action.payload.requestName]),
        ),
      ];

      nextState.loadingSections = loadingSections;
    }),
  )
  .handleAction(actions.removeLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections = { ...state.loadingSections };

      if (loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = loadingSections[
          action.payload.loadingSection
        ].filter((loadingRequest) => loadingRequest !== action.payload.requestName);

        if (!loadingSections[action.payload.loadingSection].length) {
          delete loadingSections[action.payload.loadingSection];
        }

        nextState.loadingSections = loadingSections;
      }
    }),
  )
  .handleAction(actions.setIsOnline, (state, action) =>
    produce(state, (nextState) => {
      nextState.is_online = action.payload;
    }),
  );

export default reducer;
