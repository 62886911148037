import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import ReactSelect, { OptionsType, OptionTypeBase, ValueType } from "react-select";
import { FormikErrors, FormikTouched } from "formik";

import "./index.scss";
import { MenuList } from "./SelectPartials";
import styleVariables from "../../../../styles/variables.scss";
import { Label } from "../Label";

// eslint-disable-next-line
const customStyles: any = {
  control: (provided: CSSProperties, { isDisabled }: { isDisabled: boolean }) => {
    return {
      ...provided,
      background: styleVariables.inputBgColor,
      color: styleVariables.inputColor,
      fontFamily: styleVariables.inputFontFamily,
      borderRadius: styleVariables.inputBorderRadius,
      fontSize: styleVariables.inputFontSize,
      border: isDisabled ? `none` : `1px solid ${styleVariables.inputBorderColor}`,
    };
  },
  option: (provided: CSSProperties) => {
    return {
      ...provided,
      fontFamily: styleVariables.inputFontFamily,
      fontSize: styleVariables.inputFontSize,
      color: styleVariables.inputColor,
      backgroundColor: null,
    };
  },
  menu: (provided: CSSProperties) => ({
    ...provided,
    fontFamily: styleVariables.inputFontFamily,
    zIndex: 100,
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    fontFamily: styleVariables.inputFontFamily,
    zIndex: 100,
    maxHeight: "200px",
  }),
  placeholder: (provided: CSSProperties) => {
    return {
      ...provided,
      color: styleVariables.grey,
      fontFamily: styleVariables.inputFontFamily,
      fontSize: styleVariables.inputFontSize,
      fontWeight: styleVariables.inputFontWeight,
    };
  },
  multiValue: (provided: CSSProperties) => {
    return {
      ...provided,
      backgroundColor: "#F6F7F8",
    };
  },
  multiValueLabel: (provided: CSSProperties) => ({
    ...provided,
    color: "#2B4144",
    fontFamily: styleVariables.inputFontFamily,
    fontSize: styleVariables.inputFontSize,
    fontWeight: styleVariables.inputFontWeight,
  }),
  multiValueRemove: (provided: CSSProperties) => ({
    ...provided,
    color: "#828F92",
    ":hover": {
      color: "#828F92",
    },
  }),
  singleValue: (provided: CSSProperties) => ({
    ...provided,
    color: styleVariables.inputColor,
  }),
  indicatorsContainer: (provided: CSSProperties, { isDisabled }: { isDisabled: boolean }) => ({
    ...provided,
    display: isDisabled ? "none" : "contents",
  }),
  clearIndicator: (provided: CSSProperties) => ({
    ...provided,
    padding: "0px",
  }),
};

interface CustomSelectInput {
  isRequiredField?: boolean;
  addButtonLabel?: string;
  addButtonHandler?: () => void;
  onChange: (options: ValueType<OptionTypeBase, true | false>) => void;
  noOptionsMessage?: () => string;
  // eslint-disable-next-line
  errors?: FormikErrors<any>;
  // eslint-disable-next-line
  touched?: FormikTouched<any>;
  max?: number;
}

const SelectInput: React.FunctionComponent<
  React.ComponentProps<typeof ReactSelect> & CustomSelectInput
> = (props) => (
  <ReactSelect
    styles={customStyles}
    {...props}
    components={{
      IndicatorSeparator: () => null,
      MenuList: (innerProps) => (
        <MenuList
          {...innerProps}
          addButtonHandler={props.addButtonHandler}
          addButtonLabel={props.addButtonLabel}
        />
      ),
    }}
  />
);

const SelectComponent = (props: React.ComponentProps<typeof ReactSelect> & CustomSelectInput) => {
  const { isRequiredField, name, max, errors, onChange } = props;

  const isError = useMemo(() => {
    return errors && name && errors[name];
  }, [errors, name]);

  const [prevValue, setPrevValue] = useState(props.value);

  const localOnChange = useCallback(
    (value: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
      if (max && value?.length > max && prevValue) {
        onChange(prevValue);
        return;
      }
      onChange(value);
      setPrevValue(value);
    },
    [onChange, prevValue, max],
  );

  return (
    <div
      id={props.name}
      className={classnames("select-wrapper-root", {
        erorr: isError,
      })}
    >
      <Label name={props.name} isRequiredField={isRequiredField} label={props.label} />
      {max && (
        <div className="input-max-counter">
          {props.value?.length || 0}/{max}
        </div>
      )}
      <SelectInput {...props} onChange={localOnChange} />
    </div>
  );
};

export default SelectComponent;
