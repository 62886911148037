import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { ROUTE_PATHS } from "../../shared/constants";
import { checkPermissions } from "../../shared/utils/ACL";

/* eslint-disable */
interface PrivateRouteProps extends RouteProps {
  component: React.FunctionComponent<any>;
  authentificated: boolean;
  permissions?: string[];
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  authentificated,
  permissions,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (authentificated && (!permissions || checkPermissions(permissions))) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: ROUTE_PATHS.AUTH_LOGIN,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
