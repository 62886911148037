import React from "react";

import { ProcedureType } from "../../../../../shared/models";
import { ProtocolProcedureTypeItemFilter } from "./components";

import "./index.scss";

interface ProtocolProcedureTypeFilterProps {
  procedure_types: ProcedureType[];
  current_procedure_type: string;
  onSelect: (value: string) => void;
}

function ProtocolProcedureTypeFilter(props: ProtocolProcedureTypeFilterProps) {
  const { procedure_types, current_procedure_type, onSelect } = props;
  return (
    <div className="protocol-procedure-type-filter">
      {procedure_types.map((pt) => (
        <ProtocolProcedureTypeItemFilter
          key={pt.id}
          procedure_type={pt}
          is_selected={current_procedure_type === String(pt.id)}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}

export default ProtocolProcedureTypeFilter;
