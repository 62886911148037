import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { updateProtocolFavorites } from "@shared/utils/protocol";

import { FilterDefaultValue } from "../constants";
import { DashboardStateType } from "../interfaces";
import * as actions from "./actions";

export const initialState: DashboardStateType = {
  protocols: [],
  filter: FilterDefaultValue,
};

const reducer = createReducer<DashboardStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getProtocols.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.protocols = action.payload.items;
    }),
  )
  .handleAction(actions.updateFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = { ...nextState.filter, ...action.payload };
    }),
  )
  .handleAction(actions.setProtocolFavorite, (state, action) =>
    produce(state, (nextState) => {
      const result = action.payload;
      const foundProtocolIndex = nextState.protocols.findIndex(
        (item) => item.id === result.protocol_id,
      );
      if (foundProtocolIndex >= 0) {
        updateProtocolFavorites(nextState.protocols[foundProtocolIndex], result);
      }
    }),
  );

export default reducer;
