import { call, put, takeLatest } from "redux-saga/effects";

import { actions as sharedActions } from "../../../shared/store";
import { actions as dashboardActions } from "../../../containers/Dashboard/store";
import { AUTH_FORM_TYPES, ROUTE_PATHS, APP_VERSION_KEY } from "../../../shared/constants";
import history from "../../../shared/history";
import { startLoading, stopLoading } from "../../../shared/store/actions";
import { tokenHandler, sendLoginEvent, sendSignUpEvent } from "../../../shared/utils";
import api from "../api";
import {
  AuthShape,
  LoginShape,
  RegistrationShape,
  ActivateShape,
  ResendResponseShape,
  SetNewPasswordShape,
  SetNewPasswordResponseShape,
} from "../interfaces";
import { ActionWithPayload } from "../../../shared/interfaces";

import { actions } from "./";

function* login(token: string, redirectPath?: string) {
  yield tokenHandler.set(token);
  yield put(dashboardActions.getProtocols.request());
  if (redirectPath) {
    history.push(redirectPath);
  }
}

function* logoutSaga() {
  const appVersion = localStorage.getItem(APP_VERSION_KEY);
  localStorage.clear();
  if (appVersion) {
    localStorage.setItem(APP_VERSION_KEY, appVersion);
  }
  yield put(actions.logout.success());
  yield put(sharedActions.getUserData.success(null));
  yield put(dashboardActions.getProtocols.request());
  yield history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
}

function* loginSaga({ payload }: ActionWithPayload<LoginShape>) {
  try {
    yield put(startLoading());
    const { token } = yield call(api.login, payload);
    yield put(actions.login.success());
    sendLoginEvent();
    yield put(stopLoading());
    yield login(token, ROUTE_PATHS.PROTOCOLS_DASHBOARD);
  } catch (error) {
    yield put(actions.login.failure(error));
    yield put(stopLoading());
  }
}

function* checkEmailAvailabilitySaga({ payload }: ActionWithPayload<AuthShape>) {
  try {
    yield put(startLoading());
    const { available } = yield call(api.checkEmailAvailability, payload);
    yield put(
      actions.updateCurrentAuthForm(
        available ? AUTH_FORM_TYPES.LOGIN : AUTH_FORM_TYPES.REGISTRATION,
      ),
    );
    yield put(actions.checkEmailAvailability.success());

    yield put(stopLoading());
  } catch (error) {
    yield put(actions.login.failure(error));
    yield put(stopLoading());
  }
}

function* registrationSaga({ payload }: ActionWithPayload<RegistrationShape>) {
  try {
    yield put(startLoading());
    const { password, email, first_name, last_name, title, service_line } = payload;
    yield call(api.signUp, {
      password,
      email,
      first_name,
      last_name,
      title,
      service_line,
    } as Partial<RegistrationShape>);
    yield put(actions.signUp.success());
    sendSignUpEvent();
    yield put(actions.updateCurrentAuthForm(AUTH_FORM_TYPES.CONFIRMATION));
  } catch (error) {
    yield put(actions.signUp.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* activateAccountSaga({ payload }: ActionWithPayload<ActivateShape>) {
  try {
    yield put(startLoading());
    const { hash } = payload;
    const { token } = yield call(api.activateAccount, hash);
    yield put(sharedActions.getUserData.request({}));
    yield put(actions.login.success());
    yield put(stopLoading());
    yield login(token, ROUTE_PATHS.DISCLAIMER);
  } catch (error) {
    yield put(actions.activateAccount.failure(error));
    history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
  } finally {
    yield put(stopLoading());
  }
}

function* resendConfirmationSaga({ payload }: ActionWithPayload<AuthShape>) {
  try {
    yield put(startLoading());
    const response: ResendResponseShape = yield call(api.resendConfirmation, payload);
    yield put(actions.resendConfirmation.success(response));
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.resendConfirmation.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* restorePasswordSaga({ payload }: ActionWithPayload<AuthShape>) {
  try {
    yield put(startLoading());
    yield call(api.forgotPassword, payload);
    yield put(actions.restorePassword.success());
    yield put(actions.updateCurrentAuthForm(AUTH_FORM_TYPES.RESTORE_PASSWORD_EMAIL));
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.restorePassword.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* resendRestorePasswordSaga({ payload }: ActionWithPayload<AuthShape>) {
  try {
    yield put(startLoading());
    const response: ResendResponseShape = yield call(api.resendForgotPassword, payload);
    yield put(actions.resendRestorePassword.success(response));
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.resendRestorePassword.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* setNewPasswordSaga({ payload }: ActionWithPayload<SetNewPasswordShape>) {
  try {
    yield put(startLoading());
    const response: SetNewPasswordResponseShape = yield call(api.setNewPassword, payload);
    yield put(actions.setNewPassword.success(response));
    yield login(response.token);
    yield put(
      sharedActions.getUserData.request({
        callback: () => history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD),
      }),
    );
    yield put(stopLoading());
  } catch (error) {
    yield put(actions.setNewPassword.failure(error));
  } finally {
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(actions.login.request, loginSaga);
  yield takeLatest(actions.signUp.request, registrationSaga);
  yield takeLatest(actions.logout.request, logoutSaga);
  yield takeLatest(actions.checkEmailAvailability.request, checkEmailAvailabilitySaga);
  yield takeLatest(actions.activateAccount.request, activateAccountSaga);
  yield takeLatest(actions.resendConfirmation.request, resendConfirmationSaga);
  yield takeLatest(actions.restorePassword.request, restorePasswordSaga);
  yield takeLatest(actions.resendRestorePassword.request, resendRestorePasswordSaga);
  yield takeLatest(actions.setNewPassword.request, setNewPasswordSaga);
}

export default authSagas;
