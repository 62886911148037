export enum SharedActionTypes {
  START_LOADING = "@@SHARED/START_LOADING",
  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",
  STOP_LOADING = "@@SHARED/STOP_LOADING",
  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",
  CLEAR_CREATED_TAG = "@@SHARED/CLEAR_CREATED_TAG",

  GET_PROCEDURE_TYPES = "@@SHARED/GET_PROCEDURE_TYPES",
  GET_PROCEDURE_TYPES_SUCCESS = "@@SHARED/GET_PROCEDURE_TYPES_SUCCESS",
  GET_PROCEDURE_TYPES_FAILURE = "@@SHARED/GET_PROCEDURE_TYPES_FAILURE",

  GET_TYPE_SUBTYPES = "@@SHARED/GET_TYPE_SUBTYPES",
  GET_TYPE_SUBTYPES_SUCCESS = "@@SHARED/GET_TYPE_SUBTYPES_SUCCESS",
  GET_TYPE_SUBTYPES_FAILURE = "@@SHARED/GET_TYPE_SUBTYPES_FAILURE",

  GET_PARAMETERS = "@@SHARED/GET_PARAMETERS",
  GET_PARAMETERS_SUCCESS = "@@SHARED/GET_PARAMETERS_SUCCESS",
  GET_PARAMETERS_FAILURE = "@@SHARED/GET_PARAMETERS_FAILURE",

  GET_TAGS = "@@SHARED/GET_TAGS",
  GET_TAGS_SUCCESS = "@@SHARED/GET_TAGS_SUCCESS",
  GET_TAGS_FAILURE = "@@SHARED/GET_TAGS_FAILURE",

  GET_PRESIGNED_URL = "@@SHARED/GET_PRESIGNED_URL",
  GET_PRESIGNED_URL_SUCCESS = "@@SHARED/GET_PRESIGNED_URL_SUCCESS",
  GET_PRESIGNED_URL_FAILURE = "@@SHARED/GET_PRESIGNED_URL_FAILURE",

  CREATE_TAG = "@@SHARED/CREATE_TAG",
  CREATE_TAG_SUCCESS = "@@SHARED/CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILURE = "@@SHARED/CREATE_TAG_FAILURE",

  GET_USER = "@@SHARED/GET_USER",
  GET_USER_SUCCESS = "@@SHARED/GET_USER_SUCCESS",
  GET_USER_FAILURE = "@@SHARED/GET_USER_FAILURE",

  GET_LATEST_APP_VERSION = "@@SHARED/GET_LATEST_APP_VERSION",
  GET_LATEST_APP_VERSION_SUCCESS = "@@SHARED/GET_LATEST_APP_VERSION_SUCCESS",
  GET_LATEST_APP_VERSION_FAILURE = "@@SHARED/GET_LATEST_APP_VERSION_FAILURE",

  SET_IS_ACKNOWLEDGED = "@@SHARED/SET_IS_ACKNOWLEDGED",
  SET_IS_ACKNOWLEDGED_SUCCESS = "@@SHARED/SET_IS_ACKNOWLEDGED_SUCCESS",
  SET_IS_ACKNOWLEDGED_FAILURE = "@@SHARED/SET_IS_ACKNOWLEDGED_FAILURE",

  SET_PLAY_GUIDE_STATUS = "@@SHARED/SET_PLAY_GUIDE_STATUS",
  SET_PLAY_GUIDE_STATUS_SUCCESS = "@@SHARED/SET_PLAY_GUIDE_STATUS_SUCCESS",
  SET_PLAY_GUIDE_STATUS_FAILURE = "@@SHARED/SET_PLAY_GUIDE_STATUS_FAILURE",

  SHOW_DISCLAIMER_MODAL = "@@SHARED/SHOW_DISCLAIMER_MODAL",
  CLOSE_DISCLAIMER_MODAL = "@@SHARED/CLOSE_DISCLAIMER_MODAL",

  EDIT_PROFILE = "@@SHARED/EDIT_PROFILE",
  EDIT_PROFILE_SUCCESS = "@@SHARED/EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILURE = "@@SHARED/EDIT_PROFILE_FAILURE",

  SET_IS_ONLINE = "@@SHARED/SET_IS_ONLINE",
}
