import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { Button, StyledButton, InputFile, ImageCropperModal } from "@shared/components";
import { CustomFile, ProfileEditDto } from "@shared/interfaces";
import { ProfileEditShape } from "@containers/Auth/interfaces";
import { AuthFormSchema } from "@containers/Auth/constants";
import { User } from "@shared/models";
import { actions } from "@shared/store";
import { APPEARANCE } from "@shared/components/Notification/constants";

import { getFormValues, prepareEditFormValues } from "./formHelpers";
import { BaseProfileForm } from "../";

import "./index.scss";

const MAX_FILE_SIZE = 30000000;
const TOO_BIG_AVATAR_MESSAGE = "The image is too big";

interface ProfileEditFormProps {
  onClose: () => void;
  onSubmit: (values: ProfileEditDto) => void;
  user: User | null;
  onChange: () => void;
}

const ProfileEditForm = (props: ProfileEditFormProps) => {
  const dispatch = useDispatch();
  const { user, onClose, onSubmit, onChange } = props;
  const [formValues, setFormValues] = useState<ProfileEditShape>(getFormValues(user));
  const [currentAvatar, setCurrentAvatar] = useState("");
  const [srcAvatarToCrop, setSrcAvatarToCrop] = useState<CustomFile | null>(null);

  const displayFileRejectMessage = useCallback(
    (message: string) => {
      dispatch(
        actions.showNotification({
          message,
          appearance: APPEARANCE.ERROR,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (user) {
      setFormValues(getFormValues(user));
      if (user.files.length) {
        setCurrentAvatar(user.files[0].url || "");
      }
    }
  }, [user]);

  const handleSubmit = useCallback(
    (values: ProfileEditShape) => {
      onSubmit(prepareEditFormValues(values));
    },
    [onSubmit],
  );

  return (
    <div className="profile-form">
      <Formik
        initialValues={formValues}
        validationSchema={AuthFormSchema.PROFILE_EDIT.VALIDATION}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        validate={onChange}
      >
        {({ errors, touched, setFieldValue }: FormikProps<ProfileEditShape>) => (
          <Form autoComplete="off">
            <div className="close-profile-view" onClick={onClose}>
              Back
            </div>
            <div className="profile-form-title">Edit profile</div>
            {srcAvatarToCrop && (
              <ImageCropperModal
                isShowing={Boolean(srcAvatarToCrop)}
                handleCancel={() => setSrcAvatarToCrop(null)}
                handleSuccess={(croppedImage) => {
                  setFieldValue("images", {
                    files: [croppedImage],
                    old_files: [],
                  });
                  setCurrentAvatar(croppedImage.base64);
                  setSrcAvatarToCrop(null);
                  onChange();
                }}
                srcImage={srcAvatarToCrop}
              />
            )}
            <InputFile
              onChangeFile={(value) => {
                if (value.size <= MAX_FILE_SIZE) {
                  setSrcAvatarToCrop(value);
                } else {
                  displayFileRejectMessage(TOO_BIG_AVATAR_MESSAGE);
                }
              }}
              name="images"
              accept="image/png, image/jpg, image/jpeg"
            >
              <div className="avatar-wrapper">
                {currentAvatar && (
                  <div
                    className="avatar-remove"
                    onClick={(e: React.MouseEvent) => {
                      setFieldValue("images", {
                        files: [],
                        old_files: [],
                      });
                      setCurrentAvatar("");
                      onChange();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                )}
                <div className="avatar-edit" />
                {currentAvatar && (
                  <img
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    src={currentAvatar}
                    alt=""
                  />
                )}
              </div>
            </InputFile>
            <BaseProfileForm editMode={true} errors={errors} touched={touched} />
            <div className="profile-form-footer">
              <StyledButton
                label="Cancel"
                className="styled-button-black styled-button-bordered customize"
                bordered={true}
                onClick={onClose}
                type="button"
                disabled={Boolean(srcAvatarToCrop)}
              />
              <Button label="Save" type="submit" disabled={Boolean(srcAvatarToCrop)} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileEditForm;
