import { createSelector } from "reselect";

import { AppState } from "../../../shared/interfaces";
import { Protocol } from "../../../shared/models";

const selectDashboard = (state: AppState) => state.dashboard;

export const getProtocols = () =>
  createSelector(selectDashboard, (state) => {
    const protocolsBySearch = getProtocolsBySearch(state.filter.search, state.protocols);
    const protocolsByTags = getProtocolsByTags(state.filter.tags, protocolsBySearch);
    return getProtocolsByProcedureTypes(state.filter.procedure_type, protocolsByTags);
  });

export const getAllProtocols = () => createSelector(selectDashboard, (state) => state.protocols);

export const getFilter = () => createSelector(selectDashboard, (state) => state.filter);

function getProtocolsBySearch(search: string, protocols: Protocol[]) {
  if (!search) return protocols;
  return protocols.filter((p) => {
    const s = search.toLowerCase();
    const name = p.name ? p.name.toLowerCase() : "";
    const longDescription = p.long_description ? p.long_description.toLowerCase() : "";
    const shortDescription = p.short_description ? p.short_description.toLowerCase() : "";

    return name.includes(s) || longDescription.includes(s) || shortDescription.includes(s);
  });
}

function getProtocolsByTags(tags: string[], protocols: Protocol[]) {
  if (!tags.length) return protocols;
  return protocols.filter((p) => {
    const protocolTags = p.tags || [];
    return tags.find((t) => protocolTags.find((pt) => String(pt.id) === String(t)));
  });
}

function getProtocolsByProcedureTypes(procedure_type: string, protocols: Protocol[]) {
  if (!procedure_type) return protocols;
  return protocols.filter((p) =>
    (p.procedure_types || []).find((p) => String(p.id) === procedure_type),
  );
}
