import React from "react";

import { IEdgeMarkerProps } from "../../interfaces";

export default ({ id, children }: IEdgeMarkerProps) => {
  return (
    <marker
      id={id}
      markerWidth="15"
      markerHeight="15"
      viewBox="-10 -10 20 20"
      orient="auto"
      markerUnits="userSpaceOnUse"
      refX="0"
      refY="0"
    >
      {children}
    </marker>
  );
};
