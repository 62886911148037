import React, { useState, useCallback, useRef } from "react";
import { Formik, Form, FieldArray, FormikProps } from "formik";
import {
  InteractiveGuideStepFormShape,
  InteractiveGuideStepSettingFormShape,
} from "@containers/Protocol/interfaces";
import { StyledButton } from "@shared/components";
import { IGuideStep, ParameterUnit } from "@shared/models";
import { ProtocolFormSchema } from "@containers/Protocol/constants";
import { OptionTypeBase, ValueType } from "react-select";

import {
  getField,
  getFieldName,
  getFormValueFromGuideStep,
} from "../ProtocolInteractiveGuideForm/formHelpers";

import "./index.scss";

interface ProtocolInteractiveGuideMobileFormProps {
  parameters: ParameterUnit[];
  guideStep: IGuideStep;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (values: InteractiveGuideStepFormShape) => void;
}

function ProtocolInteractiveGuideMobileForm(props: ProtocolInteractiveGuideMobileFormProps) {
  const { guideStep, onChangeForm, onCloseForm, onSubmitForm, parameters } = props;

  const [formValues] = useState(getFormValueFromGuideStep(guideStep, parameters));
  const formRef = useRef<FormikProps<InteractiveGuideStepFormShape> | null>(null);

  const handleChangeForm = useCallback(
    (
      fieldValue: ValueType<OptionTypeBase, false> | string,
      shapeValue: InteractiveGuideStepSettingFormShape,
    ) => {
      if (shapeValue.dependencies.length && formRef && formRef.current) {
        const values = formRef.current.values;
        shapeValue.dependencies.forEach((d) => {
          const settingIndex = values.settings.findIndex((s) => s.id === d);
          if (settingIndex !== -1) {
            const fieldName = getFieldName(shapeValue.field_type);
            formRef.current?.setFieldValue(`settings.${settingIndex}.${fieldName}`, fieldValue);
          }
        });
      }

      onChangeForm();
    },
    [onChangeForm, formRef],
  );

  const getRowFormFieldsWithTypeConditions = useCallback(
    (formikProps: FormikProps<InteractiveGuideStepFormShape>) => {
      return (
        <FieldArray
          name={"settings"}
          render={() => (
            <div className="guide-step-row">
              {formikProps.values.settings.map(
                (value: InteractiveGuideStepSettingFormShape, indexSetting: number) => (
                  <div key={value.id} className="guide-step-setting-field">
                    <div className="field-wrapper">
                      {getField(
                        value,
                        formikProps.values.settings,
                        parameters,
                        `settings.${indexSetting}`,
                        handleChangeForm,
                        formikProps,
                      )}
                    </div>
                  </div>
                ),
              )}
            </div>
          )}
        />
      );
    },
    [parameters, handleChangeForm],
  );

  const getRowFormFields = useCallback(
    (formikProps: FormikProps<InteractiveGuideStepFormShape>) => {
      return getRowFormFieldsWithTypeConditions(formikProps);
    },
    [getRowFormFieldsWithTypeConditions],
  );

  return (
    <div className="guide-mobile-form">
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ProtocolFormSchema.CREATE_EDIT_INTERACTIVE_STEP_GUIDE.VALIDATION}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitForm(values);
        }}
        initialValues={formValues}
        validateOnMount
      >
        {(formikProps) => (
          <Form noValidate={true}>
            {getRowFormFields(formikProps)}
            <div className="modal-actions">
              <StyledButton
                label={"Cancel"}
                bordered={true}
                onClick={() => onCloseForm()}
                type="button"
              />
              <StyledButton
                label={"Ok"}
                bordered={true}
                filled={true}
                color="accent"
                textColor="white"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProtocolInteractiveGuideMobileForm;
