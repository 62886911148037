import { SelectedStepResult } from "@containers/Protocol/interfaces";
import { ParameterUnit } from "@shared/models";
import { sliceArray } from "@shared/utils";
import React, { useMemo, useCallback } from "react";

import GuideElement from "../GuideElement/GuideElement";

import "./index.scss";

interface GuideElementsProps {
  parameters: ParameterUnit[];
  selectedSteps: SelectedStepResult[];
  onElementClick: (step: SelectedStepResult) => void;
}

const GuideElements = (props: GuideElementsProps) => {
  const { selectedSteps, onElementClick, parameters } = props;

  const filteredSelectedSteps: SelectedStepResult[] = useMemo(() => {
    return sliceArray(0, selectedSteps.length - 1, selectedSteps);
  }, [selectedSteps]);

  const getNextConnection = useCallback(
    (i: number) => {
      const selectedStep = selectedSteps[i + 1];
      if (!selectedStep) return null;
      return selectedStep.connections.find(Boolean) || null;
    },
    [selectedSteps],
  );

  return (
    <>
      {filteredSelectedSteps.map((s, i) => (
        <GuideElement
          selectedStep={s}
          connection={getNextConnection(i)}
          onElementClick={onElementClick}
          key={`${s.step.id}${i}`}
          parameters={parameters}
        />
      ))}
    </>
  );
};

export default GuideElements;
