import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { actions } from "@shared/store";
import { isMobile } from "@shared/utils/functions";
import { ROUTE_PATHS, SIDE_URL, EDWARDS_EMAIL } from "@shared/constants";

import "./index.scss";

function Footer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLegalClick = useCallback(() => {
    isMobile() ? history.push(ROUTE_PATHS.DISCLAIMER) : dispatch(actions.showDisclaimerModal());
  }, [dispatch, history]);

  return (
    <div className="footer-container">
      <div className="footer-legal" onClick={onLegalClick}>
        Terms of Use
      </div>
      <a
        href={SIDE_URL.PRIVACY_POLICY}
        target="_blank"
        rel="noopener noreferrer"
        className="footer-legal"
      >
        Privacy Policy
      </a>
      <a
        href={SIDE_URL.NORMAL_HEMODYNAMIC_RANGES}
        target="_blank"
        rel="noopener noreferrer"
        className="footer-legal"
      >
        Normal Hemodynamic Ranges
      </a>
      <a
        href={`mailto:${EDWARDS_EMAIL.SUPPORT}`}
        target="_blank"
        rel="noopener noreferrer"
        className="footer-legal"
      >
        Contact Us
      </a>
      <div id="irmlink"></div>
      <div id="teconsent"></div>
      <div id="consent_blackbar"></div>
    </div>
  );
}

export default Footer;
