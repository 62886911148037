import React, { useMemo } from "react";
import { Switch } from "react-router";
import { useSelector } from "react-redux";

import { getLoading, getLoadingSections } from "../../../../shared/store/selectors";
import { ProtocolDashboardContainer } from "../";
import { ROUTE_PATHS } from "../../../../shared/constants";
import PrivateRoute from "../../../App/PrivateRoute";
import { LoadingIndicator } from "../../../../shared/components";

const DashboardContainer = () => {
  const loading = useSelector(getLoading());
  const loadingSections = useSelector(getLoadingSections());

  const isLoader = useMemo(() => loading || !!loadingSections.length, [loading, loadingSections]);

  return (
    <div className="dashboard-container">
      {isLoader && <LoadingIndicator />}
      <Switch>
        <PrivateRoute
          path={ROUTE_PATHS.PROTOCOLS_DASHBOARD}
          component={ProtocolDashboardContainer}
          authentificated={true}
          exact
        />
        <PrivateRoute
          path={ROUTE_PATHS.RESTORE_PASSWORD}
          component={ProtocolDashboardContainer}
          authentificated={true}
          exact
        />
      </Switch>
    </div>
  );
};

export default DashboardContainer;
