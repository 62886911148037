import React from "react";

import { LoginShape } from "../../../interfaces";
import { Button } from "../../../../../shared/components";
import "./index.scss";

interface ConfirationProps {
  handleSubmit: (values: LoginShape) => void;
  defaultValue: LoginShape;
  onResendConfirmation: (email: string) => void;
}

const Confirmation = ({ handleSubmit, defaultValue, onResendConfirmation }: ConfirationProps) => {
  return (
    <div className="registration-confirmation">
      <p>
        Email has been sent to <span>{defaultValue.email}.</span> Please check your inbox to
        continue.
      </p>
      <hr />
      <p>
        If you didn’t get the email please{" "}
        <span
          className="resend-confirmation-link"
          onClick={() => onResendConfirmation(defaultValue.email)}
        >
          click here to send again.
        </span>
      </p>
      <div className="confirmation-footer">
        <Button onClick={() => handleSubmit(defaultValue)} label="Already Confirmed" />
      </div>
    </div>
  );
};

export default Confirmation;
