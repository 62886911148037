import React, { useCallback, useEffect } from "react";
import { Disclaimer } from "@shared/components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { selectors, actions } from "@shared/store";
import { setGuestIsAcknowledged, sendDisclaimerEvent } from "@shared/utils";
import { useScrollToTop } from "@shared/hooks";
import { ROUTE_PATHS } from "@shared/constants";

import "./index.scss";

export default function DisclaimerContainer() {
  const dispatch = useDispatch();
  useScrollToTop();
  useEffect(() => {
    sendDisclaimerEvent();
  }, []);
  const history = useHistory();
  const user = useSelector(selectors.getUser());
  const handleSubmit = useCallback(() => {
    if (user) {
      user.is_acknowledged ? history.goBack() : dispatch(actions.setIsAcknowledged.request());
    } else {
      setGuestIsAcknowledged();
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
      }
    }
  }, [user, dispatch, history]);

  return (
    <div className="disclaimer-page-container">
      <Disclaimer onSubmit={handleSubmit} />
    </div>
  );
}
