import React, { useRef } from "react";

import "./index.scss";
import { CustomFile } from "../../../interfaces";

export interface InputFileProps {
  onChangeFile: (value: CustomFile) => void;
  name: string;
  children: React.ReactNode;
  accept?: string;
}

const InputFile = (props: InputFileProps) => {
  const { onChangeFile, name, accept } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        onChangeFile({ name: file.name, base64: String(reader.result), size: file.size });
      };
    }
  };

  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <div className="input-file-wrapper">
      <input
        type="file"
        name={name}
        onChange={changeHandler}
        ref={hiddenFileInput}
        accept={accept}
      />
      <div className="children-content" onClick={() => handleClick()}>
        {props.children}
      </div>
    </div>
  );
};

export default InputFile;
