import React, { useMemo, useCallback } from "react";
import { SelectedStepResult } from "@containers/Protocol/interfaces";
import { checkIsLabel } from "@containers/Protocol/utils";
import { InteractiveGuide } from "@shared/components";
import { IGuideStep, IGuideStepConnection, ParameterUnit } from "@shared/models";
import { OnLoadParams, ReactFlowProvider } from "react-flow-renderer";
import { scrollToBottom } from "@shared/utils/functions";

import { ProtocolGuidePlayConditions } from "../ProtocolGuidePlayConditions";
import { GuideElements } from "./components";

import "./index.scss";

interface ProtocolGuidePlayMobileProps {
  isGuideView: boolean;
  selectedSteps: SelectedStepResult[];
  isFirstStep: boolean;
  nextSteps: IGuideStep[];
  currentStep: IGuideStep;
  nextConnections: IGuideStepConnection[];
  handleSubmit: (step: IGuideStep) => void;
  onElementClick: (step: SelectedStepResult) => void;

  guideSteps: IGuideStep[];
  guideStepConnections: IGuideStepConnection[];
  onInitReactFlowInstance?: (instance: OnLoadParams) => void;
  isShowRestartCondition: boolean;
  handleRestartSubmit: () => void;
  parameters: ParameterUnit[];
}

const ProtocolGuidePlayMobile = (props: ProtocolGuidePlayMobileProps) => {
  const {
    selectedSteps,
    isFirstStep,
    nextSteps,
    currentStep,
    nextConnections,
    handleSubmit,
    onElementClick,
    isGuideView,
    guideSteps,
    guideStepConnections,
    onInitReactFlowInstance,
    isShowRestartCondition,
    handleRestartSubmit,
    parameters,
  } = props;

  const selectedStepsWithoutLabels = useMemo(() => {
    return selectedSteps.filter((s) => !checkIsLabel(s.step));
  }, [selectedSteps]);

  const localHandleSubmit = useCallback(
    (step: IGuideStep) => {
      handleSubmit(step);
      setTimeout(() => scrollToBottom("play-guide mobile"), 0);
    },
    [handleSubmit],
  );

  return (
    <div className="guide-play-mobile-view">
      {!isFirstStep && (
        <div className="guide-play-mobile-instruction">Click on item to go back</div>
      )}
      <div className="guide-play-mobile-content">
        <div className="guide-play-mobile-view">
          {!isGuideView ? (
            <>
              <GuideElements
                parameters={parameters}
                selectedSteps={selectedStepsWithoutLabels}
                onElementClick={onElementClick}
              />
              <ProtocolGuidePlayConditions
                isMobile={true}
                isFirstStep={isFirstStep}
                currentStep={currentStep}
                nextSteps={nextSteps}
                nextConnections={nextConnections}
                handleSubmit={localHandleSubmit}
                isShowRestartCondition={isShowRestartCondition}
                handleRestartSubmit={handleRestartSubmit}
                parameters={parameters}
              />
            </>
          ) : (
            <ReactFlowProvider>
              <InteractiveGuide
                parameters={parameters}
                isSetCenterActiveStep={true}
                isFitView={true}
                isEdit={false}
                guideSteps={guideSteps}
                guideStepConnections={guideStepConnections}
                onElementClick={() => {}}
                onInitReactFlowInstance={onInitReactFlowInstance}
                showTooltip={true}
              />
            </ReactFlowProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProtocolGuidePlayMobile;
