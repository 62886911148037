import React, { useState, useEffect, useCallback, useMemo } from "react";
import jsPDF from "jspdf";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { RouteParams } from "@shared/interfaces";
import { useExtraInfoProtocol, useLoadSharedData, useSendPageEvent } from "@shared/hooks";
import { StyledButton, InteractiveGuide, Divider } from "@shared/components";
import { useEditProtocol } from "@containers/Protocol/hooks";
import { ProtocolView } from "@containers/Protocol/components";
import { getImageData, getImageDimension } from "@containers/Protocol/utils";
import { push } from "connected-react-router";
import { actions, selectors } from "@containers/Protocol/store";
import { actions as sharedActions } from "@shared/store";
import { APPEARANCE } from "@shared/components/Notification/constants";
import { ReactFlowProvider } from "react-flow-renderer";
import "./index.scss";
import { generatePath } from "react-router-dom";
import { ROUTE_PATHS } from "@shared/constants";

const BRAND_IMG_URL = "/images/general/logo_pdf.png";

function addBrandToPage(doc: jsPDF) {
  try {
    doc.addImage(BRAND_IMG_URL, "JPEG", 375, 600, 60, 0);
  } catch (e) {
    console.error(e);
  }
}

const ProtocolPrintContainer = () => {
  const protocol = useSelector(selectors.getCurrentProtocol());
  const params = useParams<Partial<RouteParams>>();
  const [disabledPrint, setDisabledPrint] = useState(false);
  const dispatch = useDispatch();

  const { guideSteps } = useEditProtocol();
  const { parameters } = useLoadSharedData();
  const { isInteractive } = useExtraInfoProtocol(protocol);

  const guideStepConnections = useMemo(() => {
    return protocol && protocol.guide_step_connections ? protocol.guide_step_connections : [];
  }, [protocol]);

  const fileName = useMemo(() => {
    if (!protocol) return "protocol.pdf";
    return `${protocol.name.split(" ").join("_")}.pdf`;
  }, [protocol]);

  useSendPageEvent("protocol_print");

  const onExport = useCallback(async () => {
    try {
      setDisabledPrint(true);
      dispatch(sharedActions.startLoading());
      const doc = new jsPDF({ unit: "px" });
      const imgViewData = await getImageData(
        document.getElementsByClassName("protocol-tab-content-info")[0] as HTMLElement,
      );

      doc.addImage(imgViewData, "JPEG", 0, 1, 445, 0);
      addBrandToPage(doc);
      if (isInteractive) {
        doc.addPage();
        const guideComponent = document.getElementsByClassName("guide-container")[0];
        const imgGuideData = await getImageData(guideComponent as HTMLElement, true);
        const dimensions = await getImageDimension(imgGuideData);
        doc.addImage(imgGuideData, "JPEG", 10, 10, dimensions.w > 600 ? 400 : 0, 0);
        addBrandToPage(doc);
      }
      doc.save(fileName);
    } catch (error) {
      console.error("print dialog error", error);
      dispatch(
        sharedActions.showNotification({
          message: "Oops! Something went wrong. Please try again.",
          appearance: APPEARANCE.ERROR,
        }),
      );
    } finally {
      setDisabledPrint(false);
      dispatch(sharedActions.stopLoading());
    }
  }, [isInteractive, fileName, dispatch]);

  useEffect(() => {
    if (params.protocolId) {
      dispatch(actions.getProtocol.request(params.protocolId));
    }
  }, [dispatch, params.protocolId]);

  const onBackClick = useCallback(() => {
    dispatch(push(generatePath(ROUTE_PATHS.PROTOCOLS, { protocolId: params.protocolId })));
  }, [params.protocolId, dispatch]);

  return (
    <div className="protocol-print-container">
      <div className="protocol-print-header">
        <div className="protocol-header-back-button" onClick={onBackClick}>
          Back
        </div>
        <div className="protocol-print-button-wrapper">
          <StyledButton
            filled={true}
            color="red"
            textColor="white"
            label="Print"
            disabled={disabledPrint}
            disabledTextColor="white"
            onClick={onExport}
          />
        </div>
      </div>
      <div className="protocol-content-wrapper">
        <div className="protocol-tab-content protocol-tab-content-info">
          {protocol ? <ProtocolView forPdf={true} protocol={protocol} /> : null}
        </div>
        {isInteractive && (
          <>
            {(protocol?.short_description || protocol?.long_description || protocol?.reference) && (
              <Divider marginTop="23px" marginBottom="23px" />
            )}
            <div className="protocol-tab-content protocol-tab-content-guide">
              {protocol && (
                <div className="interractive-guide-view">
                  <ReactFlowProvider>
                    <InteractiveGuide
                      parameters={parameters}
                      showTooltip={false}
                      forPdf={true}
                      isEdit={false}
                      guideSteps={guideSteps}
                      onElementClick={() => {}}
                      guideStepConnections={guideStepConnections}
                    />
                  </ReactFlowProvider>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProtocolPrintContainer;
