import { useEffect } from "react";

import { scrollToTop } from "../utils";

const useScrollToTop = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      scrollToTop();
    }
  }, []);
};

export default useScrollToTop;
