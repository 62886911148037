import React from "react";
import "./index.scss";

interface CardFooterProps {
  children?: React.ReactNode;
}

const CardFooter = (props: CardFooterProps) => {
  return <div className="card-footer">{props.children}</div>;
};

export default CardFooter;
