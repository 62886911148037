import { useMemo, useState, useEffect } from "react";

import { Protocol, User } from "../models";
import {
  PROTOCOL_TYPES,
  PROTOCOL_SUBTYPES,
  PROTOCOL_STATUSES,
  API,
  PWA_CACHE_NAME,
} from "../constants";
import { getUSFormattedDate } from "../utils";

const useExtraInfoProtocol = (protocol: Protocol | null, user?: User | null) => {
  const [availableOffline, setAvailableOffline] = useState(false);

  const isNative = useMemo(() => {
    return protocol && !protocol.user_id;
  }, [protocol]);

  const typeName = useMemo(() => {
    return isNative ? PROTOCOL_TYPES.NATIVE : PROTOCOL_TYPES.CUSTOM;
  }, [isNative]);

  const subTypeName = useMemo(() => {
    return protocol ? protocol.type_subtype?.subtype?.name : "";
  }, [protocol]);

  const isInteractive = useMemo(() => {
    return subTypeName === PROTOCOL_SUBTYPES.INTERACTIVE;
  }, [subTypeName]);

  const isFinished = useMemo(() => {
    return protocol && protocol.status === PROTOCOL_STATUSES.FINISHED;
  }, [protocol]);

  const isDocument = useMemo(() => {
    return protocol && protocol.files && protocol.files.length;
  }, [protocol]);

  const createdByLabel = useMemo(() => {
    if (protocol && protocol.user_id && protocol.parent_protocol_id) return "Customized";
    if (protocol && protocol.user_id && !protocol.parent_protocol_id) return "Created";

    return "Authored";
  }, [protocol]);

  const createdAtFormatted = useMemo(() => {
    return getUSFormattedDate(protocol?.created_at);
  }, [protocol]);

  const isFavorite = useMemo(() => {
    return protocol && user && protocol.favorites?.find((item) => item.user_id === user.id);
  }, [user, protocol]);

  const isShared = useMemo(() => {
    return protocol && protocol.is_shared;
  }, [protocol]);

  const isOwn = useMemo(() => {
    return user && protocol && user.id === protocol.user_id;
  }, [user, protocol]);

  useEffect(() => {
    if (!window.caches) {
      return;
    }
    const checkCache = async () => {
      if (!protocol) {
        return false;
      }
      const cache = await window.caches.open(PWA_CACHE_NAME);
      const keys = await cache.keys();
      setAvailableOffline(
        Boolean(keys.find((req) => req.url.includes(API.PROTOCOL.GET_PROTOCOL(protocol.id)))),
      );
    };
    checkCache();
  }, [protocol]);

  return {
    isNative,
    typeName,
    isInteractive,
    subTypeName,
    isFinished,
    isDocument,
    createdByLabel,
    createdAtFormatted,
    isFavorite,
    isShared,
    isOwn,
    availableOffline,
  };
};

export default useExtraInfoProtocol;
