import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "@shared/store";

export default function useLoadSharedData() {
  const dispatch = useDispatch();
  const procedureTypes = useSelector(selectors.getProcedureTypes());
  const typesSubTypes = useSelector(selectors.getTypeSubtypes());
  const tags = useSelector(selectors.getTags());
  const parameters = useSelector(selectors.getParameters());

  useEffect(() => {
    if (!procedureTypes.length) {
      dispatch(actions.getProcedureTypes.request());
    }
  }, [dispatch, procedureTypes]);

  useEffect(() => {
    dispatch(actions.getTags.request());
  }, [dispatch]);

  useEffect(() => {
    if (!parameters.length) {
      dispatch(actions.getParameters.request());
    }
  }, [dispatch, parameters]);

  useEffect(() => {
    if (!typesSubTypes.length) {
      dispatch(actions.getTypeSubtypes.request());
    }
  }, [dispatch, typesSubTypes]);

  return { procedureTypes, typesSubTypes, tags, parameters };
}
