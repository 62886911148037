import { useEffect } from "react";

import { sendPageViewEvent } from "../utils";

const useSendPageEvent = (pageTitle: string, condition = true) => {
  useEffect(() => {
    if (condition) {
      sendPageViewEvent({ page_title: pageTitle });
    }
  }, [condition, pageTitle]);
};

export default useSendPageEvent;
