import React, { useContext } from "react";
import { StyledButton } from "@shared/components";
import { Protocol } from "@shared/models";

import { ProtocolShareViewContext } from "../ProtocolShareView";

import "./index.scss";

interface ShareViewActionsProps {
  protocolsToShare: Protocol[];
}

const ShareViewActions = (props: ShareViewActionsProps) => {
  const { cancel, unselectAll, selectAll, share } = useContext(ProtocolShareViewContext);
  const { protocolsToShare } = props;
  return (
    <div className="share-desktop-actions">
      <div className="selected-protocol-count">{protocolsToShare.length} Selected</div>
      <div className="vertical-divider" />
      <StyledButton
        className="squized-mobile-button"
        label="Unselect All"
        textColor="grey"
        onClick={unselectAll}
        hideLabelMobile={true}
      />
      <div className="vertical-divider" />
      <StyledButton
        className="squized-mobile-button"
        label="Select All"
        textColor="white"
        onClick={selectAll}
        hideLabelMobile={true}
      />
      <StyledButton
        filled={true}
        label="Share"
        color="red"
        icon="share-white"
        onClick={share}
        textColor="white"
        className="share-button"
      />
      <StyledButton
        className="cancel-share-button"
        width={40}
        filled={true}
        color="darkgrey"
        icon="close-white"
        onClick={cancel}
      />
    </div>
  );
};

export default ShareViewActions;
