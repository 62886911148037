import React from "react";
import { Modal, ModalContent } from "@shared/components";

import "./index.scss";

interface LeaveConfirmModalProps {
  handleSuccess: () => void;
  handleCancel: () => void;
  isShowing: boolean;
}

const LeaveConfirmModal = (props: LeaveConfirmModalProps) => {
  const { isShowing, handleCancel, handleSuccess } = props;

  return (
    <Modal
      isShowing={isShowing}
      closeOnOutside={false}
      onClose={handleCancel}
      isSmall={true}
      showCloseIcon={false}
      heading="Leave the page"
      className="leave-confirm-modal"
    >
      <ModalContent
        content="Are you sure you want to leave the page? The changes will not be saved."
        onClose={handleCancel}
        onSuccess={handleSuccess}
        cancelText="Cancel"
        removeText="Leave"
      />
    </Modal>
  );
};

export default LeaveConfirmModal;
