import React, { useState, useEffect, useCallback } from "react";

interface OnlineDetectorProps {
  onChange: (value: boolean) => void;
  url: string;
  timeout?: number;
  interval?: number;
}

const ping = ({ url, timeout }: { url: string; timeout: number }): Promise<boolean> => {
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open("GET", url);
    xhr.timeout = timeout;
    xhr.send();
  });
};

function OnlineDetector(props: OnlineDetectorProps) {
  const { onChange, url, interval = 5000, timeout = 5000 } = props;
  const [poolingId, setPoolingId] = useState<number | null>(null);

  const startPooling = useCallback(() => {
    const timerId = setInterval(() => {
      ping({ url, timeout }).then((online: boolean) => {
        onChange(online);
      });
    }, interval);
    setPoolingId(timerId);
  }, [onChange, timeout, interval, url]);

  useEffect(() => {
    startPooling();
  }, [startPooling]);

  useEffect(() => {
    return () => {
      poolingId && clearInterval(poolingId);
    };
  }, [poolingId]);

  return <div />;
}

export default OnlineDetector;
