import React from "react";
import classnames from "classnames";
import { PROTOCOL_TYPES_IMG_NAME } from "@containers/Dashboard/constants";

import { ProcedureType } from "../../../../../../../shared/models";

import "./index.scss";

interface ProtocolProcedureTypeItemFilterProps {
  procedure_type: ProcedureType;
  is_selected: boolean;
  onSelect: (value: string) => void;
}

function ProtocolProcedureTypeItemFilter(props: ProtocolProcedureTypeItemFilterProps) {
  const { procedure_type, is_selected, onSelect } = props;
  return (
    <div
      style={is_selected ? { backgroundColor: procedure_type.color } : {}}
      className={classnames("protocol-procedure-type-item-filter box", {
        selected: is_selected,
      })}
      onClick={() => onSelect(is_selected ? "" : String(procedure_type.id))}
    >
      <div className="protocol-procedure-type-item-filter-left">
        <img
          src={
            is_selected
              ? `/images/protocolTypes/${PROTOCOL_TYPES_IMG_NAME[procedure_type.name]}_selected.svg`
              : `/images/protocolTypes/${PROTOCOL_TYPES_IMG_NAME[procedure_type.name]}.svg`
          }
          alt="icon"
        />
      </div>
      <div className="protocol-procedure-type-item-filter-right">
        <div className="protocol-procedure-type-item-filter-name">{procedure_type.name}</div>
      </div>
    </div>
  );
}

export default ProtocolProcedureTypeItemFilter;
