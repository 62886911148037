import html2canvas from "html2canvas";

export async function getImageData(component: HTMLElement, defaultWidth?: boolean) {
  const canvas = await html2canvas(component, {
    windowWidth: defaultWidth ? undefined : 800,
    useCORS: true,
    scale: 3,
  });
  return canvas.toDataURL("image/jpeg");
}

export function getImageDimension(imageData: string): Promise<{ w: number; h: number }> {
  return new Promise(function (resolve) {
    const image = new Image();
    image.onload = function () {
      resolve({ w: image.width, h: image.height });
    };
    image.src = imageData;
  });
}
