import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileEditDto } from "@shared/interfaces";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";

import { actions, selectors } from "../../store";
import { Modal, LeaveConfirmModal } from "../../../../shared/components";
import { AUTH_FORM_TYPES } from "../../../../shared/constants/";
import { useModal } from "../../../../shared/hooks";
import {
  AuthShapeType,
  LoginShape,
  RegistrationShape,
  AuthShape,
  NewPasswordShape,
} from "../../interfaces";
import {
  LoginForm,
  EmailForm,
  RegistrationForm,
  Confirmation,
  ForgotPasswordForm,
  RestorePasswordEmail,
  SetNewPasswordForm,
  ProfileEditForm,
} from "../../components";
import { User } from "../../../../shared/models";
import { AuthFormSchema } from "../../constants";

interface AuthLogicContainerProps {
  user: User | null;
}

interface ModalInfo {
  type: string;
  payload: AuthShapeType;
  heading: string;
  showCloseIcon?: boolean;
  onBack?: () => void;
  closeOnOutside?: boolean;
}

export default function AuthLogicContainer(props: AuthLogicContainerProps) {
  const dispatch = useDispatch();
  const authForm = useSelector(selectors.currentAuthForm());
  const restoreHash = useSelector(selectors.restoreHash());
  const currentUser = useSelector(sharedSelectors.getUser());
  const { user } = props;
  const { isShowing, onOpen, onClose } = useModal(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    type: "",
    payload: null,
    heading: "",
    showCloseIcon: true,
    closeOnOutside: false,
  });

  const handleLeaveCancel = useCallback(() => {
    setShowLeaveConfirm(false);
  }, []);

  const closeModalHandler = useCallback(() => {
    onClose();
    dispatch(actions.updateCurrentAuthForm(""));
    setHasUnsavedChanges(false);
    setModalInfo({
      type: "",
      payload: null,
      heading: "",
      showCloseIcon: true,
      onBack: undefined,
      closeOnOutside: false,
    });
  }, [onClose, dispatch]);

  const handleLeaveSuccess = useCallback(() => {
    setShowLeaveConfirm(false);
    closeModalHandler();
  }, [closeModalHandler]);

  const handleForgot = useCallback(() => {
    dispatch(actions.updateCurrentAuthForm(AUTH_FORM_TYPES.FORGOT_PASSWORD));
  }, [dispatch]);

  const handleModalOpen = useCallback(
    ({ type, payload, heading, onBack, closeOnOutside = false }: ModalInfo) => {
      setModalInfo({ type, payload, heading, onBack, closeOnOutside });
      onOpen();
    },
    [setModalInfo, onOpen],
  );

  const onLogin = useCallback(
    (values: LoginShape) => {
      dispatch(actions.login.request(values));
    },
    [dispatch],
  );

  const onSignUp = useCallback(
    (values: RegistrationShape) => {
      setEmail(values.email);
      setPassword(values.password);
      dispatch(actions.signUp.request(values));
    },
    [dispatch],
  );

  const onProcced = useCallback(
    (values: AuthShape) => {
      setEmail(values.email);
      dispatch(actions.checkEmailAvailability.request(values));
    },
    [dispatch],
  );

  const onResendConfirmation = useCallback(
    (email: string) => {
      dispatch(actions.resendConfirmation.request({ email }));
    },
    [dispatch],
  );

  const onSendForgot = useCallback(
    (values: AuthShape) => {
      dispatch(actions.restorePassword.request(values));
    },
    [dispatch],
  );

  const onResendForgot = useCallback(
    (values: AuthShape) => {
      dispatch(actions.resendRestorePassword.request(values));
    },
    [dispatch],
  );

  const onForgotBack = useCallback(() => {
    dispatch(actions.updateCurrentAuthForm(AUTH_FORM_TYPES.LOGIN));
  }, [dispatch]);

  const onRestorePasswordBack = useCallback(() => {
    dispatch(actions.updateCurrentAuthForm(AUTH_FORM_TYPES.FORGOT_PASSWORD));
  }, [dispatch]);

  const onSetNewPassword = useCallback(
    (values: NewPasswordShape) => {
      if (restoreHash) {
        dispatch(
          actions.setNewPassword.request({
            hash: restoreHash,
            password: values.password,
          }),
        );
      }
      closeModalHandler();
    },
    [restoreHash, dispatch, closeModalHandler],
  );

  const onProfileEditSubmit = useCallback(
    (values: ProfileEditDto) => {
      dispatch(
        sharedActions.editProfile.request({
          data: values,
        }),
      );
    },
    [dispatch],
  );

  const closeHandlerWithCheckUnsavedChanges = useCallback(() => {
    hasUnsavedChanges ? setShowLeaveConfirm(true) : closeModalHandler();
  }, [closeModalHandler, hasUnsavedChanges]);

  useEffect(() => {
    switch (authForm) {
      case AUTH_FORM_TYPES.PROCCED: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.PROCCED,
          payload: null,
          heading: "Log in or Sign up",
        });
        break;
      }
      case AUTH_FORM_TYPES.LOGIN: {
        handleModalOpen({ type: AUTH_FORM_TYPES.LOGIN, payload: null, heading: "Log in" });
        break;
      }
      case AUTH_FORM_TYPES.REGISTRATION: {
        handleModalOpen({ type: AUTH_FORM_TYPES.REGISTRATION, payload: null, heading: "Sign up" });
        break;
      }
      case AUTH_FORM_TYPES.CONFIRMATION: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.CONFIRMATION,
          payload: null,
          heading: "Confirmation email sent",
        });
        break;
      }
      case AUTH_FORM_TYPES.FORGOT_PASSWORD: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.FORGOT_PASSWORD,
          payload: null,
          heading: "Forgot Password",
          onBack: onForgotBack,
        });
        break;
      }
      case AUTH_FORM_TYPES.RESTORE_PASSWORD_EMAIL: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.RESTORE_PASSWORD_EMAIL,
          payload: null,
          heading: "Email was sent",
          onBack: onRestorePasswordBack,
        });
        break;
      }
      case AUTH_FORM_TYPES.SET_NEW_PASSWORD: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.SET_NEW_PASSWORD,
          payload: null,
          heading: "Set New Password",
        });
        break;
      }
      case AUTH_FORM_TYPES.PROFILE_EDIT: {
        handleModalOpen({
          type: AUTH_FORM_TYPES.PROFILE_EDIT,
          payload: null,
          heading: "Edit Profile",
        });
        break;
      }
      case AUTH_FORM_TYPES.CLOSE: {
        closeModalHandler();
      }
    }
  }, [handleModalOpen, authForm, closeModalHandler, onForgotBack, onRestorePasswordBack]);

  useEffect(() => {
    if (user) {
      closeModalHandler();
    }
  }, [user, closeModalHandler]);

  const renderModal = useCallback(() => {
    switch (modalInfo.type) {
      case AUTH_FORM_TYPES.LOGIN:
        return (
          <LoginForm
            defaultValue={{ ...AuthFormSchema.LOGIN.SCHEME, email }}
            handleSubmit={onLogin}
            handleForgot={handleForgot}
          />
        );
      case AUTH_FORM_TYPES.REGISTRATION:
        return (
          <RegistrationForm
            defaultValue={{ ...AuthFormSchema.REGISTRATION.SCHEME, email }}
            handleSubmit={onSignUp}
          />
        );
      case AUTH_FORM_TYPES.CONFIRMATION:
        return (
          <Confirmation
            onResendConfirmation={onResendConfirmation}
            defaultValue={{ email, password }}
            handleSubmit={onLogin}
          />
        );
      case AUTH_FORM_TYPES.PROCCED:
        return <EmailForm handleSubmit={onProcced} />;
      case AUTH_FORM_TYPES.FORGOT_PASSWORD:
        return <ForgotPasswordForm handleSubmit={onSendForgot} defaultValue={{ email }} />;
      case AUTH_FORM_TYPES.RESTORE_PASSWORD_EMAIL:
        return <RestorePasswordEmail onResend={onResendForgot} defaultValue={{ email }} />;
      case AUTH_FORM_TYPES.SET_NEW_PASSWORD:
        return <SetNewPasswordForm handleSubmit={onSetNewPassword} />;
      case AUTH_FORM_TYPES.PROFILE_EDIT:
        return (
          <ProfileEditForm
            user={currentUser}
            onSubmit={onProfileEditSubmit}
            onClose={closeHandlerWithCheckUnsavedChanges}
            onChange={() => setHasUnsavedChanges(true)}
          />
        );
    }
  }, [
    modalInfo,
    email,
    password,
    onLogin,
    onProcced,
    onSignUp,
    handleForgot,
    onResendConfirmation,
    onSendForgot,
    onResendForgot,
    onSetNewPassword,
    onProfileEditSubmit,
    currentUser,
    closeHandlerWithCheckUnsavedChanges,
  ]);

  return (
    <>
      <LeaveConfirmModal
        isShowing={showLeaveConfirm}
        handleCancel={handleLeaveCancel}
        handleSuccess={handleLeaveSuccess}
      />
      <Modal
        closeOnOutside={modalInfo.closeOnOutside}
        isShowing={isShowing}
        onClose={closeHandlerWithCheckUnsavedChanges}
        isSmall
        heading={modalInfo.heading}
        onBack={modalInfo.onBack}
        showCloseIcon={modalInfo.showCloseIcon}
      >
        {renderModal()}
      </Modal>
    </>
  );
}
