import { FormikErrors, FormikTouched } from "formik";
import React, { useMemo, useRef, useEffect, useCallback } from "react";
import classnames from "classnames";

import { TextareaWrapper } from "../";
import { Label } from "../Label";
import "./index.scss";

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  setFieldValue?: () => void;
  values?: string;
  isRequiredField?: boolean;
  // eslint-disable-next-line
  errors?: FormikErrors<any>;
  // eslint-disable-next-line
  touched?: FormikTouched<any>;
  max?: number;
}

const MIN_HEIGHT = 90;

const Textarea: React.FunctionComponent<TextareaProps> = (props: TextareaProps) => {
  const { isRequiredField, errors, touched, max, ...inputProps } = props;
  const refTextArea = useRef<HTMLTextAreaElement>(null);

  const isError = useMemo(() => {
    return errors && touched && props.name && errors[props.name] && touched[props.name];
  }, [errors, touched, props.name]);

  useEffect(() => {
    if (refTextArea.current) {
      refTextArea.current.style.height = `${MIN_HEIGHT}PX`;
      const scrollHeight = refTextArea.current.scrollHeight;
      refTextArea.current.style.height =
        scrollHeight >= MIN_HEIGHT ? `${scrollHeight}px` : `${MIN_HEIGHT}px`;
    }
  }, [props.value]);

  const checkTextSize = useCallback(
    (target) => {
      if (max && String(target.value || "").length > max) {
        target.value = String(target.value).slice(0, max);
      }
    },
    [max],
  );

  const onInput = useCallback(
    (e) => {
      checkTextSize(e.target);
      if (inputProps.onInput) {
        inputProps.onInput(e);
      }
    },
    [checkTextSize, inputProps],
  );

  return (
    <TextareaWrapper
      className={classnames("textarea-wrapper-root", {
        error: isError,
      })}
    >
      <Label name={props.name} isRequiredField={isRequiredField} label={props.label} />
      {max && (
        <div className="input-max-counter">
          {String(refTextArea.current?.value || "").length || 0}/{max}
        </div>
      )}
      <textarea {...inputProps} id={props.name} ref={refTextArea} onInput={onInput} />
    </TextareaWrapper>
  );
};
export default Textarea;
