import React, { useState, useCallback, useMemo, useEffect } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { Modal, ModalContent } from "@shared/components";
import { CustomFile } from "@shared/interfaces";
import { getFileSizeInBytesFromBase64 } from "@shared/utils";

import "react-image-crop/dist/ReactCrop.css";
import "./index.scss";

type OutputType = "image/jpeg" | "image/png";

interface ImageCropperModalProps {
  handleSuccess: (croppedFile: CustomFile) => void;
  handleCancel: () => void;
  srcImage: CustomFile;
  isShowing: boolean;
  outputType?: OutputType;
}

function getCroppedImageBase64(image: HTMLImageElement, crop: Crop, outputType: OutputType) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  if (!ctx) {
    return null;
  }
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );
  const base64Image = canvas.toDataURL(outputType);
  return base64Image;
}

const defaultCrop: Crop = {
  aspect: 1,
  x: 0,
  y: 0,
  width: 50,
  height: 0,
  unit: "%",
};

function getNewFileName(oldFileName: string, type: OutputType) {
  const newExtension = type === "image/jpeg" ? "jpg" : "png";
  return `${oldFileName.split(".").slice(0, -1).join(".")}.${newExtension}`;
}

const ImageCropperModal = (props: ImageCropperModalProps) => {
  const { isShowing, handleCancel, handleSuccess, srcImage, outputType = "image/jpeg" } = props;
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>(defaultCrop);

  useEffect(() => {
    if (srcImage) {
      setCrop(defaultCrop);
    }
  }, [srcImage]);

  const localHandleSuccess = useCallback(() => {
    if (loadedImage) {
      const croppedImage = getCroppedImageBase64(loadedImage, crop, outputType);
      if (croppedImage) {
        handleSuccess({
          base64: croppedImage,
          size: getFileSizeInBytesFromBase64(croppedImage),
          name: getNewFileName(srcImage.name, outputType),
        });
      }
    }
  }, [handleSuccess, loadedImage, crop, srcImage, outputType]);

  const renderImageCropper = useMemo(() => {
    return (
      <ReactCrop
        src={srcImage.base64}
        crop={crop}
        onChange={setCrop}
        onImageLoaded={(image) => setLoadedImage(image)}
      />
    );
  }, [srcImage, crop]);

  return (
    <Modal
      isShowing={isShowing}
      onClose={handleCancel}
      showCloseIcon={false}
      closeOnOutside={false}
      heading="Crop the image"
      className="image-cropper-modal"
    >
      <ModalContent
        content={renderImageCropper}
        onClose={handleCancel}
        onSuccess={localHandleSuccess}
        cancelText="Cancel"
        removeText="Confirm"
      />
    </Modal>
  );
};

export default ImageCropperModal;
