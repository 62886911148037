import React, { useState } from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { Button, Input, FormikErrorMessage } from "@shared/components";
import { InputTypes } from "@shared/constants";
import { RegistrationShape } from "@containers/Auth/interfaces";
import { AuthFormSchema } from "@containers/Auth/constants";

import { BaseProfileForm } from "../";

import "./index.scss";

interface RegistrationFormProps {
  handleSubmit: (values: RegistrationShape) => void;
  defaultValue: RegistrationShape;
}

const RegistrationForm = (props: RegistrationFormProps) => {
  const { handleSubmit, defaultValue } = props;
  const [formValues] = useState<RegistrationShape>(defaultValue);

  return (
    <div className="registration-form">
      <Formik
        initialValues={formValues}
        validationSchema={AuthFormSchema.REGISTRATION.VALIDATION}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched }: FormikProps<RegistrationShape>) => (
          <Form>
            <Field name="email">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.EMAIL}
                    isRequiredField={true}
                    label="Email"
                    {...field}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <BaseProfileForm errors={errors} touched={touched} />
            <div className="registration-form-footer">
              <Button label="Sign Up" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
