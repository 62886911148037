import { AnyAction, combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";

import { AppState } from "../shared/interfaces";
import { SharedReducer } from "../shared/store/";
import { DashboardReducer } from "../containers/Dashboard/store";
import { AuthReducer } from "../containers/Auth/store";
import { ProtocolReducer } from "../containers/Protocol/store";

export default (history: History) => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    dashboard: DashboardReducer,
    protocol: ProtocolReducer,
    router: connectRouter(history),
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
