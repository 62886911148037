import { API, SCHEMAS, METHODS } from "../constants";
import { API_ENUM } from "../constants/api";
import { SchemasMap } from "../interfaces";
import { generateDynamicSchemaKeyNumeric, mapToSchema } from "../utils";
import {
  protocolsResponseSchema,
  createProtocolRequestSchema,
  createProtocolResponseSchema,
  editProtocolRequestSchema,
  editProtocolResponseSchema,
  deleteProtocolResponseSchema,
  favoriteProtocolResponseSchema,
  sharedProtocolResponseSchema,
  sharedProtocolRequestSchema,
  protocolSchema,
} from "../validators";

const schemaShapes: SchemasMap<keyof typeof API.PROTOCOL> = {
  [API_ENUM.GET_PROTOCOLS]: {
    request: { name: `${API.PROTOCOL.GET_PROTOCOLS}${SCHEMAS.REQUEST}${METHODS.GET}`, schema: {} },
    response: {
      name: `${API.PROTOCOL.GET_PROTOCOLS}${SCHEMAS.RESPONSE}${METHODS.GET}`,
      schema: protocolsResponseSchema,
    },
  },
  [API_ENUM.GET_PROTOCOL]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.GET_PROTOCOL("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: {
        additionalProperties: false,
      },
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.GET_PROTOCOL("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.GET}`,
      schema: protocolSchema,
    },
  },
  [API_ENUM.CREATE_PROTOCOL]: {
    request: {
      name: `${API.PROTOCOL.CREATE_PROTOCOL}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: createProtocolRequestSchema,
    },
    response: {
      name: `${API.PROTOCOL.CREATE_PROTOCOL}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: createProtocolResponseSchema,
    },
  },
  [API_ENUM.EDIT_PROTOCOL]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.EDIT_PROTOCOL("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.PUT}`,
      schema: editProtocolRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.EDIT_PROTOCOL("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.PUT}`,
      schema: editProtocolResponseSchema,
    },
  },
  [API_ENUM.DELETE_PROTOCOL]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.DELETE_PROTOCOL("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.DELETE}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.DELETE_PROTOCOL("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.DELETE}`,
      schema: deleteProtocolResponseSchema,
    },
  },
  [API_ENUM.SET_PROTOCOL_FAVORITE]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.SET_PROTOCOL_FAVORITE("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.DELETE}`,
      schema: {},
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.PROTOCOL.SET_PROTOCOL_FAVORITE("$id"))}${
        SCHEMAS.RESPONSE
      }${METHODS.DELETE}`,
      schema: favoriteProtocolResponseSchema,
    },
  },
  [API_ENUM.SHARE_PROTOCOLS]: {
    request: {
      name: `${API.PROTOCOL.SHARE_PROTOCOLS}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: sharedProtocolRequestSchema,
    },
    response: {
      name: `${API.PROTOCOL.SHARE_PROTOCOLS}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: sharedProtocolResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
