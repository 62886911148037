import React from "react";
import { OptionTypeBase, ValueType } from "react-select";
import { getOptions } from "@shared/utils";
import { PROTOCOL_STATUSES, PROTOCOL_SUBTYPES } from "@shared/constants";
import { FileType, Protocol, Subtype, TypeSubtype } from "@shared/models";
import { Icon } from "@shared/components";

import { ProtocolFormShape, CreateProtocolDto, EditProtocolDto } from "../../../interfaces";
import { ProtocolFormSchema } from "../../../constants";

export const uploadImagesChildren = (
  <div className="upload-images">
    <Icon type="plus-gray" />
  </div>
);

export const uploadDocumentsChildren = (
  <div className="upload-documents">
    <div className="upload-documents-text">+ Add reference documents</div>
  </div>
);

export const MAX_FILE_SIZE = 30000000;
export const MAX_FILES = 5;
export const MAX_ADDITIONAL_IMAGES = 15;

export const validationSchema = ProtocolFormSchema.CREATE_EDIT_PROTOCOL.VALIDATION;

export const getSubtypeOptions = (typeSubtypes: TypeSubtype[], protocolTypeNmae: string) => {
  const typeSubtypesByTypes = typeSubtypes.filter(
    (tst) => tst.type && tst.type.name === protocolTypeNmae,
  );
  return getOptions<Subtype>(
    typeSubtypesByTypes.map((tst) => tst.subtype) as Subtype[],
    "name",
    "id",
  );
};

export const getFormValues = (
  subtypeOptions: ValueType<OptionTypeBase, true>,
  protocol: Protocol | null,
): ProtocolFormShape => {
  const protocolTypeLabel =
    protocol && protocol.type_subtype && protocol.type_subtype.subtype
      ? protocol.type_subtype.subtype.name
      : PROTOCOL_SUBTYPES.STATIC;

  const protocolTypeOption = subtypeOptions.find((s) => s.label === protocolTypeLabel);
  return {
    procedure_types:
      protocol && protocol.procedure_types
        ? getOptions(protocol.procedure_types, "name", "id")
        : [],
    protocol_type: protocolTypeOption || null,
    name: protocol ? protocol.name : "",
    tags: protocol && protocol.tags ? getOptions(protocol.tags, "name", "id") : [],
    short_description: protocol ? protocol.short_description : "",
    long_description: protocol ? protocol.long_description : "",
    reference: protocol ? protocol.reference || "" : "",
    images: {
      files: [],
      old_files:
        protocol && protocol.files ? protocol.files.filter((f) => f.type === FileType.image) : [],
    },
    documents: {
      files: [],
      old_files:
        protocol && protocol.files
          ? protocol.files.filter((f) => f.type === FileType.document)
          : [],
    },
    study_size: protocol && protocol.study_size ? protocol.study_size || "" : "",
    asa_rating: protocol && protocol.asa_rating ? protocol.asa_rating || "" : "",
    based_on: protocol && protocol.based_on ? protocol.based_on : "",
    status: PROTOCOL_STATUSES.FINISHED,
    main_images: {
      files: [],
      old_files:
        protocol && protocol.files
          ? protocol.files.filter((f) => f.type === FileType.mainImage)
          : [],
    },
  };
};

export const prepareFormValues = (
  values: ProtocolFormShape,
  typeSubtypes: TypeSubtype[],
  typeName: string,
  protocol: Protocol | null,
): CreateProtocolDto | EditProtocolDto | null => {
  const isNative = protocol && !protocol.user_id && !protocol.parent_protocol_id;
  const isShared = protocol && protocol.is_shared;
  return protocol && !isNative && !isShared
    ? prepareEditFormValues(values, typeSubtypes, typeName)
    : prepareCreateFormValues(values, typeSubtypes, typeName, protocol);
};

export const prepareCreateFormValues = (
  values: ProtocolFormShape,
  typeSubtypes: TypeSubtype[],
  typeName: string,
  protocol: Protocol | null,
): CreateProtocolDto | null => {
  const oldDocuments = values.documents.old_files.map((f) => f.id);
  const documents = values.documents.files.map((d) => ({
    fileName: d.name,
    base64Data: d.base64,
    type: FileType.document,
  }));

  const images = values.images.files.map((d) => ({
    fileName: d.name,
    base64Data: d.base64,
    type: FileType.image,
  }));

  const oldImages = values.images.old_files.map((f) => f.id);

  const mainImages = values.main_images.files.map((f) => ({
    fileName: f.name,
    base64Data: f.base64,
    type: FileType.mainImage,
  }));

  const oldMainImages = values.main_images.old_files.map((f) => f.id);

  const typeSubtype = typeSubtypes.find(
    (ts) =>
      ts.type &&
      ts.type.name === typeName &&
      values.protocol_type &&
      ts.subtype_id === Number(values.protocol_type.value),
  );

  if (!typeSubtype) {
    return null;
  }

  return {
    name: values.name,
    short_description: values.short_description,
    long_description: values.long_description,
    reference: values.reference,
    study_size: values.study_size,
    asa_rating: String(values.asa_rating),
    based_on: values.based_on,
    tags: values.tags.map((t) => Number(t.value)),
    procedure_types: values.procedure_types.map((t) => Number(t.value)),
    files: [...documents, ...images, ...mainImages],
    type_subtype_id: typeSubtype.id,
    status: values.status,
    guide_steps: [],
    parent_protocol_id: protocol ? protocol.id : null,
    parent_files: [...oldDocuments, ...oldImages, ...oldMainImages],
  };
};

export const prepareEditFormValues = (
  values: ProtocolFormShape,
  typeSubtypes: TypeSubtype[],
  typeName: string,
): EditProtocolDto | null => {
  const oldDocuments = values.documents.old_files.map((f) => f.id);
  const documents = values.documents.files.map((d) => ({
    fileName: d.name,
    base64Data: d.base64,
    type: FileType.document,
  }));

  const oldImages = values.images.old_files.map((f) => f.id);
  const images = values.images.files.map((d) => ({
    fileName: d.name,
    base64Data: d.base64,
    type: FileType.image,
  }));

  const mainImages = values.main_images.files.map((f) => ({
    fileName: f.name,
    base64Data: f.base64,
    type: FileType.mainImage,
  }));

  const mainImagesOld = values.main_images.old_files.map((f) => f.id);

  const typeSubtype = typeSubtypes.find(
    (ts) =>
      ts.type &&
      ts.type.name === typeName &&
      values.protocol_type &&
      ts.subtype_id === Number(values.protocol_type.value),
  );

  if (!typeSubtype) {
    return null;
  }

  return {
    name: values.name,
    short_description: values.short_description,
    long_description: values.long_description,
    reference: values.reference,
    study_size: values.study_size,
    asa_rating: String(values.asa_rating),
    based_on: values.based_on || "",
    tags: values.tags.map((t) => Number(t.value)),
    procedure_types: values.procedure_types.map((t) => Number(t.value)),
    new_files: [...documents, ...images, ...mainImages],
    old_files: [...oldDocuments, ...oldImages, ...mainImagesOld],
    type_subtype_id: typeSubtype.id,
    status: values.status,
    guide_steps: [],
  };
};

export const basedOnItems = [
  { name: "SV", value: "sv" },
  { name: "SVV", value: "svv" },
  { name: "N/A", value: "" },
];
