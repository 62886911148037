import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { StyledButton } from "@shared/components";
import variables from "@styles/variables.scss";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectors as sharedSelectors } from "@shared/store";
import { useWindowDimensions, useExtraInfoProtocol } from "@shared/hooks";
import { Protocol } from "@shared/models";
import { setGuestDemoPopup, getGuestDemoPopup } from "@shared/utils";
import classnames from "classnames";

import "./index.scss";

export enum PlayDemoTypes {
  MODAL = "modal",
  POPUP = "popup",
}

type PlayDemoType = PlayDemoTypes.MODAL | PlayDemoTypes.POPUP;

interface InteractiveGuideDemoProps {
  protocol: Protocol | null;
  customClass: string;
  type: PlayDemoType;
  onClose?: () => void;
}

const InteractiveGuideDemo = ({
  protocol,
  customClass,
  type,
  onClose,
}: InteractiveGuideDemoProps) => {
  const [showDemoPopUp, setPopupStatus] = useState<boolean>(true);
  const dispatch = useDispatch();
  const user = useSelector(sharedSelectors.getUser());
  const { isDesktop, isMobileVersion } = useWindowDimensions();
  const location = useLocation();
  const { isInteractive } = useExtraInfoProtocol(protocol);

  const isPlay = useMemo(() => {
    return location.pathname.includes("play");
  }, [location]);

  const hideDemo = useCallback(() => {
    switch (type) {
      case PlayDemoTypes.POPUP:
        if (user) {
          localStorage.setItem("show-demo", "false");
        } else {
          setGuestDemoPopup();
        }
        setPopupStatus(false);
        break;
      case PlayDemoTypes.MODAL:
        onClose?.();
        break;
      default:
        break;
    }
  }, [user, onClose, type]);

  useEffect(() => {
    if (!user) {
      setPopupStatus(!getGuestDemoPopup());
    }
  }, [user]);

  const play = useCallback(() => {
    if (!protocol) {
      return;
    }
    hideDemo();
    dispatch(push(`/protocols/${protocol.id}/play`));
  }, [hideDemo, dispatch, protocol]);

  const show = useMemo(() => {
    if (type === PlayDemoTypes.MODAL) {
      return true;
    }

    if (!isInteractive || !isDesktop) {
      return false;
    }

    if (user) {
      return !localStorage.getItem("show-demo") && !user.is_played_guide;
    } else {
      return isPlay ? false : showDemoPopUp;
    }
  }, [showDemoPopUp, user, type, isPlay, isInteractive, isDesktop]);

  return show ? (
    <div className={classnames("guide-demo", customClass)}>
      <img src="/images/guide/demo.gif" alt="Play demo" />
      <div className="demo-footer">
        <span className="demo-question">Want to try this guide?</span>
        <div className="demo-actions">
          <StyledButton
            className="play-now-btn"
            height={40}
            width={isMobileVersion ? 152 : 90}
            label="Not now"
            type="button"
            textColor="black"
            onClick={hideDemo}
            bordered={true}
          />
          <StyledButton
            onClick={play}
            height={40}
            width={isMobileVersion ? 152 : 70}
            label="Start"
            type="button"
            textColor="white"
            bordered={true}
            color="red"
            background={variables.red}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default InteractiveGuideDemo;
