import * as Yup from "yup";
import { ANY_SYMBOLS, FORM_ERROR_MESSAGES } from "@shared/constants";
import {
  SharedProtocolShape,
  ProtocolFormShape,
  ProtocolInteractiveGuideFormShape,
  InteractiveGuideStepFormShape,
  InteractiveGuideStepSettingFormShape,
} from "@containers/Protocol/interfaces";

import { GUIDE_STEP_SETTING_FIELDS } from "./guideStepSettingFields";

export const MAX_NAME = 70;
export const MAX_TAGS_COUNT = 15;
export const MAX_SHORT_DESCRIPTION = 250;
export const MAX_LONG_DESCRIPTION = 850;
export const MAX_REFERENCE = 500;
export const MAX_STUDY_SIZE = 250;
export const MAX_ASA_RATING = 10;

export const MAX_SHARE_EMAILS = 10;
export const MAX_GUIDE_VALUE = 20;
export const MAX_GUIDE_TEXT = 320;

export const ProtocolFormSchema = {
  SHARE_PROTOCOL: {
    SCHEME: {
      emails: [],
      protocols: [],
      message: "",
    },
    VALIDATION: Yup.object<SharedProtocolShape>().shape({
      emails: Yup.array()
        .of(Yup.string().email(FORM_ERROR_MESSAGES.EMAIL))
        .min(1, FORM_ERROR_MESSAGES.REQUIRED)
        .max(MAX_SHARE_EMAILS, "Count of emails should be less than 10"),
      protocols: Yup.array().min(1, FORM_ERROR_MESSAGES.REQUIRED),
      message: Yup.string(),
    }),
  },
  CREATE_EDIT_PROTOCOL: {
    SCHEME: {
      procedure_types: [],
      protocol_type: null,
      name: "",
      tags: [],
      short_description: "",
      long_description: "",
      reference: "",
      images: {
        files: [],
        old_files: [],
      },
      documents: {
        files: [],
        old_files: [],
      },
      study_size: "",
      asa_rating: "",
      status: "",
    },
    VALIDATION: Yup.object<ProtocolFormShape>().shape({
      name: Yup.string()
        .max(MAX_NAME, `Protocol Name ${FORM_ERROR_MESSAGES.LONG}`)
        .required(FORM_ERROR_MESSAGES.REQUIRED),
      short_description: Yup.string().max(
        MAX_SHORT_DESCRIPTION,
        `Findings ${FORM_ERROR_MESSAGES.LONG}`,
      ),
      long_description: Yup.string().max(
        MAX_LONG_DESCRIPTION,
        `Background ${FORM_ERROR_MESSAGES.LONG}`,
      ),
      reference: Yup.string().max(MAX_REFERENCE, `Reference ${FORM_ERROR_MESSAGES.LONG}`),
      protocol_type: Yup.object()
        .shape({
          value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
          label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
        })
        .nullable()
        .required(FORM_ERROR_MESSAGES.REQUIRED),
      procedure_types: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
            label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
          }),
        )
        .min(1, FORM_ERROR_MESSAGES.REQUIRED),
      tags: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
            label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
          }),
        )
        .max(MAX_TAGS_COUNT, `Maximum number of the tags per protocol is 15.`),
      study_size: Yup.string().max(MAX_STUDY_SIZE, `Study size ${FORM_ERROR_MESSAGES.LONG}`),
      asa_rating: Yup.lazy((value) =>
        value === ""
          ? Yup.string()
          : Yup.string()
              .max(MAX_ASA_RATING, `ASA Score ${FORM_ERROR_MESSAGES.LONG}`)
              .matches(ANY_SYMBOLS, `ASA Score should contain only digits and symbols`),
      ),
    }),
  },
  CREATE_EDIT_INTERACTIVE_GUIDE: {
    SCHEME: {
      guide_steps: [],
    },
    VALIDATION: Yup.object<ProtocolInteractiveGuideFormShape>().shape({
      guide_steps: Yup.array().of(
        Yup.lazy(() => {
          return Yup.object<InteractiveGuideStepFormShape>().shape({
            id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
            sequence: Yup.number().required(),
            settings: Yup.array().of(
              Yup.lazy((setting) => {
                const { field_type, is_editable } = setting as InteractiveGuideStepSettingFormShape;
                return Yup.object<InteractiveGuideStepSettingFormShape>().shape({
                  id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
                  parameter_unit:
                    field_type === GUIDE_STEP_SETTING_FIELDS.PARAMETER
                      ? Yup.object()
                          .shape({
                            value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
                            label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
                          })
                          .nullable()
                          .required(FORM_ERROR_MESSAGES.REQUIRED)
                      : Yup.object().nullable(),
                  text: getTextValidation(field_type, is_editable),
                });
              }),
            ),
          });
        }),
      ),
    }) as Yup.Schema<ProtocolInteractiveGuideFormShape>,
  },
  CREATE_EDIT_INTERACTIVE_STEP_GUIDE: {
    SCHEME: {},
    VALIDATION: Yup.object<InteractiveGuideStepFormShape>().shape({
      id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
      sequence: Yup.number().required(),
      settings: Yup.array().of(
        Yup.lazy((setting) => {
          const { field_type, is_editable } = setting as InteractiveGuideStepSettingFormShape;
          return Yup.object<InteractiveGuideStepSettingFormShape>().shape({
            id: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
            parameter_unit:
              field_type === GUIDE_STEP_SETTING_FIELDS.PARAMETER
                ? Yup.object()
                    .shape({
                      value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
                      label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
                    })
                    .nullable()
                    .required(FORM_ERROR_MESSAGES.REQUIRED)
                : Yup.object().nullable(),
            text: getTextValidation(field_type, is_editable),
          });
        }),
      ),
    }),
  },
};

const getTextValidation = (fieldType: string, is_editable: boolean) => {
  const TEXT_FIELDS: string[] = [GUIDE_STEP_SETTING_FIELDS.NUMBER, GUIDE_STEP_SETTING_FIELDS.TEXT];
  if (!TEXT_FIELDS.includes(fieldType)) return Yup.string().nullable();
  if (!is_editable) return Yup.string().nullable();
  if (fieldType === GUIDE_STEP_SETTING_FIELDS.NUMBER)
    return Yup.string()
      .max(5, `This field ${FORM_ERROR_MESSAGES.LONG}`)
      .required(FORM_ERROR_MESSAGES.REQUIRED);

  return Yup.string()
    .max(22, `This field ${FORM_ERROR_MESSAGES.LONG}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED);
};
