import { Field, FieldProps, Formik } from "formik";
import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Tag } from "@shared/models";
import { FORM_ERROR_MESSAGES, InputTypes } from "@shared/constants";
import { Button, FormikErrorMessage, Input, StyledButton } from "@shared/components";

import "./index.scss";

const validationSchema = Yup.object<Partial<Tag>>().shape({
  name: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

interface TagFormProps {
  handleSubmit: (values: Partial<Tag>) => void;
  handleCancel: () => void;
  focusOnOpen?: boolean;
}

const EmailForm = (props: TagFormProps) => {
  const { handleSubmit, focusOnOpen } = props;
  const [formValues] = useState<Partial<Tag>>({
    name: "",
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusOnOpen]);

  return (
    <div className="tag-form">
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={formValues}
        validateOnMount
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <Field name="name">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input {...field} inputRef={inputRef} label="Tag Name" type={InputTypes.TEXT} />
                  <FormikErrorMessage
                    name="name"
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                  />
                </div>
              )}
            </Field>
            <div className="tag-form-footer">
              <StyledButton label={"Cancel"} bordered={true} onClick={props.handleCancel} />
              <Button label="Save" type="submit" disabled={!formikProps.isValid} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EmailForm;
