import React, { useMemo, useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { useWindowDimensions } from "@shared/hooks";
import { Protocol } from "@shared/models";
import { StyledButton, Modal } from "@shared/components";
import { canNativeShare, nativeShareProtocols, sendShareEvent } from "@shared/utils";
import { ShareProtocol, ShareProtocolFormWrapper } from "@containers/Protocol/components";

interface ShareProtocolModalProps {
  isOpened: boolean;
  toggleShareModal: () => void;
  closeShareModal: () => void;
  onSubmit: () => void;
  protocols: Protocol[];
}

const ShareProtocolModal = (props: ShareProtocolModalProps) => {
  const { isOpened, toggleShareModal, closeShareModal, onSubmit, protocols } = props;
  const [showCommonShareForm, setShowCommonShareForm] = useState(false);
  const { isDesktop } = useWindowDimensions();

  const showNativeShare = useMemo(() => {
    return !isDesktop && canNativeShare();
  }, [isDesktop]);

  useEffect(() => {
    if (!isOpened) {
      setShowCommonShareForm(false);
    } else {
      setShowCommonShareForm(!showNativeShare);
    }
  }, [isOpened, showNativeShare]);

  const handleShareAllProtocols = useCallback(() => {
    const shareId = Date.now();
    for (const p of protocols) {
      sendShareEvent({
        protocol_id: p.id,
        protocol_name: p.name,
        recipient: "unknown",
        type: "copy_link",
        share_id: String(shareId),
      });
    }
    nativeShareProtocols(protocols.map((p) => ({ name: p.name, id: p.id })));
  }, [protocols]);

  const getCloseShareButton = useMemo(() => {
    if (!showCommonShareForm) {
      return (
        <StyledButton
          height={40}
          width={160}
          label="Share via App"
          type="button"
          textColor={"black"}
          bordered={true}
          color="black"
          disabled={!protocols.length}
          iconWidth="25px"
          onClick={handleShareAllProtocols}
        />
      );
    }
    if (isDesktop) {
      return (
        <StyledButton
          height={40}
          width={80}
          label="Cancel"
          type="button"
          textColor="grey"
          bordered={false}
          color="black"
          onClick={closeShareModal}
        />
      );
    }
    return null;
  }, [showCommonShareForm, handleShareAllProtocols, closeShareModal, isDesktop, protocols]);

  const getConfirmShareButton = useMemo(() => {
    return (
      <StyledButton
        height={40}
        width={!isDesktop ? undefined : 115}
        label={isDesktop ? "Send" : "Send Email"}
        type="button"
        bordered={true}
        color="accent"
        icon={showCommonShareForm ? "send-red" : undefined}
        textColor="accent"
        className={classnames("modal-confirm-button", {
          fullwidth: !getCloseShareButton && !isDesktop,
        })}
        onClick={showCommonShareForm ? onSubmit : () => setShowCommonShareForm(true)}
      />
    );
  }, [onSubmit, isDesktop, getCloseShareButton, showCommonShareForm]);

  return (
    <ShareProtocolFormWrapper>
      <Modal
        isShowing={isOpened}
        onClose={toggleShareModal}
        width={!isDesktop ? "100vw" : "590px"}
        heading="Share with Others"
        showCloseIcon={false}
        showActionBtnGroup={true}
        actionBtnPosition={!isDesktop ? "footer" : "header"}
        isFixed={!isDesktop}
        pipeProps={true}
        noDisabledButton={true}
        closeBtn={getCloseShareButton}
        confirmBtn={getConfirmShareButton}
      >
        <ShareProtocol
          closeModal={!isDesktop ? closeShareModal : undefined}
          showCommonForm={showCommonShareForm}
        />
      </Modal>
    </ShareProtocolFormWrapper>
  );
};

export default ShareProtocolModal;
