import { StyledButton } from "@shared/components/Button";
import { Divider } from "@shared/components/Divider";
import { Icon } from "@shared/components/Icon";
import React from "react";
import "./index.scss";

interface InstallSafariProps {
  onClose: () => void;
}

const InstallFromSafari = (props: InstallSafariProps) => {
  return (
    <div className="install-modal-content-wrapper">
      <div className="modal-content">
        <div>
          Install this applicatin on your home screen for quick and easy acces when you’re on the go
        </div>
        <Divider />
        <div className="modal-content-instruction">
          Just tap <Icon type="safari-download" />
          then ‘Add to Home Screen’
        </div>
      </div>
      <div className="modal-actions">
        <StyledButton label={"Ok, got it"} bordered={true} onClick={props.onClose} />
      </div>
    </div>
  );
};
export default InstallFromSafari;
