import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import "./index.scss";
import { Button, FormikErrorMessage, Input } from "../../../../../shared/components";
import { FORM_ERROR_MESSAGES, InputTypes } from "../../../../../shared/constants";
import { AuthShape } from "../../../interfaces";

const validationSchema = Yup.object<AuthShape>().shape({
  email: Yup.string().email(FORM_ERROR_MESSAGES.EMAIL).required(FORM_ERROR_MESSAGES.REQUIRED),
});

interface EmailFormProps {
  handleSubmit: (values: AuthShape) => void;
}

const EmailForm = (props: EmailFormProps) => {
  const { handleSubmit } = props;
  const [formValues] = useState<AuthShape>({
    email: "",
  });

  return (
    <div className="email-form">
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={formValues}
        validateOnMount
      >
        {({ errors, touched, isValid }: FormikProps<AuthShape>) => (
          <Form>
            <Field name="email">
              {({ field }: FieldProps) => (
                <div className="input-row">
                  <Input
                    autoComplete="off"
                    type={InputTypes.EMAIL}
                    isRequiredField={true}
                    label="Email"
                    {...field}
                  >
                    <FormikErrorMessage name={field.name} errors={errors} touched={touched} />
                  </Input>
                </div>
              )}
            </Field>
            <div className="email-form-footer">
              <Button label="Proceed" type="submit" disabled={!isValid} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailForm;
