import { ExtraKeyType } from "@shared/interfaces";

export const METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const SCHEMAS = {
  REQUEST: ":request@",
  RESPONSE: ":response@",
};

export enum PROTOCOL_TYPES {
  CUSTOM = "Custom",
  NATIVE = "Native",
}

export enum PROTOCOL_STATUSES {
  FINISHED = "Finished",
  DRAFT = "Draft",
}

export enum MEDIA_WIDTH {
  TABLET = 991,
}

export enum InputTypes {
  PASSWORD = "password",
  PHONE = "tel",
  EMAIL = "email",
  DATE = "date",
  NUMBER = "number",
  TEXT = "text",
}

export enum ACTION_LIST {
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_PROCEED = "AUTH_PROCEED",
}

export enum AUTH_FORM_TYPES {
  PROCCED = "PROCEED FORM",
  LOGIN = "LOGIN FORM",
  REGISTRATION = "REGISTRATION FORM",
  CONFIRMATION = "CONFIRMATION",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESTORE_PASSWORD_EMAIL = "RESTORE_PASSWORD_EMAIL",
  SET_NEW_PASSWORD = "SET_NEW_PASSWORD",
  PROFILE_EDIT = "PROFILE_EDIT",
  CLOSE = "CLOSE",
}

export enum PROTOCOL_SUBTYPES {
  INTERACTIVE = "Interactive",
  STATIC = "Static",
}

export const CONDITION_OPTIONS = [
  {
    label: "Greater than",
    value: "Greater than",
  },
  {
    label: "Less than",
    value: "Less than",
  },
  {
    label: "Equal to",
    value: "Equal to",
  },
];

export const CONDITIONS_VIEW: ExtraKeyType<string> = {
  "Greater than": ">",
  "Less than": "<",
  "Equal to": "=",
};

export const ACKNOWLEDGE_EXPIRE_IN_DAYS = 5;
export const DEMO_POPUP_EXPIRE_IN_DAYS = 1;

export const PLATFORM = {
  MOBILE: "Mobile",
  TABLET: "Tablet",
  DESKTOP: "Desktop",
};

export const PWA_CACHE_NAME = "edwards";
export const APP_VERSION_KEY = "app-version";

export enum SIDE_URL {
  PRIVACY_POLICY = "https://www.edwards.com/legal/privacypolicy",
  NORMAL_HEMODYNAMIC_RANGES = "https://education.edwards.com/normal-hemodynamic-parameters-pocket-card/72011#",
}

export enum EDWARDS_EMAIL {
  SUPPORT = "Tech_support@edwards.com",
}
