export const favoriteSchema = {
  type: "object",
  required: ["user_id", "id"],
  properties: {
    id: { type: "number" },
    user_id: { type: "number" },
    protocol_id: { type: "string" },
    is_deleted: { type: ["boolean"] },
    created_at: { type: ["string"] },
    updated_at: { type: ["string", "null"] },
    created_by: { type: ["string"] },
    updated_by: { type: ["string", "null"] },
  },
  additionalProperties: false,
};
