import React from "react";

import { EdgeMarker } from "../EdgeMarker";
import { IEdgeMarkerDefinitionProps } from "../../interfaces";

export default ({ id, color }: IEdgeMarkerDefinitionProps) => {
  return (
    <svg>
      <defs>
        <EdgeMarker id={id}>
          <polyline
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            fill={color}
            points="-12,-6 0,0 -12,6 -12,-6"
          />
        </EdgeMarker>
      </defs>
    </svg>
  );
};
