import React, { useState, useCallback, useEffect, useRef } from "react";
import classnames from "classnames";

import { useOutsideClick } from "../../hooks";

import "./index.scss";

interface InjectedHoverPopupProps {
  onClose: () => void;
}

interface HoverPopupProps {
  children: (props: InjectedHoverPopupProps) => JSX.Element;
  button: React.ReactNode;
  onChangeOpen?: (opened: boolean) => void;
  className?: string;
}

function HoverPopup(props: HoverPopupProps) {
  const { children, button, onChangeOpen, className } = props;
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef(null);
  const { isOutside } = useOutsideClick(ref);

  const handleChangeOpened = useCallback(
    (value: boolean) => {
      setIsOpened(value);
      onChangeOpen && onChangeOpen(value);
    },
    [onChangeOpen],
  );

  const onClose = useCallback(() => setIsOpened(false), []);

  useEffect(() => {
    if (isOutside) {
      handleChangeOpened(false);
    }
  }, [isOutside, handleChangeOpened]);

  const handleButtonClick = useCallback(() => {
    handleChangeOpened(!isOpened);
  }, [isOpened, handleChangeOpened]);

  return (
    <div
      ref={ref}
      className={classnames("hover-popup", className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classnames("hover-popup-button", { opened: isOpened })}
        onClick={handleButtonClick}
      >
        {button}
      </div>
      {isOpened && <div className="hover-popup-content">{children({ onClose })}</div>}
    </div>
  );
}

export default HoverPopup;
