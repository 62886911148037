import React from "react";

import "./index.scss";

interface AddBtnProps {
  onBtnClick: () => void;
}

function AddBtn(props: AddBtnProps) {
  const { onBtnClick } = props;
  return (
    <div className={`filter-btn`} onClick={onBtnClick}>
      <div className={`icon filter`} />
    </div>
  );
}

export default AddBtn;
