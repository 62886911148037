import React, { useRef, useEffect, useState, RefObject, useMemo } from "react";
import classnames from "classnames";
import { Page, Document, pdfjs } from "react-pdf";
import { Item, ItemRef } from "react-photoswipe-gallery";
import { convertPdfToImages } from "@shared/utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfRenderMode = "canvas" | "svg";

interface PdfViewerProps {
  data: string;
  pageNumber?: number;
  children?: React.ReactNode;
  className?: string;
  renderMode?: PdfRenderMode;
  height?: number;
  width?: number;
  viewerRef?: React.LegacyRef<HTMLDivElement>;
  onlyImage?: boolean;
  onClick?: () => void;
  firstPage?: boolean;
  preloader?: boolean;
}

function PdfViewer(props: PdfViewerProps) {
  const {
    firstPage,
    data,
    className,
    children,
    height,
    onClick,
    pageNumber = 1,
    onlyImage,
    width,
    preloader,
  } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageData, setImageData] = useState<string[]>([]);
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    let isActive = true;
    async function getImageFromPdf() {
      if (preloader) {
        setShowPreloader(true);
      }
      const images = await convertPdfToImages(data);
      if (isActive) {
        firstPage ? setImageData(images.slice(0, 1)) : setImageData(images);
        setShowPreloader(false);
      }
    }
    if (onlyImage) {
      getImageFromPdf();
    }
    return () => {
      isActive = false;
    };
  }, [data, onlyImage, firstPage, preloader]);

  const renderImages = useMemo(() => {
    if (showPreloader) {
      return (
        <div className={classnames("pdf-preloader", className)}>
          <div className="pdf-loader" />
          <div className="pdf-loader-title">Loading image</div>
        </div>
      );
    }
    return (
      <>
        {imageData.map((image, index) => (
          <div className={classnames(className)} key={index}>
            <Item original={image} thumbnail={image} width="1024" height="768">
              {({ ref, open }: { ref: ItemRef; open: () => void }) => (
                <img ref={ref as RefObject<HTMLImageElement>} onClick={open} src={image} alt="" />
              )}
            </Item>
          </div>
        ))}
      </>
    );
  }, [showPreloader, imageData, className]);

  return onlyImage ? (
    renderImages
  ) : (
    <div onClick={onClick} className={classnames("pdf-viewer-wrapper", className)}>
      <Document file={data}>
        <Page canvasRef={canvasRef} pageNumber={pageNumber} width={width} height={height} />
      </Document>
      {children}
    </div>
  );
}

export default PdfViewer;
