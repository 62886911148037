import React, { useState, useMemo, useCallback, useRef } from "react";
import classnames from "classnames";
import "./index.scss";

import { InputTypes } from "../../../constants/shared";
import { InputProps } from "../../../interfaces/Input.interface";
import { InputWrapper } from "../InputWrapper";
import { Label } from "../Label";

const Input: React.FunctionComponent<InputProps> = (props) => {
  const {
    value,
    className,
    isShowPasswordIcon,
    isRequiredField,
    type,
    errors,
    touched,
    children,
    inputRef,
    max,
    ...inputProps
  } = props;
  const [isShowPasword, setShowPassword] = useState(false);
  const localInputRef = useRef<HTMLInputElement>(null);
  const isError = useMemo(() => {
    return errors && touched && props.name && errors[props.name] && touched[props.name];
  }, [errors, touched, props.name]);

  const resultRef = inputRef || localInputRef;

  const checkTextSize = useCallback(
    (target) => {
      if (max && String(target.value || "").length > max) {
        target.value = String(target.value).slice(0, max);
      }
    },
    [max],
  );

  const onInput = useCallback(
    (e) => {
      checkTextSize(e.target);
      if (inputProps.onInput) {
        inputProps.onInput(e);
      }
    },
    [checkTextSize, inputProps],
  );

  return (
    <InputWrapper
      className={classnames(className, "input-wrapper-root", {
        error: isError,
      })}
    >
      <Label name={props.name} isRequiredField={isRequiredField} label={props.label} />
      {max && (
        <div className="input-max-counter">
          {String(props.value || "").length || 0}/{max}
        </div>
      )}
      <input
        ref={resultRef}
        {...inputProps}
        value={value}
        type={isShowPasword ? InputTypes.TEXT : type}
        id={props.name}
        onInput={onInput}
      />
      {isShowPasswordIcon ? (
        <i
          className={classnames("password-icon-block icon", {
            "password-hide": isShowPasword,
            "password-show": !isShowPasword,
          })}
          onClick={() => setShowPassword(!isShowPasword)}
        />
      ) : null}
      {children}
    </InputWrapper>
  );
};

export default Input;
