import React, { RefObject, useCallback } from "react";
import { Formik, FieldArray, FormikProps, FormikErrors, FormikTouched, Form } from "formik";
import {
  ProtocolInteractiveGuideFormShape,
  InteractiveGuideStepSettingFormShape,
} from "@containers/Protocol/interfaces";
import { ProtocolFormSchema } from "@containers/Protocol/constants";
import { ParameterUnit } from "@shared/models";
import { OptionTypeBase, ValueType } from "react-select";

import { getField } from "./formHelpers";
import "./index.scss";
interface ProtocolInteractiveGuideFormProps {
  parameters: ParameterUnit[];
  formRef: RefObject<FormikProps<ProtocolInteractiveGuideFormShape>> | null;
  formValues: ProtocolInteractiveGuideFormShape;
  formErrors: FormikErrors<ProtocolInteractiveGuideFormShape>;
  formTouched: FormikTouched<ProtocolInteractiveGuideFormShape>;
  onChangeForm: (
    fieldValue: ValueType<OptionTypeBase, false> | string,
    shapeValue: InteractiveGuideStepSettingFormShape,
  ) => void;
}

function ProtocolInteractiveGuideForm(props: ProtocolInteractiveGuideFormProps) {
  const { formValues, formRef, formErrors, formTouched, onChangeForm, parameters } = props;

  const getRowFormFieldsWithTypeConditions = useCallback(
    (formikProps: FormikProps<ProtocolInteractiveGuideFormShape>, indexStep: number) => {
      return (
        <FieldArray
          name={`guide_steps.${indexStep}.settings`}
          render={() => (
            <div className="guide-step-row">
              <div className="guide-step-row-sequence">
                {formikProps.values.guide_steps[indexStep].sequence}
              </div>
              <div className="guide-step-row-value">
                {formikProps.values.guide_steps[indexStep].settings.map(
                  (value: InteractiveGuideStepSettingFormShape, indexSetting: number) => (
                    <div key={value.id} className="guide-step-setting-column">
                      {getField(
                        value,
                        formikProps.values.guide_steps[indexStep].settings,
                        parameters,
                        `guide_steps.${indexStep}.settings.${indexSetting}`,
                        onChangeForm,
                        formikProps,
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
        />
      );
    },
    [parameters, onChangeForm],
  );

  const getRowFormFields = useCallback(
    (formikProps: FormikProps<ProtocolInteractiveGuideFormShape>, index: number) => {
      return getRowFormFieldsWithTypeConditions(formikProps, index);
    },
    [getRowFormFieldsWithTypeConditions],
  );

  return (
    <div className="guide-form">
      <Formik
        innerRef={formRef}
        initialErrors={formErrors}
        initialTouched={formTouched}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ProtocolFormSchema.CREATE_EDIT_INTERACTIVE_GUIDE.VALIDATION}
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
        }}
        initialValues={formValues}
      >
        {(formikProps) => (
          <Form noValidate={true}>
            <FieldArray
              name="guide_steps"
              render={() => (
                <>
                  {formikProps.values.guide_steps.map((_, index: number) => (
                    <div className="guide-steps-rows" key={index}>
                      {getRowFormFields(formikProps, index)}
                    </div>
                  ))}
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProtocolInteractiveGuideForm;
