import React, {
  useContext,
  useEffect,
  useState,
  ReactNode,
  ReactElement,
  useCallback,
} from "react";
import { Formik, FormikProps, Form, FormikErrors } from "formik";
import { ProtocolContext, ProtocolContextInterface } from "@containers/Dashboard/containers";
import {
  ProtocolDetailsContext,
  ProtocolDetailsContextInterface,
} from "@containers/Protocol/containers/ProtocolDetailContainer";
import { useDispatch } from "react-redux";
import { actions as protocolActions } from "@containers/Protocol/store";
import { SharedProtocolShape } from "@containers/Protocol/interfaces";
import { ProtocolFormSchema } from "@containers/Protocol/constants";

const originUrl = window.location.origin.toString();
const getUrl = (id: string) => `${originUrl}/protocol/${id}`;

type ContextType = ProtocolDetailsContextInterface | ProtocolContextInterface;

interface SharedProtocolFormWrapperProps {
  handleCloseShareView?: () => void;
  children: ReactNode;
}

const SharedProtocolFormWrapper = ({
  handleCloseShareView,
  children,
}: SharedProtocolFormWrapperProps) => {
  const [context, setContext] = useState<null | ContextType>(null);
  const dispatch = useDispatch();

  const protocolContext = useContext(ProtocolContext);
  const protocolDetailsContext = useContext(ProtocolDetailsContext);

  useEffect(() => {
    setContext({ ...protocolContext, ...protocolDetailsContext });
  }, [protocolContext, protocolDetailsContext]);

  const [initialValues, setInitialValues] = useState<SharedProtocolShape>(
    ProtocolFormSchema.SHARE_PROTOCOL.SCHEME,
  );

  const handleClose = useCallback(() => {
    const { isShare, toggleShareModal, setProtocolToShare } = context as ProtocolContextInterface;
    setProtocolToShare();
    toggleShareModal();

    if (isShare) handleCloseShareView?.();
  }, [context, handleCloseShareView]);

  const handleSubmit = useCallback(
    (data: SharedProtocolShape) => {
      dispatch(
        protocolActions.shareProtocols.request({
          ...data,
          url: data.protocols.map((p) => `${p.name}:${getUrl(p.id)}`).join(", "),
          protocols: data.protocols.map((p) => ({
            url: getUrl(p.id),
            id: p.id,
            name: p.name,
          })),
        }),
      );
      handleClose();
    },
    [dispatch, handleClose],
  );

  useEffect(() => {
    if (
      context &&
      context.protocolsToShare.length &&
      initialValues.protocols.length !== context.protocolsToShare.length
    ) {
      setInitialValues((v) => {
        const initValues = { ...v, protocols: context.protocolsToShare || [] };
        return initValues;
      });
    }
  }, [context, initialValues]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProtocolFormSchema.SHARE_PROTOCOL.VALIDATION}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<SharedProtocolShape> & FormikErrors<SharedProtocolShape>) => {
        return (
          <Form ref={context?.submitRef}>
            {React.cloneElement(children as ReactElement, { ...formikProps, handleCloseShareView })}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SharedProtocolFormWrapper;
