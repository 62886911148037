export enum ROUTE_PATHS {
  APP = "/",
  AUTH = "/auth/",
  AUTH_LOGIN = "/auth/logIn",

  ACTIVATE = "/activate/:hash",
  RESTORE_PASSWORD = "/restore/:hash",

  DASHBOARD = "/dashboard",
  PROTOCOLS_DASHBOARD = "/dashboard/protocols",

  PROTOCOLS_LIST = "/protocols",
  PROTOCOLS = "/protocols/:protocolId",
  PROTOCOLS_GUIDE_PLAY = "/protocols/:protocolId/play",
  PROTOCOLS_PRINT = "/protocols/:protocolId/print",
  CREATE_PROTOCOL = "/protocols/new",

  PROFILE = "/profile",
  PROFILE_VIEW = "/profile/view",
  PROFILE_EDIT = "/profile/edit",

  DISCLAIMER = "/disclaimer",
}
