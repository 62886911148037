import React from "react";
import classnames from "classnames";
import { Icon } from "@shared/components";

import "./index.scss";

export interface AvatarProps {
  url: string | null;
  onClick?: () => void;
  className?: string;
}

function Avatar(props: AvatarProps) {
  const { url, onClick, className } = props;

  return (
    <div className={classnames("avatar-wrapper", className)} onClick={onClick}>
      {url ? (
        <div className="user-avatar">
          <img src={url} alt="" />
        </div>
      ) : (
        <div className="user-default-avatar">
          <Icon type="person" />
        </div>
      )}
    </div>
  );
}

export default Avatar;
