import { StyledButton } from "@shared/components";
import React from "react";

import "./index.scss";

interface RestartConditionProps {
  onHandleSubmit: () => void;
}

const RestartCondition = (props: RestartConditionProps) => {
  const { onHandleSubmit } = props;

  return (
    <div className="restart-condition">
      <div className="restart-condition-text">Would you like to restart guide?</div>
      <div className="restart-condition-action">
        <StyledButton
          label="Start"
          className="styled-button-bg red styled-button-bordered customize"
          filled={true}
          color="accent"
          textColor="white"
          onClick={() => onHandleSubmit()}
        />
      </div>
    </div>
  );
};

export default RestartCondition;
