import React from "react";
import { useToasts } from "react-toast-notifications";
import { Icon } from "@shared/components";

import { APPEARANCE } from "../../constants";
import { IToastProps } from "../../interfaces";
import "./index.scss";

const Toast = (props: IToastProps) => {
  const { appearance, children } = props;
  const { removeAllToasts } = useToasts();

  return (
    <div className={`notification ${appearance}`}>
      <div className="notification-content">
        <div className="notification-title">
          {appearance === APPEARANCE.SUCCESS ? "Success!" : "Oops!"}{" "}
        </div>
        <div className="notification-message">{children}</div>
      </div>
      <Icon type="close-gray" onClick={removeAllToasts} />
    </div>
  );
};

export default Toast;
