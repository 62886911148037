import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import { selectors as sharedSelectors } from "../../shared/store";
import ApplicationContainer from "./ApplicationContainer";
import { ROUTE_PATHS } from "../../shared/constants";
import { Toast, Notification } from "../../shared/components/Notification";

const App = () => {
  const notification = useSelector(sharedSelectors.getNotification());

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://consent.trustarc.com/notice?domain=edwards_ccpa.com&c=teconsent&js=nj&noticeType=bb&gtm=1&irmc=irmlink";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ToastProvider components={{ Toast }}>
      <Notification notification={notification} />
      <div className="App">
        <Switch>
          <Route path={ROUTE_PATHS.APP} component={ApplicationContainer} />
        </Switch>
      </div>
    </ToastProvider>
  );
};

export default App;
