import produce from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { updateProtocolFavorites } from "@shared/utils/protocol";

import { ProtocolStateType } from "../interfaces";
import * as actions from "./actions";

export const initialState: ProtocolStateType = {
  current_protocol: null,
  is_changed_protocol_form: false,
};

const reducer = createReducer<ProtocolStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getProtocol.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.current_protocol = action.payload;
    }),
  )
  .handleAction(actions.createProtocol.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.current_protocol = action.payload.protocol;
    }),
  )
  .handleAction(actions.editProtocol.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.current_protocol = action.payload.protocol;
    }),
  )
  .handleAction(actions.setIsChangedProtocolForm, (state, action) =>
    produce(state, (nextState) => {
      nextState.is_changed_protocol_form = action.payload;
    }),
  )
  .handleAction(actions.deleteProtocol.success, (state) =>
    produce(state, (nextState) => {
      nextState.is_changed_protocol_form = false;
      nextState.current_protocol = null;
    }),
  )
  .handleAction(actions.setProtocolFavorite.success, (state, action) =>
    produce(state, (nextState) => {
      const result = action.payload;
      const { current_protocol: protocol } = nextState;

      if (protocol && result.protocol_id === protocol.id) {
        updateProtocolFavorites(protocol, result);
      }
    }),
  );

export default reducer;
