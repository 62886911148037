import React, { useEffect, useState, useMemo, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { generatePath, useParams } from "react-router";
import { selectors as sharedSelectors } from "@shared/store";
import { Protocol } from "@shared/models";
import { ROUTE_PATHS } from "@shared/constants/routePaths";
import { AUTH_FORM_TYPES } from "@shared/constants";
import { RouteParams } from "@shared/interfaces";
import {
  useLoadSharedData,
  useWindowDimensions,
  useScrollToTop,
  useSendPageEvent,
  useOnlineAction,
} from "@shared/hooks";
import { StyledButton } from "@shared/components";
import classnames from "classnames";
import { PROTOCOLS_LOADING_SECTION } from "@containers/Dashboard/constants";

import "./index.scss";

import {
  ProtocolList,
  ProtocolProcedureTypeFilter,
  ProtocolView,
  ProtocolShareView,
  MarketingCards,
} from "../../components";
import { actions, selectors } from "../../store";
import { actions as authActions } from "../../../Auth/store";
import { useFilterDashboardSelector } from "../../hooks";
import { handleFilterChange } from "../../utils";
import { actions as protocolActions } from "../../../Protocol/store";

export interface ProtocolContextInterface {
  toggleShareModal: () => void;
  isShare: boolean;
  protocolsToShare: Protocol[];
  setProtocolToShare: (protocol?: Protocol | Protocol[]) => void;
  protocols: Protocol[];
  submitRef: React.RefObject<HTMLFormElement>;
}

export const ProtocolContext = React.createContext({} as ProtocolContextInterface);

const ProtocolDashboardContainer = () => {
  useScrollToTop();

  const dispatch = useDispatch();
  const { onlineAction } = useOnlineAction();
  const { tags, procedureTypes } = useLoadSharedData();
  const { protocolFilter, isProtocolSearch } = useFilterDashboardSelector();
  const { isDesktop } = useWindowDimensions();
  const { hash } = useParams<Partial<RouteParams>>();

  const loading = useSelector(sharedSelectors.getLoadingSection(PROTOCOLS_LOADING_SECTION));
  const protocols = useSelector(selectors.getProtocols());
  const allProtocols = useSelector(selectors.getAllProtocols());
  const user = useSelector(sharedSelectors.getUser());

  const [protocolsToShare, onProtocolShare] = useState<Protocol[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isShare, setShareStatus] = useState(false);
  const btnRef = React.useRef<HTMLFormElement>(null);

  const toggleShareModal = useCallback(() => {
    if (isShare) {
      setShareStatus(false);
    } else {
      onlineAction(() => setShareStatus(true));
    }
    setShowFilter(false);
  }, [isShare, onlineAction]);

  const tagsOptions = useMemo(() => {
    return tags.map((t) => ({ value: t.id, label: t.name }));
  }, [tags]);

  const onCloseFilter = useCallback(() => {
    setShowFilter(false);
    handleFilterChange(dispatch, { tags: [], search: "", procedure_type: "" });
  }, [dispatch]);

  useLayoutEffect(() => {
    if (hash) {
      dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.SET_NEW_PASSWORD));
      dispatch(authActions.setRestoreHash(hash));
    }
  }, [hash, dispatch]);

  useEffect(() => {
    dispatch(actions.getProtocols.request());
  }, [dispatch]);

  useSendPageEvent("protocol_library");

  useEffect(() => {
    if (!user) setShareStatus(false);
  }, [user]);

  const onProtocolFavorite = useCallback(
    (protocol: Protocol) => {
      if (user) {
        dispatch(protocolActions.setProtocolFavorite.request(protocol));
        return;
      }
      dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROCCED));
    },
    [dispatch, user],
  );

  const onProtocolClick = useCallback(
    (protocol: Protocol) => {
      dispatch(protocolActions.getProtocol.success(protocol));
      dispatch(push(generatePath(ROUTE_PATHS.PROTOCOLS, { protocolId: protocol.id })));
    },
    [dispatch],
  );

  const onCreateNewProtocol = useCallback(() => {
    if (user) {
      dispatch(push(generatePath(ROUTE_PATHS.CREATE_PROTOCOL)));
      return;
    }

    dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROCCED));
  }, [dispatch, user]);

  const setProtocolToShare = (data?: Protocol | Protocol[]) => {
    if (!data) {
      onProtocolShare([]);
    } else if (Array.isArray(data)) {
      onProtocolShare(data);
    } else {
      if (protocolsToShare.every((p) => p.id !== data.id)) {
        onProtocolShare((state) => state.concat(data));
      } else {
        onProtocolShare((state) => state.filter((p) => p.id !== data.id));
      }
    }
  };

  return (
    <ProtocolContext.Provider
      value={{
        toggleShareModal,
        isShare,
        protocolsToShare,
        setProtocolToShare,
        protocols,
        submitRef: btnRef,
      }}
    >
      <div className="protocol-dashboard-container">
        <div
          className={classnames("protocol-dashboard-header", {
            row: !showFilter,
          })}
        >
          <div
            className={classnames("left-protocol-dashboard-header", {
              row: showFilter,
            })}
          >
            <div
              className={classnames("dashboard-name", { "reduced-width": isShare || !showFilter })}
            >
              <div className="dashboard-name-row">
                <div className="dashboard-name-wrapper">
                  {showFilter && (
                    <div className="close-filter" onClick={() => onCloseFilter()}>
                      Back
                    </div>
                  )}
                  <div>{showFilter ? "Filters" : "Protocol Library"}</div>
                </div>
                {showFilter && !isShare && (
                  <div className="share">
                    <StyledButton
                      className="squized-mobile-button hide-desktop"
                      label="Share"
                      icon="share"
                      onClick={toggleShareModal}
                      hideLabelMobile={true}
                      iconMarginRight="0"
                    />
                  </div>
                )}
              </div>
              {isShare && !isDesktop ? (
                <div className="selected-protocol-count">{protocolsToShare.length} Selected</div>
              ) : null}
            </div>
          </div>
          <div
            className={classnames("right-protocol-dashboard-header", {
              row: !showFilter,
            })}
          >
            <ProtocolShareView />
            <ProtocolView
              tagsOptions={tagsOptions}
              onCreateNewProtocol={() => onlineAction(onCreateNewProtocol)}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              handleFilterChange={handleFilterChange}
              protocolFilter={protocolFilter}
            />
          </div>
        </div>
        {!isShare && !showFilter ? <MarketingCards /> : null}
        {!isShare && (
          <div className="protocol-procedure-filter">
            <ProtocolProcedureTypeFilter
              procedure_types={procedureTypes}
              current_procedure_type={protocolFilter.procedure_type}
              onSelect={(procedure_type) => handleFilterChange(dispatch, { procedure_type })}
            />
          </div>
        )}
        <ProtocolList
          onProtocolFavorite={(protocol: Protocol) =>
            onlineAction(() => onProtocolFavorite(protocol))
          }
          onProtocolClick={onProtocolClick}
          protocols={protocols}
          allProtocols={allProtocols}
          user={user}
          isSearch={isProtocolSearch}
          loading={Boolean(loading)}
        />
      </div>
    </ProtocolContext.Provider>
  );
};

export default ProtocolDashboardContainer;
