import { roleSchema } from "./role.schema";
import { fileSchema } from "./file.schema";

export const checkEmailAvailabilityRequestSchema = {
  type: "object",
  properties: {
    params: {
      properties: {
        email: { type: "string" },
      },
      additionalProperties: false,
    },
  },
  additionalProperties: false,
};

export const checkEmailAvailabilityResponsSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    available: { type: "boolean" },
  },
};

export const getUserDetailsResponsSchema = {
  type: "object",
  additionalProperties: false,
  required: [
    "id",
    "first_name",
    "last_name",
    "phone",
    "email",
    "service_line",
    "title",
    "is_acknowledged",
    "files",
    "is_played_guide",
  ],
  properties: {
    id: { type: "number" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    phone: { type: ["string", "null"] },
    title: { type: ["string", "null"] },
    service_line: { type: ["string", "null"] },
    email: { type: "string" },
    is_acknowledged: { type: ["boolean", "null"] },
    files: {
      type: ["array"],
      items: [fileSchema],
    },
    is_played_guide: { type: ["boolean", "null"] },
    roles: {
      type: "array",
      items: [roleSchema],
    },
  },
};

export const editProfileRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["first_name", "last_name"],
  properties: {
    current_password: { type: "string" },
    password: { type: "string" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    title: { type: ["string", "null"] },
    service_line: { type: ["string", "null"] },
    new_files: {
      type: ["array"],
      items: [
        {
          type: "object",
          required: ["fileName", "base64Data", "type"],
          properties: {
            fileName: { type: "string" },
            base64Data: { type: "string" },
            type: { type: "string" },
          },
        },
      ],
    },
    old_files: {
      type: ["array"],
      items: [
        {
          type: "number",
        },
      ],
    },
  },
};
