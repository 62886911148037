export const parameterUnitSchepa = {
  type: "object",
  required: ["id", "parameter", "unit"],
  properties: {
    id: { type: "number" },
    parameter: { type: "string" },
    unit: { type: "string" },
  },
};

export const parameterUnitsResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    count: { type: "number" },
    items: {
      type: ["array"],
      items: parameterUnitSchepa,
    },
  },
};
