import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDebounce } from "@shared/hooks";
import { Icon } from "@shared/components";

import "./index.scss";

interface InputSearchProps extends React.HTMLProps<HTMLInputElement> {
  onChangeSearch: (value: string) => void;
  showClearText?: boolean;
}

const SearchInput = (props: InputSearchProps) => {
  const { onChangeSearch, value, showClearText, ...inputProps } = props;
  const [inputValue, setInputValue] = useState<string>(value ? String(value) : "");
  const debounceValue = useDebounce<string>(inputValue, 500);

  const prevFilterValue = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value && prevFilterValue.current) {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (debounceValue !== value && debounceValue !== undefined) {
      onChangeSearch(debounceValue);
    }
  }, [debounceValue, onChangeSearch, inputValue, value]);

  const onChangeInput = (event: React.SyntheticEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  const clearInput = useCallback(() => {
    setInputValue("");
  }, [setInputValue]);

  return (
    <div className="search">
      <input
        className="input-search"
        {...inputProps}
        onChange={onChangeInput}
        value={inputValue}
        ref={prevFilterValue}
      />
      <Icon type="find" className="search-icon-block" />
      {showClearText && inputValue ? (
        <div className="clear-text" onClick={clearInput}>
          Clear
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
