import { API, SCHEMAS, METHODS } from "../constants";
import { API_ENUM } from "../constants/api";
import { SchemasMap } from "../interfaces";
import { mapToSchema, generateDynamicSchemaKeyNumeric } from "../utils";
import {
  loginRequestSchema,
  loginResponseSchema,
  registrationRequestSchema,
  registrationResponseSchema,
  activateRequestSchema,
  activateResponseSchema,
  resendConfirmationRequestSchema,
  resendConfirmationResponseSchema,
  restorePasswordRequestSchema,
  restorePasswordResponseSchema,
} from "../validators";

const schemaShapes: SchemasMap<keyof typeof API.AUTH> = {
  [API_ENUM.LOGIN]: {
    request: {
      name: `${API.AUTH.LOGIN}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: loginRequestSchema,
    },
    response: {
      name: `${API.AUTH.LOGIN}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: loginResponseSchema,
    },
  },
  [API_ENUM.REGISTRATION]: {
    request: {
      name: `${API.AUTH.REGISTRATION}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: registrationRequestSchema,
    },
    response: {
      name: `${API.AUTH.REGISTRATION}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: registrationResponseSchema,
    },
  },
  [API_ENUM.FORGOT_PASSWORD]: {
    request: { name: "", schema: {} },
    response: { name: "", schema: {} },
  },
  [API_ENUM.SET_PASSWORD]: {
    request: { name: "", schema: {} },
    response: { name: "", schema: {} },
  },
  [API_ENUM.GET_USER_HASH]: {
    request: { name: "", schema: {} },
    response: { name: "", schema: {} },
  },
  [API_ENUM.ACTIVATE_USER_ACCOUNT]: {
    request: {
      name: `${generateDynamicSchemaKeyNumeric(API.AUTH.ACTIVATE_USER_ACCOUNT("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: activateRequestSchema,
    },
    response: {
      name: `${generateDynamicSchemaKeyNumeric(API.AUTH.ACTIVATE_USER_ACCOUNT("$id"))}${
        SCHEMAS.REQUEST
      }${METHODS.GET}`,
      schema: activateResponseSchema,
    },
  },
  [API_ENUM.RESEND_CONFIRMATION]: {
    request: {
      name: `${API.AUTH.RESEND_CONFIRMATION}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: resendConfirmationRequestSchema,
    },
    response: {
      name: `${API.AUTH.RESEND_CONFIRMATION}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: resendConfirmationResponseSchema,
    },
  },
  [API_ENUM.FORGOT_PASSWORD]: {
    request: {
      name: `${API.AUTH.FORGOT_PASSWORD}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: restorePasswordRequestSchema,
    },
    response: {
      name: `${API.AUTH.FORGOT_PASSWORD}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: restorePasswordResponseSchema,
    },
  },
  [API_ENUM.RESEND_RESTORE_PASSWORD]: {
    request: {
      name: `${API.AUTH.RESEND_RESTORE_PASSWORD}${SCHEMAS.REQUEST}${METHODS.POST}`,
      schema: restorePasswordRequestSchema,
    },
    response: {
      name: `${API.AUTH.RESEND_RESTORE_PASSWORD}${SCHEMAS.RESPONSE}${METHODS.POST}`,
      schema: restorePasswordResponseSchema,
    },
  },
};

export default mapToSchema(schemaShapes);
