import React, { useMemo, useContext, useCallback } from "react";
import classnames from "classnames";
import { Protocol, User } from "@shared/models";
import { Icon, StyledButton } from "@shared/components";
import DropDown, { IDropDownItem } from "@shared/components/DropDown/DropDown";
import { useExtraInfoProtocol, useOnlineAction } from "@shared/hooks";
import { openPrintDialog } from "@shared/utils";
import { ProtocolDetailsContext } from "@containers/Protocol/containers/ProtocolDetailContainer";
import "./index.scss";
import variables from "@styles/variables.scss";

import config from "../../../../../config";

function getDropDownMenu(list: IDropDownItem[], className?: string) {
  return (
    <DropDown
      list={list}
      className={classnames("protocol-dropdown-mobile", className)}
      openButton={<StyledButton icon="dots-dark" bordered={true} />}
      closeButton={
        <StyledButton
          icon="dots-white"
          bordered={true}
          color="black"
          filled={true}
          textColor="black"
        />
      }
    />
  );
}

export interface ProtocolHeaderProps {
  protocol: Protocol | null;
  isEdit: boolean;
  isChangedForms: boolean;
  isMobileVersion: boolean;
  onFinishForm: () => void;
  onDraftForm: () => void;
  onBackClick: () => void;
  onPlayGuide: () => void;
  onEdit: () => void;
  onDeleteProtocol: () => void;
  onRejectProtocol: () => void;
  onFavoriteProtocol: () => void;
  user: User | null;
}

function ProtocolHeader(props: ProtocolHeaderProps) {
  const { toggleShareModal } = useContext(ProtocolDetailsContext);
  const {
    protocol,
    onFinishForm,
    onDraftForm,
    onBackClick,
    onDeleteProtocol,
    isEdit,
    onEdit,
    onFavoriteProtocol,
    user,
    isChangedForms,
    isMobileVersion,
    onPlayGuide,
    onRejectProtocol,
  } = props;

  const {
    isInteractive,
    createdByLabel,
    createdAtFormatted,
    isNative,
    isFavorite,
    isShared,
    isOwn,
    availableOffline,
  } = useExtraInfoProtocol(protocol, user);

  const isDisabledEditButtons = useMemo(() => !isChangedForms && !isEdit, [isChangedForms, isEdit]);
  const { onlineAction } = useOnlineAction();
  const editButtonsShouldBeRendered = useMemo(() => !protocol || isEdit, [protocol, isEdit]);
  const viewButtonsShouldBeRendered = useMemo(() => protocol && !isEdit, [protocol, isEdit]);
  const rejectButtonShouldBeRendered = useMemo(
    () => protocol && !isEdit && isShared,
    [protocol, isEdit, isShared],
  );
  const playButtonShouldBeRendered = useMemo(
    () => isInteractive && !isEdit,
    [isInteractive, isEdit],
  );
  const deleteButtonShouldBeRendered = useMemo(
    () => protocol && isEdit && isOwn,
    [protocol, isEdit, isOwn],
  );

  const onExport = useCallback(() => {
    protocol && openPrintDialog(protocol.id);
  }, [protocol]);

  const mobileDropDownItems = useMemo(() => {
    const items: IDropDownItem[] = [
      {
        label: "Save as Draft",
        onClick: onDraftForm,
        disabled: isDisabledEditButtons,
      },
    ];

    if (deleteButtonShouldBeRendered) {
      items.push({
        label: "Delete",
        onClick: onDeleteProtocol,
        className: "red",
      });
    }

    return items;
  }, [onDraftForm, isDisabledEditButtons, onDeleteProtocol, deleteButtonShouldBeRendered]);

  const editButtonShouldRender = useMemo(() => {
    return (!isNative && !isShared) || !config.hideCustomize;
  }, [isNative, isShared]);

  const mobileDropDownViewItems = useMemo<IDropDownItem[]>(() => {
    const items = [];
    if (editButtonShouldRender) {
      items.push({
        label: isNative || isShared ? "Customize" : "Edit",
        onClick: onEdit,
        icon: "edit",
      });
    }

    if (!config.hideShare) {
      items.push({
        label: "Share",
        onClick: toggleShareModal,
        icon: "share",
      });
    }

    items.push({
      label: "Export",
      onClick: () => onlineAction(onExport),
      icon: "export",
    });
    return items;
  }, [
    onExport,
    onEdit,
    isShared,
    isNative,
    toggleShareModal,
    editButtonShouldRender,
    onlineAction,
  ]);

  const renderViewButtons = useMemo(() => {
    return (
      <>
        <StyledButton
          className="squized-mobile-button hide-large-tablet"
          label="Export"
          icon="export"
          hideLabelMobile={true}
          onClick={() => onlineAction(onExport)}
          tabletWidth={variables.largeTablet}
        />
        <div className="vertical-divider" />
        {editButtonShouldRender && (
          <>
            <StyledButton
              className="squized-mobile-button hide-large-tablet"
              label={isNative || isShared ? "Customize" : "Edit"}
              onClick={onEdit}
              icon="edit"
              hideLabelMobile={true}
              tabletWidth={variables.largeTablet}
            />
            <div className="vertical-divider" />
          </>
        )}
        {!config.hideShare && (
          <>
            <StyledButton
              className="squized-mobile-button hide-large-tablet"
              label="Share"
              onClick={toggleShareModal}
              icon="share"
              hideLabelMobile={true}
              tabletWidth={variables.largeTablet}
            />
            <div className="vertical-divider" />
          </>
        )}
        <StyledButton
          className="squized-mobile-button"
          textColor="accent"
          label={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
          icon={isFavorite ? "heart-selected" : "heart-empty"}
          hideLabelMobile={true}
          onClick={onFavoriteProtocol}
          tabletWidth={variables.largeTablet}
        />
        {getDropDownMenu(mobileDropDownViewItems, "protocol-view-dropdown")}
      </>
    );
  }, [
    onEdit,
    isNative,
    onFavoriteProtocol,
    isFavorite,
    toggleShareModal,
    isShared,
    mobileDropDownViewItems,
    onExport,
    editButtonShouldRender,
    onlineAction,
  ]);

  const renderEditButtons = useMemo(() => {
    return (
      <>
        {getDropDownMenu(mobileDropDownItems)}
        <StyledButton
          label="Save as Draft"
          className="styled-button-black styled-button-bordered customize hide-tablet"
          bordered={true}
          onClick={onDraftForm}
          disabled={isDisabledEditButtons}
          textColor={isDisabledEditButtons ? "disabled" : undefined}
          color={isDisabledEditButtons ? "disabled" : undefined}
          hideTablet={true}
        />
        <StyledButton
          label="Finish"
          className="styled-button-bg red styled-button-bordered customize"
          filled={true}
          color={isDisabledEditButtons ? "disabled" : "accent"}
          textColor="white"
          disabledTextColor="white"
          onClick={onFinishForm}
          disabled={isDisabledEditButtons}
        />
      </>
    );
  }, [onDraftForm, onFinishForm, mobileDropDownItems, isDisabledEditButtons]);

  const hideDemo = useCallback(() => {
    if (user) {
      localStorage.setItem("show-demo", "false");
    }
  }, [user]);

  const play = useCallback(() => {
    hideDemo();
    onPlayGuide();
  }, [hideDemo, onPlayGuide]);

  const renderPlayButton = useMemo(() => {
    return (
      <>
        <StyledButton
          bordered={true}
          color="accent"
          textColor="accent"
          className="customize mobile-start-button"
          icon="play-white"
          filled={true}
          background={variables.red}
          onClick={play}
        />
        <StyledButton
          bordered={true}
          color="accent"
          className="customize desktop-start-button"
          textColor="accent"
          label="Start"
          icon="play-red"
          onClick={play}
        />
      </>
    );
  }, [play]);

  const renderDeleteButton = useMemo(() => {
    return (
      <StyledButton label="Delete" hideTablet={true} onClick={onDeleteProtocol} textColor="grey" />
    );
  }, [onDeleteProtocol]);

  const renderRejectButton = useMemo(() => {
    return (
      <>
        <StyledButton
          className="squized-mobile-button"
          label="Reject"
          onClick={onRejectProtocol}
          icon="reject"
          hideLabelMobile={true}
          tabletWidth={variables.largeTablet}
        />
        <div className="vertical-divider" />
      </>
    );
  }, [onRejectProtocol]);

  const renderButtons = useMemo(() => {
    return (
      <div className="protocol-header-buttons">
        {rejectButtonShouldBeRendered ? renderRejectButton : null}
        {viewButtonsShouldBeRendered ? renderViewButtons : null}
        {deleteButtonShouldBeRendered ? renderDeleteButton : null}
        {editButtonsShouldBeRendered ? renderEditButtons : null}
        {playButtonShouldBeRendered ? renderPlayButton : null}
      </div>
    );
  }, [
    viewButtonsShouldBeRendered,
    playButtonShouldBeRendered,
    editButtonsShouldBeRendered,
    deleteButtonShouldBeRendered,
    rejectButtonShouldBeRendered,
    renderEditButtons,
    renderViewButtons,
    renderPlayButton,
    renderDeleteButton,
    renderRejectButton,
  ]);

  const renderBackButton = useMemo(() => {
    return (
      <div className="protocol-header-back-button" onClick={onBackClick}>
        {isEdit || !protocol ? "Back" : "Library"}
      </div>
    );
  }, [onBackClick, isEdit, protocol]);

  const renderCreatedBy = useMemo(() => {
    return protocol && !(isMobileVersion && isEdit) ? (
      <>
        <div className="protocol-author">
          {createdByLabel} {createdAtFormatted} by {protocol.created_by}
        </div>
        {availableOffline ? (
          <div className="protocol-offline">
            <Icon type="available-offline" />
            <div>Available for offline use</div>
          </div>
        ) : null}
      </>
    ) : null;
  }, [protocol, createdByLabel, createdAtFormatted, isMobileVersion, isEdit, availableOffline]);

  const renderTitle = useMemo(() => {
    let title = "New Protocol";
    if (protocol && isEdit) title = isNative ? "Customize Protocol" : "Edit Protocol";
    if (protocol && !isEdit) title = protocol.name;

    return !(isMobileVersion && isEdit) ? (
      <div className={classnames("protocol-header-name", { "new-protocol": !protocol })}>
        {title}
      </div>
    ) : null;
  }, [protocol, isEdit, isNative, isMobileVersion]);

  return (
    <>
      <div className="protocol-header-wrapper">
        <div
          className={classnames("protocol-header-title", {
            "is-shared": rejectButtonShouldBeRendered,
          })}
        >
          <div className="title-info">
            {renderBackButton}
            {renderTitle}
          </div>
          {availableOffline ? (
            <div className="offline-tips">
              <Icon type="available-offline" />
              <div>Available for offline use</div>
            </div>
          ) : null}
        </div>
        {renderButtons}
      </div>
      <div className="protocol-header-wrapper-mobile">
        <div className="protocol-header-title">
          {renderBackButton}
          {renderButtons}
        </div>
        <div className="protocol-header-name-wrapper">
          {renderTitle}
          {renderCreatedBy}
        </div>
      </div>
    </>
  );
}

export default ProtocolHeader;
