import { getStepLabelText } from "@containers/Protocol/utils";
import { IGuideStep, ParameterUnit } from "@shared/models";
import React, { useMemo } from "react";

import "./index.scss";

interface DiamondShapeProps {
  parameters: ParameterUnit[];
  step: IGuideStep;
  onClick: (step: IGuideStep) => void;
}

export default ({ step, onClick, parameters }: DiamondShapeProps) => {
  const stepText = useMemo(() => getStepLabelText(step, parameters, true), [step, parameters]);

  const diamondBg = useMemo(() => {
    const COLOR_ATTRIBUTE = "color";
    const DEFAULT_COLOR = "white";
    return (step.styles || []).find((s) => s.attribute === COLOR_ATTRIBUTE)?.value || DEFAULT_COLOR;
  }, [step]);

  return (
    <section className="diamond-shape-section">
      <div className={`diamond-shape-wrapper ${diamondBg}`}>
        <div className={`diamond-shape ${diamondBg}`} onClick={() => onClick(step)}>
          <div className="item-text" dangerouslySetInnerHTML={{ __html: stepText }} />
        </div>
      </div>
    </section>
  );
};
