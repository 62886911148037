import classnames from "classnames";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { generatePath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { ROUTE_PATHS } from "@shared/constants/routePaths";
import { RouteParams } from "@shared/interfaces";
import { Protocol, ProcedureType } from "@shared/models";
import {
  useExtraInfoProtocol,
  useScrollToTop,
  useWindowDimensions,
  useLoadSharedData,
  useSendPageEvent,
  useOnlineAction,
} from "@shared/hooks";
import {
  openLinkInNewWindow,
  sendProtocolViewEvent,
  getProtocolViewObject,
  sendShareRejectEvent,
  sendGuideStartEvent,
} from "@shared/utils";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import { checkPermissions } from "@shared/utils/ACL";
import { processShareFormBeforeSubmit } from "@shared/utils/functions";
import { AUTH_FORM_TYPES, PERMISSION, PROTOCOL_STATUSES } from "@shared/constants";
import {
  actions as dashboardActions,
  selectors as dashboardSelectors,
} from "@containers/Dashboard/store";
import { actions as authActions } from "@containers/Auth/store";
import {
  Modal,
  ModalContent,
  Footer,
  InteractiveGuideDemo,
  PlayDemoTypes,
} from "@shared/components";
import { ShareProtocolModal } from "@containers/Protocol/components";
import { ProtocolTabs, Tabs } from "@containers/Protocol/constants";
import { useEditProtocol } from "@containers/Protocol/hooks";
import { useLoader } from "@shared/hooks/LoaderHook";

import { ProtocolHeader, SimilarProtocolsList } from "../../components/ProtocolDetailContainer";
import { actions, selectors } from "../../store";
import ProtocolCreateEditContainer from "./ProtocolCreateEditContainer/ProtocolCreateEditContainer";
import { ProtocolViewContainer } from "./ProtocolViewContainer";
import { ProtocolInteractiveGuideContainer } from "./ProtocolInteractiveGuideContainer";

import "./index.scss";

export interface ProtocolDetailsContextInterface {
  toggleShareModal: () => void;
  showShareModal: boolean;
  protocolsToShare: Protocol[];
  setProtocolToShare: (protocol?: Protocol | Protocol[]) => void;
  submitRef: React.RefObject<HTMLFormElement>;
}

export const ProtocolDetailsContext = React.createContext({} as ProtocolDetailsContextInterface);

const onProtocolClick = (protocol: Protocol) => {
  openLinkInNewWindow(`${ROUTE_PATHS.PROTOCOLS_LIST}/${protocol.id}`);
};

const isSimilar = (
  currentProcedureTypes?: ProcedureType[],
  targetProcedureTypes?: ProcedureType[],
) => {
  if (!targetProcedureTypes || !currentProcedureTypes) {
    return false;
  }
  for (const currentType of currentProcedureTypes) {
    if (targetProcedureTypes.find((targetType) => targetType.id === currentType.id)) {
      return true;
    }
  }
  return false;
};

const ProtocolDetailContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { onlineAction } = useOnlineAction();

  useScrollToTop();
  useLoadSharedData();
  useLoader({
    name: "ProtocolDetails",
    actionTypes: useMemo(() => [actions.getProtocol], []),
  });

  const btnRef = React.useRef<HTMLFormElement>(null);
  const { protocolId } = useParams<Partial<RouteParams>>();

  const currentProtocol = useSelector(selectors.getCurrentProtocol());
  const protocols = useSelector(dashboardSelectors.getProtocols());
  const user = useSelector(sharedSelectors.getUser());

  const { createdByLabel, isInteractive, isNative } = useExtraInfoProtocol(currentProtocol);
  const [isShareModalOpened, setIsShareModalOpened] = useState(false);
  const [isLeaveModalOpened, setIsLeaveModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isRejectModalOpened, setIsRejectModalOpened] = useState(false);
  const [protocolsToShare, onProtocolShare] = useState<Protocol[]>([]);
  const { isDesktop, isMobileVersion } = useWindowDimensions();
  const [isPlayDemoModalOpened, setIsPlayDemoModalOpened] = useState(false);

  const {
    protocolFormRef,
    protocolFormErrors,
    protocolFormTouched,
    protocolFormValues,
    guideFormRef,
    guideFormErrors,
    guideFormTouched,
    guideFormValues,
    setGuideFormValues,
    updateForms,
    currentForm,
    setCurrentForm,
    createEditProtocol,
    isChangedForms,
    setIsChangedForms,
    setIsEditProtocolForm,
    isEditProtocolForm,
    guideSteps,
    setGuideSteps,
    parameters,
    hasFiles,
  } = useEditProtocol();

  const isGuideView = useMemo(() => currentForm === ProtocolTabs.guide, [currentForm]);
  const toggleShareModal = useCallback(() => {
    if (isShareModalOpened) {
      setIsShareModalOpened(false);
    } else {
      onlineAction(() => setIsShareModalOpened(true));
    }
  }, [onlineAction, isShareModalOpened]);

  useEffect(() => {
    if (!protocolId) {
      setCurrentForm(ProtocolTabs.info);
    }
  }, [protocolId, setCurrentForm]);

  useEffect(() => {
    if (protocolId) {
      dispatch(actions.getProtocol.request(protocolId));
      dispatch(
        actions.updateProtocolStatistic.request({
          protocolId,
          fields: ["viewed"],
        }),
      );
    }

    return () => {
      dispatch(sharedActions.removeLoadingType("ProtocolDetails"));
      dispatch(actions.getProtocol.success(null));
    };
  }, [dispatch, protocolId]);

  useSendPageEvent(isEditProtocolForm ? "protocol_edit" : "protocol_preview", Boolean(protocolId));

  const protocolIdForEvent = useMemo(() => {
    return currentProtocol?.id;
  }, [currentProtocol]);

  const protocolNameForEvent = useMemo(() => {
    return currentProtocol?.name;
  }, [currentProtocol]);

  useEffect(() => {
    if (protocolIdForEvent && protocolNameForEvent) {
      sendProtocolViewEvent(
        getProtocolViewObject(
          protocolIdForEvent,
          protocolNameForEvent,
          Boolean(isNative),
          isInteractive,
        ),
      );
    }
  }, [protocolIdForEvent, protocolNameForEvent, isNative, isInteractive]);

  useEffect(() => {
    if (protocolId && isInteractive) {
      dispatch(
        actions.updateProtocolStatistic.request({
          protocolId,
          fields: ["guide_viewed"],
        }),
      );
    }
  }, [protocolId, isInteractive, dispatch]);

  useEffect(() => {
    if (protocolId && !protocols.length) {
      dispatch(dashboardActions.getProtocols.request());
    }
  }, [dispatch, protocolId, protocols.length]);

  const setProtocolToShare = useCallback(
    (data?: Protocol | Protocol[]) => {
      if (!data) return onProtocolShare([]);
      if (Array.isArray(data)) return onProtocolShare(data);
      if (protocolsToShare.every((p) => p.id !== data.id)) {
        onProtocolShare((state) => state.concat(data));
      } else {
        onProtocolShare((state) => state.filter((p) => p.id !== data.id));
      }
    },
    [protocolsToShare],
  );

  const onFavoriteProtocol = useCallback(
    (protocol: Protocol) => {
      if (!user) {
        dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROCCED));
        return;
      }

      if (protocol) {
        dispatch(actions.setProtocolFavorite.request(protocol));
      }
    },
    [dispatch, user],
  );

  const handleRedirectToDashboard = useCallback(() => {
    if (isChangedForms) {
      setIsLeaveModalOpened(true);
      return;
    }

    if (!currentProtocol || (currentProtocol && !isEditProtocolForm)) {
      history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
      return;
    }

    if (currentProtocol && isEditProtocolForm) {
      setIsEditProtocolForm(false);
      return;
    }
  }, [history, currentProtocol, isEditProtocolForm, isChangedForms, setIsEditProtocolForm]);

  const renderTabs = useMemo(() => {
    return Tabs.map(({ key, title, icon }, index) => (
      <div
        onClick={() => {
          updateForms();
          setCurrentForm(key);
        }}
        key={index}
        className={classnames("protocol-tab-item", {
          active: key === currentForm,
          hidden: !isInteractive && key === ProtocolTabs.guide,
        })}
      >
        {title}
        {icon ? (
          <div
            className={classnames("icon", key === currentForm ? "active" : "default")}
            onClick={() => setIsPlayDemoModalOpened((prev) => !prev)}
          />
        ) : null}
      </div>
    ));
  }, [currentForm, isInteractive, updateForms, setCurrentForm]);

  const renderTabContent = useMemo(() => {
    switch (currentForm) {
      case ProtocolTabs.info:
        return protocolId && !isEditProtocolForm ? (
          <ProtocolViewContainer protocolId={protocolId} hasFiles={hasFiles} />
        ) : (
          <ProtocolCreateEditContainer
            formRef={protocolFormRef}
            formValues={protocolFormValues}
            formErrors={protocolFormErrors}
            formTouched={protocolFormTouched}
            onChangeForm={setIsChangedForms}
          />
        );

      case ProtocolTabs.guide:
        return isInteractive ? (
          <ProtocolInteractiveGuideContainer
            parameters={parameters}
            protocol={currentProtocol}
            formRef={guideFormRef}
            formValues={guideFormValues}
            formErrors={guideFormErrors}
            formTouched={guideFormTouched}
            setFormValues={setGuideFormValues}
            isEdit={isEditProtocolForm}
            onChangeForm={setIsChangedForms}
            guideSteps={guideSteps}
            setGuideSteps={setGuideSteps}
          />
        ) : null;
      default:
        return null;
    }
  }, [
    guideFormRef,
    protocolFormRef,
    currentForm,
    protocolId,
    isInteractive,
    isEditProtocolForm,
    protocolFormValues,
    guideFormValues,
    setGuideFormValues,
    protocolFormErrors,
    protocolFormTouched,
    guideFormErrors,
    guideFormTouched,
    setIsChangedForms,
    guideSteps,
    setGuideSteps,
    currentProtocol,
    parameters,
    hasFiles,
  ]);

  const handleEditProtocol = useCallback(() => {
    if (!checkPermissions([PERMISSION.EDIT_PROTOCOL])) {
      dispatch(authActions.updateCurrentAuthForm(AUTH_FORM_TYPES.PROCCED));
      return;
    }

    setIsEditProtocolForm(true);
  }, [dispatch, setIsEditProtocolForm]);

  const similarProtocols = useMemo(() => {
    if (!currentProtocol) {
      return [];
    }
    return protocols.filter(
      (p) =>
        isSimilar(currentProtocol.procedure_types, p.procedure_types) &&
        p.id !== currentProtocol.id,
    );
  }, [protocols, currentProtocol]);

  const showSimilarProtocols = useMemo(
    () => currentProtocol && !isGuideView && !isEditProtocolForm && similarProtocols.length,
    [currentProtocol, isGuideView, isEditProtocolForm, similarProtocols],
  );

  const handleCloseLeaveConfirmModal = useCallback(() => {
    setIsLeaveModalOpened(false);
  }, [setIsLeaveModalOpened]);

  const handleSuccessLeaveConfirmModal = useCallback(() => {
    setIsChangedForms(false);
    setIsLeaveModalOpened(false);
    if (!currentProtocol || (currentProtocol && !isEditProtocolForm)) {
      history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
      return;
    }

    if (currentProtocol && isEditProtocolForm) {
      setIsEditProtocolForm(false);
      return;
    }
  }, [
    setIsLeaveModalOpened,
    currentProtocol,
    isEditProtocolForm,
    setIsEditProtocolForm,
    history,
    setIsChangedForms,
  ]);

  const handlePlay = useCallback(() => {
    if (currentProtocol) {
      dispatch(
        actions.updateProtocolStatistic.request({
          protocolId: currentProtocol.id,
          fields: ["guide_started"],
        }),
      );
      sendGuideStartEvent({
        protocol_id: currentProtocol.id,
        protocol_name: currentProtocol.name,
      });
      history.push(
        generatePath(ROUTE_PATHS.PROTOCOLS_GUIDE_PLAY, { protocolId: currentProtocol.id }),
      );
    }
  }, [dispatch, currentProtocol, history]);

  const handleCloseDeleteConfirmModal = useCallback(() => {
    setIsDeleteModalOpened(false);
  }, [setIsDeleteModalOpened]);

  const handleCloseRejectConfirmModal = useCallback(() => {
    setIsRejectModalOpened(false);
  }, []);

  const handleDeleteProtocol = useCallback(() => {
    if (currentProtocol) {
      dispatch(actions.deleteProtocol.request(currentProtocol.id));
    }
  }, [dispatch, currentProtocol]);

  const handleRejectProtocol = useCallback(() => {
    handleDeleteProtocol();
    if (user && currentProtocol) {
      sendShareRejectEvent({
        recipient: user.email,
        protocol_id: currentProtocol.id,
        protocol_name: currentProtocol.name,
      });
    }
  }, [handleDeleteProtocol, user, currentProtocol]);

  const onSubmitSharedProtocolForm = useCallback(() => {
    processShareFormBeforeSubmit();
    setTimeout(
      () => btnRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true })),
      0,
    );
  }, []);

  const renderInteractiveGuideDemo = useMemo(() => {
    return isEditProtocolForm || !isGuideView ? null : (
      <InteractiveGuideDemo
        type={PlayDemoTypes.POPUP}
        customClass="pop-up"
        protocol={currentProtocol}
        onClose={() => setIsPlayDemoModalOpened(false)}
      />
    );
  }, [isEditProtocolForm, currentProtocol, isGuideView]);

  const closeShareModal = useCallback(() => {
    setProtocolToShare();
    toggleShareModal();
  }, [toggleShareModal, setProtocolToShare]);

  return (
    <div className="protocol-detail-container">
      <ProtocolDetailsContext.Provider
        value={{
          toggleShareModal,
          showShareModal: isShareModalOpened,
          protocolsToShare: currentProtocol ? [currentProtocol as Protocol] : [],
          setProtocolToShare,
          submitRef: btnRef,
        }}
      >
        <ProtocolHeader
          protocol={currentProtocol}
          isEdit={isEditProtocolForm}
          isChangedForms={isChangedForms}
          onFinishForm={() => onlineAction(() => createEditProtocol(PROTOCOL_STATUSES.FINISHED))}
          onDraftForm={() => onlineAction(() => createEditProtocol(PROTOCOL_STATUSES.DRAFT))}
          onPlayGuide={handlePlay}
          onDeleteProtocol={() => onlineAction(() => setIsDeleteModalOpened(true))}
          onRejectProtocol={() => setIsRejectModalOpened(true)}
          onBackClick={handleRedirectToDashboard}
          onEdit={() => onlineAction(handleEditProtocol)}
          onFavoriteProtocol={() =>
            currentProtocol && onlineAction(() => onFavoriteProtocol(currentProtocol))
          }
          user={user}
          isMobileVersion={!isDesktop}
        />
        <div className="protocol-detail">
          <div
            className={classnames("protocol-content-wrapper", {
              full: !showSimilarProtocols,
            })}
          >
            <div className="protocol-tabs-wrapper">
              <div className="protocol-tabs">{renderTabs}</div>
              {currentProtocol ? (
                <div className="protocol-author protocol-author-desktop">
                  {createdByLabel} by {currentProtocol.created_by}
                </div>
              ) : null}
            </div>
            <div
              className={classnames("protocol-tab-content", {
                "interactive-guide": currentForm === ProtocolTabs.guide,
              })}
            >
              {renderTabContent}
              {renderInteractiveGuideDemo}
            </div>
            <div className="app-footer protocol-content-footer">
              <Footer />
            </div>
          </div>
          {showSimilarProtocols && currentProtocol ? (
            <SimilarProtocolsList
              onProtocolClick={onProtocolClick}
              similarProtocols={similarProtocols}
              onProtocolFavorite={onFavoriteProtocol}
              user={user}
            />
          ) : null}
        </div>
        <ShareProtocolModal
          closeShareModal={closeShareModal}
          isOpened={isShareModalOpened}
          onSubmit={onSubmitSharedProtocolForm}
          protocols={currentProtocol ? [currentProtocol] : []}
          toggleShareModal={toggleShareModal}
        />
        <Modal
          isShowing={isLeaveModalOpened}
          onClose={handleCloseLeaveConfirmModal}
          isSmall={true}
          showCloseIcon={false}
          heading="Leave the page"
        >
          <ModalContent
            content="Are you sure you want to leave the page? The changes will not be saved."
            onClose={handleCloseLeaveConfirmModal}
            onSuccess={handleSuccessLeaveConfirmModal}
            cancelText="Cancel"
            removeText="Leave"
          />
        </Modal>
        <Modal
          isShowing={isDeleteModalOpened}
          onClose={handleCloseDeleteConfirmModal}
          isSmall={true}
          showCloseIcon={false}
          heading="Delete Protocol"
        >
          <ModalContent
            content="Are you sure you want to delete this protocol?"
            onClose={handleCloseDeleteConfirmModal}
            onSuccess={handleDeleteProtocol}
            cancelText="Cancel"
            removeText="Delete"
          />
        </Modal>
        <Modal
          isShowing={isPlayDemoModalOpened}
          onClose={() => setIsPlayDemoModalOpened(false)}
          isLarge={isDesktop}
          isSmall={isMobileVersion}
          showCloseIcon={false}
          heading={`<div class="small">Here is how it works</div>`}
          className="guide-demo-modal"
        >
          <InteractiveGuideDemo
            type={PlayDemoTypes.MODAL}
            customClass="modal-content"
            protocol={currentProtocol}
            onClose={() => setIsPlayDemoModalOpened(false)}
          />
        </Modal>
        <Modal
          isShowing={isRejectModalOpened}
          onClose={handleCloseRejectConfirmModal}
          isSmall={true}
          showCloseIcon={false}
          heading="Reject Protocol"
        >
          <ModalContent
            content="Are you sure you want to reject this protocol?"
            onClose={handleCloseRejectConfirmModal}
            onSuccess={handleRejectProtocol}
            cancelText="Cancel"
            removeText="Reject"
          />
        </Modal>
      </ProtocolDetailsContext.Provider>
    </div>
  );
};

export default ProtocolDetailContainer;
