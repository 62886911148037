import React from "react";
import classnames from "classnames";

import "./index.scss";

interface StyledCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  checked: boolean;
  className?: string;
}

function StyledCheckbox(props: StyledCheckboxProps) {
  const { label, checked, className, ...inputProps } = props;
  return (
    <div className={classnames("styled-checkbox", className)}>
      <label className={classnames({ checked })}>
        <div className="checkmark" />
        <div className="styled-checkbox-label">{label}</div>
        <input type="checkbox" id={`${props.name}`} checked={checked} {...inputProps} />
      </label>
    </div>
  );
}

export default StyledCheckbox;
