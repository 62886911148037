import { getStepLabelText } from "@containers/Protocol/utils";
import { StyledButton } from "@shared/components";
import { EDGES } from "@shared/components/InteractiveGuide/constants";
import { IGuideStep, IGuideStepConnection, ParameterUnit } from "@shared/models";
import React, { useMemo } from "react";

import "./index.scss";

interface YesNoConditionProps {
  step: IGuideStep;
  steps: IGuideStep[];
  connections: IGuideStepConnection[];
  onHandleSubmit: (step: IGuideStep) => void;
  parameters: ParameterUnit[];
}

const YesNoCondition = (props: YesNoConditionProps) => {
  const { step, steps, connections, onHandleSubmit, parameters } = props;

  const yesStep = useMemo(() => {
    const yesConnection = connections.find((c) => c.type === EDGES.CUSTOM_EDGE_SUCCESS);
    return steps.find((s) => yesConnection?.step_target_id === s.id);
  }, [connections, steps]);

  const noStep = useMemo(() => {
    const noConnection = connections.find((c) => c.type === EDGES.CUSTOM_EDGE_FAILURE);
    return steps.find((s) => noConnection?.step_target_id === s.id);
  }, [connections, steps]);

  const stepText = useMemo(() => getStepLabelText(step, parameters), [step, parameters]);

  return (
    <div className="yes-no-condition">
      <div className="yes-no-condition-text" dangerouslySetInnerHTML={{ __html: stepText }} />
      <div className="yes-no-condition-actions">
        {noStep ? (
          <StyledButton
            label="No"
            className="styled-button-bg red styled-button-bordered customize"
            filled={true}
            color="accent"
            textColor="white"
            onClick={() => onHandleSubmit(noStep)}
          />
        ) : null}

        {yesStep ? (
          <StyledButton
            label="Yes"
            className="styled-button-bg red styled-button-bordered customize"
            filled={true}
            color="grey"
            textColor="white"
            onClick={() => onHandleSubmit(yesStep)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default YesNoCondition;
