import React, { useState, useCallback, useMemo } from "react";
import classnames from "classnames";
import { IconButton } from "@shared/components/Button/StyledButton";

import { HoverPopup } from "../";

import "./index.scss";

export interface IDropDownItem {
  label: string;
  onClick?: (value?: string) => void;
  value?: string;
  className?: string;
  disabled?: boolean;
  icon?: string;
}

export interface DropDownProps {
  list: IDropDownItem[];
  className?: string;
  openButton: React.ReactNode;
  closeButton?: React.ReactNode;
}

function DropDown(props: DropDownProps) {
  const { list, className, openButton, closeButton } = props;
  const [isOpened, setIsOpened] = useState(false);
  const onChangeOpen = useCallback((opened: boolean) => {
    setIsOpened(opened);
  }, []);

  const renderButton = useMemo(() => {
    return (
      <div className={classnames("dropdown-button", { opened: isOpened })}>
        {!isOpened ? openButton : closeButton || openButton}
      </div>
    );
  }, [isOpened, openButton, closeButton]);

  const renderMenu = useMemo(() => {
    return (
      <div className="dropdown-menu">
        {list.map((item, index) => (
          <React.Fragment key={index}>
            <div
              onClick={() => item.onClick && !item.disabled && item.onClick(item.value)}
              className={classnames("dropdown-item", item.className, { disabled: item.disabled })}
            >
              {item.icon && <IconButton className={`icon icon-button ${item.icon}`} />}
              {item.label}
            </div>
            {index !== list.length - 1 && <div className="horizontal-divider" />}
          </React.Fragment>
        ))}
      </div>
    );
  }, [list]);

  return (
    <HoverPopup
      className={classnames("dropdown-wrapper", className)}
      button={renderButton}
      onChangeOpen={onChangeOpen}
    >
      {() => renderMenu}
    </HoverPopup>
  );
}

export default DropDown;
