export const tagSchema = {
  type: "object",
  required: ["name", "id"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    description: { type: ["string", "null"] },
    is_deleted: { type: ["boolean", "null"] },
    created_at: { type: ["string"] },
    updated_at: { type: ["string", "null"] },
    created_by: { type: ["string"] },
    updated_by: { type: ["string", "null"] },
  },
  additionalProperties: false,
};

export const tagsResponseSchema = {
  type: "object",
  additionalProperties: false,
  properties: {
    count: { type: "number" },
    items: {
      type: ["array"],
      items: tagSchema,
    },
  },
};

export const tagsRequestSchema = {
  type: "object",
  properties: {
    params: {
      properties: {
        name: { type: "string" },
      },
      additionalProperties: false,
    },
  },
  additionalProperties: false,
};

export const createTagRequestSchema = {
  type: "object",
  additionalProperties: false,
  required: ["name"],
  properties: {
    name: { type: "string" },
  },
};

export const createTagResponseSchema = {
  type: "object",
  additionalProperties: false,
  required: ["message", "tag"],
  properties: {
    message: { type: "string" },
    tag: tagSchema,
  },
};
