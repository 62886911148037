import { User, FileType } from "@shared/models";
import { ProfileEditDto } from "@shared/interfaces";

import { ProfileEditShape } from "../../../interfaces";

export function getFormValues(user: User | null): ProfileEditShape {
  return {
    first_name: user ? user.first_name : "",
    last_name: user ? user.last_name : "",
    service_line: user ? user.service_line || "" : "",
    title: user ? user.title || "" : "",
    current_password: "",
    password: "",
    password_confirmation: "",
    images: {
      files: [],
      old_files: user && user.files ? user.files.filter((f) => f.type === FileType.avatar) : [],
    },
  };
}

export const prepareEditFormValues = (values: ProfileEditShape): ProfileEditDto => {
  const avatars = values.images.files.map((f) => ({
    fileName: f.name,
    base64Data: f.base64,
    type: FileType.avatar,
  }));

  const avatarsOld = values.images.old_files.map((f) => f.id);

  return {
    first_name: values.first_name,
    last_name: values.last_name,
    title: values.title,
    service_line: values.service_line,
    current_password: values.current_password,
    password: values.password,
    new_files: avatars,
    old_files: avatarsOld,
  };
};
