export enum ProtocolTabs {
  info = "info",
  guide = "guide",
}

export interface TabItem {
  title: string;
  key: ProtocolTabs;
  icon?: string | null;
}

export const Tabs: TabItem[] = [
  {
    title: "Interactive Guide",
    key: ProtocolTabs.guide,
    icon: "/icons/general/info.svg",
  },
  {
    title: "Protocol Information",
    key: ProtocolTabs.info,
    icon: null,
  },
];
