import { permissionSchema } from "./permission.schema";

export const roleSchema = {
  type: "object",
  required: ["id", "name"],
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    permissions: {
      type: "array",
      items: [permissionSchema],
    },
  },
  additionalProperties: false,
};
