import React, { useContext, useState, useCallback } from "react";
import { ProtocolContext } from "@containers/Dashboard/containers";
import { ShareProtocolModal } from "@containers/Protocol/components";
import "./index.scss";
import { useWindowDimensions } from "@shared/hooks";
import { processShareFormBeforeSubmit } from "@shared/utils/functions";

import { ShareViewActions, MobileShareViewActions } from "./";

export interface ProtocolShareViewCtxInterface {
  [key: string]: () => void;
}
export const ProtocolShareViewContext = React.createContext({} as ProtocolShareViewCtxInterface);

const ProtocolShareView = () => {
  const { isDesktop } = useWindowDimensions();
  const [share, setShareStatus] = useState<boolean>(false);
  const toggleShare = useCallback(() => setShareStatus((show) => !show), []);

  const { toggleShareModal, isShare, setProtocolToShare, protocols, submitRef, protocolsToShare } =
    useContext(ProtocolContext);

  const onSubmitSharedProtocolForm = () => {
    processShareFormBeforeSubmit();
    setTimeout(
      () =>
        submitRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true })),
      0,
    );
  };

  const handleCloseView = () => {
    setProtocolToShare();
    toggleShareModal();
  };

  const handleCloseShareView = () => {
    toggleShare();
    setProtocolToShare();
  };

  return isShare ? (
    <div className="protocol-share-view">
      <ProtocolShareViewContext.Provider
        value={{
          cancel: handleCloseView,
          unselectAll: () => setProtocolToShare(),
          selectAll: () => setProtocolToShare(protocols),
          share: toggleShare,
        }}
      >
        {!isDesktop ? (
          <MobileShareViewActions />
        ) : (
          <ShareViewActions protocolsToShare={protocolsToShare} />
        )}
      </ProtocolShareViewContext.Provider>
      <ShareProtocolModal
        isOpened={share}
        closeShareModal={handleCloseShareView}
        onSubmit={onSubmitSharedProtocolForm}
        protocols={protocolsToShare}
        toggleShareModal={toggleShareModal}
      />
    </div>
  ) : null;
};

export default ProtocolShareView;
