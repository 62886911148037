import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { RouteParams } from "@shared/interfaces";
import { ROUTE_PATHS } from "@shared/constants";
import { selectors as sharedSelectors } from "@shared/store";

import { actions } from "../../store";

export default function ActivateContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(sharedSelectors.getUser());
  const { hash } = useParams<Partial<RouteParams>>();

  useEffect(() => {
    if (hash && !user) {
      dispatch(actions.activateAccount.request({ hash }));
    } else {
      history.push(ROUTE_PATHS.PROTOCOLS_DASHBOARD);
    }
  }, [hash, dispatch, user, history]);

  return <div />;
}
