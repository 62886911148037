import React from "react";
import classnames from "classnames";
import "./index.scss";

interface ButtonProps {
  label: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset" | undefined;
  icon?: string;
  style?: string;
}

function Button(props: ButtonProps & React.HTMLProps<HTMLButtonElement>) {
  const { label, type, icon, className, ...btnProps } = props;
  return (
    <button
      className={classnames("button-general", className)}
      type={type || "button"}
      {...btnProps}
    >
      {icon && <div className={`icon-button icon ${icon}`} />}
      {label}
    </button>
  );
}

export default Button;
