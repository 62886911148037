import { useEffect, useState, useCallback } from "react";
import variables from "@styles/variables.scss";
import { PLATFORM } from "@shared/constants";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isTabletVersion, setTabletStatus] = useState(false);
  const [isMobileVersion, setMobileStatus] = useState(false);
  const [platform, setPlatform] = useState("");

  const handleResize = useCallback(() => setWindowDimensions(getWindowDimensions()), []);

  useEffect(() => {
    windowDimensions
      ? setTabletStatus(
          windowDimensions.width <= Number(variables.tabletWidth?.replace("px", "")) &&
            windowDimensions.width >= Number(variables.mobileWidth?.replace("px", "")),
        )
      : setTabletStatus(false);

    windowDimensions
      ? setMobileStatus(windowDimensions.width <= Number(variables.mobileWidth?.replace("px", "")))
      : setMobileStatus(false);
  }, [handleResize, windowDimensions]);

  useEffect(() => {
    if (!windowDimensions) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, windowDimensions]);

  useEffect(() => {
    window.addEventListener("orientationchange", handleResize, false);
    return () => window.removeEventListener("orientationchange", handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (isMobileVersion) {
      setPlatform(PLATFORM.MOBILE);
      return;
    }

    if (isTabletVersion) {
      setPlatform(PLATFORM.TABLET);
      return;
    }

    setPlatform(PLATFORM.DESKTOP);
  }, [isTabletVersion, isMobileVersion]);

  return {
    ...windowDimensions,
    isTabletVersion,
    isMobileVersion,
    isDesktop: !isTabletVersion && !isMobileVersion,
    platform,
  };
}
